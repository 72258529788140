import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { BulkChangesBarModule } from '../../components/bulk-changes-bar/bulk-changes-bar.module';
import { ButtonModule } from '../../components/button/button.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { LayoutModule } from '../layout/layout.module';
import { TagsModule } from '../tags/tags.module';
import { AccountUserManagementCreateAccountAdminButtonComponent } from './account-user-management/account-user-management-header-buttons/account-user-management-create-account-admin-button/account-user-management-create-account-admin-button.component';
import { AccountUserManagementExportComponent } from './account-user-management/account-user-management-header-buttons/account-user-management-export/account-user-management-export.component';
import { AccountUserManagementHeaderButtonsComponent } from './account-user-management/account-user-management-header-buttons/account-user-management-header-buttons.component';
import { AccountUserManagementHeaderCustomFieldComponent } from './account-user-management/account-user-management-header/account-user-management-header-custom-field/account-user-management-header-custom-field.component';
import { AccountUserManagementHeaderComponent } from './account-user-management/account-user-management-header/account-user-management-header.component';
import { AccountUserManagementComponent } from './account-user-management/account-user-management.component';
import { UserManagementSiteRowComponent } from './account-user-management/user-management-user-section/user-management-site-row/user-management-site-row.component';
import { UserManagementSitesChipComponent } from './account-user-management/user-management-user-section/user-management-sites-chip/user-management-sites-chip.component';
import { UserManagementUserOptionsDropdownComponent } from './account-user-management/user-management-user-section/user-management-user-options-dropdown/user-management-user-options-dropdown.component';
import { UserManagementUserRowComponent } from './account-user-management/user-management-user-section/user-management-user-row/user-management-user-row.component';
import { UserManagementUserSectionComponent } from './account-user-management/user-management-user-section/user-management-user-section.component';
import { UserManagementUserTwoFactorAuthenticationChipComponent } from './account-user-management/user-management-user-section/user-management-user-two-factor-authentication-chip/user-management-user-two-factor-authentication-chip.component';
import { DeleteAccountUserModalComponent } from './delete-account-user-modal/delete-account-user-modal.component';
import { UserManagementBulkChangesBarComponent } from './user-management-bulk-changes-bar/user-management-bulk-changes-bar.component';
import { UserManagementAccessLevelCellComponent } from './user-management-table-cells/user-management-access-level-cell/user-management-access-level-cell.component';
import { UserManagementActivityCellComponent } from './user-management-table-cells/user-management-activity-cell/user-management-activity-cell.component';
import { UserManagementCommentsCellComponent } from './user-management-table-cells/user-management-comments-cell/user-management-comments-cell.component';
import { UserManagementCustomFieldCellComponent } from './user-management-table-cells/user-management-custom-field-cell/user-management-custom-field-cell.component';
import { UserManagementExportCellComponent } from './user-management-table-cells/user-management-export-cell/user-management-export-cell.component';
import { UserManagementRestrictedTagsCellComponent } from './user-management-table-cells/user-management-restricted-tags-cell/user-management-restricted-tags-cell.component';
import { UserManagementSelectCellComponent } from './user-management-table-cells/user-management-select-cell/user-management-select-cell.component';
import { UserManagementSiteCellComponent } from './user-management-table-cells/user-management-site-cell/user-management-site-cell.component';
import { UserManagementTagCellComponent } from './user-management-table-cells/user-management-tag-cell/user-management-tag-cell.component';
import { UserManagementTableFooterComponent } from './user-management-table-footer/user-management-table-footer.component';
import { PlaceholdersModule } from '../placeholders/placeholders.module';

@NgModule({
  declarations: [
    AccountUserManagementComponent,
    AccountUserManagementHeaderComponent,
    UserManagementUserSectionComponent,
    UserManagementUserRowComponent,
    UserManagementSiteRowComponent,
    UserManagementSiteCellComponent,
    UserManagementAccessLevelCellComponent,
    UserManagementRestrictedTagsCellComponent,
    UserManagementSelectCellComponent,
    UserManagementExportCellComponent,
    UserManagementCommentsCellComponent,
    UserManagementActivityCellComponent,
    UserManagementTagCellComponent,
    UserManagementCustomFieldCellComponent,
    UserManagementSitesChipComponent,
    UserManagementUserOptionsDropdownComponent,
    DeleteAccountUserModalComponent,
    UserManagementBulkChangesBarComponent,
    UserManagementTableFooterComponent,
    AccountUserManagementHeaderCustomFieldComponent,
    AccountUserManagementHeaderButtonsComponent,
    AccountUserManagementExportComponent,
    UserManagementUserTwoFactorAuthenticationChipComponent,
    AccountUserManagementCreateAccountAdminButtonComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    LayoutModule,
    SpinnerModule,
    TooltipsModule,
    FocusModule,
    ImagesFallbackModule,
    SecureSrcModule,
    IconModule,
    CheckboxModule,
    ToggleModule,
    ModalModule,
    OnRenderedModule,
    BulkChangesBarModule,
    TagsModule,
    ScrollingModule,
    ButtonModule,
    SearchBoxModule,
    PlaceholdersModule,
  ],
  exports: [
    AccountUserManagementComponent,
    AccountUserManagementHeaderComponent,
    DeleteAccountUserModalComponent,
    AccountUserManagementHeaderButtonsComponent,
  ],
})
export class UserManagementModule {}
