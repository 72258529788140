import { EDashletType } from '../dashlets-enums';
import { TTranslationKey } from '../../../features/translate/types';

export const DASHLET_NAMES: Record<EDashletType, TTranslationKey> = {
  DEFECT_CREATE: 'dashlet_points_created',
  COMMENT_CREATE: 'dashlet_comments_created',
  CLOSED: 'dashlet_points_completed',
  CURRENT_STATUS: 'dashlets_live_status',
  CURRENT_PRIORITY: 'dashlets_live_priority',
  OVER_TIME_STATUS: 'dashlet_over_time_status',
  OVER_TIME_PRIORITY: 'dashlet_over_time_priority',
  HIGH: 'dashlet_points_high',
  MEDIUM: 'dashlet_points_medium',
  LOW: 'dashlet_points_low',
  ONHOLD: 'dashlet_points_on_hold',
  IN_PROGRESS: 'dashlet_points_in_progress',
  OPEN: 'dashlet_points_open',
  TO_REVIEW: 'dashlet_points_to_review',
  CANCELED: 'dashlet_points_canceled',
  DEFECT_UPDATE: 'dashlet_points_updated',
  DEFECT_DELETE: 'dashlet_points_deleted',
  ACTIVITIES: 'dashlets_activities',
};
