<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_fleet_name_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_fleet_name_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetNewItemSectionContent">
    <div class="newAsset__name">
      <header class="newAsset__nameHeader">
        {{ 'new_fleet_account_input_header' | ppTranslate }}
      </header>

      <pp-fleet-management-empty-fleet-account-select
        (ppAccountSelected)="selectAccount($event)"
        [ppDisabled]="ppAccountSelectDisabled"
        [ppAccountId]="ppAccountId"
      ></pp-fleet-management-empty-fleet-account-select>

      <header class="newAsset__nameHeader">
        {{ 'new_fleet_name_input_header' | ppTranslate }}
      </header>

      <input
        class="newAsset__nameInput"
        [(ngModel)]="ppFleetName"
        (ngModelChange)="updateFleetName($event)"
        data-test="fleetNameInput"
        [placeholder]="'new_fleet_name_input_placeholder' | ppTranslate"
      />
    </div>
  </div>
</section>
