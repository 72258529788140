import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject, of, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { UpdatePointComment } from 'src/app/project/modules/points/points.actions';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TReaction } from 'src/app/project/modules/reactions/reactions.model';
import { TPushNotification } from '../notification.model';

import { TWorkspaceUser } from '@project/view-models';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TRichTextUpdate } from 'src/app/project/components/input/rich-text/rich-text.model';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TNewComment } from 'src/app/project/data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { PointActivityService } from 'src/app/project/modules/points/point-modal/point-timeline/point-activity.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { SiteTableEventsService } from 'src/app/project/modules/site/site-table/site-table-events.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNotifications,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { ReactionsService } from '../../../services/reactions.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { NotificationsService } from '../notifications.service';
import { generateNewCommentBase64 } from '../utils/generate-new-comment';
import { ENotificationType } from './notification-type.enum';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);

@Component({
  selector: 'pp-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  ENotificationType = ENotificationType;
  ECustomFieldType = ECustomFieldType;

  @Input() ppNotificationId: string;
  @Input() ppAccountName: string;
  @Input() ppDataFetched: {
    dataFetched: boolean;
  };
  @Input() ppDateFormat: string;

  notification: TPushNotification;
  user: TWorkspaceUser;
  timeStamp: string;
  timeStampHover: string;
  workspace: TWorkspace;
  account: TAccount;
  change: string;
  iconClass = 'fas fa-align-left';
  spanClass = null;
  respondingToComment = false;
  processing = false;
  commentSent = false;
  isCustomField = false;
  notificationVersion = 1;
  activeUserId = '';
  reaction: TReaction;
  EIconPath = EIconPath;

  newComment: TNewComment = {
    comment: '',
    commentRich: '',
    mentions: [],
  };

  private respondingToCommentTimerMs = 3000;

  constructor(
    private store: Store,
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private siteTableEventsService: SiteTableEventsService,
    private router: Router,
    private activeService: ActiveService,
    private sidePanelService: SidePanelService,
    private userService: UserService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private reactionsService: ReactionsService,
  ) {}

  ngOnInit(): void {
    this.reactionsService.reactionsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getReactions();
    });
  }

  ngOnChanges(): void {
    this.notification = this.notificationsService.getNotification(this.ppNotificationId);

    if (this.notification) {
      this.user = this.usersService.getUsers()[this.notification.creatorUserId];

      this.commentSent = !!this.notification.markedAsAnswered;

      this.formatDatetime(this.notification);
      this.createNotificationDescription();

      this.getReactions();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openSite(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.ppDataFetched.dataFetched) {
      return;
    }

    if (
      (this.router.url.startsWith('/site') &&
        this.notification.workspaceId !== this.activeService.getActiveWorkspaceId()) ||
      !this.router.url.startsWith('/site')
    ) {
      this.sidePanelService.switchSite(this.notification.workspaceId);
    }

    this.sidePanelService.collapsePanel();
  }

  openPoint(event: Event): void {
    if (this.respondingToComment && event) {
      return;
    }

    if (!this.ppDataFetched.dataFetched) {
      return;
    }

    if (this.notification.pushNotificationType === ENotificationType.REACTION_COMMENT) {
      if (this.notification.reactionDetails) {
        this.siteTableEventsService.openPointFromNotification(
          this.notification.workspaceId,
          this.notification.reactionDetails.pointRef.id,
        );
      } else {
        const prompt = this.translationPipe.transform('prompt_error_opening_point');

        this.promptService.showError(prompt);
      }
    } else {
      this.siteTableEventsService.openPointFromNotification(
        this.notification.workspaceId,
        this.notification.refObject.id,
      );
    }

    this.notificationsService.changeNotificationReadStatus(this.notification.id, true);
    this.sidePanelService.collapsePanel();
  }

  comment(event: TRichTextUpdate): void {
    if (!this.commentSent) {
      this.newComment = {
        comment: event.mergedText,
        commentRich: event.richText,
        mentions: event.mentions,
      };

      if (!event?.mergedText?.trim()) {
        const promptText = this.translationPipe.transform('prompt_comment_empty_error');

        this.promptService.showError(promptText);

        return;
      } else {
        const _id = this.notification.refObject.id;

        this.store.dispatch(
          new UpdatePointComment({
            workspaceId: this.notification.workspaceId,
            _id: this.notification.refObject.id,
          }),
        );

        this.processing = true;

        this.pointsUpdateService
          .addComment(_id, this.newComment)
          .pipe(
            takeUntil(this.destroy$),
            tap(() => {
              this.commentSent = true;

              timer(this.respondingToCommentTimerMs)
                .pipe(
                  takeUntil(this.destroy$),
                  tap(() => {
                    this.respondingToComment = false;
                  }),
                )
                .subscribe();

              this.notificationsService.markNotificationAsCommented(this.notification.id);
              this.pointActivityService.refreshTimeline(this.notification.workspaceId, _id, {
                refreshComments: true,
              });
            }),
            catchError((error) => {
              const promptText = this.translationPipe.transform('prompt_comment_saved_error');

              if (error.status === EStatusCode.GONE) {
                this.translationPipe.transform('prompt_point_removed');
              }

              this.promptService.showError(promptText);

              return of();
            }),
            finalize(() => {
              this.processing = false;
            }),
          )
          .subscribe();
      }
    }
  }

  respond(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.user) {
      this.newComment.comment = '@' + this.user.userName;
      this.newComment.mentions = [this.user.userId];
      this.newComment.commentRich = generateNewCommentBase64(this.user);
    }

    this.respondingToComment = true;

    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS_RESPOND, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
    });
  }

  private getReactions(): void {
    this.reaction = this.reactionsService
      .getReactions()
      .notifications.data.find(
        (_reaction) => _reaction.targetRef.id === this.notification.changeBody?.commentId,
      );
  }

  private formatDatetime(notification: TPushNotification): void {
    const date = dayjs(notification.timestampEpochMillis);

    if (date.isAfter(dayjs().subtract(1, 'm'))) {
      this.timeStamp = this.translationPipe.transform('just_now');
    } else if (date.isBetween(dayjs().subtract(7, 'd'), dayjs())) {
      this.timeStamp = date.fromNow();
    } else {
      this.timeStamp = transformDate(date, '', this.ppDateFormat);
    }

    this.timeStampHover = transformDate(date, ' [at] hh:mm a', this.ppDateFormat);
  }

  private createNotificationDescription(): void {
    if (this.notification.changeBody) {
      this.notificationVersion = 2;
    } else {
      this.notificationVersion = 1;
    }

    switch (this.notification.pushNotificationType) {
      case ENotificationType.POINT_EDITION_CUSTOM_FIELDS:
        this.isCustomField = this.notificationVersion === 2;

        break;
      case ENotificationType.POINT_EDITION_ASSIGNEE:
        this.activeUserId = this.userService.getUser().userId;

        if (this.notification.changeBody.length) {
          if (this.notification.changeBody[0]) {
            this.notificationVersion = 1;
          }
        }

        break;
      case ENotificationType.POINT_MENTION_CUSTOM_FIELD:
        if (this.notificationVersion === 2) {
          this.isCustomField = true;
        }

        break;
      case ENotificationType.POINT_MENTION_NEW_POINT:
      case ENotificationType.POINT_CREATION_ASSIGNEE:
        this.notificationVersion = 2;

        break;

      case ENotificationType.REACTION_COMMENT:
        this.notificationVersion = 2;

        if (!this.notification.reactionDetails) {
          logErrorInSentry(
            new Error(`Notification reaction details missing for ID ${this.notification.id}`),
          );
        }

        break;

      default:
        break;
    }
  }
}
