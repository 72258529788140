<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_asset_name_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_asset_name_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetNewItemSectionContent">
    <div class="newAsset__name">
      <header class="newAsset__nameHeader">
        {{ 'new_asset_name_input_header' | ppTranslate }}
      </header>

      <input
        class="newAsset__nameInput"
        [(ngModel)]="assetName"
        (ngModelChange)="updateAssetName()"
        data-test="assetNameInput"
        [placeholder]="'new_asset_name_placeholder' | ppTranslate"
      />
    </div>

    <div class="newAsset__image">
      <div class="newAsset__imageBox">
        <div *ngIf="uploadedImage || imageUrl" class="newAssetImageOptions">
          <section class="newAsset__imageBoxButtons">
            <button class="newAsset__imageBoxButton" (click)="downloadImage()">
              <pp-icon
                [ppSrc]="EIconPath.ICON_MISC_DOWNLOAD_24"
                ppClass="setSize24"
                ppColor="grey-500"
              >
              </pp-icon>
            </button>

            <button class="newAsset__imageBoxButton" (click)="removeImage()">
              <pp-icon [ppSrc]="EIconPath.ICON_BIN_24" ppClass="setSize24" ppColor="grey-500">
              </pp-icon>
            </button>
          </section>
        </div>

        <label class="newAsset__imageBoxContent" for="assetImageUploadButton">
          <ng-container *ngIf="!uploadedImage && !imageUrl">
            <pp-icon
              [ppSrc]="EIconPath.ICON_MISC_PUBLISH"
              ppColor="inherit"
              [ppDisplayContents]="true"
              class="newAsset__imageBoxImage"
            ></pp-icon>

            {{ 'new_asset_image_input_description' | ppTranslate }}
          </ng-container>

          <ng-container *ngIf="!uploadedImage && imageUrl">
            <img ppImage class="newAsset__imagePreview" [ppSecureSrc]="imageUrl" />
          </ng-container>

          <ng-container *ngIf="uploadedImage">
            <img ppImage class="newAsset__imagePreview" [src]="uploadedImage" />
          </ng-container>
        </label>

        <pp-upload
          data-test="uploadImageDragDropZone"
          id="assetImageUpload"
          ppId="assetImageUploadButton"
          ppAccept=".jpg, .jpeg, .png, .bmp"
          (ppUpload)="setLogo($event)"
        >
        </pp-upload>
      </div>

      <div class="newAsset__imageFooter">
        <pp-icon
          [ppSrc]="EIconPath.ICON_BADGE_INFO"
          ppClass="setSize14"
          ppColor="grey-700"
        ></pp-icon>

        {{ 'new_asset_image_input_footer' | ppTranslate }}
      </div>
    </div>
  </div>
</section>
