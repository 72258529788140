import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'src/app/project/modules/user/user.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { ActiveService } from '../../../services/active/active.service';
import { ClearService } from '../../../services/clear.service';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { AuthService } from '../auth.service';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DeviceService } from '../../../../core/services/device.service';
import { TAccount } from '../../account/account.model';

import { Title } from '@angular/platform-browser';
import { TAccountSummaryResponse } from '@project/view-models';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EAuthRoute } from 'src/app/project/shared/constants/auth.constants';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { ResponseErrorService } from '../../errors/response-error.service';
import { UpdateService } from '../../offline/update.service';
import { TwoFactorAuthenticationService } from '../2fa/two-factor-authentication.service';
import { TPasswordInputVisibilityType } from '../auth.types';
import { LoginRedirectService } from '../login-redirect.service';
import { LogoutService } from '../logout.service';
import { PasswordResetService } from '../password-reset/password-reset.service';

@Component({
  selector: 'pp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  email = '';
  password = '';
  rememberMe = false;
  processing = false;
  passInputType: TPasswordInputVisibilityType;
  private pageTitle = 'Pinpoint Works';

  isiPhone: boolean = this.deviceService.isiPhone();
  isiPod: boolean = this.deviceService.isiPod();
  isiPad: boolean = this.deviceService.isiPad();

  private accounts$: Observable<TAccount[]>;
  private readonly destroy$ = new Subject<void>();
  EIconPath = EIconPath;

  constructor(
    private store: Store<{
      accounts: TAccount[];
    }>,
    private router: Router,
    private authService: AuthService,
    private logoutService: LogoutService,
    private promptService: PromptService,
    private workspaceService: WorkspaceService,
    private whiteLabelService: WhiteLabelService,
    private clearService: ClearService,
    private activeService: ActiveService,
    private deviceService: DeviceService,
    private translationPipe: TranslationPipe,
    private userService: UserService,
    private titleService: Title,
    private updateService: UpdateService,
    private passwordResetService: PasswordResetService,
    private responseErrorService: ResponseErrorService,
    private twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private loginRedirectService: LoginRedirectService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.pageTitle);
    this.whiteLabelService.setDefaultStyle();

    this.checkIfIsLoggingOut();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  login(): void {
    this.processing = true;
    this.clearService.clearAllData();
    this.trySetRememberMeForMobiles();

    this.authService
      .login(this.email, this.password, this.rememberMe)
      .pipe(
        switchMap(() => {
          return this.handleLoginSuccess();
        }),
        catchError((error) => {
          return this.handleLoginError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  onRememberMeChecked(): void {
    this.rememberMe = !this.rememberMe;
  }

  onKeyDownEnter(event: Event): void {
    blurInput(event.target);

    if (!this.processing) {
      this.login();
    }
  }

  forgotPassword(): void {
    this.passwordResetService.setEmail(this.email);
    this.router.navigate([EAuthRoute.RESET_PASSWORD]);
  }

  private trySetRememberMeForMobiles(): void {
    if (this.isiPhone || this.isiPod || this.isiPad) {
      this.rememberMe = true;
    }
  }

  private checkIfIsLoggingOut(): void {
    const isLoggingOut = this.logoutService.getIsLoggingOut();

    if (isLoggingOut) {
      this.logoutService.setIsLoggingOutComplete();
    } else {
      this.checkActiveAuth();
    }
  }

  private checkActiveAuth(): void {
    // TODO: need product spec for navigating to '/'; rethink if it's needed; rethink logic
    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      const accountId = this.activeService.getActiveAccountId();

      if (accountId) {
        this.router.navigate(['/']);
      }
    });

    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const user = this.userService.getUser();

          // TODO: need product spec for what to do when there's no active workspace for this scenario
          if (user.activeWorkspaceId) {
            this.router.navigate(['/site', user.activeWorkspaceId]);
          }
        }),
      )
      .subscribe();
  }

  private handleLoginSuccess(): Observable<TAccountSummaryResponse[]> {
    this.updateService.checkForUpdateAfterLogin();

    return this.workspaceService.fetchWorkspaces().pipe(
      tap(() => {
        this.userService
          .fetchUser()
          .pipe(
            tap(() => {
              this.handleRedirect();
            }),
          )
          .subscribe();
      }),
    );
  }

  private handleRedirect(): void {
    const user = this.userService.getUser();
    const isSetup2FALaterExpired = !user.setup2faAfter || user.setup2faAfter < Date.now();

    if (!user.enabled2fa && isSetup2FALaterExpired) {
      this.router.navigate([routeToUrl(EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP)]);
    }

    if (!user.enabled2fa && !isSetup2FALaterExpired) {
      this.loginRedirectService.redirectAfterLogin();
    }
  }

  private handleLoginError(error: any) {
    if (error.status === EStatusCode.FORBIDDEN) {
      this.twoFactorAuthenticationService.handleLoginError(error);
    } else {
      error.email = this.email;
      logErrorInSentry(error);
      this.showErrorPrompt(error.status);
    }

    this.responseErrorService.errors(error.status);

    return throwError(error);
  }

  private showErrorPrompt(statusCode: EStatusCode): void {
    this.promptService.showError(this.getErrorPromptText(statusCode), {
      duration: this.getErrorPromptDurationSeconds(statusCode),
      left: true,
    });
  }

  private getErrorPromptText(statusCode: EStatusCode): string {
    switch (statusCode) {
      case EStatusCode.UNAUTHORIZED:
        return this.translationPipe.transform('prompt_incorrect_credentials');
      case EStatusCode.NO_RESPONSE:
        return this.translationPipe.transform('prompt_internet_connection');
      case EStatusCode.GATEWAY_TIMEOUT:
        return this.translationPipe.transform('prompt_server_error');
      default:
        return this.translationPipe.transform('prompt_error');
    }
  }

  private getErrorPromptDurationSeconds(statusCode: EStatusCode): number {
    switch (statusCode) {
      case EStatusCode.GATEWAY_TIMEOUT:
        return 30;
      default:
        return 5;
    }
  }
}
