<pp-modal [ppScrollOnDefault]="modal.data.customField.type === customFieldTypes.LIST">
  <ng-container heading> {{ 'edit_custom_field' | ppTranslate }} </ng-container>

  <ng-container body>
    <div>
      <pp-edit-custom-field-details
        [(ppCustomField)]="editedCustomField"
        (ppListError)="updateListError($event)"
      ></pp-edit-custom-field-details>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!editedCustomField.label"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
