import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { createAutonumeric } from 'src/app/core/helpers/create-autonumeric';

@Component({
  selector: 'pp-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ppCanEdit: boolean;
  @Input() ppShowHoursOnly: boolean;
  @Output() ppOnModelChange = new EventEmitter<void>();
  @Output() ppUpdate = new EventEmitter<number>();
  @Input() ppValue: number;
  @Output() ppValueChange = new EventEmitter<number>();
  @Input() ppWidth = '4.5rem';
  @Input() ppClearObservable: Subject<void>;

  @ViewChild('daysInput', { static: false }) daysInputElement: ElementRef;
  @ViewChild('hoursInput', { static: false }) hoursInputElement: ElementRef;
  @ViewChild('minutesInput', { static: false }) minutesInputElement: ElementRef;
  private readonly destroy$ = new Subject<void>();

  inputDays: string = null;
  inputHours: string = null;
  inputMinutes: string = null;

  dayDurationInMs = 8.64e7;
  hourDurationInMs = 3.6e6;
  minuteDurationInMs = 60000;

  ngOnInit(): void {
    if (!this.ppValue) {
      this.inputDays = null;
      this.inputHours = null;
      this.inputMinutes = null;
    } else {
      const days = this.ppShowHoursOnly ? 0 : Math.floor(this.ppValue / this.dayDurationInMs);
      const hours = Math.floor(
        (this.ppValue - days * this.dayDurationInMs) / this.hourDurationInMs,
      );
      const minutes = Math.floor(
        (this.ppValue - days * this.dayDurationInMs - hours * this.hourDurationInMs) /
          this.minuteDurationInMs,
      );

      this.inputDays = days ? days.toString() : null;
      this.inputHours = hours ? hours.toString() : null;
      this.inputMinutes = minutes ? minutes.toString() : null;
    }

    if (this.ppClearObservable) {
      this.ppClearObservable.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.inputDays = null;
        this.inputHours = null;
        this.inputMinutes = null;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    const autonumericOptions = {
      watchExternalChanges: true,
      modifyValueOnWheel: false,
      allowDecimalPadding: false,
    };

    if (this.daysInputElement) {
      createAutonumeric(this.daysInputElement.nativeElement, autonumericOptions);
    }

    if (this.hoursInputElement) {
      createAutonumeric(this.hoursInputElement.nativeElement, autonumericOptions);
    }
    if (this.minutesInputElement) {
      createAutonumeric(this.minutesInputElement.nativeElement, autonumericOptions);
    }
  }

  onModelChange(): void {
    this.ppOnModelChange.emit();
    this.updateFieldValue();
  }

  updateField(): void {
    this.minutesInputElement.nativeElement.blur();
    this.hoursInputElement.nativeElement.blur();

    if (this.daysInputElement) {
      this.daysInputElement.nativeElement.blur();
    }

    this.updateFieldValue();

    this.inputMinutes = null;
    this.inputHours = null;
    this.inputDays = null;
  }

  updateFieldValue(): void {
    const days = this.inputDays ? +this.inputDays.replace(/,/g, '') : 0;
    const hours = this.inputHours ? +this.inputHours.replace(/,/g, '') : 0;
    const minutes = this.inputMinutes ? +this.inputMinutes.replace(/,/g, '') : 0;

    const valueToSend =
      hours * this.hourDurationInMs +
      minutes * this.minuteDurationInMs +
      days * this.dayDurationInMs;

    this.ppValue = valueToSend;
    this.ppValueChange.emit(valueToSend);

    this.ppUpdate.emit(valueToSend);
  }
}
