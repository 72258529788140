import { createElement } from 'src/app/core/helpers/dom';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';
import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';

export function createRichTextElement(
  _index: number,
  _column: TColumn,
  _customField: TCustomFieldResponse<string>,
): HTMLElement {
  let value = generatePlainText(decodeBase64Text(_customField.value));

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = '';
  }

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(value)],
    }),
  });
}
