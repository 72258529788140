import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TDashlet } from '../preferences/preferences.model';
import { addGraphDatasetLabels } from './dashboard-chart/add-graph-dataset-labels';
import { DashboardChartService } from './dashboard-chart/dashboard-chart.service';
import { TExportDashlet } from './dashboard-export-modal/dashboard-export-dashlet.model';
import { DashboardService } from './dashboard-service/dashboard.service';
import { EDashletPeriod } from './dashboard-timeframes-enums';
import { TChartSettings } from './dashboard.model';
import { TDashletGraph } from './dashlet-graph.model';
import { EDashletType } from './dashlets-enums';
import { EReportType } from './report-types-enum';

@Injectable({
  providedIn: 'root',
})
export class DashboardReportService {
  constructor(
    private dashboardService: DashboardService,
    private dashboardChartService: DashboardChartService,
  ) {}

  fetchCurrentTypeCount(
    dashlet: TDashlet | TExportDashlet,
    graph: TDashletGraph,
    ctx: HTMLCanvasElement,
  ): Observable<TChartSettings> {
    const type =
      dashlet.name === EDashletType.CURRENT_STATUS || dashlet.name === EDashletType.OVER_TIME_STATUS
        ? 'status'
        : 'priority';

    return this.dashboardService
      .fetchCurrentTypeCount({
        type,
        accountId: dashlet.selectedRange.accountId,
        workspaceId: dashlet.selectedRange.workspaceId,
      })
      .pipe(
        map((response) => {
          const totalPoints = response.reduce((a, b) => a + b, 0);
          const context = ctx.getContext('2d');

          graph.data.datasets[0].data = response;
          graph.data.labels = this.dashboardChartService.addGraphLabels(type, response);

          graph.options = { ...graph.options, animation: false, responsive: true };

          const chart = new Chart(context, graph);

          return {
            chart,
            totalPoints,
          };
        }),
      );
  }

  fetchExportReports(
    dashlet: TExportDashlet,
    initialGraph: TDashletGraph,
    ctx: HTMLCanvasElement,
  ): Observable<TChartSettings> {
    return this.fetchReports(dashlet, initialGraph).pipe(
      map(({ graph, totalPoints, response }) => {
        const context = ctx.getContext('2d');
        const type =
          dashlet.name === EDashletType.OVER_TIME_STATUS
            ? EReportType.STATUS
            : EReportType.PRIORITY;
        addGraphDatasetLabels(graph, type, response);

        graph.options = { ...graph.options, animation: false, responsive: true };
        const chart = new Chart(context, graph);

        return {
          chart,
          totalPoints,
        };
      }),
    );
  }

  fetchReports(
    dashlet: TDashlet | TExportDashlet,
    graph: TDashletGraph,
  ): Observable<{
    graph: TDashletGraph;
    totalPoints: number;
    response: number[][];
  }> {
    const length = 5;
    const period: string = dashlet.period.toLowerCase() || 'week';
    const capitalizedPeriodWord = period.charAt(0).toUpperCase() + period.slice(1);

    const labelArray = this.dashboardChartService.createChartLabels(
      capitalizedPeriodWord,
      length,
      dashlet.period,
    );

    const type =
      dashlet.name === EDashletType.CURRENT_STATUS || dashlet.name === EDashletType.OVER_TIME_STATUS
        ? EReportType.STATUS
        : EReportType.PRIORITY;

    return this.dashboardService
      .fetchReports({
        type,
        period,
        length,
        accountId: dashlet.selectedRange.accountId,
        workspaceId: dashlet.selectedRange.workspaceId,
      })
      .pipe(
        map((response) => {
          let addFixedStep = false;

          const revertedResponse: number[][] = [];

          response.forEach((data) => {
            revertedResponse.push(cloneDeep(data).reverse());
          });

          revertedResponse.forEach((data, index) => {
            if (graph.data.datasets[index]) {
              graph.data.datasets[index].data = data;
            }
          });

          this.setGraphFixedStep(revertedResponse, graph, addFixedStep);
          this.setGraphData(graph, type, revertedResponse, period, labelArray);

          let totalPoints = 0;

          response.forEach((option) => {
            totalPoints += option[0];
          });

          return {
            graph,
            totalPoints,
            response,
          };
        }),
      );
  }

  setGraphFixedStep(dataList: number[][], graph: TDashletGraph, addFixedStep: boolean): void {
    dataList.forEach((data, index) => {
      if (graph.data.datasets[index]) {
        graph.data.datasets[index].data = data;
      }
    });
  }

  setGraphData(
    graph: TDashletGraph,
    type: EReportType,
    response: number[][],
    period: string,
    labelArray: string[],
  ): void {
    this.dashboardChartService.addGraphDatasetLabels(graph, type, response);

    graph.period = period.toUpperCase() as EDashletPeriod;
    graph.data.labels = labelArray;
  }
}
