import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ECustomFieldType } from '../../../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../../../custom-fields/custom-fields.store';
import { TColumn } from '../../../../site/site-table/columns/column.model';
import { GET_COLUMNS } from '../../../../site/site-table/columns/columns.store';
import { EAdvancedFilterType } from '../../../models/advanced-filter-type.enum';

export function getAdvancedFilterFields(
  columns: TColumn[],
  workspace: TWorkspace,
): TSelectOption[] {
  const sitePlanExists = workspace.siteImageRef?.id ? true : false;

  const fields = columns.map((column) => ({
    value: column.name,
    label: column.name,
    iconSrc: searchNewValue(column.name, workspace.workspaceId).iconSrc,
  }));

  if (sitePlanExists) {
    fields.unshift({
      value: EAdvancedFilterType.LOCATION,
      label: translate('location'),
      iconSrc: EIconPath.ICON_ACTIVITIES_LOCATION_14,
    });
  }

  fields.unshift({
    value: EAdvancedFilterType.REMINDER,
    label: translate('reminders'),
    iconSrc: EIconPath.ICON_FIELDS_DATE_14,
  });

  return fields;
}

export function getFilterType(customFieldType: ECustomFieldType): {
  type: EAdvancedFilterType;
  iconSrc: EIconPath;
} {
  switch (customFieldType) {
    case ECustomFieldType.TEXT:
      return { type: EAdvancedFilterType.TEXT, iconSrc: EIconPath.ICON_FIELDS_TEXT_14 };
    case ECustomFieldType.LIST:
      return { type: EAdvancedFilterType.LIST, iconSrc: EIconPath.ICON_FIELDS_LIST_14 };
    case ECustomFieldType.DATE:
      return { type: EAdvancedFilterType.DATE, iconSrc: EIconPath.ICON_FIELDS_DATE_14 };
    case ECustomFieldType.COST:
      return { type: EAdvancedFilterType.COST, iconSrc: EIconPath.ICON_FIELDS_COST_14 };
    case ECustomFieldType.NUMBERS:
      return { type: EAdvancedFilterType.NUMBER, iconSrc: EIconPath.ICON_FIELDS_NUMBERS_14 };
    case ECustomFieldType.RICHTEXT:
      return { type: EAdvancedFilterType.RICH_TEXT, iconSrc: EIconPath.ICON_FIELDS_RICH_TEXT_14 };
    case ECustomFieldType.TIME:
      return { type: EAdvancedFilterType.DURATION, iconSrc: EIconPath.ICON_FIELDS_TIME_14 };
    case ECustomFieldType.PERCENTAGE:
      return { type: EAdvancedFilterType.PERCENTAGE, iconSrc: EIconPath.ICON_FIELDS_PERCENTAGE_14 };
    case ECustomFieldType.CHECKBOX:
      return { type: EAdvancedFilterType.CHECKBOX, iconSrc: EIconPath.ICON_FIELDS_CHECKBOX_14 };
    case ECustomFieldType.TIMELINE:
      return { type: EAdvancedFilterType.TIMELINE, iconSrc: EIconPath.ICON_FIELDS_TIMELINE_14 };
    default:
      return null;
  }
}

export function filterIdToColumn(id: string): TColumn {
  const columns = GET_COLUMNS();

  switch (id) {
    case null:
    case undefined:
      return null;
    case EBasicField.PRIORITY:
      return columns.find((column) => column.name === EBasicField.PRIORITY);
    case EBasicField.STATUS:
      return columns.find((column) => column.name === EBasicField.STATUS);
    case EBasicField.ASSIGNEES:
      return columns.find((column) => column.name === EBasicField.ASSIGNEES);
    case EBasicField.TITLE:
      return columns.find((column) => column.name === EBasicField.TITLE);
    case EBasicField.DESCRIPTION:
      return columns.find((column) => column.name === EBasicField.DESCRIPTION);
    case EBasicField.CREATED:
      return columns.find((column) => column.name === EBasicField.CREATED);
    case EBasicField.UPDATED:
      return columns.find((column) => column.name === EBasicField.UPDATED);
    case EBasicField.CREATED_BY:
      return columns.find((column) => column.name === EBasicField.CREATED_BY);
    case EBasicField.TAGS:
      return columns.find((column) => column.name === EBasicField.TAGS);
    case EBasicField.SEQUENCE_NUMBER:
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return columns.find((column) => column.name === EBasicFieldShort.SEQUENCE_NUMBER);

    case EAdvancedFilterType.LOCATION:
      return {
        name: EAdvancedFilterType.LOCATION,
      };
    case EAdvancedFilterType.REMINDER:
      return {
        name: EAdvancedFilterType.REMINDER,
      };
    case EAdvancedFilterType.FLAGGED:
      return columns.find((column) => column.name === EBasicFieldShort.FLAGGED);
    default: {
      const customFields = GET_CUSTOM_FIELDS();
      const field = Object.values(customFields).find((item) => item.id === id);

      return columns.find((column) => column.name === field.label);
    }
  }
}

export function searchNewValue(
  newValue: string,
  workspaceId: string,
): {
  id: EBasicField | string;
  type: EAdvancedFilterType;
  iconSrc: EIconPath;
} {
  switch (newValue) {
    case EBasicField.PRIORITY:
      return {
        id: EBasicField.PRIORITY,
        type: EAdvancedFilterType.PRIORITY,
        iconSrc: EIconPath.ICON_ACTIVITIES_PRIORITY_14,
      };
    case EBasicField.STATUS:
      return {
        id: EBasicField.STATUS,
        type: EAdvancedFilterType.STATUS,
        iconSrc: EIconPath.ICON_ACTIVITIES_STATUS_14,
      };
    case EBasicField.TITLE:
      return {
        id: EBasicField.TITLE,
        type: EAdvancedFilterType.TEXT,
        iconSrc: EIconPath.ICON_FIELDS_TEXT_14,
      };
    case EBasicField.DESCRIPTION:
      return {
        id: EBasicField.DESCRIPTION,
        type: EAdvancedFilterType.TEXT,
        iconSrc: EIconPath.ICON_FIELDS_TEXT_14,
      };
    case EBasicField.CREATED:
      return {
        id: EBasicField.CREATED,
        type: EAdvancedFilterType.DATE_CREATED,
        iconSrc: EIconPath.ICON_FIELDS_DATE_14,
      };
    case EBasicField.SEQUENCE_NUMBER:
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return {
        id: EBasicField.SEQUENCE_NUMBER,
        type: EAdvancedFilterType.SEQUENCE_NUMBER,
        iconSrc: EIconPath.ICON_FIELDS_NUMBERS_14,
      };
    case EBasicField.UPDATED:
      return {
        id: EBasicField.UPDATED,
        type: EAdvancedFilterType.DATE_UPDATED,
        iconSrc: EIconPath.ICON_FIELDS_DATE_14,
      };
    case EBasicField.CREATED_BY:
      return {
        id: EBasicField.CREATED_BY,
        type: EAdvancedFilterType.CREATED_BY,
        iconSrc: EIconPath.ICON_ACTIVITIES_USER_14,
      };
    case EBasicField.ASSIGNEES:
      return {
        id: EBasicField.ASSIGNEES,
        type: EAdvancedFilterType.ASSIGNEE,
        iconSrc: EIconPath.ICON_ACTIVITIES_USER_14,
      };
    case EBasicField.TAGS:
      return {
        id: EBasicField.TAGS,
        type: EAdvancedFilterType.TAG,
        iconSrc: EIconPath.ICON_ACTIVITIES_TAGS_14,
      };
    case EAdvancedFilterType.LOCATION:
      return {
        id: EAdvancedFilterType.LOCATION,
        type: EAdvancedFilterType.LOCATION,
        iconSrc: EIconPath.ICON_ACTIVITIES_LOCATION_14,
      };
    case EAdvancedFilterType.REMINDER:
      return {
        id: EAdvancedFilterType.REMINDER,
        type: EAdvancedFilterType.REMINDER,
        iconSrc: EIconPath.ICON_FIELDS_DATE_14,
      };
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return {
        id: EAdvancedFilterType.FLAGGED,
        type: EAdvancedFilterType.FLAGGED,
        iconSrc: EIconPath.ICON_ACTIVITIES_RED_FLAG_14,
      };
    default: {
      const customFields = GET_CUSTOM_FIELDS();

      const field = Object.values(customFields).find(
        (item) => item.label === newValue && item.workspaceId === workspaceId,
      );
      const { type, iconSrc } = getFilterType(field.type as ECustomFieldType);

      return {
        id: field.id,
        type,
        iconSrc,
      };
    }
  }
}
