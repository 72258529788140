import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';
import { SortingService } from '@core/helpers';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';

import { TAllFilters, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TPointsByWorkspace } from 'src/app/project/modules/points/points.model';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TReminder } from '../../reminder.model';
import { TGroupedReminders, TReminderList } from './reminder-list.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { RemindersService } from '../../reminders.service';

import { GET_POINT } from 'src/app/project/modules/points/points.store';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';
import { getVisiblePoints } from '../../../points/visible-points';
import { SortRemindersService } from './sort-reminders.service';
import { getInitialReminders } from './utils/get-initial-reminders';
import { pushFutureReminder } from './utils/push-future-reminders';
import { pushPastReminders } from './utils/push-past-reminders';
import { pushTodayReminder } from './utils/push-today-reminders';

@Injectable({
  providedIn: 'root',
})
export class ReminderListService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private siteFilters$ = new Observable<TAllFilters>();
  private filters: TFilters;

  private points$: Observable<TPointsByWorkspace>;
  private points: TPointsByWorkspace;

  constructor(
    private store: Store<{
      points: TPointsByWorkspace;
      siteFilter: TAllFilters;
    }>,

    private remindersService: RemindersService,
    private sortingService: SortingService,
    private workspaceService: WorkspaceService,
    private sortRemindersService: SortRemindersService,
    private sitePointFilterService: SitePointFilterService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.points$ = this.store.pipe(select(EStore.POINTS));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.filters = allFilters?.[EWorkspaces.REMINDERS];

      this.sitePointFilterService.filterPoints(true);
    });

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => {
      this.points = points;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateReminders(
    oldPastReminders: TReminderList,
    oldTodayReminders: TReminderList,
    oldFutureReminders: TReminderList,
  ): {
    groupedReminders: TGroupedReminders;
    pastReminders: TReminderList;
    todayReminders: TReminderList;
    futureReminders: TReminderList;
  } {
    const preferences = GET_PREFERENCES();
    let reminders = cloneDeep(this.remindersService.getReminders());
    const today = new Date();
    const sortedReminders: string[] = [];

    today.setHours(0, 0, 0, 0);

    const groupedReminders = {};

    reminders.sort((a, b) =>
      this.sortingService.naturalSort(a.timestampEpochMillis, b.timestampEpochMillis),
    );

    let {
      futureReminders,
      todayReminders,
      pastReminders,
    }: {
      futureReminders: TReminderList;
      todayReminders: TReminderList;
      pastReminders: TReminderList;
    } = getInitialReminders(oldPastReminders, oldTodayReminders, oldFutureReminders);

    reminders = this.filterReminders(reminders);

    const updatedTodayFutureRReminders = this.pushTodayFutureReminders(
      reminders,
      groupedReminders,
      preferences,
      today,
      futureReminders,
      todayReminders,
    );

    todayReminders = updatedTodayFutureRReminders.todayReminders;
    futureReminders = updatedTodayFutureRReminders.futureReminders;

    pastReminders = pushPastReminders(reminders, preferences, today, pastReminders);

    this.sortRemindersService.sortReminders(
      pastReminders,
      todayReminders,
      futureReminders,
      groupedReminders,
    );

    todayReminders.values = todayReminders.values.filter((group) => group.values.length > 0);
    pastReminders.values = pastReminders.values.filter((group) => group.values.length > 0);
    futureReminders.values = futureReminders.values.filter((group) => group.values.length > 0);

    this.setSortedReminders(pastReminders, sortedReminders, todayReminders, futureReminders);

    return {
      groupedReminders,
      pastReminders,
      todayReminders,
      futureReminders,
    };
  }

  private pushTodayFutureReminders(
    reminders: TReminder[],
    groupedReminders: TGroupedReminders,
    preferences: TPreferences,
    today: Date,
    futureReminders: TReminderList,
    todayReminders: TReminderList,
  ): {
    todayReminders: TReminderList;
    futureReminders: TReminderList;
  } {
    reminders.forEach((reminder) => {
      const reminderDate = new Date(+reminder.timestampEpochMillis);
      reminderDate.setHours(0, 0, 0, 0);
      groupedReminders[reminder.reminderId] = reminder;

      if (reminder.refObject?.id) {
        const point = GET_POINT(reminder.refObject.id);

        groupedReminders[reminder.reminderId].point = point;
      }

      const reminderDateFormatted = transformDate(
        reminder.timestampEpochMillis,
        '',
        preferences.dateFormat,
      );

      if (reminderDate.getTime() > today.getTime()) {
        futureReminders = pushFutureReminder(
          futureReminders,
          reminderDateFormatted,
          reminderDate,
          reminder,
        );
      } else if (!(reminderDate.getTime() < today.getTime())) {
        todayReminders = pushTodayReminder(
          todayReminders,
          reminderDateFormatted,
          reminderDate,
          reminder,
        );
      }
    });

    return {
      todayReminders,
      futureReminders,
    };
  }

  private setSortedReminders(
    pastReminders: TReminderList,
    sortedReminders: string[],
    todayReminders: TReminderList,
    futureReminders: TReminderList,
  ): void {
    pastReminders.values.forEach((group) => {
      group.values.forEach((value) => {
        sortedReminders.push(value);
      });
    });

    todayReminders.values.forEach((group) => {
      group.values.forEach((value) => {
        sortedReminders.push(value);
      });
    });

    futureReminders.values.forEach((group) => {
      group.values.forEach((value) => {
        sortedReminders.push(value);
      });
    });

    this.remindersService.setSortedReminders(sortedReminders);
  }

  private filterReminders(reminders: TReminder[]): TReminder[] {
    const workspaces = this.workspaceService.getWorkspaces();

    if (reminders) {
      return reminders.filter((reminder) => {
        if (this.filters && this.filters.reminders) {
          if (this.filters.reminders.startDate) {
            if (reminder.timestampEpochMillis < this.filters.reminders.startDate) {
              return false;
            }
          }

          if (this.filters.reminders.endDate) {
            if (reminder.timestampEpochMillis > this.filters.reminders.endDate) {
              return false;
            }
          }
        }

        if (!reminder.refObject) {
          return true;
        } else {
          const workspaceExists = workspaces[reminder.workspaceId];
          const pointsInWorkspace = this.points[reminder.workspaceId];
          const visiblePointsIds = getVisiblePoints();

          let pointVisible = false;

          if (pointsInWorkspace) {
            const point = pointsInWorkspace.entities.find(
              (pointInWorkspace) => pointInWorkspace._id === reminder.refObject.id,
            );

            if (point && visiblePointsIds.includes(point._id)) {
              pointVisible = true;
            }
          }

          return workspaceExists && pointVisible;
        }
      });
    } else {
      return [];
    }
  }
}
