import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { CustomFieldsService } from '../../custom-fields.service';

import { humanizedTypeNames } from '../../custom-fields.data';
import { ECustomFieldType } from '../../custom-field-types-enums';
import { EImportCustomFieldStep } from './import-cf-steps.enum';
import { TSelectedCustomFields } from '../add-custom-field.model';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { EStore } from '../../../../shared/enums/store.enum';
import { TGuid } from '@core/helpers';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-add-custom-field-modal-import',
  templateUrl: './add-custom-field-modal-import.component.html',
  styleUrls: ['./add-custom-field-modal-import.component.scss'],
})
export class AddCustomFieldModalImportComponent implements OnInit, OnDestroy {
  // FIXME Dataflow violation
  @Input() ppSelectedCustomFields: TSelectedCustomFields;
  @Input() ppProcessingImport: boolean;
  @Input() ppTimelineExists: boolean;
  @Input() ppTimelineEnabled: boolean;

  @Input() ppTotalSelectedFields: {
    number: number;
  };

  accounts: TAccount[];
  data: TGuid[] = [];
  site: TWorkspace;
  customFields = this.customFieldsService.getCustomFields();
  customFieldTypes = ECustomFieldType;

  activeStep = EImportCustomFieldStep.SITES;
  initialWorkspaceCustomFields: string[] = [];
  selectedCustomFieldsNames: {
    [fieldName: string]: string;
  } = {};
  customFieldsPerAccount: {
    [accountId: string]: number;
  } = {};
  customFieldsPerWorkspace: {
    [workspaceId: string]: number;
  } = {};
  numberOfSelectableFields = 0;

  humanizedTypeNames = humanizedTypeNames;
  EImportCustomFieldStep = EImportCustomFieldStep;
  EIconPath = EIconPath;

  private workspaces: TWorkspacesById;
  private activeWorkspaceId = '';
  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;
  private accounts$: Observable<TAccount[]>;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
    }>,
    private activeService: ActiveService,
    private customFieldsService: CustomFieldsService,
    private modalService: ModalService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit(): void {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.activeWorkspaceId = this.activeService.getActiveWorkspaceId();

      this.workspaces = workspaces;
      this.initialWorkspaceCustomFields = [];

      if (this.customFields[this.activeWorkspaceId]) {
        Object.keys(this.customFields[this.activeWorkspaceId]).forEach((customFieldId) => {
          this.initialWorkspaceCustomFields.push(
            this.customFields[this.activeWorkspaceId][customFieldId].label,
          );
        });
      }
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;

      this.accounts.forEach((account) => {
        this.ppSelectedCustomFields[account.accountId] = {};
        this.customFieldsPerAccount[account.accountId] = 0;

        account.workspaces.forEach((workspace) => {
          this.ppSelectedCustomFields[account.accountId][workspace] = [];
          this.customFieldsPerWorkspace[workspace] = 0;
        });

        this.calculateTotalNumberOfSelectedCustomFields();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openSiteCustomFields(workspaceId: string): void {
    if (
      workspaceId === this.activeWorkspaceId ||
      this.workspaces[workspaceId]?.customFields?.length === 0
    ) {
      return;
    }

    this.activeStep = EImportCustomFieldStep.CUSTOM_FIELDS;
    this.data = [...this.workspaces[workspaceId].customFields];
    this.site = this.workspaces[workspaceId];
    this.numberOfSelectableFields = 0;

    this.data.forEach((customFieldId) => {
      const customField = this.customFields[this.site.workspaceId][customFieldId];
      const blockedTimelineField =
        customField.type === ECustomFieldType.TIMELINE &&
        (this.ppTimelineExists || !this.ppTimelineEnabled);

      if (!this.initialWorkspaceCustomFields.includes(customField.label) && !blockedTimelineField) {
        const fieldAlreadyCounted =
          this.selectedCustomFieldsNames[customField.label] &&
          this.selectedCustomFieldsNames[customField.label] !== this.site.workspaceId;

        if (!fieldAlreadyCounted) {
          this.numberOfSelectableFields += 1;
        }
      }
    });
  }

  selectAll(): void {
    if (this.site) {
      this.data.forEach((customFieldId) => {
        const customField = this.customFields[this.site.workspaceId][customFieldId];
        const initialCustomField = this.initialWorkspaceCustomFields.includes(customField.label);
        const selectedCustomField = this.selectedCustomFieldsNames[customField.label];
        const blockedTimelineField =
          customField.type === ECustomFieldType.TIMELINE &&
          (this.ppTimelineExists || !this.ppTimelineEnabled);

        if (
          !initialCustomField &&
          (!selectedCustomField || selectedCustomField === this.site.workspaceId) &&
          !blockedTimelineField
        ) {
          const index = this.ppSelectedCustomFields[this.site.accountId][
            this.site.workspaceId
          ].findIndex((cf) => cf === customFieldId);

          if (!(index > -1)) {
            this.selectCustomField(customFieldId);
          }
        }
      });
    }

    this.calculateTotalNumberOfSelectedCustomFields();
  }

  deselectAll(): void {
    if (this.site) {
      this.data.forEach((customFieldId) => {
        const customField = this.customFields[this.site.workspaceId][customFieldId];
        const initialCustomField = this.initialWorkspaceCustomFields.includes(customField.label);
        const selectedCustomField = this.selectedCustomFieldsNames[customField.label];

        if (
          !initialCustomField &&
          (!selectedCustomField ||
            (selectedCustomField && selectedCustomField === this.site.workspaceId))
        ) {
          const index = this.ppSelectedCustomFields[this.site.accountId][
            this.site.workspaceId
          ].findIndex((searchedCustomField) => searchedCustomField === customFieldId);

          if (index > -1) {
            this.selectCustomField(customFieldId);
          }
        }
      });
    }

    this.calculateTotalNumberOfSelectedCustomFields();
  }

  selectCustomField(customFieldId: string): void {
    const selectedWorkspaceFields =
      this.ppSelectedCustomFields[this.site.accountId][this.site.workspaceId];

    const index = selectedWorkspaceFields.findIndex(
      (searchedCustomField) => searchedCustomField === customFieldId,
    );
    const customField = this.customFields[this.site.workspaceId][customFieldId];

    if (index > -1) {
      selectedWorkspaceFields.splice(index, 1);
      this.customFieldsPerWorkspace[this.site.workspaceId] -= 1;
      this.customFieldsPerAccount[this.site.accountId] -= 1;

      delete this.selectedCustomFieldsNames[customField.label];
    } else {
      selectedWorkspaceFields.push(customFieldId);
      this.selectedCustomFieldsNames[customField.label] = this.site.workspaceId;
      this.customFieldsPerWorkspace[this.site.workspaceId] += 1;
      this.customFieldsPerAccount[this.site.accountId] += 1;
    }

    this.calculateTotalNumberOfSelectedCustomFields();
  }

  back(): void {
    this.activeStep = EImportCustomFieldStep.SITES;
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  calculateTotalNumberOfSelectedCustomFields(): void {
    this.ppTotalSelectedFields.number = 0;

    Object.keys(this.customFieldsPerAccount).forEach((accountId) => {
      this.ppTotalSelectedFields.number += this.customFieldsPerAccount[accountId];
    });
  }
}
