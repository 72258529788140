var parseMessage = require('./parse-message').parseMessage;

/**
 *
 * @param {*} parser
 * @param {Buffer} message
 * @param {*} shape
 * @api private
 */
function parseEvent(parser, message, shape) {
  var parsedMessage = parseMessage(message);

  // check if message is an event or error
  var messageType = parsedMessage.headers[':message-type'];
  if (messageType) {
    if (messageType.value === 'error') {
      throw parseError(parsedMessage);
    } else if (messageType.value !== 'event') {
      // not sure how to parse non-events/non-errors, ignore for now
      return;
    }
  }

  // determine event type
  var eventType = parsedMessage.headers[':event-type'];
  // check that the event type is modeled
  var eventModel = shape.members[eventType.value];
  if (!eventModel) {
    return;
  }
  var result = {};
  // check if an event payload exists
  var eventPayloadMemberName = eventModel.eventPayloadMemberName;
  if (eventPayloadMemberName) {
    var payloadShape = eventModel.members[eventPayloadMemberName];
    // if the shape is binary, return the byte array
    if (payloadShape.type === 'binary') {
      result[eventPayloadMemberName] = parsedMessage.body;
    } else {
      result[eventPayloadMemberName] = parser.parse(parsedMessage.body.toString(), payloadShape);
    }
  }

  // read event headers
  var eventHeaderNames = eventModel.eventHeaderMemberNames;
  for (var i = 0; i < eventHeaderNames.length; i++) {
    var name = eventHeaderNames[i];
    if (parsedMessage.headers[name]) {
      // parse the header!
      result[name] = eventModel.members[name].toType(parsedMessage.headers[name].value);
    }
  }
  var output = {};
  output[eventType.value] = result;
  return output;
}
function parseError(message) {
  var errorCode = message.headers[':error-code'];
  var errorMessage = message.headers[':error-message'];
  var error = new Error(errorMessage.value || errorMessage);
  error.code = error.name = errorCode.value || errorCode;
  return error;
}

/**
 * @api private
 */
module.exports = {
  parseEvent: parseEvent
};