import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

import { TDashlet, TPreferences } from '../../preferences/preferences.model';

import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { ResponseErrorService } from '../../errors/response-error.service';

import { DOCUMENT } from '@angular/common';
import { DashboardApiProviderService } from '@core/api';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDashletPeriod } from '../dashboard-timeframes-enums';
import { TDashletActivity } from '../dashlet/dashlet-activities/dashlet-activity/dashlet-activity.model';
import { EDashletType } from '../dashlets-enums';
import { DEFAULT_DASHLETS } from './default-dashlets';
import { getTargetId } from './get-target-id';
import { mergeDashboardActivities } from './merge-dashboard-activities';
import { updateDashletSize } from './update-dashlet-size';

@Injectable({
  providedIn: 'root',
})
export class DashboardService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly newDashletPrefsUpdateRequested$ = new Subject<void>();

  private readonly _dashlets$ = new BehaviorSubject<TDashlet[]>([]);
  readonly dashlets$ = this._dashlets$.asObservable();

  activities: TDashletActivity[];

  private bodyFixedCssClass = 'fixed-width';
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private responseErrorService: ResponseErrorService,
    private preferencesService: PreferencesService,
    private dashboardApiProviderService: DashboardApiProviderService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initDashlets(): void {
    const preferences = this.preferencesService.getPreferences();

    if (preferences.dashlets && preferences.dashlets.data && preferences.dashlets.data.length > 0) {
      this._dashlets$.next([...preferences.dashlets.data]);
    } else {
      this.setDefaultDashlets();
    }

    const updatedDashlets = updateDashletSize(this._dashlets$.value);
    this._dashlets$.next([...updatedDashlets]);
  }

  getDashlets(): TDashlet[] {
    return this._dashlets$.value;
  }

  addDashlet(field: { value: EDashletType }): void {
    let dashletSize = { x: 10, y: 15 };

    if (
      field.value === EDashletType.HIGH ||
      field.value === EDashletType.MEDIUM ||
      field.value === EDashletType.LOW ||
      field.value === EDashletType.OPEN ||
      field.value === EDashletType.IN_PROGRESS ||
      field.value === EDashletType.ONHOLD ||
      field.value === EDashletType.CLOSED ||
      field.value === EDashletType.COMMENT_CREATE ||
      field.value === EDashletType.DEFECT_CREATE ||
      field.value === EDashletType.TO_REVIEW ||
      field.value === EDashletType.CANCELED
    ) {
      dashletSize = { x: 6, y: 6 };
    } else if (field.value === EDashletType.ACTIVITIES) {
      dashletSize = { x: 18, y: 24 };
    }

    const newDashlet: TDashlet = {
      x: 0,
      y: 0,
      rows: dashletSize.x,
      cols: dashletSize.y,
      selectedRange: {
        accountId: null,
        workspaceId: null,
      },
      period:
        field.value === EDashletType.ACTIVITIES ||
        field.value === EDashletType.CURRENT_PRIORITY ||
        field.value === EDashletType.CURRENT_STATUS
          ? null
          : EDashletPeriod.WEEK,
      name: field.value,
    };

    this._dashlets$.next([...this._dashlets$.value, newDashlet]);
    this.updateUserDashlets();
  }

  removeDashlet(dashlet: TDashlet): void {
    const dashlets: TDashlet[] = [...this._dashlets$.value];
    dashlets.splice(dashlets.indexOf(dashlet), 1);

    this._dashlets$.next(dashlets);
    this.updateUserDashlets();
  }

  updateUserDashlets(skipCheck: boolean = false): void {
    const oldPreferences = this.preferencesService.getPreferences();

    // Gridster plugin sends these requests even if nothing really changed
    if (!skipCheck && isEqual(oldPreferences?.dashlets?.data, this._dashlets$.value)) {
      return;
    }

    this.newDashletPrefsUpdateRequested$.next();

    const body: TPreferences = {
      dashlets: {
        data: this._dashlets$.value,
      },
    };

    timer(200)
      .pipe(
        takeUntil(this.newDashletPrefsUpdateRequested$),
        tap(() => {
          this.preferencesService
            .updatePreferences(body)
            .pipe(
              takeUntil(this.destroy$),
              tap(() => {
                const preferences = this.preferencesService.getPreferences();

                preferences.dashlets.data = this._dashlets$.value;
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  fetchCurrentTypeCount({
    type = '',
    accountId = '',
    workspaceId = '',
  }: {
    type?: string;
    accountId?: string;
    workspaceId?: string;
  }): Observable<number[]> {
    const targetId = getTargetId(workspaceId, accountId);

    return this.dashboardApiProviderService
      .fetchCurrentTypeCount(type, targetId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchReports({
    type = '',
    period = 'week',
    length = 5,
    accountId = '',
    workspaceId = '',
  }: {
    type?: string;
    period?: string;
    length?: number;
    accountId?: string;
    workspaceId?: string;
  }): Observable<number[][]> {
    const targetId = getTargetId(workspaceId, accountId);

    return this.dashboardApiProviderService
      .fetchReports(type, period, length, targetId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  setDefaultDashlets(): void {
    const dashlets: TDashlet[] = [];

    DEFAULT_DASHLETS.forEach((dashlet) => {
      dashlets.push({ ...dashlet });
    });

    this._dashlets$.next(dashlets);

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__SET_DEFAULT, {
      event_category: EGoogleEventCategory.DASHBOARD,
    });

    this.updateUserDashlets();
  }

  mergeActivities(activity: TDashletActivity, index: number): void {
    return mergeDashboardActivities(activity, index, this.activities);
  }

  setActivities(activities: TDashletActivity[]): void {
    this.activities = activities;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setBodyTagFixed(): void {
    this.renderer.addClass(this.document.body, this.bodyFixedCssClass);
  }

  unsetBodyTagFixed(): void {
    this.renderer.removeClass(this.document.body, this.bodyFixedCssClass);
  }
}
