import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetSiteFilter } from './site-filter.actions';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from './site-filter.model';

import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { ActiveService } from '../../services/active/active.service';

import { checkCustomWorkspaceId } from '../workspace/workspace';
import { ECustomFieldType } from '../custom-fields/custom-field-types-enums';
import { isPriorityDefault } from './clear-filter-service/is-priority-default';
import { isSitesDefault } from './clear-filter-service/is-sites-default';
import { isStatusDefault } from './clear-filter-service/is-status-default';
import { TAllCustomFields } from '../custom-fields/custom-fields.model';
import { EStore } from '../../shared/enums/store.enum';

@Injectable({
  providedIn: 'root',
})
export class ApplyFiltersService {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;
  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private customFieldsService: CustomFieldsService,
    private activeService: ActiveService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });
  }

  applyFilters(): void {
    const customFields = this.customFieldsService.getCustomFields();
    let customWorkspaceId = this.workspaceId;

    this.applySitesFilters();
    this.applyPriorityFilters();
    this.applyStatusFilters();

    this.filters.tags.appliedValue = [...this.filters.tags.value];
    this.filters.excludedTags.appliedValue = [...this.filters.excludedTags.value];
    this.filters.createdBy.appliedValue = [...this.filters.createdBy.value];
    this.filters.assignees.appliedValue = [...this.filters.assignees.value];

    this.filters.date.created.appliedValues.startDate = this.filters.date.created.startDate;
    this.filters.date.created.appliedValues.endDate = this.filters.date.created.endDate;
    this.filters.date.updated.appliedValues.startDate = this.filters.date.updated.startDate;
    this.filters.date.updated.appliedValues.endDate = this.filters.date.updated.endDate;

    this.applyCustomFieldsFilter(customFields);

    if (!this.workspaceId) {
      customWorkspaceId = checkCustomWorkspaceId();
    }

    this.store.dispatch(
      new SetSiteFilter({
        filter: this.filters,
        workspaceId: customWorkspaceId,
      }),
    );
  }

  private applyCustomFieldsFilter(customFields: TAllCustomFields): void {
    this.filters.customFields.forEach((field) => {
      if (customFields[field.workspaceId] && customFields[field.workspaceId][field.id]) {
        if (
          customFields[field.workspaceId][field.id].type === ECustomFieldType.DATE ||
          customFields[field.workspaceId][field.id].type === ECustomFieldType.TIMELINE
        ) {
          field.appliedValues.startDate = field.startDate;
          field.appliedValues.endDate = field.endDate;
        }

        if (
          customFields[field.workspaceId][field.id].type === ECustomFieldType.COST ||
          customFields[field.workspaceId][field.id].type === ECustomFieldType.PERCENTAGE ||
          customFields[field.workspaceId][field.id].type === ECustomFieldType.NUMBERS ||
          customFields[field.workspaceId][field.id].type === ECustomFieldType.TIME
        ) {
          field.appliedValues.min = field.min;
          field.appliedValues.max = field.max;
        }

        if (customFields[field.workspaceId][field.id].type === ECustomFieldType.CHECKBOX) {
          field.appliedValues.value = field.value;
        }

        if (customFields[field.workspaceId][field.id].type === ECustomFieldType.LIST) {
          field.appliedValues.idOfChosenElement = field.idOfChosenElement;
          field.appliedValues.path = field.path;
          field.appliedValues.text = field.text;
        }
      }
    });
  }

  private applyStatusFilters(): void {
    this.filters.status.forEach((status) => {
      status.appliedValue = status.value;
    });

    this.filters.statusDefault = isStatusDefault(this.filters);
  }

  private applyPriorityFilters(): void {
    this.filters.priority.forEach((priority) => {
      priority.appliedValue = priority.value;
    });

    this.filters.priorityDefault = isPriorityDefault(this.filters);
  }

  private applySitesFilters(): void {
    this.filters.sites.forEach((account) => {
      account.appliedValue = account.value;

      account.workspaces.forEach((workspace) => {
        workspace.appliedValue = workspace.value;
      });
    });

    this.filters.sitesDefault = isSitesDefault(this.filters);
  }
}
