var escapeElement = require('./escape-element').escapeElement;

/**
 * Represents an XML text value.
 * @api private
 */
function XmlText(value) {
  this.value = value;
}
XmlText.prototype.toString = function () {
  return escapeElement('' + this.value);
};

/**
 * @api private
 */
module.exports = {
  XmlText: XmlText
};