import { Injectable } from '@angular/core';
import { TPoint } from '../../points/points.model';
import { CustomTableService } from './custom-table/custom-table.service';

import { sortPoints } from './sorting/sorting';

@Injectable({
  providedIn: 'root',
})
export class SiteTablePointsService {
  highlightedPoint: {
    id: string;
  } = {
    id: null,
  };

  constructor(private customTableService: CustomTableService) {}

  highlightPoint(query: string | number): void {
    this.highlightedPoint.id = query.toString();
  }

  dehighlightPoint(): void {
    this.highlightedPoint.id = null;
  }

  sortPoints(points: TPoint[]): TPoint[] {
    const sortedPoints = points.sort(sortPoints());

    return sortedPoints;
  }

  updateTablePoint(point: TPoint): void {
    const table = this.customTableService.getTable();

    if (table) {
      const points = table.points;
      const tablePointIndex = points.findIndex((_point) => _point._id === point._id);

      if (tablePointIndex !== -1) {
        points.splice(tablePointIndex, 1, point);
      } else {
        points.push(point);
      }

      table.updatePoints(points);
      table.load(true);
    }
  }
}
