import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { CustomFieldListSelectDropdownComponent } from '../../custom-field-list-select-dropdown/custom-field-list-select-dropdown.component';
import { TCustomField } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-field-list',
  templateUrl: './bulk-field-list.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldListComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<string>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<string>;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService) {}

  clearCustomField(field: TCustomField): void {
    this.ppCustomFields[field.id] = undefined;
    this.ppCustomFieldsExtended[field.id] = {};

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }

  showList(buttonId: string, field: TCustomField): void {
    this.dropdownService.setData({
      field,
      selected: this.ppCustomFields[field.id],
    });

    this.dropdownService.showDropdown(buttonId, CustomFieldListSelectDropdownComponent, {
      callback: (item) => {
        if (item) {
          this.ppCustomFields[field.id] = item.id;

          this.ppCustomFieldsExtended[field.id] = {
            idOfChosenElement: item.id,
            value: item.path,
            label: field.label,
            type: field.type,
          };
        } else {
          this.ppCustomFields[field.id] = null;

          this.ppCustomFieldsExtended[field.id] = {
            idOfChosenElement: null,
            value: null,
            label: field.label,
            type: field.type,
          };
        }

        this.ppCustomFieldsChange.emit(this.ppCustomFields);
        this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
      },
    });
  }
}
