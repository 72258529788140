import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TIconColor } from 'src/app/project/components/icon/icon-colors.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSavedViews,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AdvancedFilterService } from '../../../filters-advanced/advanced-filter.service';
import { TAllFilters } from '../../../filters/site-filter.model';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { ESavedView } from '../../models/saved-view.enum';
import { TSavedView } from '../../models/saved-view.model';
import { SavedViewsService } from '../../saved-views.service';

@Component({
  selector: 'pp-saved-views-dropdown-new-view-button',
  templateUrl: './saved-views-dropdown-new-view-button.component.html',
  styleUrls: ['./saved-views-dropdown-new-view-button.component.scss'],
})
export class SavedViewsDropdownNewViewButtonComponent implements OnDestroy, OnChanges {
  @Input() ppWorkspaceId: string;
  @Input() ppType: ESavedView;

  private siteFilters$ = new Observable<TAllFilters>();
  private readonly destroy$ = new Subject<void>();
  private allFilters: TAllFilters;

  EIconPath = EIconPath;
  hidden: boolean;

  iconColour: TIconColor = 'primary-600';

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
    }>,
    private preferencesService: PreferencesService,
    private savedViewsService: SavedViewsService,
    private advancedFilterService: AdvancedFilterService,
    private workspaceService: WorkspaceService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
    });
  }

  ngOnChanges(): void {
    this.checkShowCreateButton();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  checkShowCreateButton(): void {
    const workspaceData = this.workspaceService.getWorkspace(this.ppWorkspaceId);
    const userType = workspaceData.share.shareOption;

    if (
      userType !== EUserRole.ACCOUNT_ADMIN &&
      userType !== EUserRole.SITE_ADMIN &&
      userType !== EUserRole.OWNER &&
      this.ppType === ESavedView.SHARED
    ) {
      this.hidden = true;
    } else {
      this.hidden = false;
    }
  }

  createView(): void {
    const columns = this.preferencesService.getPreferences().workspaces[this.ppWorkspaceId].columns;
    const advancedFiltersToSave = cloneDeep(
      this.advancedFilterService.getAdvancedFilter(this.ppWorkspaceId),
    );
    advancedFiltersToSave.filters = advancedFiltersToSave.filters.filter((filter) => filter.type);

    const newView: Partial<TSavedView> = {
      name: 'New View',
      filters: {
        basic: this.allFilters[this.ppWorkspaceId],
        advanced: advancedFiltersToSave,
      },
      columns,
      viewType: this.ppType,
    };

    logEventInGTAG(EGoogleEventSavedViews.CREATE_SAVED_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.ppType,
    });

    this.savedViewsService.createView(newView, this.ppWorkspaceId).subscribe();
  }
}
