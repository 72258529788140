import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { UpdatePointAssignees } from '../../../points.actions';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { Modal, ModalService } from '../../../../../components/modal/modal.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { ActiveService } from '../../../../../services/active/active.service';
import { WorkspaceService } from '../../../../workspace/workspace.service';
import { PointsService } from '../../../points.service';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';

@Component({
  selector: 'pp-point-bulk-assignees-modal',
  templateUrl: './point-bulk-assignees-modal.component.html',
  styleUrls: ['./point-bulk-assignees-modal.component.scss'],
})
export class PointBulkAssigneesModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspace = this.workspaceService.findWorkspace(this.activeService.getActiveWorkspaceId());
  modal: Modal = this.modalService.getModal();

  processing = false;
  selectedAssignees: string[] = [];

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  updateAssignees(assignees: string[]): void {
    this.selectedAssignees = assignees.slice();
  }

  save(): void {
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = pointIds.length;
    this.processing = true;

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EDIT, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'ASSIGNEES',
      event_value: `${numberOfSelectedPoints}`,
    });

    this.pointsUpdateService
      .updatePointsAssignees(pointIds, this.selectedAssignees)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const assignees = this.selectedAssignees;
          const promptText = this.translationPipe.transform('prompt_points_update_success');

          selectedPoints.forEach((point) => {
            const newAssignees = Array.from(new Set([...point.assignees, ...assignees]));

            this.store.dispatch(
              new UpdatePointAssignees({
                workspaceId: this.workspace.workspaceId,
                _id: point._id,
                assignees: newAssignees,
              }),
            );

            this.customTableService.updatePoint({
              _id: point._id,
              field: EBasicField.ASSIGNEES,
              newValue: newAssignees,
              updatedDate: new Date().getTime(),
            });
          });

          this.sitePointFilterService.filterPoints(true);
          this.promptService.showSuccess(promptText);
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }
}
