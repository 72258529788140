<div
  class="assetCard"
  (dragover)="onDragOver($event)"
  (dragenter)="$event.preventDefault()"
  (dragleave)="$event.preventDefault()"
  #assetCard
  [class.assetCard__reordered]="isBeingReordered"
>
  <pp-asset-card-header class="assetCard__header" [ppAsset]="ppAsset"></pp-asset-card-header>
  <pp-asset-card-information
    class="assetCard__details"
    [ppDetails]="ppAsset.details"
    [ppLabels]="ppAsset.labels"
  ></pp-asset-card-information>
  <pp-asset-card-sites class="assetCard__sites" [ppSites]="ppAsset.sites"></pp-asset-card-sites>
</div>
