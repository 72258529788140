import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteListSelectComponent } from './site-list-select.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';

@NgModule({
  declarations: [SiteListSelectComponent],
  imports: [
    CommonModule,
    PipesModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
  ],
  exports: [SiteListSelectComponent],
})
export class SiteListSelectModule {}
