import { Injectable } from '@angular/core';
import { API_files_images_bounded_size } from '@core/api/paths';
import { Observable, Subject, catchError, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { AssetApiProviderService } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-api-provider.service';
import { TAssetCreateDTO } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ResponseErrorService } from '../../errors/response-error.service';
import { UsersService } from '../../users/users.service';
import { FleetService } from '../fleet-service/fleet.service';
import { TAsset, TAssetDetails, TAssetLabel, TNewAsset, TNewAssetSite } from './asset.consts';
import { checkAssetDetails, checkAssetName, checkAssetSites } from './asset.filters';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private assets: TAsset[] = [];

  private _assetsChange$ = new Subject<TAsset[]>();
  public assetsChange$ = this._assetsChange$.asObservable();

  private _keywordChange$ = new Subject<string>();
  public keywordChange$ = this._keywordChange$.asObservable();

  constructor(
    private assetApiProviderService: AssetApiProviderService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private responseErrorService: ResponseErrorService,
    private fleetService: FleetService,
    private usersService: UsersService,
  ) {}

  filterFleetAssets(keyword: string): void {
    this._assetsChange$.next(
      this.assets.filter((asset) => this.filterAssetsByKeyword(keyword, asset)),
    );
    this._keywordChange$.next(keyword);
  }

  filterAssetsByKeyword(keyword: string, asset: TAsset): boolean {
    keyword = keyword.toLocaleLowerCase();
    return (
      checkAssetName(asset.name, keyword) ||
      checkAssetDetails(asset.details, keyword) ||
      checkAssetSites(asset.sites, keyword) ||
      this.checkAssetLabels(asset.labels, keyword)
    );
  }

  getFleetAssets(fleetAssetIds: string[]): TAsset[] {
    return this.assets
      .filter((asset) => fleetAssetIds.includes(asset.id))
      .sort((a, b) => {
        return fleetAssetIds.indexOf(a.id) - fleetAssetIds.indexOf(b.id);
      });
  }

  getAsset(assetId: string): TAsset {
    return this.assets.find((a) => a.id === assetId);
  }

  getAssets(): TAsset[] {
    return this.assets;
  }

  createAsset({
    fleetId,
    name,
    imageId,
    details,
    labels,
    sites,
  }: {
    fleetId: string;
    name: string;
    imageId: string;
    details: TAssetDetails;
    labels: TAssetLabel[];
    sites: TNewAssetSite[];
  }): Observable<TAsset> {
    const body: TAssetCreateDTO = {
      name,
      imageId,
      details,
      labels,
      sites,
    };

    logEventInGTAG(EGoogleEventFleetManagement.ASSET_CREATE_NEW, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    return this.assetApiProviderService.createAsset(fleetId, body).pipe(
      tap((asset) => {
        this.assets = [...this.assets, asset];
        this.fleetService.addAssetToFleet(fleetId, asset.id);
        this.updateAssets();
      }),
      catchError((error) => {
        this.showErrorPrompt(error, 'prompt_asset_create_error');

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  fetchAssetsForFleet(fleetId: string): Observable<TAsset[]> {
    return this.assetApiProviderService.fetchAssets(fleetId).pipe(
      tap((assets) => {
        const fleet = this.fleetService.getFleet(fleetId);
        const fleetAssetIds = fleet.assetIds;

        this.assets = assets.sort((a, b) => {
          return fleetAssetIds.indexOf(a.id) - fleetAssetIds.indexOf(b.id);
        });

        this.updateAssets();
      }),
      catchError((error) => {
        this.showErrorPrompt(error, 'prompt_assets_get_error');

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  fetchAsset(assetId: string): Observable<TAsset> {
    return this.assetApiProviderService.fetchAsset(assetId).pipe(
      tap((asset) => {
        this.assets = [...this.assets, asset];

        this.updateAssets();
      }),
      catchError((error) => {
        this.showErrorPrompt(error, 'prompt_asset_get_error');

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  editAsset(assetId: string, body: Partial<TNewAsset>): Observable<TAsset> {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_EDIT, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    return this.assetApiProviderService.editAsset(assetId, body).pipe(
      tap((asset) => {
        this.assets = this.assets.map((a) => {
          if (a.id === assetId) {
            return asset;
          }

          return a;
        });

        this.updateAssets();
      }),
      catchError((error) => {
        this.showErrorPrompt(error, 'prompt_asset_edit_error');

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  deleteAsset(assetId: string): Observable<null> {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_DELETE, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    return this.assetApiProviderService.deleteAsset(assetId).pipe(
      tap(() => {
        this.assets = this.assets.filter((a) => a.id !== assetId);
        this.updateAssets();
        this.fleetService.removeAssetFromFleets(assetId);
      }),
      catchError((error) => {
        this.showErrorPrompt(error, 'prompt_asset_delete_error');

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  uploadAssetImage(image: File): Observable<string> {
    return this.assetApiProviderService.uploadAssetImage(image).pipe(
      catchError((error) => {
        // TODO Handle upload image errors

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  getImageUrl(imageId: string): string {
    return API_files_images_bounded_size(imageId, 1200);
  }

  updateAssetsOrder(): void {
    this.updateAssets();
  }

  private checkAssetLabels(labels: TAssetLabel[], keyword: string): boolean {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];

      if (label.name.toLowerCase().includes(keyword)) {
        return true;
      }
      const labelUser = this.usersService.getUser(label.assigneeId);

      if (
        labelUser?.email.toLowerCase().includes(keyword) ||
        labelUser?.userName.toLowerCase().includes(keyword)
      ) {
        return true;
      }
    }
    return false;
  }

  private updateAssets(): void {
    this._assetsChange$.next(this.assets);
  }

  private showErrorPrompt(error: any, defaultPrompt: TTranslationKey) {
    this.getErrorMessage(error, defaultPrompt).then((message) => {
      this.promptService.showError(message);
    });
  }

  getErrorMessage(error: any, defaultPrompt: TTranslationKey): Promise<string> {
    return new Promise((resolve) => {
      switch (error.status) {
        case EStatusCode.NOT_FOUND: {
          resolve(this.translationPipe.transform('prompt_asset_does_not_exist'));

          break;
        }
        case EStatusCode.UNAUTHORIZED: {
          resolve(this.translationPipe.transform('prompt_asset_permission_denied'));

          break;
        }
        case EStatusCode.BAD_REQUEST: {
          getErrorMessage(error).then((message) => {
            resolve(message);
          });

          break;
        }
        default: {
          resolve(this.translationPipe.transform(defaultPrompt));
        }
      }
    });
  }
}
