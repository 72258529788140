import { Injectable } from '@angular/core';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PointExportModalService {
  constructor(private permissionsService: PermissionsService) {}

  checkExportPermissions(workspaceId: string): {
    commentsVisible: boolean;
    tagsVisible: boolean;
    timelineVisible: boolean;
  } {
    const commentsVisible = this.permissionsService.getCommentPermissions(workspaceId).read;
    const tagsVisible = this.permissionsService.getTagPermissions(workspaceId).read;
    const timelineVisible = this.permissionsService.isTimelineEnabled(workspaceId);

    return {
      commentsVisible,
      tagsVisible,
      timelineVisible,
    };
  }
}
