<div class="dashlet-flip__content-wrapper dashletFlip__back">
  <pp-dashlet-head [ppDashlet]="ppDashlet" (ppToggleFlip)="flipDashlet()"> </pp-dashlet-head>

  <div
    class="dashletFlip__container"
    [class.dashletFlip__container--active]="ppFlipped"
    #dashletFlipContainer
  >
    <h6
      class="dashletFlip__title"
      *ngIf="
        ppDashlet.period &&
        ppDashlet.name !== dashletTypes.CURRENT_STATUS &&
        ppDashlet.name !== dashletTypes.CURRENT_PRIORITY
      "
    >
      {{ 'select_time_period' | ppTranslate }}
    </h6>

    <ul
      class="periodSelect"
      *ngIf="
        ppDashlet.period &&
        ppDashlet.name !== dashletTypes.OVER_TIME_STATUS &&
        ppDashlet.name !== dashletTypes.OVER_TIME_PRIORITY &&
        ppDashlet.name !== dashletTypes.CURRENT_STATUS &&
        ppDashlet.name !== dashletTypes.CURRENT_PRIORITY
      "
    >
      <li
        *ngFor="let timePeriod of timePeriods"
        class="periodSelect__option"
        [ngClass]="{ 'periodSelect__option--active': period.selected === timePeriod.value }"
        (click)="updatePeriod({ dashlet: ppDashlet, period: timePeriod.value })"
      >
        {{ timePeriod.name | ppTranslate }}
      </li>
    </ul>

    <ul
      class="periodSelect"
      *ngIf="
        ppDashlet.name === dashletTypes.OVER_TIME_STATUS ||
        ppDashlet.name === dashletTypes.OVER_TIME_PRIORITY
      "
    >
      <li
        *ngFor="let overTimePeriod of overTimePeriods"
        class="periodSelect__option"
        [ngClass]="{ 'periodSelect__option--active': period.selected === overTimePeriod.value }"
        (click)="updatePeriod({ dashlet: ppDashlet, period: overTimePeriod.value })"
      >
        {{ overTimePeriod.name | ppTranslate }}
      </li>
    </ul>

    <h6 class="dashletFlip__title" *ngIf="ppDashlet.selectedRange">
      {{ 'select_account_site' | ppTranslate }}
    </h6>

    <p class="dashletFlip__section-description">
      {{ 'choose_sites_data_display' | ppTranslate }}
    </p>

    <ul class="dashletFlip__accounts" *ngIf="ppDashlet.selectedRange">
      <li
        class="dashletFlip__account"
        [ngClass]="{ 'dashletFlip__account--active': !siteRange.selected }"
        (click)="updateSiteRange({ dashlet: ppDashlet })"
      >
        <h4 class="dashletSiteacc__acc-circle">A</h4>
        <p class="dashletSiteacc__acc-label">{{ 'all_accounts_sites' | ppTranslate }}</p>
      </li>

      <ng-container *ngIf="isAccountsArray">
        <li
          class="dashletFlip__account"
          [ngClass]="{ 'dashletFlip__account--active': account.accountId === siteRange.selected }"
          *ngFor="let account of accounts"
        >
          <span
            class="dashletFlip__account-wrapper"
            (click)="
              updateSiteRange({
                dashlet: ppDashlet,
                id: account.accountId,
                accountId: account.accountId
              })
            "
          >
            <h4 class="dashletSiteacc__acc-circle">
              {{ account.accountName | firstLetter }}
            </h4>
            <p class="dashletSiteacc__acc-label">
              {{ account.accountName }} - {{ 'all_sites' | ppTranslate }}
            </p>
          </span>

          <pp-dashlet-timeline-account
            [ppAccount]="account"
            [ppDashlet]="ppDashlet"
            [ppSiteRange]="siteRange"
            [ppBlockedTimelines]="blockedTimelines"
            [ppWorkspaces]="workspaces"
            (ppUpdateSiteRange)="updateSiteRange($event)"
          ></pp-dashlet-timeline-account>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="dashletFlip__footer">
    <pp-delete-button
      (mousedown)="$event.stopPropagation()"
      (ppAction)="removeDashlet()"
    ></pp-delete-button>

    <pp-button-modal-confirm
      ppDataTarget="Dashlet settings apply button"
      ppStyle="primary"
      (ppAction)="apply()"
    >
      {{ 'save' | ppTranslate }}
    </pp-button-modal-confirm>
  </div>
</div>
