<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_asset_site_picker_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_asset_site_picker_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetNewItemSectionContent">
    <div class="newAssetDetails">
      <header class="newAssetSitePickerHeader">{{ 'sites' | ppTranslate }}</header>
      <section class="newAssetSitePickerSearch">
        <pp-search-box
          [ppPlaceholder]="'search_sites' | ppTranslate"
          (ppAction)="updateSearchBox($event)"
          ppSize="wide"
        ></pp-search-box>
      </section>
      <pp-new-asset-site-picker-account
        *ngFor="let account of filteredAccounts"
        [ppAccount]="account"
      ></pp-new-asset-site-picker-account>
    </div>
  </div>
</section>
