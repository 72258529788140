import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { clone, isEqual } from 'lodash';
import { Subject, catchError, finalize, of, takeUntil, tap, timer } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { TTagSelectOptions } from 'src/app/project/modules/tags/tag-select/tag-select.component';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointsUpdateService } from '../../../points-update.service';
import { UpdatePointTags } from '../../../points.actions';
import { TPointsByWorkspace } from '../../../points.model';
import { PointActivityService } from '../../point-timeline/point-activity.service';

@Component({
  selector: 'pp-point-fields-tags',
  templateUrl: './point-fields-tags.component.html',
  styleUrls: ['./point-fields-tags.component.scss'],
})
export class PointFieldsTagsComponent {
  @Input() ppPointId: string = null;
  @Input() ppTags: string[] = [];
  @Input() ppWorkspaceId: string;
  @Input() ppNew: boolean;
  EIconPath = EIconPath;
  private readonly destroy$ = new Subject<void>();

  private cancelUpdatePointFieldTags$ = new Subject<void>();
  private updatePointFieldTagsTimerMs = 500;
  private savedTags: string[] = [];
  updatingTags = false;
  tagsSuccess = false;
  tagsError = false;
  tagsVisible: boolean;
  tagsEditable: boolean;
  tagsLimited: boolean;
  private successTimerMs = 2500;
  tagSelectOptions: TTagSelectOptions;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      points: TPointsByWorkspace;
      offline: boolean;
    }>,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private workspaceService: WorkspaceService,
    private pointsUpdateService: PointsUpdateService,
    private permissionsService: PermissionsService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnChanges(): void {
    this.savedTags = this.ppTags;

    this.setTagsPermissions();
    this.setTagSelectOptions();
  }

  ngOnInit(): void {
    this.savedTags = this.ppTags;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setTagSelectOptions(): void {
    this.tagSelectOptions = {
      showIcon: false,
      noTagRemovedDebounceTime: this.ppNew,
      singleLineInput: true,
      disabled: !this.tagsEditable,
      tagsLimited: this.tagsLimited,
      hasError: this.tagsError,
      showSaveIndicator: this.tagsEditable,
      showClearBtn: !this.tagsLimited,
      showUpdateIndicator: this.updatingTags,
    };
  }

  updateTags(tags: string[], clear?: boolean): void {
    const _id = clone(this.ppPointId);

    if (this.ppNew) {
      this.store.dispatch(
        new UpdatePointTags({
          workspaceId: this.ppWorkspaceId,
          _id: _id,
          tags,
        }),
      );
    } else if (!isEqual(this.savedTags, tags)) {
      this.updatingTags = true;

      this.cancelUpdatePointFieldTags$.next();

      timer(this.updatePointFieldTagsTimerMs)
        .pipe(
          takeUntil(this.cancelUpdatePointFieldTags$),
          tap(() => {
            this.updatePointFieldTags(_id, tags, clear);
          }),
        )
        .subscribe();
    }
  }

  private updatePointFieldTags(_id: string, tags: string[], clear: boolean): void {
    this.pointsUpdateService
      .updatePointField(_id, { tags })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.savedTags = response.tags;
          const promptText = this.translationPipe.transform('prompt_point_tags_update');

          if (clear) {
            this.ppTags = [...tags];
          }

          this.store.dispatch(
            new UpdatePointTags({
              workspaceId: this.ppWorkspaceId,
              _id: _id,
              tags,
            }),
          );

          this.customTableService.updatePoint({
            _id: _id,
            field: EBasicField.TAGS,
            newValue: tags,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.tagsSuccess = true;

          timer(this.successTimerMs).subscribe(() => {
            this.tagsSuccess = false;
          });
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          } else if (
            error.status === EStatusCode.BAD_REQUEST &&
            this.ppTags.length === 1 &&
            this.tagsLimited
          ) {
            promptText = this.translationPipe.transform('prompt_changes_tags_limited');
          }

          this.tagsError = true;

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.updatingTags = false;
        }),
      )
      .subscribe();
  }

  setTagsPermissions(): void {
    const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);

    if (workspace) {
      // ngOnChanges runs before ngOnInit, might not be loaded yet
      const tagPermissions = this.permissionsService.getTagPermissions(workspace.workspaceId);

      this.tagsEditable = tagPermissions.edit;
      this.tagsVisible = tagPermissions.read;
      this.tagsLimited = this.permissionsService.getTagLimited(workspace.workspaceId);
    }
  }
}
