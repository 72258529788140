import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AccountService } from '../../../account/account-service/account.service';
import { TAccount } from '../../../account/account.model';
import { checkIfHasRole } from '../../../account/check-if-has-role';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { FleetService } from '../../fleet-service/fleet.service';
import { EFleetShareOption } from '../../fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-fleet-management-empty-fleet-account-select',
  templateUrl: './fleet-management-empty-fleet-account-select.component.html',
  styleUrls: ['./fleet-management-empty-fleet-account-select.component.scss'],
})
export class FleetManagementEmptyFleetAccountSelectComponent implements OnInit, OnDestroy {
  @Input() ppAccountId: string;
  @Output() ppAccountSelected = new EventEmitter<string>();
  @Input() ppDisabled: boolean;

  private readonly destroy$ = new Subject<void>();

  items: TSelectOption[] = [];
  selectedIndex: number = -1;
  private workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  constructor(
    private store: Store<{ workspaces: TWorkspacesById }>,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private fleetService: FleetService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.setItems();
    });
  }

  ngOnInit(): void {
    this.setItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectItem(item: TSelectOption): void {
    this.selectedIndex = this.items.findIndex((i) => i.value === item.value);

    this.ppAccountSelected.emit(item.value);
  }

  private setItems(): void {
    const accounts = this.accountService.getAccounts();
    const items: TSelectOption[] = [];

    if (this.ppDisabled) {
      const account = this.accountService.getAccount(this.ppAccountId);

      if (account) {
        const accountSelectOption: TSelectOption = {
          value: account.accountId,
          label: account.accountName,
        };

        items.push(accountSelectOption);
      }

      this.items = items;
      this.selectedIndex = 0;
    } else {
      accounts.forEach((account) => {
        if (this.canCreateFleet(account)) {
          const accountSelectOption: TSelectOption = {
            value: account.accountId,
            label: account.accountName,
          };

          items.push(accountSelectOption);
        }
      });

      this.items = items;

      this.selectedIndex = this.items.findIndex((i) => i.value === this.ppAccountId);
    }
  }

  private canCreateFleet(account: TAccount): boolean {
    const workspaces = this.workspaceService.getWorkspaces();
    const fleetManagementEnabled = account.accountFeatures?.fleetManagement;
    const isOwner = checkIfHasRole(EUserRole.OWNER, account.workspaces, workspaces);

    if (!fleetManagementEnabled) {
      return false;
    }

    if (isOwner) {
      return true;
    }

    const isAccountAdmin = checkIfHasRole(EUserRole.ACCOUNT_ADMIN, account.workspaces, workspaces);
    const hasOwnFleets = this.checkIfHasOwnFleetOnAccount(account.accountId);

    if (isAccountAdmin && !hasOwnFleets) {
      return true;
    }

    return false;
  }

  private checkIfHasOwnFleetOnAccount(accountId: string): boolean {
    const fleet = this.fleetService.getFleets()[accountId];

    const fleetAlreadyExists = fleet && Object.keys(fleet).length > 0;

    if (fleetAlreadyExists) {
      return Object.values(fleet).some(
        (fleet) => fleet.fleetShareOption === EFleetShareOption.EDIT,
      );
    }
    return false;
  }
}
