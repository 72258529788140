import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TAccount } from '../../../account/account.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

import { PermissionsService } from '../../../share/permissions.service';
import { DashboardService } from '../../dashboard-service/dashboard.service';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from '../../../../shared/enums/store.enum';
import { EDashletType } from '../../dashlets-enums';
import { overTimePeriods, timePeriods } from './dashlet-flip-periods';
import { TDashletFlipData, TDashletFlipDataData } from './dashlet-flip.model';

@Component({
  selector: 'pp-dashlet-flip',
  templateUrl: './dashlet-flip.component.html',
  styleUrls: ['./dashlet-flip.component.scss'],
})
export class DashletFlipComponent implements OnInit, OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Input() ppFlipped: boolean;
  @Output() ppToggleFlip = new EventEmitter<void>();
  @Output() ppSelectPeriod = new EventEmitter<TDashletFlipDataData>();
  @Output() ppSelectSiteRange = new EventEmitter<TDashletFlipDataData>();

  overTimePeriods = overTimePeriods;
  timePeriods = timePeriods;

  blockedTimelines: string[] = [];
  accounts: TAccount[] = null;
  isAccountsArray = false;
  accounts$: Observable<TAccount[]>;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  dashletTypes = EDashletType;
  period: TDashletFlipData;
  siteRange: TDashletFlipData;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{ accounts: TAccount[]; workspaces: TWorkspacesById }>,
    private dashboardService: DashboardService,
    private permissionsService: PermissionsService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.period = {
      data: null,
      updated: false,
      selected: this.ppDashlet.period,
    };

    this.siteRange = {
      data: null,
      updated: false,
      selected: null,
    };

    if (this.ppDashlet.selectedRange.workspaceId) {
      this.siteRange.selected = this.ppDashlet.selectedRange.workspaceId;
    } else if (this.ppDashlet.selectedRange.accountId) {
      this.siteRange.selected = this.ppDashlet.selectedRange.accountId;
    }

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces: TWorkspacesById) => {
      this.workspaces = workspaces;
      this.blockedTimelines = [];

      Object.keys(this.workspaces).forEach((workspaceId) => {
        const timelineEnabled = this.permissionsService.isTimelineEnabled(workspaceId);
        const tagPermissions = this.permissionsService.getTagPermissions(workspaceId);
        const commentPermissions = this.permissionsService.getCommentPermissions(workspaceId);

        if ((!timelineEnabled || !tagPermissions.read) && !commentPermissions.read) {
          this.blockedTimelines.push(workspaceId);
        }
      });
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;
      this.isAccountsArray = Array.isArray(this.accounts);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updatePeriod(data: TDashletFlipDataData): TDashletFlipData {
    this.period.updated = true;
    this.period.selected = data.period;
    this.period.data = data;

    return this.period;
  }

  updateSiteRange(data: TDashletFlipDataData): TDashletFlipData {
    this.siteRange.updated = true;
    this.siteRange.selected = data.id;
    this.siteRange.data = data;

    return this.siteRange;
  }

  removeDashlet(): void {
    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__DELETE, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_value: this.ppDashlet.name,
    });

    this.dashboardService.removeDashlet(this.ppDashlet);
  }

  apply(): void {
    if (this.period.updated) {
      this.ppSelectPeriod.emit(this.period.data);
    }

    if (this.siteRange.updated) {
      this.ppSelectSiteRange.emit(this.siteRange.data);
    }

    this.ppToggleFlip.emit();
  }

  flipDashlet(): void {
    this.period.selected = this.ppDashlet.period;

    if (this.ppDashlet.selectedRange.workspaceId) {
      this.siteRange.selected = this.ppDashlet.selectedRange.workspaceId;
    } else if (this.ppDashlet.selectedRange.accountId) {
      this.siteRange.selected = this.ppDashlet.selectedRange.accountId;
    } else {
      this.siteRange.selected = null;
    }

    this.ppToggleFlip.emit();
  }
}
