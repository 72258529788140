import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';
import { CustomFieldsService } from '../custom-fields.service';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { getDecimalPlacesValue } from './utils/getDecimalPlacesValue';
import { getDecimalPlacesPlaceholder } from './utils/getDecimalPlacesPlaceholder';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';

@Component({
  selector: 'pp-custom-field-numbers',
  templateUrl: './custom-field-numbers.component.html',
  styleUrls: ['./custom-field-numbers.component.scss'],
})
export class CustomFieldNumbersComponent implements OnInit, AfterViewInit {
  @Input() ppField: TCustomFieldResponse;
  @Input() ppValue: number;
  @Input() ppReadOnly = false;
  @Input() ppUpdating: boolean | string = false;
  @Input() ppWorkspaceId = '';
  @Input() ppFieldId = '';

  @Output() ppUpdate = new EventEmitter();

  value: number;
  decimalPlaces = 2;
  showCommas: boolean;
  placeholder = '---.--';
  autonumericOptions: TAutoNumericOptions;

  public unit = '';

  constructor(private customFieldsService: CustomFieldsService, private ngZone: NgZone) {}

  ngOnInit(): void {
    const customField =
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

    if (customField) {
      this.unit = customField.unit;
      this.showCommas = customField.showCommas;
      this.decimalPlaces = getDecimalPlacesValue(customField.decimalPlaces);

      if (this.decimalPlaces !== 2) {
        this.placeholder = getDecimalPlacesPlaceholder(this.decimalPlaces);
      }
    }

    this.autonumericOptions = {
      watchExternalChanges: true,
      modifyValueOnWheel: false,
      decimalPlaces: this.decimalPlaces,
      decimalPlacesRawValue: this.decimalPlaces,
      digitGroupSeparator: this.showCommas ? ',' : '',
    };
  }

  ngAfterViewInit(): void {
    this.value = this.ppValue;
  }

  clearCustomField(): void {
    this.value = null;

    this.updateField();
  }

  updateField(): void {
    if (this.ppUpdate) {
      const value = this.value || null;

      this.ppUpdate.emit(value);
    }
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }
}
