import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { SetActiveAccountId } from '../../../services/active/active.actions';
import { TSync } from '../../offline/sync.model';
import { TAccount } from '../account.model';

import { Title } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EStore } from '../../../shared/enums/store.enum';
import { SyncService } from '../../offline/sync.service';
import { EUserRole } from '../../share/share-utils/user-roles';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { AccountService } from '../account-service/account.service';
import { checkIfHasRole } from '../check-if-has-role';
import { EAccountSettingsTabPath } from './account-settings-tabs/account-settings-tabs.const';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly synced$ = new Subject<void>();

  private accounts$: Observable<TAccount[]>;
  private sync$: Observable<TSync>;
  account: TAccount;
  accountId = '';
  url: string;
  EAccountSettingsTabPath = EAccountSettingsTabPath;
  isAccountAdmin: boolean;

  constructor(
    private store: Store<{
      accounts: TAccount[];
      sync: TSync;
    }>,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private syncService: SyncService,
    private whiteLabelService: WhiteLabelService,
    private titleService: Title,
    private translationPipe: TranslationPipe,
    private router: Router,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.sync$ = this.store.pipe(select(EStore.SYNC));

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.getAccountId(params);
      this.store.dispatch(new SetActiveAccountId(this.accountId));
    });
  }

  ngOnInit() {
    this.setTitle();
    const accountsLoaded = this.areAccountsLoaded();

    this.listenToSyncChange();
    this.listenToUrlChange();

    if (accountsLoaded) {
      this.initAccountSettings();
    } else {
      this.syncService.firstLoad().pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  initAccountSettings(): void {
    this.workspaceService
      .generateWorkspaces({ refetch: false, fromNewSite: true, showHidden: true })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.listenToParamMapChange();
        }),
      )
      .subscribe();
  }

  private listenToParamMapChange(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.accountService.fetchAccount(this.accountId).pipe(takeUntil(this.destroy$)).subscribe();

      this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
        this.tryToRedirect();

        if (accounts.length > 0) {
          this.account = accounts.find((account) => account.accountId === this.accountId);
        }
      });

      this.whiteLabelService.fetchWhiteLabel(this.accountId);
    });
  }

  private getAccountId(params: ParamMap): void {
    const id = params.get('id');

    if (id) {
      this.accountId = id;
    }
  }

  private listenToUrlChange(): void {
    this.url = this.router.url;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = this.router.url;

        this.tryToRedirect();
      }
    });
  }

  private tryToRedirect(): void {
    this.checkIfAccountAdmin();

    if (this.isAccountAdmin) {
      const blockedUrl =
        this.url.indexOf(EAccountSettingsTabPath.SUBSCRIPTION) !== -1 ||
        this.url.indexOf(EAccountSettingsTabPath.SKINS) !== -1 ||
        this.url.indexOf(EAccountSettingsTabPath.OPTIONS) !== -1;

      if (blockedUrl) {
        this.router.navigate([
          'settings/account/',
          this.accountId,
          EAccountSettingsTabPath.USER_MANAGEMENT,
        ]);
      }
    }
  }

  private checkIfAccountAdmin(): void {
    this.isAccountAdmin = checkIfHasRole(
      EUserRole.ACCOUNT_ADMIN,
      this.account?.workspaces,
      this.workspaceService.getWorkspaces(),
    );
  }

  private listenToSyncChange(): void {
    this.sync$.pipe(takeUntil(this.synced$)).subscribe((sync) => {
      if (sync.synced) {
        this.synced$.next();

        this.initAccountSettings();
      }
    });
  }

  private areAccountsLoaded(): number {
    const accounts = this.accountService.getAccounts();
    const accountsLoaded = accounts.length;
    return accountsLoaded;
  }

  private setTitle(): void {
    this.titleService.setTitle(this.translationPipe.transform('account_settings_title'));
  }
}
