import { cloneDeep } from 'lodash';
import { SelectWorkspacePoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { addSelectedPoint } from '../selected-points';
import { getVisiblePoints } from '../visible-points';

export function selectWorkspacePoints(
  oldState: TPointsByWorkspace,
  action: SelectWorkspacePoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  const visiblePointsIds = getVisiblePoints();

  state[action.payload.workspaceId].entities.forEach((point) => {
    if (visiblePointsIds.includes(point._id)) {
      addSelectedPoint(point._id);
    }
  });

  return state;
}
