import { EBasicField } from '../../../../../../shared/enums/basic-fields-enums';
import { translate } from 'src/app/project/features/translate/translate';
import { TColumn } from '../../../columns/column.model';
import { createHeaderNameElement } from './create-header-name-element';

export function createHeaderCellTypeElement(_column: TColumn): HTMLElement {
  switch (_column.name) {
    case EBasicField.PRIORITY:
    case EBasicField.STATUS:
    case EBasicField.SEQUENCE_NUMBER:
      return createHeaderNameElement(
        translate(_column.name.toLowerCase()),
        _column.width,
        _column,
        true,
      );
    case EBasicField.TITLE:
    case EBasicField.DESCRIPTION:
    case EBasicField.CREATED:
    case EBasicField.UPDATED:
    case EBasicField.ASSIGNEES:
    case EBasicField.TAGS:
      return createHeaderNameElement(
        translate(_column.name.toLowerCase()),
        _column.width,
        _column,
        false,
      );
    case EBasicField.CREATED_BY:
      return createHeaderNameElement(translate('createdBy'), _column.width, _column, false);
    default:
      return createHeaderNameElement(_column.name, _column.width, _column, false);
  }
}
