import { TCustomFieldResponse } from '@project/view-models';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { translate } from 'src/app/project/features/translate/translate';
import { transformTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TCustomField } from '../../../custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { TPreferences } from '../../../preferences/preferences.model';
import { getEmptyGroupName } from './empty-group-name';

export function transformCustomFieldValue(
  customFieldId: string,
  pointCustomField?: TCustomFieldResponse | null,
): string {
  const emptyGroupValue = getEmptyGroupName();
  let value = '';
  const preferences = GET_PREFERENCES();
  const customField = GET_CUSTOM_FIELDS()[customFieldId];

  switch (customField.type) {
    case ECustomFieldType.RICHTEXT:
      value = generatePlainText(decodeBase64Text(pointCustomField?.value));
      break;
    case ECustomFieldType.CHECKBOX:
      value =
        pointCustomField?.value === 'true'
          ? translate('checkbox_filled')
          : translate('checkbox_empty');
      break;
    case ECustomFieldType.DATE:
      value = transformDate(pointCustomField?.value);
      break;
    case ECustomFieldType.TIME:
      value = transformTimeCF(
        pointCustomField?.value ? pointCustomField?.value : '',
        customField.showHoursOnly,
      );
      break;
    case ECustomFieldType.COST:
      value = transformCostCF(customField, pointCustomField, value);
      break;
    case ECustomFieldType.NUMBERS:
      value = transformNumbersCF(customField, pointCustomField, value);
      break;
    case ECustomFieldType.PERCENTAGE:
      value = pointCustomField?.value ? pointCustomField?.value + '%' : '';
      break;
    case ECustomFieldType.TIMELINE:
      value = transformTimelineCF(pointCustomField, emptyGroupValue, preferences, value);
      break;
    default:
      value = pointCustomField?.value;
      break;
  }

  return value || emptyGroupValue;
}
function transformTimelineCF(
  pointCustomField: TCustomFieldResponse,
  emptyGroupValue: string,
  preferences: TPreferences,
  value: string,
) {
  const timelineValue = pointCustomField?.value;
  let date = emptyGroupValue;

  if (timelineValue) {
    const dates = timelineValue.split('~');

    date =
      transformDate(dates[0], '', preferences.dateFormat) +
      ' - ' +
      transformDate(dates[1], '', preferences.dateFormat);
  }

  value = date;
  return value;
}

function transformNumbersCF(
  customField: TCustomField,
  pointCustomField: TCustomFieldResponse,
  value: string,
) {
  if (!value) {
    return '';
  }

  const unit = customField.unit ? customField.unit : '';
  const numbersValue = pointCustomField?.value ? pointCustomField?.value : '';

  value = numbersValue + unit;
  return value;
}

function transformCostCF(
  customField: TCustomField,
  pointCustomField: TCustomFieldResponse,
  value: string,
) {
  const costCurrencySymbol = customField.currencySymbol;
  const costValue = parseFloat(pointCustomField?.value);

  if (costValue) {
    value = formatCurrency(costValue, costCurrencySymbol, +customField.decimalPlaces);
  } else {
    value = '';
  }
  return value;
}
