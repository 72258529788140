import { EDashletType } from '../dashlets-enums';
import { TNewDashletData } from './new-dashlet-data.model';

export const ADVANCED_DASHLETS: TNewDashletData[] = [
  {
    key: 'dashlets_live_priority',
    value: EDashletType.CURRENT_PRIORITY,
    icon: 'assets/images/dashlet-preview/live-priority.png',
    preview_url: 'assets/images/dashlet-preview/live-priority.svg',
  },
  {
    key: 'dashlets_live_status',
    value: EDashletType.CURRENT_STATUS,
    icon: 'assets/images/dashlet-preview/live-status.png',
    preview_url: 'assets/images/dashlet-preview/live-status.svg',
  },
  {
    key: 'dashlet_over_time_status',
    value: EDashletType.OVER_TIME_STATUS,
    icon: 'assets/images/dashlet-preview/over-time-status.png',
    preview_url: 'assets/images/dashlet-preview/status-over-time.svg',
  },
  {
    key: 'dashlet_over_time_priority',
    value: EDashletType.OVER_TIME_PRIORITY,
    icon: 'assets/images/dashlet-preview/over-time-priority.png',
    preview_url: 'assets/images/dashlet-preview/priority-over-time.svg',
  },
];
