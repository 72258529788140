import { VERSION } from 'src/environments/version';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TUser } from 'src/app/project/modules/user/user.model';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { ModalService } from '../../../components/modal/modal.service';
import { AuthService } from '../../auth/auth.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { UserService } from '../../user/user.service';

import { WindowService } from '@core/services';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserDropdown,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-user-menu-dropdown',
  templateUrl: './user-menu-dropdown.component.html',
  styleUrls: ['./user-menu-dropdown.component.scss'],
})
export class UserMenuDropdownComponent implements OnInit {
  user$: Observable<TUser>;
  user: TUser;
  offline: boolean = this.offlineService.getOffline();
  version = VERSION.version;
  cookiesEnabled = false;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{ user: TUser }>,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private dropdownService: DropdownService,
    private modalService: ModalService,
    private preferencesService: PreferencesService,
    private offlineService: OfflineService,
    private windowService: WindowService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
  }

  ngOnInit() {
    this.cookiesEnabled = !!this.windowService.getCookiehub();
  }

  logout(): void {
    if (!this.offline) {
      this.authService.logout();

      this.hideDropdown();
    }
  }

  hideDropdown(routerLink: string = null): void {
    this.user = this.userService.getUser();
    this.dropdownService.hideDropdown();

    if (routerLink) {
      if (routerLink === '/settings/user') {
        logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__PROFILE_SETTINGS, {
          event_category: EGoogleEventCategory.USER_DROPDOWN,
        });
        this.router.navigate([routerLink]);
      } else if (routerLink === '/settings/account') {
        routerLink += '/' + this.user.accountId + '/sites';

        logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__ACCOUNT_SETTINGS, {
          event_category: EGoogleEventCategory.USER_DROPDOWN,
        });

        this.router.navigate([routerLink]);
      } else if (routerLink === 'contact') {
        logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__CONTACT, {
          event_category: EGoogleEventCategory.USER_DROPDOWN,
        });
      }
    }
  }

  openCookiesSettings(): void {
    logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__COOKIES, {
      event_category: EGoogleEventCategory.USER_DROPDOWN,
    });

    this.windowService.getCookiehub().openSettings();
  }

  openHelpCentre(): void {
    logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__HELP, {
      event_category: EGoogleEventCategory.USER_DROPDOWN,
    });

    this.windowService.open('https://intercom.help/pinpointworks');
  }

  openChangelog(): void {
    logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__CHANGELOG, {
      event_category: EGoogleEventCategory.USER_DROPDOWN,
    });

    this.windowService.open(
      'https://intercom.help/pinpointworks/en/articles/4875753-pinpoint-works-updates',
    );
  }

  openUpgradePage(): void {
    logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN__UPGRADE, {
      event_category: EGoogleEventCategory.USER_DROPDOWN,
    });

    this.windowService.open(
      'https://intercom.help/pinpointworks/en/articles/5847615-add-on-features-timeline-reminders',
    );
  }

  openHiring(): void {
    logEventInGTAG(EGoogleEventUserDropdown.USER_DROPDOWN_HIRING, {
      event_category: EGoogleEventCategory.USER_DROPDOWN,
    });

    this.windowService.open('https://www.pinpointworks.com/hiring');
  }
}
