<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_fleet_status_filter_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_fleet_status_filter_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetStatusFilter__itemsWrapper">
    <div class="fleetStatusFilter__items">
      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.OPEN"
        [(ppInclude)]="ppStatuses[EStatus.OPEN].include"
      ></pp-fleet-management-new-fleet-status-checkbox>

      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.IN_PROGRESS"
        [(ppInclude)]="ppStatuses[EStatus.IN_PROGRESS].include"
      ></pp-fleet-management-new-fleet-status-checkbox>

      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.TO_REVIEW"
        [(ppInclude)]="ppStatuses[EStatus.TO_REVIEW].include"
      ></pp-fleet-management-new-fleet-status-checkbox>
    </div>

    <div class="fleetStatusFilter__items">
      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.ONHOLD"
        [(ppInclude)]="ppStatuses[EStatus.ONHOLD].include"
      ></pp-fleet-management-new-fleet-status-checkbox>

      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.CLOSED"
        [(ppInclude)]="ppStatuses[EStatus.CLOSED].include"
      ></pp-fleet-management-new-fleet-status-checkbox>

      <pp-fleet-management-new-fleet-status-checkbox
        [ppStatus]="EStatus.CANCELED"
        [(ppInclude)]="ppStatuses[EStatus.CANCELED].include"
      ></pp-fleet-management-new-fleet-status-checkbox>
    </div>
  </div>
</section>
