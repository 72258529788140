import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { UpdateUserUnreadNotifications } from '../user/user.actions';

import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from '../workspace/workspace.model';
import { TPushNotification } from './notification.model';

import { DeviceService } from '@core/services';
import { SidePanelService } from '../layout/side-panel/side-panel.service';
import { UsersService } from '../users/users.service';
import { NotificationsService } from './notifications.service';

import { NavigationEnd, Router } from '@angular/router';
import { SiteService } from 'src/app/project/modules/site/site.service';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNotifications,
} from '../../services/analytics/google-analytics.consts';
import { EIconPath } from '../../shared/enums/icons.enum';
import { EStore } from '../../shared/enums/store.enum';
import { DEFAULT_DATE_FORMAT } from '../preferences/default-date-format';
import { GET_NOTIFICATIONS, notificationsChange$ } from './notifications.store';

@Component({
  selector: 'pp-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notifications') notificationsElement: ElementRef;

  @Input() ppExpanded = false;

  private readonly destroy$ = new Subject<void>();

  todayNotifications: TPushNotification[] = [];
  yesterdayNotifications: TPushNotification[] = [];
  olderNotifications: TPushNotification[] = [];

  preferences$: Observable<TPreferences>;
  format = DEFAULT_DATE_FORMAT;
  preferences: TPreferences;
  notificationsMode = this.notificationsService.getNotificationsMode();
  notifications = GET_NOTIFICATIONS();
  dataFetched = this.siteService.getFetched();

  numberOfNotifications = 0;
  loading = false;
  allNotificationsRead = false;
  usersFetched = false;
  private containerResizeObserver: ResizeObserver;
  finishedFetching?: boolean;

  isMobile: boolean;
  isiPad: boolean;
  isiPod: boolean;
  EIconPath = EIconPath;

  private workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      preferences: TPreferences;
    }>,
    private notificationsService: NotificationsService,
    private deviceService: DeviceService,
    private sidePanelService: SidePanelService,
    private usersService: UsersService,
    private siteService: SiteService,
    private router: Router,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();
    this.isiPod = this.deviceService.isiPod();

    this.initNotifications(this.notifications[this.notificationsMode.mode]);
    this.usersService
      .fetchAllUsers()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.usersFetched = true;
        }),
      )
      .subscribe();

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.preferences = preferences;

      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });

    notificationsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.initNotifications(this.notifications[this.notificationsMode.mode]);

      this.allNotificationsRead = !this.notifications[this.notificationsMode.mode].some(
        (notification) => !notification.markedAsRead,
      );
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideNotificationsPanel();
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new UpdateUserUnreadNotifications(false));

    if (this.containerResizeObserver) {
      this.containerResizeObserver.disconnect();
    }

    this.destroy$.next();
  }

  markAllAsRead(): void {
    if (!this.allNotificationsRead) {
      this.notificationsService.markAllNotificationsRead();

      this.allNotificationsRead = true;
    }
  }

  hideNotificationsPanel(): void {
    this.sidePanelService.collapsePanel();
  }

  switchMode(mode: string): void {
    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS_MODE, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
      event_value: mode,
    });

    this.notificationsService.setNotificationsMode(mode);
    this.finishedFetching = false;

    this.todayNotifications = [];
    this.yesterdayNotifications = [];
    this.olderNotifications = [];

    this.loading = true;
    // improves user viewing performance so switching modes doesn't wait for it

    this.notificationsService
      .fetchNotifications({ offset: 0, refetch: true })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.initNotifications(this.notifications[mode]);
        }),
        finalize(() => {
          this.loading = false;
          this.finishedFetching = this.notificationsService.getAllNotificationsFetched();
        }),
      )
      .subscribe();
  }

  filterNotifications(): void {
    this.loading = true;
    this.notificationsService
      .fetchNotifications({ refetch: true })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  onScroll(event: Event): void {
    if (event.target instanceof HTMLElement) {
      const scrolledToBottom =
        event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight;

      if (scrolledToBottom) {
        this.fetchMoreNotifications();
      }
    }
  }

  fetchMoreNotifications(): void {
    const allNotificationsFetched = this.notificationsService.getAllNotificationsFetched();

    if (!this.loading && !allNotificationsFetched) {
      this.loading = true;

      this.notificationsService
        .fetchNotifications({ offset: this.numberOfNotifications })
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loading = false;

            this.finishedFetching = this.notificationsService.getAllNotificationsFetched();
          }),
        )
        .subscribe();
    }
  }

  onScrollRendered(): void {
    const notificationsScrollElement = document.getElementById('notificationsScroll');

    if (this.containerResizeObserver) {
      this.containerResizeObserver.disconnect();
    }

    if (notificationsScrollElement && this.notificationsElement) {
      this.containerResizeObserver = new ResizeObserver((entries) => {
        const resizeEntry = entries[0];
        const needsToLoadMore =
          (resizeEntry.target as HTMLElement).offsetHeight <
          this.notificationsElement?.nativeElement.offsetHeight;

        if (needsToLoadMore) {
          this.fetchMoreNotifications();
        }
      });

      this.containerResizeObserver.observe(notificationsScrollElement);
    }
  }

  private initNotifications(notifications: TPushNotification[]): void {
    const today = new Date();
    const yesterday = new Date();

    today.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.todayNotifications = [];
    this.yesterdayNotifications = [];
    this.olderNotifications = [];

    notifications.forEach((notification) => {
      if (notification.timestampEpochMillis > +today) {
        this.todayNotifications.push(notification);
      } else if (notification.timestampEpochMillis > +yesterday) {
        this.yesterdayNotifications.push(notification);
      } else {
        this.olderNotifications.push(notification);
      }
    });

    this.numberOfNotifications = notifications.length;
    this.finishedFetching = this.notificationsService.getAllNotificationsFetched();
  }
}
