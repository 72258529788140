import { Routes } from '@angular/router';

import { SiteComponent } from './site-component/site.component';
import { SiteNewComponent } from './site-new/site-new.component';
import { SiteSettingsComponent } from './site-settings/site-settings.component';
import { SiteSettingsTagsComponent } from './site-settings/site-settings-tags/site-settings-tags.component';
import { SiteSettingsCustomFieldsComponent } from './site-settings/site-settings-custom-fields/site-settings-custom-fields.component';
import { SiteSettingsUsersComponent } from './site-settings/site-settings-users/site-settings-users.component';
import { SiteSettingsPlanComponent } from './site-settings/site-settings-plan/site-settings-plan.component';
import { PointHalfModalComponent } from '../points/point-half-modal/point-half-modal.component';
import { PointFullModalComponent } from '../points/point-full-modal/point-full-modal.component';
import { SiteOverviewComponent } from './site-overview/site-overview.component';
import { SiteSettingsLogoComponent } from './site-settings/site-settings-logo/site-settings-logo.component';
import { PointGalleryComponent } from '../points/point-gallery/point-gallery.component';
import { SiteTimelineComponent } from './site-timeline/site-timeline.component';
import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';

export const siteRoutes: Routes = [
  {
    path: 'site/overview',
    component: SiteOverviewComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      // Points open even without that part but site is initialized again, which causes bugs
      {
        path: 'point/:_id',
        component: PointFullModalComponent,
        outlet: 'full',
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
      {
        path: 'point/:_id',
        component: PointHalfModalComponent,
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'site/timeline',
    component: SiteTimelineComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: 'point/:_id',
        component: PointFullModalComponent,
        outlet: 'full',
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
      {
        path: 'point/:_id',
        component: PointHalfModalComponent,
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'site/:id',
    component: SiteComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: 'point/:_id',
        component: PointFullModalComponent,
        outlet: 'full',
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
      {
        path: 'point/:_id',
        component: PointHalfModalComponent,
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'site',
    component: SiteComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },

  {
    path: 'settings/site/new',
    component: SiteNewComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },
  {
    path: 'settings/site/new/:id',
    component: SiteNewComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },
  {
    path: 'settings/site/:id',
    component: SiteSettingsComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'tags',
      },
      {
        path: 'tags',
        component: SiteSettingsTagsComponent,
      },
      {
        path: 'custom-fields',
        component: SiteSettingsCustomFieldsComponent,
      },
      {
        path: 'users',
        component: SiteSettingsUsersComponent,
      },
      {
        path: 'plan',
        component: SiteSettingsPlanComponent,
      },
      {
        path: 'logo',
        component: SiteSettingsLogoComponent,
      },
    ],
  },
];
