import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../components/modal/modal.service';
import { DashboardService } from '../dashboard-service/dashboard.service';

import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDashletType } from '../dashlets-enums';
import { ADVANCED_DASHLETS } from './new-dashlet-advanced-dashlets';
import { TNewDashletData } from './new-dashlet-data.model';
import { SIMPLE_DASHLETS } from './new-dashlet-simple-dashlets';
import { ENewDashletTabs } from './new-dashlet-tabs-enum';

@Component({
  selector: 'app-new-dashlet-modal',
  templateUrl: './new-dashlet-modal.component.html',
  styleUrls: ['./new-dashlet-modal.component.scss'],
})
export class NewDashletModalComponent implements OnInit {
  currentTab: string;
  simpleDashlets: TNewDashletData[];
  advancedDashlets: TNewDashletData[];
  dashletTabs = ENewDashletTabs;
  private newDashlet: {
    value: EDashletType;
  };

  constructor(private dashboardService: DashboardService, private modalService: ModalService) {}

  ngOnInit() {
    this.newDashlet = {
      value: EDashletType.ACTIVITIES,
    };

    this.currentTab = ENewDashletTabs.SIMPLE_DASHLETS;
    this.simpleDashlets = SIMPLE_DASHLETS;
    this.advancedDashlets = ADVANCED_DASHLETS;
  }

  setTab(tab: ENewDashletTabs): void {
    this.currentTab = tab;
  }

  addDashlet(newDashlet: EDashletType): void {
    this.newDashlet.value = newDashlet;

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__ADD, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_value: newDashlet,
    });

    this.dashboardService.addDashlet(this.newDashlet);
    this.hideModal();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
