import { EActionType, ActionsUnion } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { addAllSortedPointIds } from './addAllSortedPointIds';
import { addWorkspaceSortedPointIds } from './addWorkspaceSortedPointIds';
import { updatePointComment } from './updatePointComment';
import { removeAttachments } from './removeAttachments';
import { addAttachment } from './addAttachment';
import { updatePointPin } from './updatePointPin';
import { updatePointsCustomFields } from './updatePointsCustomFields';
import { updatePointCustomFields } from './updatePointCustomFields';
import { updatePointsAssignees } from './updatePointsAssignees';
import { updatePointsTags } from './updatePointsTags';
import { updatePointAssignees } from './updatePointAssignees';
import { updatePointTags } from './updatePointTags';
import { updatePointDescription } from './updatePointDescription';
import { updatePointTitle } from './updatePointTitle';
import { updatePointsPriorities } from './updatePointsPriorities';
import { updatePointReminder } from './updatePointReminder';
import { updatePointPriority } from './updatePointPriority';
import { updatePointStatus } from './updatePointStatus';
import { togglePointSelection } from './togglePointSelection';
import { toggleAllPointsSelection } from './toggleAllPointsSelection';
import { deselectWorkspacePoints } from './deselectWorkspacePoints';
import { selectWorkspacePoints } from './selectWorkspacePoints';
import { deletePoints } from './deletePoints';
import { deletePoint } from './deletePoint';
import { updatePoints } from './updatePoints';
import { addPoint } from './addPoint';
import { filterAllPoints } from './filterAllPoints';
import { filterPoints } from './filterPoints';
import { setAllPoints } from './setAllPoints';
import { updatePoint } from './updatePoint';
import { setPoints } from './setPoints';
import { togglePointsSelection } from './toggle-points-selection';
import { updatePointCustomFieldBulk } from './update-point-custom-field-bulk';
import { updatePointFlag } from './updatePointFlag';

export const initialState: TPointsByWorkspace = {};

export function pointsReducer(
  oldState: TPointsByWorkspace = initialState,
  action: ActionsUnion,
): TPointsByWorkspace {
  switch (action.type) {
    case EActionType.SET_POINTS:
      return setPoints(oldState, action);

    case EActionType.SET_ALL_POINTS:
      return setAllPoints(oldState, action);

    case EActionType.FILTER_POINTS:
      return filterPoints(oldState, action);

    case EActionType.FILTER_ALL_POINTS:
      return filterAllPoints(oldState, action);

    case EActionType.CLEAR_POINTS:
      return {};

    case EActionType.ADD_POINT:
      return addPoint(oldState, action);

    case EActionType.UPDATE_POINT:
      return updatePoint(oldState, action);
    case EActionType.UPDATE_POINTS:
      return updatePoints(oldState, action);

    case EActionType.DELETE_POINT:
      return deletePoint(oldState, action);

    case EActionType.DELETE_POINTS:
      return deletePoints(oldState, action);

    case EActionType.SELECT_WORKSPACE_POINTS:
      return selectWorkspacePoints(oldState, action);

    case EActionType.DESELECT_WORKSPACE_POINTS:
      return deselectWorkspacePoints(oldState, action);

    case EActionType.TOGGLE_ALL_POINTS_SELECTION:
      return toggleAllPointsSelection(oldState, action);

    case EActionType.TOGGLE_POINT_SELECTION:
      return togglePointSelection(oldState, action);

    case EActionType.TOGGLE_POINTS_SELECTION:
      return togglePointsSelection(oldState, action);

    case EActionType.UPDATE_POINT_STATUS:
      return updatePointStatus(oldState, action);

    case EActionType.UPDATE_POINT_FLAG:
      return updatePointFlag(oldState, action);

    case EActionType.UPDATE_POINT_PRIORITY:
      return updatePointPriority(oldState, action);

    case EActionType.UPDATE_POINT_REMINDER:
      return updatePointReminder(oldState, action);

    case EActionType.UPDATE_POINTS_PRIORITIES:
      return updatePointsPriorities(oldState, action);

    case EActionType.UPDATE_POINT_TITLE:
      return updatePointTitle(oldState, action);

    case EActionType.UPDATE_POINT_DESCRIPTION:
      return updatePointDescription(oldState, action);

    case EActionType.UPDATE_POINT_TAGS:
      return updatePointTags(oldState, action);

    case EActionType.UPDATE_POINT_ASSIGNEES:
      return updatePointAssignees(oldState, action);

    case EActionType.UPDATE_POINTS_TAGS:
      return updatePointsTags(oldState, action);

    case EActionType.UPDATE_POINTS_ASSIGNEES:
      return updatePointsAssignees(oldState, action);

    case EActionType.UPDATE_POINT_CUSTOM_FIELD:
      return updatePointCustomFields(oldState, action);

    case EActionType.UPDATE_POINT_CUSTOM_FIELD_BULK:
      return updatePointCustomFieldBulk(oldState, action);

    case EActionType.UPDATE_POINTS_CUSTOM_FIELDS:
      return updatePointsCustomFields(oldState, action);

    case EActionType.UPDATE_POINT_PIN:
      return updatePointPin(oldState, action);

    case EActionType.ADD_IMAGE:
    case EActionType.ADD_IMAGE_360:
    case EActionType.ADD_VIDEO:
    case EActionType.ADD_FILE:
    case EActionType.REPLACE_IMAGE:
      return addAttachment(oldState, action);

    case EActionType.REMOVE_ATTACHMENTS:
      return removeAttachments(oldState, action);

    case EActionType.UPDATE_POINT_COMMENT:
      return updatePointComment(oldState, action);

    case EActionType.ADD_WORKSPACE_SORTED_POINT_IDS:
      return addWorkspaceSortedPointIds(oldState, action);

    case EActionType.ADD_ALL_SORTED_POINT_IDS:
      return addAllSortedPointIds(oldState, action);

    default:
      return oldState;
  }
}
