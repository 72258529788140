import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TAssetCreateDTO } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-requests.model';
import {
  TAssetDetails,
  TAssetLabel,
  TNewAsset,
  TNewAssetSite,
} from '../../asset-service/asset.consts';

@Injectable({
  providedIn: 'root',
})
export class NewAssetService {
  private _newAssetChange$ = new Subject<TAssetCreateDTO>();
  public newAssetChange$ = this._newAssetChange$.asObservable();

  private newAsset: TAssetCreateDTO = {
    name: '',
    imageId: '',
    sites: [],
    details: {
      beam: '',
      draft: '',
      length: '',
      grossTonnage: '',
      imoNumber: '',
      build: '',
    },
    labels: [],
  };

  private assetImage: File;

  constructor() {}

  clearNewAsset(): void {
    this.newAsset = {
      name: '',
      imageId: '',
      sites: [],
      details: {
        beam: '',
        draft: '',
        length: '',
        grossTonnage: '',
        imoNumber: '',
        build: '',
      },
      labels: [],
    };

    this._newAssetChange$.next(this.newAsset);
  }

  getNewAsset(): TAssetCreateDTO {
    return this.newAsset;
  }

  setNewAsset(asset: TNewAsset): void {
    this.newAsset = {
      name: asset.name,
      imageId: asset.imageId,
      sites: asset.sites,
      details: asset.details,
      labels: asset.labels,
    };

    this._newAssetChange$.next(this.newAsset);
  }

  setAssetName(assetName: string): void {
    this.newAsset.name = assetName;

    this._newAssetChange$.next(this.newAsset);
  }

  setAssetImage(image: File): void {
    this.assetImage = image;

    this._newAssetChange$.next(this.newAsset);
  }

  getAssetImage(): File {
    return this.assetImage;
  }

  setAssetDetails(details: TAssetDetails): void {
    this.newAsset.details = details;

    this._newAssetChange$.next(this.newAsset);
  }

  setAssetLabels(labels: TAssetLabel[]): void {
    this.newAsset.labels = labels;

    this._newAssetChange$.next(this.newAsset);
  }

  setAssetSites(sites: TNewAssetSite[]): void {
    this.newAsset.sites = sites;

    this._newAssetChange$.next(this.newAsset);
  }

  setAssetImageId(imageId: string): void {
    this.newAsset.imageId = imageId;

    this._newAssetChange$.next(this.newAsset);
  }
}
