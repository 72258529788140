import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { ClickOutsideHandler } from '@core/services';
import { Subject, timer } from 'rxjs';
import { createAutonumeric } from 'src/app/core/helpers/create-autonumeric';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointFieldsService } from '../../points/point-modal/point-fields/point-fields.service';
import { TPoint } from '../../points/points.model';
import { PointsService } from '../../points/points.service';
import { ECustomFieldType } from '../custom-field-types-enums';

@Component({
  selector: 'pp-custom-field-cost',
  templateUrl: './custom-field-cost.component.html',
  styleUrls: ['./custom-field-cost.component.scss'],
})
export class CustomFieldCostComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('costInput', { static: false }) costInputElement: ElementRef;
  @ViewChild('customFieldElement', { static: false }) customFieldElement: ElementRef;

  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppFieldCurrencySymbol: string;
  @Input() ppFieldCurrencyCode: string;
  @Input() ppFieldValue: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  @Output() ppUpdate = new EventEmitter<string>();

  value: string;

  updating = false;
  success = false;
  error = false;
  focused = false;
  EIconPath = EIconPath;
  private readonly destroy$ = new Subject<void>();

  options = {
    watchExternalChanges: true,
    modifyValueOnWheel: false,
    digitGroupSeparator: '.',
    decimalCharacter: ',',
    decimalCharacterAlternative: '.',
  };

  private clickOutsideHandler: ClickOutsideHandler;
  private removeClickListenerTimerMs = 100;

  constructor(
    private pointFieldsService: PointFieldsService,
    private pointsService: PointsService,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppPointId) {
      const point: TPoint = this.pointsService.findPoint(this.ppFieldId);

      if (point) {
        this.ppFieldValue = point.customFieldsMap[this.ppFieldId]?.value;
      }
    }
  }

  ngAfterViewInit() {
    if (this.costInputElement) {
      createAutonumeric(this.costInputElement.nativeElement as HTMLInputElement);
    }

    this.clickOutsideHandler = new ClickOutsideHandler(
      this.customFieldElement.nativeElement,
      this.destroy$,
    );

    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.ppFieldValue = null;
    this.updateField();
  }

  updateField(): void {
    const fieldId = this.ppFieldId;
    const fieldValue = this.ppFieldValue;

    const newCF = this.pointFieldsService.createCustomField2(
      fieldId,
      fieldValue,
      ECustomFieldType.COST,
    );

    this.ppUpdate.emit(newCF[0][fieldId]);
  }

  onBlur(): void {
    this.focused = false;
    this.ngZone.runOutsideAngular(() => {
      timer(this.removeClickListenerTimerMs).subscribe(() => {
        this.clickOutsideHandler.disable();
      });
    });
  }

  onFocus(): void {
    this.focused = true;

    this.clickOutsideHandler.enable();
  }

  blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  private onClickOutside(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.costInputElement.nativeElement.blur();
  }
}
