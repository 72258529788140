import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointsApiProviderService } from 'src/app/project/data-providers/api-providers/points-api-provider/points-api-provider.service';
import {
  TCheckTargetSiteRequest,
  TPointSizesRequest,
} from 'src/app/project/data-providers/api-providers/points-api-provider/points-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TCheckTransferResponse } from '../../../../view-models/check-transfer-response.model';
import { logErrorInSentry } from '../../../errors/response-error';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';
import { EditPointService } from '../../../points/point-full-modal/edit-point.service';
import { PointHalfModalService } from '../../../points/point-half-modal/point-half-modal.service';
import { PointActivityService } from '../../../points/point-modal/point-timeline/point-activity.service';
import { PointsFetchingService } from '../../../points/points-fetching.service';
import { DeletePoints, DeselectWorkspacePoints } from '../../../points/points.actions';
import { TPoint } from '../../../points/points.model';
import { removeSelectedPoint } from '../../../points/selected-points';
import { TCopyMoveOptions } from './copy-move-options';

@Injectable({
  providedIn: 'root',
})
export class PointsCopyMoveModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private responseErrorService: ResponseErrorService,
    private store: Store,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private pointActivityService: PointActivityService,
    private pointsApiProviderService: PointsApiProviderService,
    private pointHalfModalService: PointHalfModalService,
    private editPointService: EditPointService,
    private sitePointFilterService: SitePointFilterService,
    private pointsFetchingService: PointsFetchingService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  checkTargetSite(
    sourceWorkspaceId: string,
    targetWorkspaceId: string,
  ): Observable<TCheckTransferResponse> {
    const body: TCheckTargetSiteRequest = {
      sourceWorkspaceId: sourceWorkspaceId,
      targetWorkspaceId: targetWorkspaceId,
    };

    return this.pointsApiProviderService
      .checkTargetSiteForTransfer(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  movePoints({
    sourceWorkspaceId,
    numberOfPoints,
    pointIds,
    selectedPoints,
    selectedWorkspaceId,
    options,
  }: {
    sourceWorkspaceId: string;
    numberOfPoints: number;
    pointIds: string[];
    selectedPoints: TPoint[];
    selectedWorkspaceId: string;
    options: TCopyMoveOptions;
  }): Observable<string> {
    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__MOVE, {
      event_category: EGoogleEventCategory.SITE,
      amount: numberOfPoints,
    });

    const body: TPointSizesRequest = {
      pointIds: pointIds,
      sourceWorkspaceId: sourceWorkspaceId,
      targetWorkspaceId: selectedWorkspaceId,
      options,
    };

    return this.pointsApiProviderService.movePoints(body).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        const promptText = this.translationPipe.transform('prompt_point_moved_success');
        const openedPointId = this.pointHalfModalService.getOpenedPointId();
        let pointDataIds;

        if (selectedPoints) {
          pointDataIds = selectedPoints.map((point) => point._id);
        } else {
          pointDataIds = [openedPointId];
        }

        this.editPointService.hideModal(true);

        if (pointIds.includes(openedPointId)) {
          this.pointHalfModalService.hideModal(false);
        }

        this.store.dispatch(
          new DeletePoints({
            workspaceId: sourceWorkspaceId,
            pointIds: pointDataIds,
          }),
        );

        pointIds.forEach((pointId) => {
          removeSelectedPoint(pointId);
        });

        this.store.dispatch(new DeselectWorkspacePoints({ workspaceId: sourceWorkspaceId }));
        this.sitePointFilterService.filterPoints();
        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        logErrorInSentry(error);

        let promptText = this.translationPipe.transform('prompt_points_move_error');

        if (error.status === EStatusCode.UNAUTHORIZED) {
          promptText = this.translationPipe.transform('prompt_move_permission_denied');
        } else if (error.status === EStatusCode.BAD_REQUEST) {
          promptText = this.translationPipe.transform('point_move_limit_exceeded');
        }

        this.promptService.showError(promptText);

        return throwError(error);
      }),
    );
  }

  copyPoints({
    sourceWorkspaceId,
    numberOfPoints,
    pointIds,
    selectedWorkspaceId,
    options,
  }: {
    sourceWorkspaceId: string;
    numberOfPoints: number;
    pointIds: string[];
    selectedWorkspaceId: string;
    options: TCopyMoveOptions;
  }): Observable<string> {
    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__COPY, {
      event_category: EGoogleEventCategory.SITE,
      amount: numberOfPoints,
    });

    const body: TPointSizesRequest = {
      pointIds: pointIds,
      sourceWorkspaceId: sourceWorkspaceId,
      targetWorkspaceId: selectedWorkspaceId,
      options,
    };

    return this.pointsApiProviderService.copyPoints(body).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        const promptText = this.translationPipe.transform('prompt_point_copy_success');
        const openedPointId = this.pointHalfModalService.getOpenedPointId();

        if (openedPointId) {
          this.pointActivityService.refreshTimeline(sourceWorkspaceId, openedPointId);
        }

        this.store.dispatch(new DeselectWorkspacePoints({ workspaceId: sourceWorkspaceId }));

        this.promptService.showSuccess(promptText);

        if (sourceWorkspaceId === selectedWorkspaceId) {
          this.pointsFetchingService
            .fetchPoints(sourceWorkspaceId)
            .pipe(
              tap(() => {
                this.sitePointFilterService.filterPoints();
              }),
            )
            .subscribe();
        }
      }),
      catchError((error) => {
        logErrorInSentry(error);

        let promptText = this.translationPipe.transform('prompt_copy_failed');

        if (error.status === EStatusCode.UNAUTHORIZED) {
          promptText = this.translationPipe.transform('prompt_copy_permission_denied');
        } else if (error.status === EStatusCode.BAD_REQUEST) {
          promptText = this.translationPipe.transform('point_copy_limit_exceeded');
        }

        this.promptService.showError(promptText);

        return throwError(error);
      }),
    );
  }
}
