import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { autosizeTextArea } from 'src/app/project/shared/utils/autosize-text-area';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TCustomField } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-field-text',
  templateUrl: './bulk-field-text.component.html',
  styleUrls: ['./bulk-field-text.component.scss'],
})
export class BulkFieldTextComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<string>;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<string>;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  EIconPath = EIconPath;
  private characterLimit = 5000;

  constructor(private translationPipe: TranslationPipe, private promptService: PromptService) {}

  updateText(field: TCustomField): void {
    if (!this.ppCustomFields[field.id]) {
      this.ppCustomFields[field.id] = '';
    }

    this.ppCustomFieldsChange.emit(this.ppCustomFields);

    if (this.ppCustomFields[field.id].trim().length > this.characterLimit) {
      const promptText = this.translationPipe.transform('prompt_text_over_limit', {
        characterLimit: this.characterLimit,
      });

      this.promptService.showWarning(promptText);

      return;
    }

    this.ppCustomFieldsExtended[field.id] = {
      value: this.ppCustomFields[field.id].trim(),
      label: field.label,
      type: field.type,
    };

    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }

  blurInput(event): void {
    event.target.blur();
  }

  clearCustomField(fieldId: string): void {
    this.ppCustomFields[fieldId] = undefined;
    this.ppCustomFieldsExtended[fieldId] = {};

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }

  onInput(event: Event): void {
    const element = event.target as HTMLElement;

    autosizeTextArea(element);
  }
}
