import { Injectable } from '@angular/core';
import {
  API_activities_from,
  API_dashboard_current,
  API_dashboard_events,
  API_dashboard_report,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TDashletActivity } from 'src/app/project/modules/dashboard/dashlet/dashlet-activities/dashlet-activity/dashlet-activity.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchEvents(type: string, period: string, targetId: string): Observable<number> {
    const url = API_dashboard_events(type, period, targetId);

    return this.apiService.get<number>(url);
  }

  fetchActivities(from: number, size: number, targetId: string): Observable<TDashletActivity[]> {
    const url = API_activities_from(from, size, targetId);

    return this.apiService.get<TDashletActivity[]>(url);
  }

  fetchReports(
    type: string,
    period: string,
    length: number,
    targetId: string,
  ): Observable<number[][]> {
    const url = API_dashboard_report(type, period, length, targetId);

    return this.apiService.get<number[][]>(url);
  }

  fetchCurrentTypeCount(type: string, targetId: string): Observable<number[]> {
    const url = API_dashboard_current(type, targetId);

    return this.apiService.get<number[]>(url);
  }
}
