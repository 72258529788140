import { cloneDeep } from 'lodash';
import { FilterPoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { addVisiblePoint, removeVisiblePoint } from '../visible-points';

export function filterPoints(
  oldState: TPointsByWorkspace,
  action: FilterPoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  state[action.payload.workspaceId].entities.forEach((point) => {
    removeVisiblePoint(point._id);

    action.payload.points.forEach((_id) => {
      if (point._id === _id) {
        addVisiblePoint(point._id);
      }
    });
  });

  return state;
}
