import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { uuid } from '@core/helpers';
import { TSelectDropdownData } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.model';
import { DropdownService } from '../../../../../../components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../../../../../components/dropdown/dropdown.consts';
import { SelectDropdownComponent } from '../../../../../../components/input/select/select-dropdown/select-dropdown.component';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { customFieldIconSrc } from '../../../../../custom-fields/custom-fields.data';
import { TCustomField } from '../../../../../custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../../../custom-fields/custom-fields.service';
import { ESimplePermission } from '../../../../../share/permissions.consts';
import {
  convertGranularPermissionToSimplePermission,
  convertSimplePermissionToGranularPermission,
} from '../../../../../share/share-utils/permissions-converter.utils';
import { TCustomFieldAccessOption } from '../../../../../share/share.model';
import { getDropdownDataForColumn } from '../../../../../user-management/user-management-table-cells/utils/dropdown-data.utils';
import { getViewDataByPermission } from '../../../../../user-management/user-management-table-cells/utils/permission-view-data.util';
import { TPermissionItemByColumn } from '../../../../../user-management/user-management-table-cells/utils/permissions-per-column.utils';
import { EColumn } from '../../../../../user-management/user-management.consts';
import { EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH } from '../../edit-account-user-modal.consts';

@Component({
  selector: 'pp-account-user-modal-custom-field',
  templateUrl: './account-user-modal-custom-field.component.html',
  styleUrls: ['./account-user-modal-custom-field.component.scss'],
})
export class AccountUserModalCustomFieldComponent implements OnChanges {
  @Input() ppCustomField: TCustomFieldAccessOption;
  @Output() ppCustomFieldChange = new EventEmitter<TCustomFieldAccessOption>();
  @Input() ppWorkspaceId: string;
  @Input() ppEven: boolean;
  @Input() ppShareOption: string; // TODO EUserRole

  customField: TCustomField;
  customFieldIconSrc = customFieldIconSrc;
  iconSrc: EIconPath;
  permissionIconPath: EIconPath;
  permissionText: string;
  EIconPath = EIconPath;
  buttonId = uuid();
  dropdown: TDropdown;

  constructor(
    private customFieldsService: CustomFieldsService,
    private dropdownService: DropdownService,
  ) {
    this.dropdown = this.dropdownService.getDropdown();
  }

  ngOnChanges() {
    this.initView();
  }

  toggleDropdown(): void {
    if (this.dropdownService.getDropdown().visible) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, SelectDropdownComponent, {
        callback: (permission: TPermissionItemByColumn) => this.selectPermission(permission),
        width: EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH,
      });
    }
  }

  private initView(): void {
    this.getCustomField();
    this.setPermissionViewData();
  }

  private getCustomField(): void {
    this.customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppCustomField.templateId,
    );
    this.iconSrc = customFieldIconSrc[this.customField.type];
  }

  private setPermissionViewData(): void {
    const simplePermission = convertGranularPermissionToSimplePermission(
      this.ppCustomField.permission,
    );
    const permission = getViewDataByPermission(simplePermission);

    this.permissionIconPath = permission.iconPath;
    this.permissionText = permission.text;
  }

  private setDropdownData(): void {
    const simplePermission = convertGranularPermissionToSimplePermission(
      this.ppCustomField.permission,
    );

    this.dropdownService.setData<TSelectDropdownData>(
      getDropdownDataForColumn(EColumn.CUSTOM_FIELD, this.ppShareOption, simplePermission),
    );
  }

  private selectPermission(permissionItem: TPermissionItemByColumn): void {
    const granularPermission = convertSimplePermissionToGranularPermission(
      permissionItem.value as ESimplePermission,
    );

    this.ppCustomField.permission = granularPermission;
    this.ppCustomFieldChange.emit(this.ppCustomField);

    this.initView();
  }
}
