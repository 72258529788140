import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';

import {
  API_preferences_sites_workspaces,
  API_preferences_user,
  API_preferences_users_workspaces,
} from '@core/api/paths';
import { TGuid } from '@core/helpers';
import { Observable } from 'rxjs';
import { getWorkspacePreferencesRequestURL } from 'src/app/project/modules/preferences/preferences-service/update-workspace-preferences-utils';
import {
  TPreferences,
  TUpdateExportPreference,
  TWorkspacePreferences,
} from 'src/app/project/modules/preferences/preferences.model';
import { TColumn } from 'src/app/project/modules/site/site-table/columns/column.model';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';

@Injectable({
  providedIn: 'root',
})
export class PreferencesApiProviderService {
  constructor(private apiService: ApiService) {}

  getUserPreferences(userId: TGuid): Observable<TPreferences> {
    const url = API_preferences_user(userId);

    return this.apiService.get<TPreferences>(url);
  }

  updateUserPreferences(id: TGuid, preferences: TPreferences): Observable<TPreferences> {
    const url = API_preferences_user(id);

    return this.apiService.post<TPreferences>(url, preferences);
  }

  getWorkspacePreferences(
    workspaceId: string,
    isGlobalPreferences: boolean,
  ): Observable<TWorkspacePreferences> {
    const url = isGlobalPreferences
      ? API_preferences_sites_workspaces(workspaceId)
      : API_preferences_users_workspaces(workspaceId);

    return this.apiService.get<TPreferences>(url);
  }

  updateWorkspacePreferences(
    workspaceId: string,
    userId: TGuid,
    isGlobalPreferences: boolean,
    newPreferences: TWorkspacePreferences,
  ): Observable<TPreferences> {
    const url = getWorkspacePreferencesRequestURL(workspaceId, isGlobalPreferences, userId);

    let bodyPropertyName = 'columns';

    if (!isGlobalPreferences && workspaceId === EWorkspaces.OVERVIEW) {
      bodyPropertyName = 'overviewColumns';
    }

    const body = { [bodyPropertyName]: newPreferences.columns };

    return this.apiService.post<TPreferences>(url, body);
  }

  getExportPreferences(workspaceId: string): Observable<TWorkspacePreferences> {
    const url = API_preferences_users_workspaces(workspaceId);

    return this.apiService.get<TPreferences>(url);
  }

  updateExportPreferences(
    workspaceId: string,
    newPreferences: TWorkspacePreferences,
    columns: TColumn[],
  ): Observable<TPreferences> {
    const url = API_preferences_users_workspaces(workspaceId);

    const body: TUpdateExportPreference = {
      exports: newPreferences.exports,
      columns,
    };

    return this.apiService.post<TPreferences>(url, body);
  }
}
