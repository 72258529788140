import { Component, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';

import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointFieldsService } from '../point-fields.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { of, Subject, timer } from 'rxjs';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';

@Component({
  selector: 'pp-point-fields-checkbox',
  templateUrl: './point-fields-checkbox.component.html',
  styleUrls: ['./point-fields-checkbox.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsCheckboxComponent implements OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TCustomFieldResponse;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppValue = 'false';

  private readonly destroy$ = new Subject<void>();

  updating = false;
  success = false;
  error = false;

  EIconPath = EIconPath;

  private successTimerMs = 2500;

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.ppValue = null;

    this.updateField();
  }

  updateField(): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = this.ppValue;
    const workspaceId = this.ppWorkspaceId;

    if (this.ppNew) {
      this.updateFieldForNewPoint(this.ppPointId, this.ppFieldId, this.ppValue, this.ppWorkspaceId);

      return;
    }

    this.updating = true;

    const customField = this.pointFieldsService.createCustomFieldCheckbox(fieldId, fieldValue);

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_checkbox_update');

          this.customTableService.updatePoint({
            _id: _id,
            field: this.ppFieldLabel,
            newValue: response.customFieldsMap[fieldId]?.value,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.store.dispatch(
            new UpdatePointCustomField({
              workspaceId: workspaceId,
              _id: _id,
              customFieldId: fieldId,
              customFieldValue: response.customFieldsMap[fieldId]?.value,
            }),
          );

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  checkField(): void {
    if (!this.updating) {
      this.ppValue = this.ppValue === 'true' ? 'false' : 'true';

      this.updateField();
    }
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    value: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        _id: pointId,
        customFieldId: fieldId,
        customFieldValue: value,
      }),
    );
  }
}
