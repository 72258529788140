import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TPoint } from 'src/app/project/modules/points/points.model';

export function updatePointCustomFields(
  pointIndex: number,
  fieldLabel: string,
  newValue: unknown,
  points: TPoint[],
): void {
  if (!newValue) {
    newValue = '';
  }

  if (pointIndex !== -1) {
    const customFieldId = Object.keys(points[pointIndex].customFieldsMap).find(
      (searchedCustomFieldId) =>
        points[pointIndex].customFieldsMap[searchedCustomFieldId].label === fieldLabel,
    );

    if (customFieldId) {
      const customField = points[pointIndex].customFieldsMap[customFieldId];

      if (customField.type === ECustomFieldType.RICHTEXT) {
        customField.value = generatePlainText(decodeBase64Text(newValue.toString()));
      } else {
        customField.value = newValue.toString();
      }
    }
  }
}
