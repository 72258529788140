import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { EStore } from '../../../../shared/enums/store.enum';

@Component({
  selector: 'pp-dashlet-head',
  templateUrl: './dashlet-head.component.html',
  styleUrls: ['./dashlet-head.component.scss'],
})
export class DashletHeadComponent implements OnInit, OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Input() ppAccountName: string;
  @Input() ppSiteName: string;
  @Output() ppToggleFlip = new EventEmitter();

  offline: boolean;
  EIconPath = EIconPath;

  private offline$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(private store: Store<{ offline: boolean }>) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  flipDashlet() {
    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__FLIP, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_value: this.ppDashlet.name,
    });

    this.ppToggleFlip.emit();
  }
}
