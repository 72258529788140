import { cloneDeep } from 'lodash';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TReminder } from 'src/app/project/modules/reminders/reminder.model';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TReminderList } from '../reminder-list.model';

export function pushPastReminders(
  reminders: TReminder[],
  preferences: TPreferences,
  today: Date,
  pastReminders: TReminderList,
): TReminderList {
  const updatedReminders = cloneDeep(pastReminders);

  reminders.reverse().forEach((reminder) => {
    const reminderDate = new Date(+reminder.timestampEpochMillis);
    reminderDate.setHours(0, 0, 0, 0);
    const reminderDateFormatted = transformDate(reminderDate, '', preferences.dateFormat);

    if (reminderDate.getTime() < today.getTime()) {
      if (updatedReminders.numberOfDisplayedValues < updatedReminders.maxValues) {
        const existingDate = updatedReminders.values.find(
          (date) => date.formattedDate === reminderDateFormatted,
        );

        if (!existingDate) {
          updatedReminders.values.push({
            formattedDate: reminderDateFormatted,
            date: reminderDate,
            values: [reminder.reminderId],
          });
        } else {
          if (!existingDate.values.includes(reminder.reminderId)) {
            existingDate.values.push(reminder.reminderId);
          }
        }

        updatedReminders.numberOfDisplayedValues++;
      }

      updatedReminders.totalValues++;
    }
  });

  return updatedReminders;
}
