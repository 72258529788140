<ng-container *ngIf="user">
  <pp-user-management-user-row
    [ppUser]="ppUser"
    [ppAccountId]="ppAccountId"
    [(ppExpanded)]="expanded"
    (ppExpandedChange)="onExpandedChange($event)"
  ></pp-user-management-user-row>

  <table
    class="userManagementSites__table"
    [style.height.px]="expanded ? ppUser.shares.length * 37 : 0"
    [class.userManagementSites__table--expanded]="delayedExpanded"
    [ngStyle]="{
      transition:
        expanded && !delayedExpanded
          ? 'height ' + animationDurationInSeconds + 's ease-in-out'
          : 'none'
    }"
  >
    <pp-user-management-site-row
      *ngFor="let share of ppUser.shares; let index = index; let last = last"
      [ppCustomFields]="ppCustomFields"
      [ppIndex]="index"
      [ppExpanded]="expanded"
      [ppShare]="share"
      [ppLast]="last"
      (ppNavigate)="navigateToSite($event)"
    ></pp-user-management-site-row>
  </table>
</ng-container>
