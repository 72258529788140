import { Injectable } from '@angular/core';

import { Observable, Subject, of, throwError } from 'rxjs';

import { ResponseErrorService } from '../errors/response-error.service';
import { logErrorInSentry } from '../errors/response-error';
import { WorkspaceApiProviderService } from '@core/api';
import { catchError, tap } from 'rxjs/operators';
import { TSiteResponse } from '../workspace/site-response.model';
import {
  TImportCustomFieldsRequest,
  TNewSiteRequest,
} from '../../data-providers/api-providers/workspace-api-provider/workspace-requests.model';
import { ELocalStorageItems } from '../../shared/enums/local-storage-items.enum';
import { LocalStorageService } from '../../services/local-storage.service';

export type TSavedSites = {
  [workspaceId: string]: {
    sitePlanVisible: boolean;
  };
};

export type TSiteData = {
  data: TSiteResponse;
  sitePlanBlurred: boolean;
  sitePlanVisible: boolean;
  sitePlanFullWidth: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class SiteDataService {
  public readonly sitePlanToggle$ = new Subject<void>();

  private site: TSiteData;

  constructor(
    private responseErrorService: ResponseErrorService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private localStorageService: LocalStorageService,
  ) {
    this.site = {
      data: {},
      sitePlanBlurred: false,
      sitePlanVisible: true,
      sitePlanFullWidth: false,
    };
  }

  getSite(): TSiteData {
    return this.site;
  }

  clearSite(): TSiteData {
    this.site.data = {};

    return this.getSite();
  }

  addSite(accountId: string, site: TNewSiteRequest): Observable<TSiteResponse> {
    if (!accountId) {
      accountId = '';
    }

    return this.workspaceApiProviderService
      .addSite(accountId, site)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateSiteName(
    workspaceId: string,
    body: string,
    update: boolean = true,
  ): Observable<TSiteResponse> {
    return this.workspaceApiProviderService.updateSiteName(workspaceId, body).pipe(
      tap((response) => {
        if (update) {
          this.site.data.name = response.name;
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  addTags(workspaceId: string, body: string[], update: boolean = true): Observable<TSiteResponse> {
    return this.workspaceApiProviderService.addTags(workspaceId, body).pipe(
      tap((response) => {
        if (update) {
          this.site.data.tags = response.tags;
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  deleteTags(
    workspaceId: string,
    body: string[],
    update: boolean = true,
  ): Observable<TSiteResponse> {
    return this.workspaceApiProviderService.deleteTags(workspaceId, body).pipe(
      tap((response) => {
        if (update) {
          this.site.data.tags = response.tags;
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  importCustomFields(
    workspaceId: string,
    body: TImportCustomFieldsRequest,
  ): Observable<TSiteResponse> {
    return this.workspaceApiProviderService
      .importCustomFields(workspaceId, body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  setSitePlanVisibility({
    sitePlanVisible = true,
    sitePlanFullWidth = false,
    workspaceId = null,
  }: {
    sitePlanVisible?: boolean;
    sitePlanFullWidth?: boolean;
    workspaceId?: string;
  } = {}): void {
    this.site.sitePlanVisible = sitePlanVisible;
    this.site.sitePlanFullWidth = sitePlanFullWidth;

    if (workspaceId) {
      this.saveSitePlanToLocalStorage(workspaceId, this.site.sitePlanVisible);
    }
  }

  setSitePlanFullWidth(sitePlanFullWidth: boolean): void {
    this.site.sitePlanFullWidth = sitePlanFullWidth;
  }

  sitePlanToggleEvent(): void {
    this.sitePlanToggle$.next();
  }

  saveSitePlanToLocalStorage(workspaceId: string, visible: boolean): void {
    let savedSites = this.getSitePlanFromLocalStorage();

    if (!savedSites) {
      savedSites = {
        [workspaceId]: {
          sitePlanVisible: visible,
        },
      };

      this.localStorageService.saveItem(ELocalStorageItems.SAVED_SITES, JSON.stringify(savedSites));
    } else {
      savedSites[workspaceId] = {
        sitePlanVisible: visible,
      };

      this.localStorageService.saveItem(ELocalStorageItems.SAVED_SITES, JSON.stringify(savedSites));
    }
  }

  getSitePlanFromLocalStorage(): TSavedSites {
    return this.localStorageService.getItem<TSavedSites>(ELocalStorageItems.SAVED_SITES);
  }
}
