import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AddFile, AddImage, AddImage360, AddVideo } from '../points.actions';
import { AddNewFile, AddNewMedia } from './attachments.actions';
import { TAttachment } from './attachments.model';

import { UploadService } from 'src/app/project/components/input/upload/upload.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { DeviceService } from '../../../../core/services/device.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { PointActivityService } from '../point-modal/point-timeline/point-activity.service';
import { PointsService } from '../points.service';

import {
  API_files_images_bounded_size,
  API_files_images_square_size,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDocumentType } from 'src/app/project/shared/enums/document-type.enum';
import { EFileType } from 'src/app/project/shared/enums/file-type.enum';
import { TFileToUpload } from '../point-modal/point-attachments/point-attachments.service';
import { AttachmentCopyExifService } from './attachment-copy-exif.service';
import { AttachmentImageCompressionService } from './attachment-image-compression.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsUploadService {
  constructor(
    private store: Store,
    private pointsService: PointsService,
    private uploadService: UploadService,
    private pointActivityService: PointActivityService,
    private activeService: ActiveService,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private deviceService: DeviceService,
    private attachmentImageCompressionService: AttachmentImageCompressionService,
    private attachmentCopyExifService: AttachmentCopyExifService,
  ) {}

  uploadImage(image: TFileToUpload, newPoint: boolean, _id: string): Promise<void> {
    const user = this.userService.getUser();
    const point = this.pointsService.findPoint(_id);

    const workspaceId = point ? point.workspaceRef.id : this.activeService.getActiveWorkspaceId();
    let updatePoint = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_UPLOAD, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'IMAGE',
      event_value: `${image.file.size}`,
    });

    if (newPoint) {
      updatePoint = false;
    }

    // TODO Encapsulate using `async await`
    return new Promise(async (resolve, reject) => {
      const isBrowserSafari = this.deviceService.isBrowserSafari();
      let photo = null;
      let type = image.file.type;

      if (type === EFileType.JPEG) {
        await this.attachmentImageCompressionService
          .fixImageRotationJPG(image)
          .then(async (fixedImage) => {
            await this.attachmentImageCompressionService
              .compressImage(fixedImage)
              .then(async (compressedImage) => {
                await this.attachmentCopyExifService
                  .copyExif(image.file, compressedImage)
                  .then((restoredImage) => {
                    photo = restoredImage;
                  });
              });
          })
          .catch(async (error) => {
            console.warn(error);

            logErrorInSentry(error);

            await this.attachmentImageCompressionService
              .fixImageRotation(image)
              .then(async (fixedImage) => {
                await this.attachmentImageCompressionService
                  .compressImage(fixedImage)
                  .then((compressedImage) => {
                    photo = compressedImage;
                  });
              })
              .catch((rotationError) => {
                console.warn(rotationError);

                logErrorInSentry(rotationError);

                photo = image.file;
              });
          });
      } else if (type === EFileType.PNG) {
        await this.attachmentImageCompressionService
          .fixImageRotation(image)
          .then(async (fixedImage) => {
            await this.attachmentImageCompressionService
              .compressImage(fixedImage)
              .then((compressedImage) => {
                photo = compressedImage;
              })
              .catch((error) => {
                console.warn(error);

                logErrorInSentry(error);

                photo = image.file;
              });
          });
      } else if (type === EFileType.BMP || type === EFileType.WEBP) {
        await this.attachmentImageCompressionService
          .compressImageBMP(image)
          .then((compressedImage) => {
            photo = compressedImage;
            type = EFileType.JPEG;
          })
          .catch((error) => {
            console.warn(error);

            logErrorInSentry(error);

            photo = image.file;
          });
      } else {
        photo = image.file;
      }

      const imageToUpload = new File([photo], image.name.split('.')[0], {
        type: type,
        lastModified: Date.now(),
      });

      image.file = imageToUpload;

      if (isBrowserSafari) {
        Object.defineProperty(image, 'type', {
          writable: true,
          value: type,
        });
      }

      this.uploadService
        .uploadImage(workspaceId, _id, image, {
          updatePoint,
        })
        .then(
          (response) => {
            const responseBody = JSON.parse(response['target'].response);
            const uploadedImage = responseBody['entity'];

            const attachmentId = uploadedImage.imageId;
            const preferences = this.preferencesService.getPreferences();
            const activePointId = this.activeService.getActivePointId();

            const imageToStore: TAttachment = {
              bounded1200Url: API_files_images_bounded_size(attachmentId, 1200),
              square100Url: API_files_images_square_size(attachmentId, 100),
              fileName: uploadedImage.fileName,
              originalFileSize: uploadedImage.originalBytes,
              mimeType: uploadedImage.mimeType,
              type: EDocumentType.IMAGE,
              createdOn: uploadedImage.exifCreatedEpochMillis
                ? uploadedImage.exifCreatedEpochMillis
                : uploadedImage.header.createdEpochMillis,
              uploaderName: user.userName,
              uploaderId: user.userId,
              uploaderAvatarId: user.avatarId,
              attachmentId,
              workspaceId,
            };

            this.store.dispatch(
              new AddImage({
                attachmentId,
                _id,
                workspaceId,
              }),
            );

            if (_id === activePointId) {
              this.store.dispatch(
                new AddNewMedia({
                  imageToStore: imageToStore,
                  dateFormat: preferences.dateFormat,
                }),
              );
            }

            if (!newPoint) {
              this.pointActivityService.refreshTimeline(workspaceId, _id);
            }

            uploadedImage.finished = true;

            resolve();
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  uploadImage360(image: TFileToUpload, newPoint: boolean, _id: string): Promise<void> {
    const preferences = this.preferencesService.getPreferences();
    const user = this.userService.getUser();

    const point = this.pointsService.findPoint(_id);
    const workspaceId = point ? point.workspaceRef.id : this.activeService.getActiveWorkspaceId();
    let updatePoint = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_UPLOAD, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'IMAGE_360',
      event_value: `${image.file.size}`,
    });

    if (newPoint) {
      updatePoint = false;
    }

    return this.uploadService
      .uploadImage(workspaceId, _id, image, {
        updatePoint,
        is360: true,
      })
      .then(
        (response) => {
          const responseBody = JSON.parse(response['target'].response);
          const attachmentId = responseBody.entity;

          const image360ToStore: TAttachment = {
            bounded1200Url: API_files_images_bounded_size(attachmentId, 1200),
            square100Url: API_files_images_square_size(attachmentId, 100),
            fileName: image.file.name,
            originalFileSize: image.file.size,
            mimeType: image.file.type,
            type: EDocumentType.IMAGE_360,
            createdOn: new Date().getTime(),
            uploaderName: user.userName,
            uploaderId: user.userId,
            uploaderAvatarId: user.avatarId,
            attachmentId,
            workspaceId,
          };

          const activePointId = this.activeService.getActivePointId();

          if (_id === activePointId) {
            this.store.dispatch(
              new AddNewMedia({
                imageToStore: image360ToStore,
                dateFormat: preferences.dateFormat,
              }),
            );
          }

          this.store.dispatch(
            new AddImage360({
              attachmentId,
              _id,
              workspaceId,
            }),
          );

          if (!newPoint) {
            this.pointActivityService.refreshTimeline(workspaceId, _id);
          }
        },
        (error) => {
          logErrorInSentry(error);

          throw error;
        },
      );
  }

  uploadHeicHeif(image: TFileToUpload, newPoint: boolean, _id: string): Promise<void> {
    const point = this.pointsService.findPoint(_id);
    const workspaceId = point ? point.workspaceRef.id : this.activeService.getActiveWorkspaceId();
    let updatePoint = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_UPLOAD, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'HEIC',
      event_value: `${image.file.size}`,
    });

    if (newPoint) {
      updatePoint = false;
    }

    return this.uploadService
      .uploadImage(workspaceId, _id, image, {
        updatePoint,
        is360: false,
        isHeicHeif: true,
      })
      .then((response) => {
        const responseBody = JSON.parse(response['target'].response);
        const uploadedImage = responseBody.entity;
        const attachmentId = uploadedImage.imageId;
        const preferences = this.preferencesService.getPreferences();
        const activePointId = this.activeService.getActivePointId();

        const imageToStore: TAttachment = {
          bounded1200Url: API_files_images_bounded_size(attachmentId, 1200),
          square100Url: API_files_images_square_size(attachmentId, 100),
          fileName: uploadedImage.fileName,
          originalFileSize: uploadedImage.originalBytes,
          mimeType: uploadedImage.mimeType,
          type: EDocumentType.IMAGE,
          createdOn: uploadedImage.header.createdEpochMillis,
          uploaderName: uploadedImage.header.createdBy.caption,
          uploaderId: uploadedImage.header.createdBy.id,
          uploaderAvatarId: uploadedImage.header.createdBy.primaryImageId,
          attachmentId,
          workspaceId,
        };

        this.store.dispatch(
          new AddImage({
            attachmentId,
            _id,
            workspaceId,
          }),
        );

        if (_id === activePointId) {
          this.store.dispatch(
            new AddNewMedia({
              imageToStore: imageToStore,
              dateFormat: preferences.dateFormat,
            }),
          );
        }

        if (!newPoint) {
          this.pointActivityService.refreshTimeline(workspaceId, _id);
        }
      });
  }

  uploadVideo(video: TFileToUpload, newPoint: boolean, _id: string): Promise<void> {
    const user = this.userService.getUser();
    const preferences = this.preferencesService.getPreferences();

    const point = this.pointsService.findPoint(_id);
    const workspaceId = point ? point.workspaceRef.id : this.activeService.getActiveWorkspaceId();
    const pointTitle = '';
    let updatePoint = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_UPLOAD, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'VIDEO',
      event_value: `${video.file.size}`,
    });

    if (newPoint) {
      updatePoint = false;
    }

    return this.uploadService
      .uploadVideo(workspaceId, _id, pointTitle, video, {
        updatePoint,
      })
      .then((response) => {
        const responseBody = JSON.parse(response['target'].response);
        const attachmentId = responseBody.entity._id;

        const videoToStore: TAttachment = {
          fileName: video.file.name,
          originalFileSize: video.file.size,
          mimeType: video.file.type,
          type: EDocumentType.VIDEO,
          createdOn: new Date().getTime(),
          uploaderName: user.userName,
          uploaderId: user.userId,
          uploaderAvatarId: user.avatarId,
          attachmentId,
          workspaceId,
        };

        const activePointId = this.activeService.getActivePointId();

        if (_id === activePointId) {
          this.store.dispatch(
            new AddNewMedia({
              imageToStore: videoToStore,
              dateFormat: preferences.dateFormat,
            }),
          );
        }

        this.store.dispatch(
          new AddVideo({
            attachmentId,
            _id,
            workspaceId,
          }),
        );

        if (!newPoint) {
          this.pointActivityService.refreshTimeline(workspaceId, _id);
        }
      });
  }

  uploadGenericFile(file: TFileToUpload, newPoint: boolean, _id: string): Promise<void> {
    const user = this.userService.getUser();
    const point = this.pointsService.findPoint(_id);
    const workspaceId = point ? point.workspaceRef.id : this.activeService.getActiveWorkspaceId();
    const pointTitle = '';
    let updatePoint = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_UPLOAD, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'GENERIC',
      event_value: `${file.file.size}`,
    });

    if (newPoint) {
      updatePoint = false;
    }

    return this.uploadService
      .uploadFile(workspaceId, _id, pointTitle, file, {
        updatePoint,
      })
      .then((response) => {
        const responseBody = JSON.parse(response['target'].response);
        const attachmentId = responseBody.entity._id;

        const fileToStore: TAttachment = {
          fileName: file.file.name,
          originalFileSize: file.file.size,
          mimeType: responseBody.entity.mimeType,
          type: EDocumentType.FILE,
          createdOn: new Date().getTime(),
          uploaderName: user.userName,
          uploaderId: user.userId,
          uploaderAvatarId: user.avatarId,
          attachmentId,
          workspaceId,
        };

        this.store.dispatch(new AddNewFile(fileToStore));

        this.store.dispatch(
          new AddFile({
            attachmentId,
            _id,
            workspaceId,
          }),
        );

        if (!newPoint) {
          this.pointActivityService.refreshTimeline(workspaceId, _id);
        }
      });
  }
}
