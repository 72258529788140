import { GET_COLUMNS } from '../columns/columns.store';
import { GET_GROUPING, SET_GROUPING } from '../columns/grouping.store';
import { CLEAR_COLLAPSED_GROUPS } from '../table.ui.store';

import { TCustomFieldResponse } from '@project/view-models';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { GET_WORKSPACES } from '../../../workspace/workspace.store';
import { TColumn } from '../columns/column.model';
import { findPropertyPath } from '../properties';
import { getEmptyGroupName } from './empty-group-name';
import { TGroupedPoints } from './group-model';
import { setPointsInOrder } from './set-points-in-order';
import { sortGroups } from './sort-groups';
import { transformCustomFieldValue } from './transform-custom-field-value';
import { transformDefaultValue } from './transform-default-value';
import { transformValue } from './transform-value';

export const generateGrouping = (): void => {
  SET_GROUPING(
    GET_COLUMNS()
      .filter((_column) => _column.groupOrder)
      .sort((_a, _b) => _a.groupIndex - _b.groupIndex),
  );
  CLEAR_COLLAPSED_GROUPS();
};

export function groupTable(_points: TPoint[]): TGroupedPoints[] {
  const grouping = GET_GROUPING();
  const column = grouping[0];
  const tablePoints = _points;

  const groupedPointIndexes = groupPoints(tablePoints, column, tablePoints, '');

  if (grouping[1]) {
    groupedPointIndexes.forEach((_groupedPointIndexes) => {
      _groupedPointIndexes.group = groupPoints(
        _groupedPointIndexes.pointObjects,
        grouping[1],
        _points,
        _groupedPointIndexes.id,
      );

      if (grouping[2]) {
        _groupedPointIndexes.group.forEach((_group) => {
          _group.group = groupPoints(_group.pointObjects, grouping[2], _points, _group.id);
        });
      }
    });
  }

  setPointsInOrder(groupedPointIndexes, tablePoints);

  return groupedPointIndexes;
}

function groupPoints(
  _points: TPoint[],
  column: TColumn,
  allPoints: TPoint[],
  idPrefix: string,
): TGroupedPoints[] {
  let groupedPointIndexes: TGroupedPoints[] = [];
  const valueIndexes = {};
  const emptyGroupValue = getEmptyGroupName();

  _points.forEach((_point) => {
    const index = allPoints.indexOf(allPoints.find((_allPoint) => _allPoint._id === _point._id));
    const propertyPath = findPropertyPath(column.name);
    let value: string = null;

    if (propertyPath) {
      const properties = propertyPath.split('.');
      value = transformValue(column.name, _point[properties[0]]);
    } else if (column.name === 'Site') {
      const workspaces = GET_WORKSPACES();
      const workspace = _point ? workspaces[_point.workspaceRef.id] : null;

      value = transformDefaultValue(workspace?.siteName);
    } else if (column.name === 'Account') {
      const workspaces = GET_WORKSPACES();
      const workspace = _point ? workspaces[_point.workspaceRef.id] : null;

      value = transformDefaultValue(workspace?.accountName);
    } else {
      const customField = Object.values(GET_CUSTOM_FIELDS()).find(
        (customField) => customField.label === column.name,
      );

      const pointCF: TCustomFieldResponse = _point.customFieldsMap[customField.id];

      if (customField) {
        value = transformCustomFieldValue(customField.id, pointCF);
      } else {
        value = emptyGroupValue;
      }
    }

    if (!Object.prototype.hasOwnProperty.call(valueIndexes, value)) {
      valueIndexes[value] = groupedPointIndexes.length;
    }

    if (!groupedPointIndexes[valueIndexes[value]]) {
      groupedPointIndexes[valueIndexes[value]] = {
        points: [],
        value,
        pointObjects: [],
        id: idPrefix + '-' + value,
      };
    }

    groupedPointIndexes[valueIndexes[value]].points.push(index);
    groupedPointIndexes[valueIndexes[value]].pointObjects.push(_point);
  });

  groupedPointIndexes = groupedPointIndexes.sort((_a, _b) => sortGroups(column, _a, _b));

  return groupedPointIndexes;
}
