var util = require('../util');
var property = util.property;
function ResourceWaiter(name, waiter, options) {
  options = options || {};
  property(this, 'name', name);
  property(this, 'api', options.api, false);
  if (waiter.operation) {
    property(this, 'operation', util.string.lowerFirst(waiter.operation));
  }
  var self = this;
  var keys = ['type', 'description', 'delay', 'maxAttempts', 'acceptors'];
  keys.forEach(function (key) {
    var value = waiter[key];
    if (value) {
      property(self, key, value);
    }
  });
}

/**
 * @api private
 */
module.exports = ResourceWaiter;