import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';
import { TCustomFieldFilter } from '../site-filter.model';

export function filterNumericCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TCustomFieldResponse,
): boolean {
  if (filterField.appliedValues.min || filterField.appliedValues.max) {
    if (filterField.appliedValues.min && filterField.appliedValues.max) {
      if (
        parseInt(pointCustomField.value, 10) >= filterField.appliedValues.min &&
        parseInt(pointCustomField.value, 10) <= filterField.appliedValues.max
      ) {
        return true;
      }
    } else if (filterField.appliedValues.min && !filterField.appliedValues.max) {
      if (parseInt(pointCustomField.value, 10) >= filterField.appliedValues.min) {
        return true;
      }
    } else if (!filterField.appliedValues.startDate && filterField.appliedValues.max) {
      if (parseInt(pointCustomField.value, 10) <= filterField.appliedValues.max) {
        return true;
      }
    }
  } else {
    return true;
  }

  return false;
}
