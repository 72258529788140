import dayjs from 'dayjs';
import { EDateFormat } from './enums/date-format.enum';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export type TTransformDateValue = dayjs.Dayjs | Date | string | number;

export function transformDate(
  value: TTransformDateValue, // it will work properly for all of these types
  inputHourFormat?: string,
  format?: string,
): string {
  const hourFormat = inputHourFormat || '';

  if (value) {
    switch (format) {
      case EDateFormat.ISO:
        return dayjs(+value)
          .utc()
          .format('YYYY-MM-DD' + hourFormat);
      case 'US':
        return dayjs(+value)
          .utc()
          .format('MM/DD/YYYY' + hourFormat);
      case 'FRIENDLY':
        return dayjs(+value)
          .utc()
          .format('DD MMM YYYY' + hourFormat);
      case 'EUROPEAN':
        return dayjs(+value)
          .utc()
          .format('DD/MM/YYYY' + hourFormat);
      default:
        return dayjs(+value)
          .utc()
          .format('YYYY-MM-DD' + hourFormat);
    }
  } else {
    return '';
  }
}
