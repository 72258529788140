import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import {
  SetActiveAccountId,
  SetActiveWorkspaceId,
} from 'src/app/project/services/active/active.actions';

import { Title } from '@angular/platform-browser';
import { ScreenService } from '@core/services';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EAccountTypes } from '../../account/account-utils/account-types-enums';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { SyncService } from '../../offline/sync.service';
import { SharesService } from '../../share/shares.service';
import { ActiveWorkspaceService } from '../../user/active-workspace.service';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteService } from '../site.service';

@Component({
  selector: 'pp-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss'],
})
export class SiteSettingsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private workspaces$: Observable<TWorkspacesById>;

  EUserRole = EUserRole;

  workspaceId: string;
  workspace: TWorkspace;
  user: TUser;
  private user$: Observable<TUser>;
  account: TAccount;
  EAccountTypes = EAccountTypes;
  EIconPath = EIconPath;
  fromAccount: boolean;
  previousPageurl: string;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      user: TUser;
    }>,
    private activatedRoute: ActivatedRoute,
    private sharesService: SharesService,
    private workspaceService: WorkspaceService,
    private sidePanelService: SidePanelService,
    private syncService: SyncService,
    private whiteLabelService: WhiteLabelService,
    private accountService: AccountService,
    private screenService: ScreenService,
    private titleService: Title,
    private siteService: SiteService,
    private router: Router,
    private activeWorkspaceService: ActiveWorkspaceService,
    private previousPageService: PreviousPageService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.user$ = this.store.pipe(select(EStore.USER));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');
    this.siteService.setFetched(true);

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.paramMap.subscribe((params) => {
      const workspaces = this.workspaceService.getWorkspaces();

      this.workspaceId = params.get('id');
      this.activeWorkspaceService.updateActiveWorkspaceId(this.workspaceId);

      if (!workspaces || Object.keys(workspaces).length === 0) {
        this.syncService.firstLoad(this.workspaceId).pipe(takeUntil(this.destroy$)).subscribe();
      }

      if (workspaces[this.workspaceId]) {
        this.store.dispatch(new SetActiveAccountId(workspaces[this.workspaceId].accountId));
        this.store.dispatch(new SetActiveWorkspaceId(this.workspaceId));
      }

      this.init();
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspace = workspaces[this.workspaceId];

      if (this.workspace) {
        this.account = this.accountService.getAccount(this.workspace.accountId);
      }
    });
  }
  hasComeFromAccountSettings(): boolean {
    return this.previousPageService.getPreviousUrl()
      ? this.previousPageService.getPreviousUrl().split('/')[2] === 'account'
      : false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.sharesService.clearWorkspaceShares();
  }

  goToPreviousPage() {
    this.router.navigateByUrl(this.previousPageurl);
  }

  init(): void {
    this.fromAccount = this.hasComeFromAccountSettings();

    if (this.fromAccount) {
      this.previousPageurl = this.previousPageService.getPreviousUrl();
      this.previousPageService.clearUrlStack();
    }

    const { isDesktop } = this.screenService.getCurrentState();
    if (!isDesktop) {
      this.sidePanelService.collapsePanel();
    }

    this.workspaceService
      .generateWorkspace(this.workspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.workspace = response;

          if (this.workspace) {
            this.whiteLabelService.fetchWhiteLabel(this.workspace.accountId);
          }
        }),
      )
      .subscribe();
  }
}
