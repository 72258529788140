import { Injectable } from '@angular/core';

import { ResponseErrorService } from '../../../errors/response-error.service';
import { DashboardApiProviderService } from '@core/api';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export type TDashletEventsParams = {
  type?: string;
  period?: string;
  accountId?: string;
  workspaceId?: string;
};

export type TDashletData = {
  type: string;
  period: string;
  targetId: string;
  data: number;
};

@Injectable({
  providedIn: 'root',
})
export class DashletEventsService {
  private dashletData: TDashletData[] = [];

  constructor(
    private responseErrorService: ResponseErrorService,
    private dashboardApiProviderService: DashboardApiProviderService,
  ) {}

  fetchEvents({
    type = '',
    period = null,
    accountId = '',
    workspaceId = '',
  }: TDashletEventsParams): Observable<number> {
    let targetId = '';

    if (accountId && workspaceId) {
      targetId = `&workspaceId=${workspaceId}`;
    } else if (accountId) {
      targetId = `&accountId=${accountId}`;
    }

    const existingData = this.dashletData.find(
      (_dashlet) =>
        _dashlet.type === type && _dashlet.period === period && _dashlet.targetId === targetId,
    );

    if (existingData) {
      return of(existingData.data);
    } else {
      return this.dashboardApiProviderService.fetchEvents(type, period, targetId).pipe(
        tap((response) => {
          this.dashletData.push({
            type,
            period,
            targetId,
            data: response,
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
    }
  }

  getDashletData(): TDashletData[] {
    return this.dashletData;
  }

  clearDashletData(): void {
    this.dashletData = [];
  }
}
