import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { GET_TABLE } from '../../table.ui.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { createElement } from 'src/app/core/helpers/dom';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';

export function createUpdatedElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();
  const preferences = GET_PREFERENCES();

  const date = transformDate(
    table.points[_index].header.updatedEpochMillis,
    '',
    preferences.dateFormat,
  );

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(date)],
    }),
  });
}
