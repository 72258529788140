import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EExportTypeName } from './export-type-names-enum';

export function sendExportGoogleEvent(selectedValue: EExportTypeName, pointExport: boolean): void {
  let exportType = '';

  switch (selectedValue) {
    case EExportTypeName.ZIP:
      exportType = 'Individual Reports';
      break;
    case EExportTypeName.SIMPLE_LIST:
      exportType = 'Simple List';
      break;
    case EExportTypeName.DETAILED_LIST:
      exportType = 'Detailed List';
      break;
    case EExportTypeName.CSV:
      exportType = 'CSV';
      break;
    case EExportTypeName.XLSX:
      exportType = 'XLSX';
      break;
    case EExportTypeName.WORD:
      exportType = 'Word';
      break;
    case EExportTypeName.PDF:
      exportType = 'Single PDF';
      break;
    case EExportTypeName.SITE_PLAN:
      exportType = 'Site Plan';
      break;
    case EExportTypeName.WORD_SIMPLE:
      exportType = 'Word Simple';
      break;
    default:
      exportType = 'Other';
      break;
  }

  logEventInGTAG(EGoogleEventSite.SITE__EXPORT, {
    event_category: EGoogleEventCategory.SITE,
    event_value: exportType,
    event_type: pointExport ? 'Point Export' : 'Site Export',
  });
}
