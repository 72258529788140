<div
  class="gridTile"
  [class.gridTile__wide]="ppWide"
  [class.gridTile__disabled]="ppDisabled"
  (click)="optionSelect()"
>
  <div class="gridTile__left">
    <div class="gridTile__imageContainer">
      <pp-icon
        class="gridTile__image"
        [ngClass]="{
          'gridTile__image--no-width': ppImage === EIconPath.CUSTOM_FIELD_TIMELINE_BLUE
        }"
        [ppSrc]="ppImage"
        ppColor="primary-600"
      ></pp-icon>
    </div>
  </div>

  <div class="gridTile__right" [class.gridTile__right--wide]="ppWide">
    <div class="gridTile__horizontalText" [class.gridTile__horizontalText--wide]="ppWide">
      <h5 class="gridTile__title">{{ ppGridTitle }}</h5>
      <div class="gridTile__text" *ngIf="ppWide">{{ ppText }}</div>
    </div>
  </div>
</div>
