import { isEqual } from 'lodash';
import tippy, { Props, Instance } from 'tippy.js';

import { isMobile, isTablet } from '../../../core/helpers/device';
import { sanitizeHTML } from '../../../core/helpers/text-sanitizer';
import { logErrorInSentry } from '../../modules/errors/response-error';

export type TTooltipElement = HTMLElement & {
  _tippy?: Instance;
};

export type TTooltipParams = {
  options?: Partial<Props>;
  title?: string;
  mobileTooltip?: boolean;
  trimTooltip?: boolean;
  childTooltip?: boolean;
  element: TTooltipElement;
  trimElement?: TTooltipElement;
  sanitize?: boolean;
};

export default class Tooltip {
  public _element: TTooltipElement;
  public _host: TTooltipElement;
  isTrimmed = true;
  private options: Partial<Props>;
  title: string;
  private mobileTooltip: boolean;
  private childTooltip: boolean;
  private trimTooltip: boolean;
  private trimElement: TTooltipElement;

  commonOption = {
    arrow: true,
    inertia: true,
    animation: 'fade',
    theme: 'pinpoint',
    allowHTML: true,
    // duration: [0, 1000000], // Enable for testing so tooltip don't dissapear
  };

  constructor({
    options = {},
    title = '',
    mobileTooltip = false,
    trimTooltip = false,
    childTooltip = false,
    element,
    trimElement = null,
    sanitize = true,
  }: TTooltipParams) {
    this._element = element;
    this._host = element;

    this.options = options;
    this.title = sanitize ? sanitizeHTML(title) : title;
    this.mobileTooltip = mobileTooltip;
    this.trimTooltip = trimTooltip;
    this.childTooltip = childTooltip;
    this.trimElement = trimElement ? trimElement : element;
  }

  createTooltip(): void {
    try {
      const mobile = isMobile();
      const tablet = isTablet();

      if ((mobile || tablet) && !this.mobileTooltip) {
        return;
      }

      setTimeout(() => {
        this.detectHostNode();

        if (this.trimTooltip && !this.isTrimmed && this._element._tippy) {
          this._element._tippy.destroy();
        }

        if (!this.trimTooltip || this.isTrimmed) {
          if (this.title) {
            if (!this.options.hasOwnProperty('html') || isEqual(this.options, {})) {
              const contentTitle = { content: this.title };
              const options = Object.assign(this.commonOption, this.options, contentTitle);

              tippy(this._element, options);
            } else {
              const template = document.getElementById(this.options['html']);
              delete this.options['html'];
              this.options['content'] = template.innerHTML;
              const options = Object.assign(this.commonOption, this.options);

              tippy(this._element, options);
            }
          }
        }
      }, 100);
    } catch (error) {
      logErrorInSentry(error);
    }
  }

  updateTooltip({
    options = {},
    title = '',
    mobileTooltip = false,
    trimTooltip = false,
    childTooltip = false,
    element,
    sanitize = true,
  }: TTooltipParams): void {
    try {
      this._element = element;
      this._host = element;

      this.options = options;
      this.title = sanitize ? sanitizeHTML(title) : title;
      this.mobileTooltip = mobileTooltip;
      this.trimTooltip = trimTooltip;
      this.childTooltip = childTooltip;

      const mobile = isMobile();
      const tablet = isTablet();

      if (mobile || tablet) {
        return;
      }

      this.detectHostNode();

      if (this.trimTooltip && !this.isTrimmed && this._element._tippy) {
        this._element._tippy.destroy();
      }

      if (!this.trimTooltip || this.isTrimmed) {
        if (this.title) {
          if (!this.options.hasOwnProperty('html') || isEqual(this.options, {})) {
            const contentTitle = { content: this.title };
            const tooltipOptions = Object.assign(this.commonOption, this.options, contentTitle);

            tippy(this._element, tooltipOptions);

            this._element._tippy.setContent(this.title);
          } else {
            const template = document.getElementById(this.options['html']);

            delete this.options['html'];
            this.options['content'] = template.innerHTML;

            const tooltipOptions = Object.assign(this.commonOption, this.options);

            tippy(this._element, tooltipOptions);

            this._element._tippy.setContent(template.innerHTML);
          }
        } else {
          this.removeTooltip();
        }
      }
    } catch (error) {
      logErrorInSentry(error);
    }
  }

  removeTooltip(): void {
    if (this._element._tippy) {
      this._element._tippy.destroy();
    }
  }

  detectHostNode(): void {
    const attrs = this._element.attributes;

    let hostNode = false;

    for (let i = 0; i < attrs.length; i++) {
      if (attrs[i].name.indexOf('_nghost') > -1) {
        hostNode = true;
      }
    }

    if (this.trimTooltip && this.childTooltip && this.trimElement?.children[0]) {
      const parentNode = this.trimElement;

      this.isTrimmed =
        parentNode.offsetWidth - (this.trimElement.children[0] as HTMLElement).offsetWidth - 26 < 0;
    }

    if (!this.childTooltip && this.trimTooltip) {
      this.isTrimmed = this.trimElement.offsetWidth < this.trimElement.scrollWidth;
    }

    if (hostNode && this._element.children.length > 0) {
      this._element = this._element.children[0] as HTMLElement;

      this.xferAttributes(this._host, this._element);
    }
  }

  xferAttributes(fromEl: HTMLElement, toEl: HTMLElement): void {
    const daList = [
      'position',
      'trigger',
      'interactive',
      'interactiveborder',
      'delay',
      'animation',
      'arrow',
      'arrowsize',
      'animatefill',
      'duration',
      'html',
      'size',
      'distance',
      'theme',
      'offset',
      'hideonclick',
      'multiple',
      'followcursor',
      'inertia',
      'flipduration',
      'sticky',
      'stickyduration',
      'appendto',
      'zindex',
      'touchhold',
      'performance',
      'popperoptions',
    ];

    daList.forEach((da) => {
      if (fromEl.dataset[da]) {
        toEl.dataset[da] = fromEl.dataset[da];
      }
    });

    if (fromEl.hasAttribute('title')) {
      toEl.title = fromEl.title;
      fromEl.title = '';
    }
  }
}
