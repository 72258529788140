<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_asset_labels_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_asset_labels_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetNewItemSectionContent" data-test="assetLabelSection">
    <div class="newAssetDetails">
      <section class="newAssetLabels__header">
        <p class="newAssetLabels__header--name">{{ 'name' | ppTranslate }}</p>
        <p class="newAssetLabels__header--color">{{ 'color' | ppTranslate }}</p>
        <p class="newAssetLabels__header--assignee">{{ 'assignee' | ppTranslate }}</p>
      </section>

      <section class="newAssetLabels">
        <pp-new-asset-label
          *ngFor="let label of assetLabels"
          [(ppColor)]="label.color"
          [(ppName)]="label.name"
          [(ppAssigneeId)]="label.assigneeId"
          (ppDeleteLabel)="deleteLabel(label)"
          (ppLabelChange)="updateAssetLabels()"
        ></pp-new-asset-label>
      </section>

      <button
        data-test="addLabelButton"
        (click)="addNewLabel()"
        class="newAssetLabelButton"
        *ngIf="assetLabels.length < 20"
      >
        {{ 'new_asset_new_label_button' | ppTranslate }}
      </button>
    </div>
  </div>
</section>
