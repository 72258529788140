import { Component, OnInit } from '@angular/core';

import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { SubscriptionsService, TSubscriptionsById } from '../subscriptions.service';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  generateErrorPrompt,
  logErrorInSentry,
} from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ESubscriptionType } from '../user-settings/subscription-types.enum';
import { TEditNotificationModalData } from './edit-notification-modal.model';
import { EditNotificationModalService } from './edit-notification-modal.service';
import { TSubscriptionTypeOption } from './subscription-type-option.model';
import { SUBSCRIPTION_TYPES } from './subscription-types';

@Component({
  selector: 'app-edit-notifications-modal',
  templateUrl: './edit-notifications-modal.component.html',
  styleUrls: ['./edit-notifications-modal.component.scss'],
})
export class EditNotificationsModalComponent implements OnInit {
  subscriptionType: ESubscriptionType;
  subscriptionLabel: string;
  processing = false;

  subscriptionTypes: TSubscriptionTypeOption[] = SUBSCRIPTION_TYPES;

  workspaceId: string;
  userRef: string;
  siteName: string;
  accountName: string;
  newSubscription = false;

  private subscriptionsById: TSubscriptionsById = this.subscriptionsService.getSubscriptionsById();
  private modal: Modal = this.modalService.getModal();

  constructor(
    private subscriptionsService: SubscriptionsService,
    private modalService: ModalService,
    private promptService: PromptService,
    private editNotificationModalService: EditNotificationModalService,
  ) {}

  ngOnInit() {
    if (!this.modal.data) {
      console.error('Data for this modal is required');

      this.hideModal();
    } else {
      const modalData: TEditNotificationModalData = this.modal.data;
      this.workspaceId = modalData.workspaceId;
      this.userRef = modalData.userRef;
      this.siteName = modalData.siteName;
      this.accountName = modalData.accountName;
    }

    if (this.subscriptionsById.data[this.workspaceId]) {
      this.subscriptionType = this.subscriptionsById.data[this.workspaceId].subscriptionType;
      this.newSubscription =
        this.subscriptionsById.data[this.workspaceId].subscriptionType === ESubscriptionType.NONE;
    } else {
      this.newSubscription = true;
      this.subscriptionType = ESubscriptionType.NONE;
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  save(): void {
    if (!this.processing) {
      this.processing = true;
      let subscriptionId;

      if (this.subscriptionsById.data[this.workspaceId] === undefined) {
        subscriptionId = false;
      } else {
        subscriptionId = this.subscriptionsById.data[this.workspaceId].id;
        this.subscriptionsById.data[this.workspaceId].subscriptionType = this.subscriptionType;
      }

      logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__NOTIFICATIONS, {
        event_category: EGoogleEventCategory.PROFILE_SETTINGS,
        event_value: this.subscriptionLabel,
      });

      const subscriptionAction$ = this.getSubscriptionAction(subscriptionId);

      if (subscriptionAction$) {
        subscriptionAction$
          .pipe(
            tap(() => {
              this.success();
            }),
            catchError((error) => {
              this.failure(error);

              return throwError(error);
            }),
          )
          .subscribe();
      } else {
        this.success();
      }
    }
  }

  success(): void {
    this.processing = false;

    this.subscriptionsService
      .fetchSubscriptions()
      .pipe(
        tap(() => {
          this.modalService.hideModal(false, false);
        }),
      )
      .subscribe();
  }

  failure(error: string): void {
    const promptText = generateErrorPrompt(error, 'prompt_error');

    this.processing = false;

    logErrorInSentry(error);
    console.error(error);

    this.hideModal();
    this.promptService.showError(promptText);
  }

  toggleSubscriptionType(option: TSubscriptionTypeOption): void {
    this.subscriptionType = option.value;
    this.subscriptionLabel = option.label;
  }

  private getSubscriptionAction(subscriptionId?: string): Observable<unknown> | null {
    if (this.subscriptionType === ESubscriptionType.NONE && subscriptionId) {
      return this.editNotificationModalService.deleteSubscription(subscriptionId);
    }

    if (!this.newSubscription && subscriptionId) {
      return this.editNotificationModalService.updateSubscription(
        subscriptionId,
        this.subscriptionType,
      );
    }

    if (this.newSubscription) {
      return this.editNotificationModalService.createSubscription(
        this.subscriptionType,
        this.workspaceId,
        this.userRef,
      );
    }

    return null;
  }
}
