const dayInEpoch = 1000 * 60 * 60 * 24;
const hourInEpoch = 1000 * 60 * 60;
const minuteInEpoch = 1000 * 60;

export function transformTimeCF(value: number | string, showHoursOnly: boolean | string): string {
  if (!value || value === null || value === '0') {
    return null;
  }

  let mergedTime = '';

  if (+value < 0) {
    mergedTime += '-';
  }

  value = +value;

  const displayDays = Math.floor(value / dayInEpoch);

  value = value - displayDays * dayInEpoch;

  let displayHours = Math.floor(value / hourInEpoch);

  value = value - displayHours * hourInEpoch;

  const displayMinutes = Math.floor(value / minuteInEpoch);

  if (!showHoursOnly) {
    mergedTime += displayDays + 'd / ';

    if (displayHours < 10) {
      mergedTime += '0';
    }

    mergedTime += displayHours + ':';

    if (displayMinutes < 10) {
      mergedTime += '0';
    }

    mergedTime += displayMinutes;
  } else {
    displayHours += displayDays * 24;

    mergedTime = displayHours.toString();

    if (displayHours < 10) {
      mergedTime = '0' + mergedTime;
    }

    mergedTime += ':';

    if (displayMinutes < 10) {
      mergedTime += '0';
    }

    mergedTime += displayMinutes;
  }

  return mergedTime;
}

export function transformSplitTimeCF(value: string | number): number[] {
  if (!value || value === null) {
    value = '0';
  }

  value = +value;

  const displayDays = Math.floor(value / dayInEpoch);

  value = value - displayDays * dayInEpoch;

  const displayHours = Math.floor(value / hourInEpoch);

  value = value - displayHours * hourInEpoch;

  const displayMinutes = Math.floor(value / minuteInEpoch);

  return [displayMinutes, displayHours, displayDays];
}
