import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TColumn } from '../../../columns/column.model';

export function checkShowingTotal(column: TColumn): boolean {
  const customFields = GET_CUSTOM_FIELDS();

  if (!column?.customFieldIds) {
    return false;
  }

  const firstCustomField = customFields[column.customFieldIds[0]];

  if (!firstCustomField) {
    return false;
  }

  if (
    (firstCustomField.type === ECustomFieldType.COST ||
      firstCustomField.type === ECustomFieldType.TIME ||
      firstCustomField.type === ECustomFieldType.NUMBERS) &&
    (firstCustomField.showTotal || typeof firstCustomField?.showTotal === 'undefined')
  ) {
    // backend can return undefined, then it's enabled by default) {
    return true;
  }

  return false;
}
