/**
 * Escapes characters that can not be in an XML attribute.
 */
function escapeAttribute(value) {
  return value.replace(/&/g, '&amp;').replace(/'/g, '&apos;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

/**
 * @api private
 */
module.exports = {
  escapeAttribute: escapeAttribute
};