import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-save-indicator',
  templateUrl: './save-indicator.component.html',
  styleUrls: ['./save-indicator.component.scss'],
})
export class SaveIndicatorComponent {
  @Input() ppUpdating = false;
  @Input() ppFilled: boolean;
  @Input() ppShowClearBtn = true;
  @Input() ppMouseDownHandler: boolean; // Flatpickr uses mouse down to open so we need to override it
  @Input() ppFocused = false;
  @Output() ppAction = new EventEmitter<void>();

  EIconPath = EIconPath;

  action($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.ppUpdating && this.ppAction) {
      this.ppAction.emit();
    }
  }
}
