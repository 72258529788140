<div class="assetCard__sitesTableContainer">
  <pp-asset-card-sites-table-header class="assetCard__tableHeader">
  </pp-asset-card-sites-table-header>
  <div class="assetCard__sitesScrollContainer">
    <pp-asset-card-sites-table-row
      *ngFor="let site of ppSites"
      [ppAssetSite]="site"
      (ppNavigate)="navigateToSite($event)"
    ></pp-asset-card-sites-table-row>
  </div>
</div>
