import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { humanizeShareOption } from 'src/app/project/modules/share/share-utils/humanize-share';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { NewAssetSitePickerExpandingService } from '../new-asset-site-picker-expanding.service';
import { NewAssetSitePickerService } from '../new-asset-site-picker.service';

type TAssetCustomField = {
  label: string;
  value: string;
};

@Component({
  selector: 'pp-new-asset-site-picker-workspace',
  templateUrl: './new-asset-site-picker-workspace.component.html',
  styleUrls: ['./new-asset-site-picker-workspace.component.scss'],
})
export class NewAssetSitePickerWorkspaceComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;

  workspace: TWorkspace;
  expanded = false;
  EIconPath = EIconPath;
  checked = false;
  role: string;
  customFields: TAssetCustomField[] = [];
  checkedCustomFieldId: string = '';

  private keyword: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private newAssetSitePickerService: NewAssetSitePickerService,
    private newAssetSitePickerExpandingService: NewAssetSitePickerExpandingService,
  ) {
    this.keyword = this.newAssetSitePickerService.getKeyword();

    this.newAssetSitePickerService.keywordChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((keyword) => {
        this.keyword = keyword;
        this.setCustomFields();
      });
  }

  ngOnChanges(): void {
    this.workspace = this.workspaceService.getWorkspace(this.ppWorkspaceId);
    this.role = humanizeShareOption(this.workspace.share.shareOption as EUserRole);
    this.setCustomFields();
    this.checkInitialAssetSelection();
    this.expanded = this.newAssetSitePickerExpandingService.isWorkspaceExpanded(this.ppWorkspaceId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateChecked(): void {
    this.checked = !this.checked;

    if (this.checked) {
      this.newAssetSitePickerService.addAssetSite({
        workspaceId: this.ppWorkspaceId,
        budgetCustomFieldId: this.checkedCustomFieldId,
      });
    } else {
      this.checkedCustomFieldId = '';

      this.newAssetSitePickerService.removeAssetSite(this.ppWorkspaceId);
    }
  }

  updateCustomFieldChecked(customField: TAssetCustomField): void {
    if (this.checkedCustomFieldId === customField.value) {
      this.checkedCustomFieldId = '';
    } else {
      this.checked = true;
      this.checkedCustomFieldId = customField.value;
    }

    if (this.checked) {
      this.newAssetSitePickerService.addAssetSite({
        workspaceId: this.ppWorkspaceId,
        budgetCustomFieldId: this.checkedCustomFieldId,
      });
    }
  }

  updateExpanded(): void {
    this.expanded = !this.expanded;

    this.newAssetSitePickerExpandingService.setWorkspaceExpanded(this.ppWorkspaceId, this.expanded);
  }

  private checkInitialAssetSelection(): void {
    const assetSite = this.newAssetSitePickerService.getAssetSites()[this.ppWorkspaceId];

    if (assetSite) {
      this.checked = true;
      this.checkedCustomFieldId = assetSite.budgetCustomFieldId;
    }
  }

  private setCustomFields(): void {
    let costCustomFields: TAssetCustomField[] = [];

    const customFields = this.customFieldsService.getWorkspaceCustomFields(
      this.workspace.workspaceId,
    );

    Object.values(customFields)
      .filter(
        (customField) =>
          customField.label.toLowerCase().includes(this.keyword.toLowerCase()) ||
          this.workspace.siteName.toLowerCase().includes(this.keyword.toLowerCase()) ||
          this.workspace.accountName.toLowerCase().includes(this.keyword.toLowerCase()),
      )
      .forEach((customField) => {
        if (customField.type === ECustomFieldType.COST) {
          costCustomFields.push({
            label: customField.label,
            value: customField.id.toString(), // TODO BE Returns number for some reason
          });
        }
      });

    this.customFields = costCustomFields;
  }
}
