import { Action } from '@ngrx/store';
import { TShare } from '../share/share.model';

import { TWorkspace, TWorkspacesById } from './workspace.model';

export enum EActionType {
  SET_WORKSPACES = '[Workspaces] Set',
  SET_WORKSPACE = '[Workspace] Set',
  CLEAR_WORKSPACES = '[Workspaces] Clear',
  UPDATE_ACCOUNT_NAME = '[Workspace] Update account name',
  UPDATE_SITE_NAME = '[Workspace] Update site name',
  UPDATE_TOTAL_BYTES = '[Workspace] Update total bytes',
  UPDATE_WORKSPACE_USERS = '[Workspace] Update workspace users',
  UPDATE_WORKSPACE_TAGS = '[Workspace] Update workspace tags',
  UPDATE_WORKSPACE_CUSTOM_FIELDS = '[Workspace] Update workspace custom fields',
  REMOVE_WORKSPACE_USER = '[Workspace] Remove workspace user',
  ADD_CUSTOM_FIELD = '[Workspace] Add custom field',
  REMOVE_CUSTOM_FIELD = '[Workspace] Remove custom field',
  UPDATE_CUSTOM_FIELD = '[Workspace] Update custom field',
  UPDATE_POINT_IDS = '[Workspace] Update point ids',
  UPDATE_ALL_POINT_IDS = '[Workspace] Update all point ids',
  DELETE_WORKSPACE = '[Workspace] Delete workspace',
  UPDATE_WORKSPACE_SHARE = '[Workspace] Update Workspace Share',
  UPDATE_WORKSPACE_LOGO = '[Workspace] Update Workspace Logo',
  DELETE_WORKSPACES = '[Workspace] Delete workspaces',
}

export class SetWorkspaces implements Action {
  readonly type = EActionType.SET_WORKSPACES;

  constructor(readonly payload: TWorkspacesById) {}
}

export class SetWorkspace implements Action {
  readonly type = EActionType.SET_WORKSPACE;

  constructor(
    readonly payload: {
      workspaceId: string;
      workspace: TWorkspace;
    },
  ) {}
}

export class ClearWorkspaces implements Action {
  readonly type = EActionType.CLEAR_WORKSPACES;

  constructor() {}
}

export class UpdateWorkspaceAccountName implements Action {
  readonly type = EActionType.UPDATE_ACCOUNT_NAME;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      accountName: TWorkspace['accountName'];
    },
  ) {}
}
export class UpdateSiteName implements Action {
  readonly type = EActionType.UPDATE_SITE_NAME;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      siteName: TWorkspace['siteName'];
    },
  ) {}
}

export class UpdateWorkspaceUsers implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_USERS;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      users: TWorkspace['users'];
    },
  ) {}
}

export class UpdateWorkspaceCustomFields implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_CUSTOM_FIELDS;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      customFields: string[];
    },
  ) {}
}

export class UpdateWorkspaceTags implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_TAGS;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      tags: string[];
    },
  ) {}
}

export class RemoveWorkspaceUser implements Action {
  readonly type = EActionType.REMOVE_WORKSPACE_USER;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      user: string;
    },
  ) {}
}
export class AddCustomField implements Action {
  readonly type = EActionType.ADD_CUSTOM_FIELD;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      customFieldId: string;
    },
  ) {}
}

export class RemoveCustomField implements Action {
  readonly type = EActionType.REMOVE_CUSTOM_FIELD;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      customFieldId: string;
    },
  ) {}
}

export class UpdateCustomField implements Action {
  readonly type = EActionType.UPDATE_CUSTOM_FIELD;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      customFieldId: string;
    },
  ) {}
}
export class UpdatePointIds implements Action {
  readonly type = EActionType.UPDATE_POINT_IDS;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      pointIds: TWorkspace['pointIds'];
    },
  ) {}
}

export class UpdateAllPointIds implements Action {
  readonly type = EActionType.UPDATE_ALL_POINT_IDS;

  constructor(
    readonly payload: {
      pointIdsByWorkspace: {
        [workspaceId: string]: string[];
      };
    },
  ) {}
}

export class DeleteWorkspace implements Action {
  readonly type = EActionType.DELETE_WORKSPACE;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
    },
  ) {}
}

export class DeleteWorkspaces implements Action {
  readonly type = EActionType.DELETE_WORKSPACES;

  constructor(
    readonly payload: {
      workspaceIds: TWorkspace['workspaceId'][];
    },
  ) {}
}

export class UpdateWorkspaceShare implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_SHARE;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      share: TShare;
    },
  ) {}
}

export class UpdateWorkspaceLogo implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_LOGO;

  constructor(
    readonly payload: {
      workspaceId: TWorkspace['workspaceId'];
      imageId: string;
    },
  ) {}
}

export type ActionsUnion =
  | SetWorkspaces
  | SetWorkspace
  | ClearWorkspaces
  | UpdateWorkspaceAccountName
  | UpdateSiteName
  | UpdateWorkspaceUsers
  | UpdateWorkspaceCustomFields
  | RemoveWorkspaceUser
  | UpdateWorkspaceTags
  | AddCustomField
  | RemoveCustomField
  | UpdateCustomField
  | UpdatePointIds
  | UpdateAllPointIds
  | DeleteWorkspace
  | DeleteWorkspaces
  | UpdateWorkspaceShare
  | UpdateWorkspaceLogo;
