import { translate } from 'src/app/project/features/translate/translate';
import { EReportType } from '../report-types-enum';

export function addGraphLabels(type: string, data: number[]): string[] {
  if (type === EReportType.STATUS) {
    return [
      `${translate('open')} (${data[0]})`,
      `${translate('in_progress')} (${data[1]})`,
      `${translate('to_review')} (${data[2]})`,
      `${translate('on_hold')} (${data[3]})`,
      `${translate('completed')} (${data[4]})`,
      `${translate('canceled')} (${data[5]})`,
    ];
  } else {
    return [
      `${translate('high')} (${data[0]})`,
      `${translate('medium')} (${data[1]})`,
      `${translate('low')} (${data[2]})`,
    ];
  }
}
