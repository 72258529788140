var util = require('../core').util;
var toBuffer = util.buffer.toBuffer;

// All prelude components are unsigned, 32-bit integers
var PRELUDE_MEMBER_LENGTH = 4;
// The prelude consists of two components
var PRELUDE_LENGTH = PRELUDE_MEMBER_LENGTH * 2;
// Checksums are always CRC32 hashes.
var CHECKSUM_LENGTH = 4;
// Messages must include a full prelude, a prelude checksum, and a message checksum
var MINIMUM_MESSAGE_LENGTH = PRELUDE_LENGTH + CHECKSUM_LENGTH * 2;

/**
 * @api private
 *
 * @param {Buffer} message
 */
function splitMessage(message) {
  if (!util.Buffer.isBuffer(message)) message = toBuffer(message);
  if (message.length < MINIMUM_MESSAGE_LENGTH) {
    throw new Error('Provided message too short to accommodate event stream message overhead');
  }
  if (message.length !== message.readUInt32BE(0)) {
    throw new Error('Reported message length does not match received message length');
  }
  var expectedPreludeChecksum = message.readUInt32BE(PRELUDE_LENGTH);
  if (expectedPreludeChecksum !== util.crypto.crc32(message.slice(0, PRELUDE_LENGTH))) {
    throw new Error('The prelude checksum specified in the message (' + expectedPreludeChecksum + ') does not match the calculated CRC32 checksum.');
  }
  var expectedMessageChecksum = message.readUInt32BE(message.length - CHECKSUM_LENGTH);
  if (expectedMessageChecksum !== util.crypto.crc32(message.slice(0, message.length - CHECKSUM_LENGTH))) {
    throw new Error('The message checksum did not match the expected value of ' + expectedMessageChecksum);
  }
  var headersStart = PRELUDE_LENGTH + CHECKSUM_LENGTH;
  var headersEnd = headersStart + message.readUInt32BE(PRELUDE_MEMBER_LENGTH);
  return {
    headers: message.slice(headersStart, headersEnd),
    body: message.slice(headersEnd, message.length - CHECKSUM_LENGTH)
  };
}

/**
 * @api private
 */
module.exports = {
  splitMessage: splitMessage
};