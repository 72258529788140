import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomFieldBulk } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PermissionsService } from '../../share/permissions.service';
import { CustomFieldsService } from '../custom-fields.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { ECustomFieldType } from '../custom-field-types-enums';
import {
  TBulkCustomFieldExtended,
  TBulkCustomFields,
  TBulkCustomFieldsExtended,
  TBulkMentions,
} from './bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-custom-fields-modal',
  templateUrl: './bulk-custom-fields-modal.component.html',
  styleUrls: ['./bulk-custom-fields-modal.component.scss'],
})
export class BulkCustomFieldsModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspaceId = this.activeService.getActiveWorkspaceId();
  workspace = this.workspaceService.findWorkspace(this.workspaceId);
  customFieldsData = this.customFieldsService.getCustomFields();

  customFieldTypes = ECustomFieldType;

  customFields: TBulkCustomFields = {};
  customFieldsExtended: TBulkCustomFieldsExtended = {};

  processing = false;
  editableCustomFieldIds: string[] = [];
  allFieldsEditable = false;
  mentions: TBulkMentions = {};

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private sitePointFilterService: SitePointFilterService,
    private customFieldsService: CustomFieldsService,
    private pointsUpdateService: PointsUpdateService,
    private permissionsService: PermissionsService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit(): void {
    this.checkForEditableFields();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  save(): void {
    this.processing = true;
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = pointIds.length;
    const mentions = [];

    Object.keys(this.mentions).forEach((fieldId) => {
      mentions.push(...this.mentions[fieldId]);
    });

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EDIT, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'CF',
      event_value: `${numberOfSelectedPoints}`,
    });

    this.pointsUpdateService
      .updatePointsCustomFields(pointIds, this.customFieldsExtended, mentions)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_points_update_success');

          selectedPoints.forEach((point) => {
            Object.keys(this.customFieldsExtended).forEach((key) => {
              let customField = this.checkForCommas(this.customFieldsExtended[key]);

              this.store.dispatch(
                new UpdatePointCustomFieldBulk({
                  workspaceId: this.workspace.workspaceId,
                  _id: point._id,
                  customFieldId: key,
                  customField: customField,
                  plainText: customField.plainText,
                }),
              );

              this.customTableService.updatePoint({
                _id: point._id,
                field: customField.label,
                newValue: customField.value,
                updatedDate: new Date().getTime(),
              });
            });
          });

          this.sitePointFilterService.filterPoints(true);
          this.promptService.showSuccess(promptText);
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  checkForEditableFields(): void {
    const editableFields = this.permissionsService.checkForEditableFields(
      this.workspace.workspaceId,
    );

    this.allFieldsEditable = editableFields.allFieldsEnabled;
    this.editableCustomFieldIds = editableFields.editableCustomFieldIds;
  }

  checkForCommas(customField: TBulkCustomFieldExtended): TBulkCustomFieldExtended {
    if (customField.type === ECustomFieldType.NUMBERS) {
      customField = {
        ...customField,
        value: customField.value.replace(/,/g, ''),
      };
    }

    return customField;
  }
}
