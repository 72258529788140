import { translate } from 'src/app/project/features/translate/translate';
import { TDashletGraph } from '../dashlet-graph.model';
import { EReportType } from '../report-types-enum';

export function addGraphDatasetLabels(
  graph: TDashletGraph,
  type: EReportType,
  data: number[][],
): void {
  if (type === EReportType.STATUS) {
    graph.data.datasets[0].label = `${translate('open')} (${data[0][0]})`;
    graph.data.datasets[1].label = `${translate('in_progress')} (${data[1][0]})`;
    graph.data.datasets[2].label = `${translate('to_review')} (${data[2][0]})`;
    graph.data.datasets[3].label = `${translate('on_hold')} (${data[3][0]})`;
    graph.data.datasets[4].label = `${translate('completed')} (${data[4][0]})`;
    graph.data.datasets[5].label = `${translate('canceled')} (${data[5][0]})`;
  } else {
    graph.data.datasets[0].label = `${translate('high')} (${data[0][0]})`;
    graph.data.datasets[1].label = `${translate('medium')} (${data[1][0]})`;
    graph.data.datasets[2].label = `${translate('low')} (${data[2][0]})`;
  }
}
