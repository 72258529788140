import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { TSelectDropdownData } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.model';
import { translate } from 'src/app/project/features/translate/translate';
import {
  ESemiSimplePermission,
  ESimplePermission,
} from 'src/app/project/modules/share/permissions.consts';
import { EColumn } from '../../user-management.consts';
import {
  TPermissionItemsByColumn,
  getPermissionItemsByColumn,
} from './permissions-per-column.utils';

// TODO: use EUserRole as type for userRole instead of string
export const getDropdownDataForColumn = (
  column: EColumn,
  userRole: string,
  currentPermission: ESimplePermission | ESemiSimplePermission,
): TSelectDropdownData => {
  const permissionItemsByColumn = getPermissionItemsByColumn(column, userRole);

  return {
    noSearchBar: true,
    items: permissionItemsByColumn.map((item) => {
      return {
        value: item.value,
        iconSrc: item.iconSrc,
        label: translate(item.translationKey),
      };
    }),
    selectedItem: tryFindSelectedPermissionItem(permissionItemsByColumn, currentPermission),
  };
};

const tryFindSelectedPermissionItem = (
  permissionItemsByColumn: TPermissionItemsByColumn,
  currentPermission: ESimplePermission | ESemiSimplePermission,
): TSelectOption => {
  try {
    const item = permissionItemsByColumn.find(
      (permissionByColumn) => permissionByColumn.value === currentPermission,
    );

    return {
      value: item.value,
      iconSrc: item.iconSrc,
      label: translate(item.translationKey),
    };
  } catch (error) {
    throw error;
  }
};
