import { Injectable, NgZone } from '@angular/core';
import { createElement } from 'src/app/core/helpers/dom';
import { DashboardReportService } from '../dashboard-report.service';
import { DashletService } from '../dashlet/dashlet-service/dashlet.service';
import { EDashletType } from '../dashlets-enums';
import {
  advancedDashletBorderRadius,
  advancedDashletCanvasWidth,
  advancedDashletGraphHeight,
  advancedDashletHeight,
  advancedDashletImageWidth,
} from './dashboard-export-variables';
import { createTotalPointsElement } from './create-total-points-element';
import { createDashletHeaderElement } from './crete-dashlet-head-element';
import {
  generateImage,
  TDashboardImageElementData,
} from './dashboard-export-elements/dashboard-image-element';
import { TExportDashlet } from '../dashboard-export-modal/dashboard-export-dashlet.model';
import { TDashletGraph } from '../dashlet-graph.model';
import { TChartSettings } from '../dashboard.model';
import { Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

type TDashletGraphSettings = {
  dashlet: TExportDashlet;
  canvas: HTMLCanvasElement;
  imageWrapper: HTMLElement;
  image: HTMLImageElement;
  scale: number;
};

@Injectable({
  providedIn: 'root',
})
export class DashboardAdvancedDashletService {
  constructor(
    private dashletService: DashletService,
    private dashboardReportService: DashboardReportService,
    private ngZone: NgZone,
  ) {}

  generateAdvancedDashlet(
    dashletsWrapperElement: HTMLElement,
    dashlet: TExportDashlet,
  ): Observable<TDashboardImageElementData> {
    const scale = 1; // change that if needed to make sure that pdf isn't pixelated
    const canvasWrapper = this.createCanvasWrapper();
    const imageWrapper = this.createImageWrapper(scale);
    const image = createElement<HTMLImageElement>('img');
    const canvas = createElement<HTMLCanvasElement>('canvas');

    image.id = 'image';
    image.style.position = 'absolute';
    image.style.bottom = '110px';
    image.style.left = '150px';
    image.style.objectFit = 'contain';
    image.style.width = '1800px';
    image.style.height = '1083px';

    canvas.style.width = `${advancedDashletCanvasWidth}px`;
    canvas.style.height = `${advancedDashletGraphHeight}px`;
    canvas.id = 'advancedDashletCanvas';

    canvasWrapper.style.width = `${advancedDashletCanvasWidth}px`;
    canvasWrapper.style.height = `${advancedDashletGraphHeight}px`;

    imageWrapper.appendChild(image);
    canvasWrapper.appendChild(canvas);

    dashletsWrapperElement.appendChild(canvasWrapper);

    const dashletGraph: TDashletGraphSettings = {
      dashlet,
      canvas,
      imageWrapper,
      image,
      scale,
    };

    return this.createGraph(dashletGraph);
  }

  private createCanvasWrapper(): HTMLElement {
    const imageScale = 1;

    const canvasWrapper: HTMLElement = createElement('div');
    canvasWrapper.style.backgroundColor = 'white';
    canvasWrapper.style.marginLeft = `${100 * imageScale}px`;
    canvasWrapper.style.width = `${advancedDashletImageWidth * imageScale}px`;
    canvasWrapper.style.height = `${advancedDashletGraphHeight * imageScale}px`;
    canvasWrapper.style.borderRadius = `${advancedDashletBorderRadius * imageScale}px`;
    canvasWrapper.id = 'canvasWrapper';

    return canvasWrapper;
  }

  private createImageWrapper(scale: number): HTMLElement {
    const imageWrapper = createElement<HTMLElement>('div');
    imageWrapper.style.width = `${advancedDashletImageWidth * scale}px`;
    imageWrapper.style.height = `${advancedDashletHeight * scale}px`;
    imageWrapper.style.backgroundColor = 'white';
    imageWrapper.style.position = 'relative';
    imageWrapper.style.borderRadius = `${advancedDashletBorderRadius * scale}px`;
    imageWrapper.id = 'imageWrapper';

    return imageWrapper;
  }

  private createGraph({
    dashlet,
    canvas,
    imageWrapper,
    image,
    scale,
  }: TDashletGraphSettings): Observable<TDashboardImageElementData> {
    const graph = this.dashletService.getDashletGraph(dashlet.name, true);
    return this.getGraphDataResolver(dashlet, graph, canvas).pipe(
      switchMap((chartSettings) =>
        this.completeChart(chartSettings, {
          imageWrapper,
          imageElement: image,
          chartType: dashlet.name,
          accountName: dashlet.accountName,
          workspaceName: dashlet.workspaceName,
          scale,
        }),
      ),
    );
  }

  private getGraphDataResolver(
    dashlet: TExportDashlet,
    graph: TDashletGraph,
    canvas: HTMLCanvasElement,
  ): Observable<TChartSettings> {
    switch (dashlet.name) {
      case EDashletType.CURRENT_PRIORITY:
      case EDashletType.CURRENT_STATUS:
        return this.dashboardReportService.fetchCurrentTypeCount(dashlet, graph, canvas);

      case EDashletType.OVER_TIME_PRIORITY:
      case EDashletType.OVER_TIME_STATUS:
        return this.dashboardReportService.fetchExportReports(dashlet, graph, canvas);

      default: {
        const message = `Unable to export "${dashlet.name}" dashlet`;
        console.error(message);

        return throwError(message);
      }
    }
  }

  private completeChart(
    { chart, totalPoints }: TChartSettings,
    {
      imageWrapper,
      imageElement,
      chartType,
      accountName,
      workspaceName,
      scale,
    }: {
      imageWrapper: HTMLElement;
      imageElement: HTMLImageElement;
      chartType: string;
      accountName: string;
      workspaceName: string;
      scale: number;
    },
  ): Promise<TDashboardImageElementData> {
    return new Promise((resolve) => {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          const src = chart.toBase64Image();

          const totalPointsElement = createTotalPointsElement(totalPoints, scale);
          const headerElement = createDashletHeaderElement(
            accountName,
            workspaceName,
            chartType,
            scale,
          );

          imageWrapper.appendChild(totalPointsElement);
          imageWrapper.appendChild(headerElement);

          imageElement.src = src;

          generateImage({
            element: imageWrapper,
            width: advancedDashletImageWidth,
            height: advancedDashletHeight,
            wrapperWidth: advancedDashletImageWidth,
            wrapperHeight: advancedDashletHeight,
            borderRadius: advancedDashletBorderRadius,
            chartType,
            scale,
          }).subscribe((response) => {
            resolve(response);
          });
        }, 1000);
        // TODO disable animation for exported dashlets to use requestAnimationFrame() here instead of waiting 1000ms
      });
    });
  }
}
