import { Component, Input, OnInit } from '@angular/core';
import { uuid } from '@core/helpers';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { getDragIcon } from 'src/app/core/helpers/dragging/get-drag-icon';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAsset } from '../../../../asset-service/asset.consts';
import { AssetService } from '../../../../asset-service/asset.service';
import { FleetService } from '../../../../fleet-service/fleet.service';
import { EFleetShareOption } from '../../../../fleet-sharing/fleet-sharing.consts';
import { AssetReorderService } from '../../asset-reorder.service';
import { AssetCardOptionsDropdownComponent } from '../asset-card-options-dropdown/asset-card-options-dropdown.component';
import { TAssetCardOptionsDropdownData } from '../asset-card-options-dropdown/asset-card-options-dropdown.consts';
import { AssetCardRedirectService } from '../asset-card-redirect.service';

@Component({
  selector: 'pp-asset-card-header',
  templateUrl: './asset-card-header.component.html',
  styleUrls: ['./asset-card-header.component.scss'],
})
export class AssetCardHeaderComponent implements OnInit {
  @Input() ppAsset: TAsset;
  keyword: string = '';

  imageUrl: string;
  optionsButtonId = uuid();
  assetName = 'Asset';
  EIconPath = EIconPath;
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  permission: EFleetShareOption;
  EFleetShareOption = EFleetShareOption;
  private destroy$ = new Subject<void>();
  private searchKeywordDebounceTime: number = 500;

  constructor(
    private assetService: AssetService,
    private dropdownService: DropdownService,
    private fleetService: FleetService,
    private assetReorderService: AssetReorderService,
    private assetCardRedirectService: AssetCardRedirectService,
  ) {
    this.assetService.keywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap((keyword) => {
          this.keyword = keyword;
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.imageUrl = this.ppAsset.imageId ? this.assetService.getImageUrl(this.ppAsset.imageId) : '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(): void {
    this.setPermissions();
  }

  toggleDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.optionsButtonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData<TAssetCardOptionsDropdownData>({
        fleetId: this.ppAsset.fleetId,
        assetId: this.ppAsset.id,
      });

      this.dropdownService.showDropdown(this.optionsButtonId, AssetCardOptionsDropdownComponent);
    }
  }

  openOverviewFromAsset(): void {
    if (this.ppAsset.sites.length === 0) {
      return;
    }

    logEventInGTAG(EGoogleEventFleetManagement.OPEN_OVERVIEW_FROM_ASSET, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.assetCardRedirectService.openOverviewFromAsset(this.ppAsset);
  }

  onDragStart(event: DragEvent): void {
    const img = new Image();

    img.src = getDragIcon();

    event.dataTransfer.setDragImage(img, 0, 0);
    this.assetReorderService.setReorderedAssetId(this.ppAsset.id);
  }

  onDragEnd(): void {
    this.assetReorderService.reorderAssets();
  }

  private setPermissions(): void {
    const fleet = this.fleetService.getFleet(this.ppAsset.fleetId);

    if (fleet) {
      this.permission = fleet.fleetShareOption;
    }
  }
}
