<pp-modal
  [ppThin]="true"
  [ppNoFooter]="step === 'select' && !importingCustomFields"
  [ppScrollOnDefault]="true"
  [ppScrollX]="maxListDepthExceeded"
>
  <ng-container heading>
    {{ 'create_custom_field' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <pp-double-select
      *ngIf="step !== 'details'"
      [ppFirstOption]="'add_new_cf_capital' | ppTranslate"
      [ppSecondOption]="'import_cf_capital' | ppTranslate"
      (ppFirstChoice)="toggleImport(false)"
      (ppSecondChoice)="toggleImport(true)"
      [ppSelected]="importingCustomFields ? 1 : 0"
      class="hideMobile"
    ></pp-double-select>

    <div *ngIf="!importingCustomFields" class="newCFModal">
      <button class="newCFModal__back-button" *ngIf="step === 'details'" (click)="back()">
        <img class="newCF__back-arrow" [src]="EIconPath.ARROW_LEFT" />
        {{ 'back_to_custom_field_selection' | ppTranslate }}
      </button>

      <div class="newCFModal__header" *ngIf="step === 'select'">
        <h5 class="addCF__field-label modal__body-title">
          {{ 'choose_type' | ppTranslate }}
        </h5>

        <div class="addCF__grid-wrapper">
          <ng-container *ngFor="let customField of customFieldTypesData">
            <pp-grid-tile
              ppTooltip
              [ppImage]="customField.iconPath"
              [ppGridTitle]="customField.text"
              [ppText]="customField.description"
              [ppDisabled]="
                (customField.value === customFieldTypes.TIMELINE && timelineExists) ||
                (!timelineEnabled && customField.value === customFieldTypes.TIMELINE)
              "
              [ppTitle]="
                customField.value === customFieldTypes.TIMELINE && timelineExists
                  ? ('prompt_field_create_error_timeline_doubled' | ppTranslate)
                  : !timelineEnabled && customField.value === customFieldTypes.TIMELINE
                  ? ('enable_timeline' | ppTranslate)
                  : ''
              "
              (ppOnSelect)="setType(customField)"
            ></pp-grid-tile>
          </ng-container>
        </div>
      </div>

      <pp-button
        ppStyle="primary"
        ppSize="small"
        *ngIf="
          step === 'details' &&
          field.type === customFieldTypes.LIST &&
          (user.isSuperUser || workspace.share.shareOption === EUserRole.OWNER)
        "
      >
        <label
          class="settingsNewSite__upload-button"
          [class.settingsNewSite__upload-button--disabled]="false"
          for="excel"
        >
          {{ 'create_list_from_excel' | ppTranslate }}
        </label>

        <pp-upload
          id="excel--update"
          [ppId]="'excel'"
          (ppUpload)="uploadExcel($event)"
          [ppDisabled]="false"
        >
        </pp-upload>
      </pp-button>

      <pp-edit-custom-field-details
        [(ppCustomField)]="field"
        (ppListError)="updateListError($event)"
        *ngIf="step === 'details'"
      ></pp-edit-custom-field-details>
    </div>

    <div class="newCFModal" *ngIf="importingCustomFields">
      <pp-add-custom-field-modal-import
        [ppSelectedCustomFields]="selectedCustomFields"
        [ppProcessingImport]="processingImport"
        [ppTotalSelectedFields]="totalSelectedFields"
        [ppTimelineExists]="timelineExists"
        [ppTimelineEnabled]="timelineEnabled"
      ></pp-add-custom-field-modal-import>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      *ngIf="!importingCustomFields && step !== 'select'"
      [ppConfirmText]="'create' | ppTranslate"
      [ppProcess]="processing || processingImport"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!field.label || processing || listCFError"
    ></pp-modal-generic-footer>

    <pp-modal-generic-footer
      *ngIf="importingCustomFields"
      [ppConfirmText]="'import' | ppTranslate"
      [ppProcess]="processing || processingImport"
      (ppCancel)="hideModal()"
      (ppConfirm)="importCustomFields()"
      [ppDisabled]="totalSelectedFields.number === 0"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
