import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TClassList } from '@core/helpers';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnChanges {
  @Input() ppDisabled = false;
  @Input() ppChecked: boolean;
  @Output() ppOnValueChange = new EventEmitter<void>();
  @Input() ppSize: 'large' | 'medium' = 'large';
  @Input() ppType: 'solid' | 'subtle' = 'subtle';

  class: TClassList;
  EIconPath = EIconPath;

  ngOnChanges() {
    this.getClass();
  }

  private getClass(): void {
    if (this.ppDisabled) {
      this.class = this.getDisabledClass();
    } else {
      this.class = this.getActiveClass();
    }
  }

  private getActiveClass(): TClassList {
    return {
      'checkbox--solid': this.ppType === 'solid',
      'checkbox--subtle': this.ppType === 'subtle',
      'checkbox--large': this.ppSize === 'large',
      'checkbox--medium': this.ppSize === 'medium',
    };
  }

  private getDisabledClass(): TClassList {
    return {
      'checkbox--disabled': true,
    };
  }

  toggleCheckbox(): void {
    if (!this.ppDisabled) {
      this.ppOnValueChange.emit();
    }
  }
}
