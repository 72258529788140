<div #assetReorderLine class="assetReorderLine"></div>

<section class="fleetAssetsPage" #fleetAssets>
  <pp-asset-card
    *ngFor="let asset of ppAssets; let index = index"
    [ppAsset]="asset"
    [ppIndex]="index"
  ></pp-asset-card>
</section>

<pp-asset-card-footer
  *ngIf="ppAssets?.length"
  [ppAssetCount]="ppAssets.length"
></pp-asset-card-footer>
