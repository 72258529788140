import { cloneDeep } from 'lodash';
import { FilterAllPoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { togglePoint } from '../selected-points';
import { addVisiblePoint, removeVisiblePoint } from '../visible-points';

export function filterAllPoints(
  oldState: TPointsByWorkspace,
  action: FilterAllPoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  Object.keys(action.payload.pointsByWorkspace).forEach((workspaceId) => {
    state[workspaceId].entities.forEach((point) => {
      removeVisiblePoint(point._id);
      if (action.payload.pointsToToggle.includes(point._id)) {
        togglePoint(point);
      }

      action.payload.pointsByWorkspace[workspaceId].forEach((_id) => {
        if (point._id === _id) {
          addVisiblePoint(point._id);
        }
      });
    });
  });

  return state;
}
