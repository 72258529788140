import { minimumFillRatioToDisplayLabel } from '../../dashboard-export/dashboard-export-variables';
import { TDashletGraphOptions } from '../../dashlet-graph.model';
import { createDashletTooltip } from './dashlet-tooltip';

const fontColor = '#586378';

export function generateDoughnutGraphOptions(
  isMobile: boolean,
  isExport: boolean,
): TDashletGraphOptions {
  const fontSize = isExport ? 42 : 12;
  if (!isMobile) {
    return {
      devicePixelRatio: window.devicePixelRatio,
      plugins: {
        legend: {
          display: true,
          onClick: null,
          position: 'right',
          labels: {
            font: {
              size: fontSize,
              color: fontColor,
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: function (tooltipModel): void {
            return createDashletTooltip(tooltipModel, this._chart);
          },
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.label || '';
            },
          },
        },

        datalabels: {
          color: 'white',
          display: (value) =>
            value.dataset.data[value.dataIndex] >
            minimumFillRatioToDisplayLabel * value.chart.canvas.height,

          font: {
            size: fontSize,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      cutout: '55%',
    };
  } else {
    return {
      devicePixelRatio: window.devicePixelRatio,
      plugins: {
        legend: {
          display: true,
          onClick: null,
          position: 'bottom',
          labels: {
            font: {
              size: fontSize,
              color: fontColor,
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: function (tooltipModel): void {
            return createDashletTooltip(tooltipModel, this._chart);
          },
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.label || '';
            },
          },
        },
        datalabels: {
          color: 'white',
          display: (value) =>
            value.dataset.data[value.dataIndex] >
            minimumFillRatioToDisplayLabel * value.chart.canvas.height,

          font: {
            size: fontSize,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      cutout: '55%',
    };
  }
}
