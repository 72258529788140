import { TCustomField, TCustomFieldList } from './custom-fields.model';

/* eslint-disable no-prototype-builtins */
export function flattenList(list: TCustomField[] | TCustomField | undefined): {
  [key: string]: boolean | string;
} {
  const toReturn = {};

  for (const i in list) {
    if (!list.hasOwnProperty(i)) {
      continue;
    }

    if (typeof list[i] === 'object' && list[i] !== null) {
      const flatObject = flattenList(list[i]);

      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = list[i];
    }
  }

  return toReturn;
}

export function getIsContainsSlash(customFieldSubList: TCustomFieldList[] | undefined): boolean {
  const flattenedList = flattenList(customFieldSubList);
  let slashFound = false;

  for (const itemId in flattenedList) {
    if (
      !itemId.includes('fieldError') &&
      typeof flattenedList[itemId] === 'string' &&
      (flattenedList[itemId] as string).includes('/')
    ) {
      slashFound = true;

      break;
    }
  }

  return slashFound;
}
