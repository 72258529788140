<div class="siteList" [class.siteList__invisible]="activeStep !== EImportCustomFieldStep.SITES">
  <pp-site-list-select
    [ppAccounts]="accounts"
    ppType="customField"
    [ppFieldsPerAccount]="customFieldsPerAccount"
    [ppFieldsPerWorkspace]="customFieldsPerWorkspace"
    (ppAction)="openSiteCustomFields($event)"
  ></pp-site-list-select>
</div>

<div class="customFieldList" *ngIf="activeStep === EImportCustomFieldStep.CUSTOM_FIELDS">
  <button class="selectCustomFields__back" (click)="back()">
    <img [src]="EIconPath.ARROW_LEFT" />
    {{ 'back_to_site_selection' | ppTranslate }}
  </button>

  <div class="selectUsers_site-name-wrapper">
    <h6 class="selectUsers__site-name" *ngIf="!(data?.length > 0)">
      {{ 'no_custom_fields_available_on_site' | ppTranslate }}
    </h6>

    <div
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="site.siteName"
      class="selectUsers__site-name"
      *ngIf="data?.length > 0"
    >
      <h6 class="selectUsers__site-name-heading">
        {{ 'select_custom_fields_from' | ppTranslate }} <strong>{{ site.siteName }}</strong>
      </h6>
    </div>

    <pp-add-custom-field-import-select-all
      [ppSite]="site"
      [ppNumberOfSelectableFields]="numberOfSelectableFields"
      [ppSelectedCustomFields]="ppSelectedCustomFields"
      (ppSelectAll)="selectAll()"
      (ppDeselectAll)="deselectAll()"
    ></pp-add-custom-field-import-select-all>
  </div>

  <table
    aria-label="Custom Fields List"
    class="customFields__table-container"
    *ngIf="data?.length > 0"
  >
    <pp-add-custom-field-import-table-header
      [ppSelectedWorkspaceCustomFields]="ppSelectedCustomFields[site.accountId][site.workspaceId]"
      [ppSite]="site"
      [ppNumberOfSelectableFields]="numberOfSelectableFields"
      (ppSelectAll)="selectAll()"
      (ppDeselectAll)="deselectAll()"
    ></pp-add-custom-field-import-table-header>

    <ng-container *ngFor="let customField of data">
      <tr
        ppTooltip
        [ppTitle]="customFields[site.workspaceId][customField].label"
        class="customField"
        [class.customField-selected]="
          ppSelectedCustomFields[site.accountId][site.workspaceId].includes(customField)
        "
        (click)="selectCustomField(customField)"
        *ngIf="
          !initialWorkspaceCustomFields.includes(
            customFields[site.workspaceId][customField].label
          ) &&
          site &&
          (!selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] ||
            (selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] &&
              selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] ===
                site.workspaceId)) &&
          !(
            customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
            (ppTimelineExists || !ppTimelineEnabled)
          )
        "
      >
        <td class="checkbox__row">
          <pp-checkbox
            [ppChecked]="
              ppSelectedCustomFields[site.accountId][site.workspaceId].includes(customField)
            "
            ppSize="medium"
            ppType="solid"
          >
          </pp-checkbox>
        </td>

        <td class="name__row">
          {{ customFields[site.workspaceId][customField].label }}
        </td>

        <td class="type__row">
          {{ humanizedTypeNames[customFields[site.workspaceId][customField].type] }}
        </td>
      </tr>

      <tr
        class="customField customField--disabled"
        *ngIf="
          (initialWorkspaceCustomFields.includes(
            customFields[site.workspaceId][customField].label
          ) &&
            (!selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] ||
              (selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] &&
                selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] ===
                  site.workspaceId))) ||
          (customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
            (ppTimelineExists || !ppTimelineEnabled))
        "
      >
        <td class="checkbox__row">
          <pp-checkbox [ppChecked]="false" [ppDisabled]="true" ppSize="medium" ppType="solid">
          </pp-checkbox>
        </td>

        <td class="name__row">
          <span
            ppTooltip
            [ppTitle]="
              customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
              ppTimelineExists
                ? ('prompt_field_create_error_timeline_doubled' | ppTranslate)
                : customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
                  !ppTimelineEnabled
                ? ('timeline_not_enabled' | ppTranslate)
                : ('custom_field_already_exists_on_site' | ppTranslate)
            "
            [ppOptions]="{ placement: 'right' }"
          >
            {{ customFields[site.workspaceId][customField].label }}
          </span>
        </td>

        <td class="type__row">
          <span
            ppTooltip
            [ppTitle]="
              customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
              ppTimelineExists
                ? ('prompt_field_create_error_timeline_doubled' | ppTranslate)
                : customFields[site.workspaceId][customField].type === customFieldTypes.TIMELINE &&
                  !ppTimelineEnabled
                ? ('timeline_not_enabled' | ppTranslate)
                : ('custom_field_already_exists_on_site' | ppTranslate)
            "
            [ppOptions]="{ placement: 'right' }"
          >
            {{ humanizedTypeNames[customFields[site.workspaceId][customField].type] }}
          </span>
        </td>
      </tr>

      <tr
        ppTooltip
        [ppTitle]="'custom_field_with_name_already_selected' | ppTranslate"
        [ppOptions]="{ placement: 'right' }"
        class="customField customField--disabled"
        *ngIf="
          selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] &&
          selectedCustomFieldsNames[customFields[site.workspaceId][customField].label] !==
            site.workspaceId
        "
      >
        <td class="checkbox__row">
          <pp-checkbox [ppChecked]="false" [ppDisabled]="true" ppSize="medium" ppType="solid">
          </pp-checkbox>
        </td>

        <td class="name__row">
          {{ customFields[site.workspaceId][customField].label }}
        </td>

        <td class="type__row">
          {{ humanizedTypeNames[customFields[site.workspaceId][customField].type] }}
        </td>
      </tr>
    </ng-container>
  </table>
</div>
