import { TTranslationKey } from 'src/app/project/features/translate/types';

export type TIncludeItem = {
  label: TTranslationKey;
  value: string;
  size?: number;
  selected: boolean;
};

export enum ECopyMoveItems {
  ACTIVITIES = 'activities',
  COMMENTS = 'comments',
  PHOTOS = 'images',
  VIDEOS = 'videos',
  FILES = 'documents',
  DESCRIPTION = 'description',
}

export type TCopyMoveOptions = {
  activities: boolean;
  comments: boolean;
  description: boolean;
  documents: boolean;
  images: boolean;
  videos: boolean;
};

export const GET_DEFAULT_INCLUDE_ITEMS = (): TIncludeItem[] => [
  {
    label: 'activities',
    value: ECopyMoveItems.ACTIVITIES,
    selected: true,
    size: null,
  },
  {
    label: 'comments',
    value: ECopyMoveItems.COMMENTS,
    selected: true,
    size: null,
  },
  {
    label: 'photos',
    value: ECopyMoveItems.PHOTOS,
    selected: true,
    size: null,
  },
  {
    label: 'videos',
    value: ECopyMoveItems.VIDEOS,
    selected: true,
    size: null,
  },
  {
    label: 'files',
    value: ECopyMoveItems.FILES,
    selected: true,
    size: null,
  },
  {
    label: 'description',
    value: ECopyMoveItems.DESCRIPTION,
    selected: true,
    size: null,
  },
];
