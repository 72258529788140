import { fabric } from 'fabric';

import { Injectable, OnDestroy } from '@angular/core';

import {
  API_files_images_bounded_size,
  API_files_images_size_original,
  API_files_images_square_size,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Store } from '@ngrx/store';
import { Observable, Subject, from } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilesApiProviderService } from 'src/app/project/data-providers/api-providers/files-api-provider/files-api-provider.service';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDocumentType } from 'src/app/project/shared/enums/document-type.enum';
import { b64toBlob } from '../../../../core/helpers/b64toBlob';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { ReplaceMedia } from '../../../modules/points/attachments/attachments.actions';
import { TAttachment } from '../../../modules/points/attachments/attachments.model';
import { AttachmentsService } from '../../../modules/points/attachments/attachments.service';
import { ReplaceImage } from '../../../modules/points/points.actions';
import { PreferencesService } from '../../../modules/preferences/preferences-service/preferences.service';
import { UserService } from '../../../modules/user/user.service';
import { PromptService } from '../../prompt/prompt.service';
import { GalleryOverlayService } from '../gallery-overlay.service';
import { ImageAnnotationsArrowService } from './image-annotations-arrow.service';
import { ImageAnnotationsEllipseService } from './image-annotations-ellipse.service';
import {
  EImageAnnotationsState,
  ImageAnnotationsStateService,
} from './image-annotations-state.service';
import { ImageAnnotationsTextService } from './image-annotations-text.service';

type TImageCanvasElementSize = {
  canvasWidth: number;
  canvasHeight: number;
};

type TImageInfo = {
  imageFabric: fabric.Image;
  originalWidth: number;
  originalHeight: number;
  scaleRatio: number;
};

@Injectable({
  providedIn: 'root',
})
export class ImageAnnotationsService implements OnDestroy {
  canvasFabric: fabric.Canvas = null;
  currentMode: EImageAnnotationsState = null;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private responseErrorService: ResponseErrorService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private filesApiProviderService: FilesApiProviderService,
    private attachmentsService: AttachmentsService,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private imageAnnotationsStateService: ImageAnnotationsStateService,
    private imageAnnotationsTextService: ImageAnnotationsTextService,
    private imageAnnotationsArrowService: ImageAnnotationsArrowService,
    private imageAnnotationsEllipseService: ImageAnnotationsEllipseService,
    private galleryOverlayService: GalleryOverlayService,
    private apiService: ApiService,
  ) {
    imageAnnotationsStateService.modeChange$.pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      this.currentMode = newMode;
    });
  }

  registerCanvasFabric(): void {
    this.canvasFabric = new fabric.Canvas('imageCanvasElement', {
      perPixelTargetFind: true,
      targetFindTolerance: 5,
    });

    this.registerSelectionEvents();

    this.imageAnnotationsTextService.setCanvasFabric(this.canvasFabric);
    this.imageAnnotationsArrowService.setCanvasFabric(this.canvasFabric);
    this.imageAnnotationsEllipseService.setCanvasFabric(this.canvasFabric);
  }

  getCanvasFabric(): fabric.Canvas {
    return this.canvasFabric;
  }

  initStrictMode(): void {
    this.canvasFabric.discardActiveObject();
    this.canvasFabric.isDrawingMode = false;
    this.canvasFabric.off('mouse:up');

    this.resetState();
  }

  initFreeDrawingMode(colorCode: string): void {
    this.canvasFabric.discardActiveObject();
    this.canvasFabric.isDrawingMode = true;
    const freeDrawingBrush = this.canvasFabric.freeDrawingBrush;

    freeDrawingBrush.color = colorCode;
    freeDrawingBrush.width = 2;

    this.canvasFabric.on('mouse:up', () => {
      if (this.currentMode === EImageAnnotationsState.FREE_DRAWING) {
        this.canvasFabric.isDrawingMode = false;
      }
    });

    this.canvasFabric.on('mouse:down:before', (e) => {
      if (this.currentMode === EImageAnnotationsState.FREE_DRAWING && e.target === null) {
        this.canvasFabric.isDrawingMode = true;

        if (!freeDrawingBrush.color) {
          freeDrawingBrush.color = colorCode;
        }
      }
    });

    this.resetState();
  }

  resetState(): void {
    this.imageAnnotationsArrowService.resetState();
    this.imageAnnotationsTextService.resetState();
  }

  getImageCanvasElementSize(): TImageCanvasElementSize {
    const imageElement = this.galleryOverlayService.getImageElement();

    if (
      imageElement.classList.contains('overlay__img--rotate-90deg') ||
      imageElement.classList.contains('overlay__img--rotate-270deg')
    ) {
      return {
        canvasWidth: imageElement.offsetHeight,
        canvasHeight: imageElement.offsetWidth,
      };
    }

    return {
      canvasWidth: imageElement.offsetWidth,
      canvasHeight: imageElement.offsetHeight,
    };
  }

  applyColor(colorCode: string): void {
    const activeObjects = this.canvasFabric.getActiveObjects();

    if (activeObjects.length > 0) {
      logEventInGTAG(EGoogleEventSite.SITE__GALLERY__OBJECT__CHANGE_COLOR, {
        event_category: EGoogleEventCategory.SITE,
        event_value: colorCode,
      });
    } else {
      logEventInGTAG(EGoogleEventSite.SITE__GALLERY__COLORS, {
        event_category: EGoogleEventCategory.SITE,
        event_value: colorCode,
      });
    }

    activeObjects.forEach((object) => {
      if (object.name === 'text') {
        object.set('fill', colorCode);
      } else if (object.name === 'arrow') {
        object._objects.forEach((groupObject) => {
          groupObject.set('fill', colorCode);
          groupObject.set('stroke', colorCode);
        });
      } else {
        object.set('stroke', colorCode);
      }
    });

    this.canvasFabric.renderAll();

    if (this.currentMode === EImageAnnotationsState.FREE_DRAWING) {
      this.canvasFabric.freeDrawingBrush.color = colorCode;
    }
  }

  deleteActiveObjects(): void {
    this.canvasFabric.getActiveObjects().forEach((obj) => {
      this.canvasFabric.remove(obj);
    });

    this.canvasFabric.discardActiveObject().renderAll();
  }

  save(
    imageId: string,
    _id: string,
    imageCanvasElement: HTMLCanvasElement,
    imageName: string,
    imageCreatedOn: string | number,
  ): Observable<{
    base64String: string;
    updatedImageId: string;
  }> {
    const canvasPlaceholderFabric = new fabric.Canvas('imageCanvasPlaceholderElement');

    canvasPlaceholderFabric._objects = this.canvasFabric._objects;
    canvasPlaceholderFabric.forEachObject((object) => (object.selectable = false));
    canvasPlaceholderFabric.renderAll();

    return from(this.getImageInfo(imageId, imageCanvasElement)).pipe(
      switchMap((imageInfo) => {
        const { imageFabric, originalWidth, originalHeight, scaleRatio } = imageInfo;

        this.mergeAnnotationsWithImage(originalWidth, originalHeight, imageFabric, scaleRatio);

        const base64String = this.mergedImageToBase64(originalWidth, originalHeight);
        const byteString = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
        const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
        const blob = b64toBlob(byteString, mimeType);
        const newFile = new File([blob], imageName, { type: mimeType });

        return this.updateImageAnnotation(imageId, newFile, _id).pipe(
          map((updatedImageId) => {
            const attachment = this.attachmentsService.findMediaAttachment(imageId);
            const user = this.userService.getUser();
            const workspaceId = attachment.workspaceId;
            const preferences = this.preferencesService.getPreferences();

            const imageToStore: TAttachment = {
              bounded1200Url: API_files_images_bounded_size(updatedImageId, 1200),
              square100Url: API_files_images_square_size(updatedImageId, 100),
              fileName: imageName,
              mimeType,
              originalFileSize: newFile.size,
              type: EDocumentType.IMAGE,
              createdOn: +imageCreatedOn,
              uploaderName: user.userName,
              uploaderId: user.userId,
              uploaderAvatarId: user.avatarId,
              attachmentId: updatedImageId,
              workspaceId,
            };

            this.store.dispatch(
              new ReplaceImage({
                workspaceId,
                _id,
                oldAttachmentId: imageId,
                newAttachmentId: updatedImageId,
              }),
            );

            this.store.dispatch(
              new ReplaceMedia({
                imageToStore,
                dateFormat: preferences.dateFormat,
                oldAttachmentId: imageId,
              }),
            );

            return {
              base64String,
              updatedImageId,
            };
          }),
        );
      }),
    );
  }

  getCanvasDimensions(): {
    width: number;
    height: number;
  } {
    return {
      width: this.canvasFabric.getWidth() as number,
      height: this.canvasFabric.getHeight() as number,
    };
  }

  closeCanvas(): void {
    if (this.canvasFabric) {
      this.canvasFabric.setDimensions({ width: 0, height: 0 });
      this.canvasFabric.clear();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private registerSelectionEvents(): void {
    this.canvasFabric.on('selection:created', (e) => {
      if (
        this.currentMode !== EImageAnnotationsState.ADDING_ARROW &&
        e.target.type === 'activeSelection'
      ) {
        this.canvasFabric.discardActiveObject();
      }

      if (e.selected.length === 1) {
        const colorCode =
          e.target.fill && e.target.fill !== 'transparent' ? e.target.fill : e.target.stroke;
        this.imageAnnotationsStateService.setColorCode(colorCode);
      }

      e.target.hasControls = false;
    });

    this.canvasFabric.on('selection:updated', (e) => {
      if (
        this.currentMode !== EImageAnnotationsState.ADDING_ARROW &&
        e.target.type === 'activeSelection'
      ) {
        this.canvasFabric.discardActiveObject();
      }

      if (e.selected.length === 1) {
        const colorCode = e.target.fill ? e.target.fill : e.target.stroke;
        this.imageAnnotationsStateService.setColorCode(colorCode);
      }

      e.target.hasControls = false;
    });
  }

  private getImageInfo(
    imageId: string,
    imageCanvasElement: HTMLCanvasElement,
  ): Promise<TImageInfo> {
    return new Promise<TImageInfo>((resolve, reject) => {
      this.apiService
        .getFile(API_files_images_size_original(imageId))
        .pipe(
          tap((response) => {
            if (!response) {
              reject(null);
            }

            response.blob().then((blob) => {
              const reader = new window.FileReader();

              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64data = reader.result;

                fabric.Image.fromURL(base64data, (imageFabric: fabric.Image) => {
                  const originalWidth = imageFabric.width;
                  const originalHeight = imageFabric.height;
                  const scaleRatioX = originalWidth / imageCanvasElement.width;
                  const scaleRatioY = originalHeight / imageCanvasElement.height;
                  const scaleRatio = Math.min(scaleRatioX, scaleRatioY);

                  resolve({
                    imageFabric,
                    originalWidth,
                    originalHeight,
                    scaleRatio,
                  });
                });
              };
            });
          }),
        )
        .subscribe();
    });
  }

  private mergeAnnotationsWithImage(
    originalWidth: number,
    originalHeight: number,
    image: fabric.Image,
    scaleRatio: number,
  ): void {
    const objects = this.canvasFabric.getObjects();

    this.canvasFabric.setDimensions({
      width: originalWidth,
      height: originalHeight,
    });

    this.canvasFabric.setBackgroundImage(
      image,
      this.canvasFabric.renderAll.bind(this.canvasFabric),
    );

    objects.forEach((object) => {
      const zoomX = object.zoomX ? object.zoomX : 1;
      const zoomY = object.zoomY ? object.zoomY : 1;

      object.scaleX = object.scaleX * scaleRatio * zoomX;
      object.scaleY = object.scaleY * scaleRatio * zoomY;
      object.left = object.left * scaleRatio * zoomX;
      object.top = object.top * scaleRatio * zoomY;

      object.setCoords();
    });

    this.canvasFabric.calcOffset();
  }

  private mergedImageToBase64(width: number, height: number): string {
    return this.canvasFabric.toDataURL({
      width,
      height,
    });
  }

  private updateImageAnnotation(imageId: string, file: File, _id: string): Observable<string> {
    const formData = new FormData();

    formData.append('image_file', file);
    formData.append('pointId', _id);

    return this.filesApiProviderService
      .updateImageAnnotation(imageId, formData)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
