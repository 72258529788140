import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { UpdatePointTags } from '../../points/points.actions';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { ActiveService } from '../../../services/active/active.service';
import { PointsService } from '../../points/points.service';
import { WorkspaceService } from '../../workspace/workspace.service';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { TTagSelectOptions } from '../tag-select/tag-select.component';

@Component({
  selector: 'app-bulk-tags-modal',
  templateUrl: './bulk-tags-modal.component.html',
  styleUrls: ['./bulk-tags-modal.component.scss'],
})
export class BulkTagsModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspaceId: string = this.activeService.getActiveWorkspaceId();
  workspace = this.workspaceService.findWorkspace(this.workspaceId);
  modal: Modal = this.modalService.getModal();
  commonTags: string[];
  tagSelectOptions: TTagSelectOptions = {
    showIcon: true,
    noTagRemovedDebounceTime: true,
  };

  processing = false;
  selectedTags: string[] = [];

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit() {
    this.displayCommonTags();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  updateTags(tags: string[]): void {
    this.selectedTags = tags.slice();
  }

  displayCommonTags(): void {
    const selectedPoints = this.pointsService.getSelectedPoints();
    const countedTags = {};
    this.commonTags = [];

    for (let i = 0; i < selectedPoints.length; i++) {
      const tags = selectedPoints[i].tags;

      for (let j = 0; j < tags.length; j++) {
        const selectedTag = selectedPoints[i].tags[j];
        countedTags[selectedTag] = (countedTags[selectedTag] || 0) + 1;
      }
    }

    Object.keys(countedTags).forEach((tag) => {
      if (countedTags[tag] === selectedPoints.length) {
        this.commonTags.push(tag);
      }
    });

    this.updateTags(this.commonTags);
  }

  save(): void {
    this.processing = true;
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = pointIds.length;

    this.pointsUpdateService
      .updatePointsTags(pointIds, this.selectedTags)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_points_update_success');

          this.updatePointTags(selectedPoints);
          this.sitePointFilterService.filterPoints(true);
          this.promptService.showSuccess(promptText);
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  updatePointTags(selectedPoints: TPoint[]): void {
    const tags: string[] = this.selectedTags;

    selectedPoints.forEach((point) => {
      const newTags = Array.from(new Set([...point.tags, ...tags]));

      this.store.dispatch(
        new UpdatePointTags({
          workspaceId: this.workspace.workspaceId,
          _id: point._id,
          tags: newTags,
        }),
      );

      this.customTableService.updatePoint({
        _id: point._id,
        field: EBasicField.TAGS,
        newValue: newTags,
        updatedDate: new Date().getTime(),
      });
    });
  }
}
