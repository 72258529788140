import { Injectable, OnDestroy } from '@angular/core';

import { ResponseErrorService } from '../../../errors/response-error.service';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { Observable, Subject } from 'rxjs';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { select, Store } from '@ngrx/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { DashboardApiProviderService } from '@core/api';
import { DEFAULT_DATE_FORMAT } from 'src/app/project/modules/preferences/default-date-format';
import { EStore } from '../../../../shared/enums/store.enum';
import { TDashletActivity } from './dashlet-activity/dashlet-activity.model';
import {
  checkActivityUser,
  mergeLocationActivities,
  mergeRemovedDocument,
} from '../../../activities/activities-merge';
import { logErrorInSentry } from '../../../errors/response-error';
import { ActivityFormatterService } from '../../../points/point-modal/point-timeline/activity-formatter.service';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { TAllUsers } from '@project/view-models';
import { PointActivityMergeService } from '../../../points/point-modal/point-timeline/point-activity-merge.service';

@Injectable({
  providedIn: 'root',
})
export class DashletActivitiesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private preferences$: Observable<TPreferences>;
  private format = DEFAULT_DATE_FORMAT;
  dashletActivities: TDashletActivity[] = null;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
    private responseErrorService: ResponseErrorService,
    private customFieldsService: CustomFieldsService,
    private dashboardApiProviderService: DashboardApiProviderService,
    private dashboardService: DashboardService,
    private activityFormatterService: ActivityFormatterService,
    private pointActivityMergeService: PointActivityMergeService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  fetchActivities({
    from = 0,
    size = 20,
    accountId = null,
    workspaceId = null,
  }): Observable<TDashletActivity[]> {
    let targetId = '';

    if (accountId && workspaceId) {
      targetId = `&workspaceId=${workspaceId}`;
    } else if (accountId) {
      targetId = `&accountId=${accountId}`;
    }

    return this.dashboardApiProviderService
      .fetchActivities(from, size, targetId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  isSubset(array1: unknown[], array2: unknown[]): boolean {
    return array1.every((value) => array2.includes(value));
  }

  formatActivities(activities: TDashletActivity[], allUsers: TAllUsers): any {
    let formattedActivities = activities.map((activity) => {
      const formattedActivity =
        this.activityFormatterService.getFormattedDatetimeActivity(activity);
      this.activityFormatterService.compareDescriptions(formattedActivity);
      this.activityFormatterService.formatRichText(formattedActivity);
      this.activityFormatterService.formatFiles(formattedActivity);
      this.activityFormatterService.formatTags(formattedActivity);

      return formattedActivity;
    });

    this.dashboardService.setActivities(formattedActivities);

    try {
      // That try catch is important, lets us know if anything went wrong
      formattedActivities = this.pointActivityMergeService.mergeActivities(formattedActivities);

      formattedActivities.forEach((activity) => {
        mergeLocationActivities(activity);
        mergeRemovedDocument(activity);
        checkActivityUser(activity, allUsers);
      });

      return formattedActivities;
    } catch (error) {
      logErrorInSentry(error);
      this.responseErrorService.errors(error);

      console.error(error);
    }
  }

  setDashletActivities(activities: TDashletActivity[]): void {
    this.dashletActivities = activities;
  }

  getDashletActivities(): TDashletActivity[] {
    return this.dashletActivities;
  }
}
