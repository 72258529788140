import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPointsByWorkspace } from 'src/app/project/modules/points/points.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SiteOptionsColumnDropdownComponent } from './site-options-column-dropdown/site-options-column-dropdown.component';

import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { DropdownService } from '../../../components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from '../../../components/modal/modal.service';
import { PointHalfModalService } from '../../points/point-half-modal/point-half-modal.service';
import { SiteDataService, TSiteData } from '../site-data.service';

import { PointExportModalComponent } from 'src/app/project/modules/exports/point-export-modal/point-export-modal.component';
import { TExportModalData } from 'src/app/project/modules/exports/utils/export-modal-data';
import { canCreatePoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { EWorkspaces } from '../../workspace/workspaces.enum';
import { columnsChange$ } from '../site-table/columns/columns.store';
import { TableColumnsService } from '../site-table/columns/table-columns.service';
import { CustomTableService } from '../site-table/custom-table/custom-table.service';
import Table from '../site-table/custom-table/table/Table';
import { getHighlights } from './site-options';

@Component({
  selector: 'pp-site-options',
  templateUrl: './site-options.component.html',
  styleUrls: ['./site-options.component.scss'],
})
export class SiteOptionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() ppDataFetched = false;
  @Input() ppIsOverview = false;

  private readonly destroy$ = new Subject<void>();
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  site: TSiteData = this.siteDataService.getSite();

  workspaces$: Observable<TWorkspacesById>;
  workspace: TWorkspace;
  private points$: Observable<TPointsByWorkspace>;
  private offline$: Observable<boolean>;
  offline = false;
  private user$: Observable<TUser>;
  private user: TUser;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private account: TAccount;
  isMobileScreen = false;
  private isDesktopScreen = false;
  highlights = getHighlights();
  canExport = true;
  exportHidden = false;
  canCreatePoint = false;
  EUserRole = EUserRole;
  EWorkspaces = EWorkspaces;
  public sitePlanExists = false;
  tableColumnsHovered = false;
  exportHovered = false;
  columnsDropdownVisible = false;
  exportModalVisible = false;
  globalSitePreferences = false;
  private viewInitialized = false;
  savedViewsEnabled = false;
  EIconPath = EIconPath;
  table: Table;
  hiddenColumnsCount = 0;

  private workspaceId: string = null;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      points: TPointsByWorkspace;
      offline: boolean;
      user: TUser;
      accounts: TAccount[];
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private dropdownService: DropdownService,
    private pointHalfModalService: PointHalfModalService,
    private siteDataService: SiteDataService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private permissionsService: PermissionsService,
    private pointsService: PointsService,
    private screenService: ScreenService,
    private customTableService: CustomTableService,
    private tableColumnsService: TableColumnsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    this.screenService
      .observeOn$(['isMobile', 'isDesktop'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ isMobile, isDesktop }) => {
        this.isMobileScreen = isMobile;
        this.isDesktopScreen = isDesktop;
      });

    this.activatedRoute.paramMap.subscribe((params) => {
      this.workspaceId = params.get('id');
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;

      this.checkAccounts();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      if (workspaces[this.workspaceId] && workspaces[this.workspaceId].loadedFullWorkspace) {
        this.sitePlanExists =
          typeof workspaces[this.workspaceId].sitePlan === 'undefined' ||
          !!workspaces[this.workspaceId]?.sitePlan;
        this.workspace = workspaces[this.workspaceId];
        this.canExport = this.permissionsService.areExportsEnabled(this.workspaceId);

        if (this.isDesktopScreen && !this.sitePlanExists) {
          this.site.sitePlanVisible = false;
        }

        if (!this.sitePlanExists) {
          const openedPointId = this.pointHalfModalService.getOpenedPointId();

          if (openedPointId) {
            const url = `/site/${this.workspaceId}/(full:point/${openedPointId})`;

            this.router.navigateByUrl(url);
          } else {
            this.pointHalfModalService.hideModal();
          }
        }

        this.canCreatePoint = canCreatePoint(this.workspace.share.shareOption, this.user);
      } else if (!this.workspaceId) {
        this.sitePlanExists = false;
      }

      this.checkAccounts();
    });

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => {
      const workspaceId = this.activeService.getActiveWorkspaceId();
      this.table = this.customTableService.getTable();

      if (!workspaceId) {
        const selectedWorkspaces = this.pointsService.getOverviewSelectedWorkspaces();

        if (selectedWorkspaces.length > 0) {
          let exportsEnabled = false;

          selectedWorkspaces.forEach((iteratedWorkspaceId) => {
            if (this.permissionsService.areExportsEnabled(iteratedWorkspaceId)) {
              exportsEnabled = true;
            }
          });
          this.exportHidden = !exportsEnabled;
        } else {
          this.exportHidden = false;
        }
      }
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    columnsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.hiddenColumnsCount = this.tableColumnsService
        .getColumns()
        .reduce((acc, column) => acc + (column.hidden ? 1 : 0), 0);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
  }

  checkAccounts(): void {
    const accountId = this.activeService.getActiveAccountId();

    if (accountId && Array.isArray(this.accounts) && this.accounts.length) {
      this.account = this.accounts.find((account) => account.accountId === accountId);

      if (
        this.account &&
        this.account.accountFeatures &&
        typeof this.account.accountFeatures.globalSitePreferences !== 'undefined'
      ) {
        this.globalSitePreferences = this.account.accountFeatures.globalSitePreferences;
      } else {
        this.globalSitePreferences = false;
      }

      this.savedViewsEnabled = this.account?.accountFeatures?.savedViews;
    }
  }

  newPoint(): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const offline = this.offlineService.getOffline();

    if (!offline) {
      this.pointHalfModalService.hideModal().then(() => {
        this.router.navigate([
          '/site',
          workspaceId,
          { outlets: { full: ['point', NEW_POINT_ID] } },
        ]);
      });
    }
  }

  export(): void {
    if (!this.exportHidden) {
      const workspaceId = this.activeService.getActiveWorkspaceId();
      const offline = this.offlineService.getOffline();

      this.exportModalVisible = true;

      if (!offline) {
        this.modalService.setData({
          workspaceId,
          formats: {
            simpleList: true,
            detailedList: true,
            csvOutput: true,
            individualReports: true,
            wordPoints: true,
            sitePlan: true,
          },
        });
      } else {
        const modalData: TExportModalData = {
          workspaceId,
          formats: {
            simpleList: false,
            detailedList: false,
            csvOutput: true,
            individualReports: false,
            wordPoints: false,
            sitePlan: false,
          },
        };

        this.modalService.setData(modalData);
      }

      this.modalService.showModal(PointExportModalComponent, {
        blur: true,
        onClose: () => {
          this.exportModalVisible = false;
        },
      });
    }
  }

  //
  // Column dropdown
  //

  toggleColumnDropdown(buttonId: string): void {
    if (!this.ppDataFetched || this.offline) {
      return;
    }

    if (!this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.columnsDropdownVisible = false;
        this.dropdownService.hideDropdown();
      } else {
        this.columnsDropdownVisible = true;

        this.dropdownService.showDropdown(buttonId, SiteOptionsColumnDropdownComponent, {
          suppressScrollbar: true,

          onClose: () => {
            this.columnsDropdownVisible = false;
          },
        });
      }
    }
  }

  //
  // Site plan
  //

  toggleSitePlan(update: boolean): void {
    const sitePlanVisible = !this.site.sitePlanVisible;
    const table = this.customTableService.getTable();

    if (update) {
      this.siteDataService.setSitePlanVisibility({
        sitePlanFullWidth: this.site.sitePlanFullWidth,
        sitePlanVisible,
        workspaceId: this.workspaceId,
      });
    } else {
      this.siteDataService.setSitePlanVisibility({
        sitePlanFullWidth: this.site.sitePlanFullWidth,
        sitePlanVisible,
      });
    }

    this.siteDataService.sitePlanToggleEvent();

    if (!this.site.sitePlanVisible) {
      this.pointHalfModalService.hideModal();
    }

    if (table) {
      // user can toggle site plan when points are still loading
      table.tableBody.load(true);
    }
  }

  tableColumnsHover(): void {
    this.tableColumnsHovered = true;
  }

  tableColumnsHoverEnd(): void {
    this.tableColumnsHovered = false;
  }

  exportHover(): void {
    if (!this.exportHidden) {
      this.exportHovered = true;
    }
  }

  exportHoverEnd(): void {
    this.exportHovered = false;
  }
}
