<form name="editCustomFieldForm">
  <pp-grid-tile
    [ppImage]="customFieldDisplayData.iconPath"
    [ppGridTitle]="customFieldDisplayData.text"
    [ppText]="customFieldDisplayData.descriptionLong"
    [ppWide]="true"
  ></pp-grid-tile>

  <div class="form-row" *ngIf="customFieldDisplayData && ppCustomField.type">
    <label class="addCF__field-label modal__body-title addCF__field-label--first">
      {{ 'custom_field_name' | ppTranslate }}
    </label>
    <div class="addCF__name" [class.addCF__name--focused]="focused">
      <input
        ppFocus
        id="nameInput"
        class="addCF__name-input"
        name="label"
        type="text"
        [(ngModel)]="ppCustomField.label"
        [placeholder]="'name_your_custom_field' | ppTranslate"
        (keydown.enter)="blurInput($event)"
        required
        (focus)="onInputFocus()"
        (focusout)="onInputFocusLost()"
      />

      <div class="closeIcon__wrapper">
        <img
          id="searchInputKeywordsClear"
          class="closeIcon"
          *ngIf="ppCustomField.label.length > 0"
          [src]="EIconPath.INTERACTION_DELETE_TEXT"
          (click)="clearName()"
        />
      </div>
    </div>
  </div>

  <div class="form-row" *ngIf="ppCustomField.type === customFieldTypes.COST">
    <label class="addCF__field-label modal__body-title">
      {{ 'choose_currency' | ppTranslate }}
    </label>

    <div class="addCF__toggle-wrapper">
      <pp-currency-picker
        [ppSelectedItem]="currency"
        (ppSelect)="setCurrency($event)"
      ></pp-currency-picker>
    </div>
  </div>

  <div class="form-row" *ngIf="ppCustomField && ppCustomField.type === customFieldTypes.NUMBERS">
    <label class="addCF__field-label modal__body-title">
      {{ 'choose_unit' | ppTranslate }}
    </label>

    <input
      class="customFieldForm__input input input-border-bottom input-inline"
      name="unit"
      type="text"
      [(ngModel)]="ppCustomField.unit"
      [placeholder]="'unit_optional' | ppTranslate"
      (keydown.enter)="blurInput($event)"
    />
  </div>

  <div class="form-row" *ngIf="ppCustomField && ppCustomField.type === customFieldTypes.NUMBERS">
    <label class="addCF__field-label modal__body-title">
      {{ 'add_comma_between_numbers' | ppTranslate }}
    </label>

    <div class="addCF__field-checkbox-wrapper">
      <pp-checkbox
        [ppChecked]="ppCustomField.showCommas !== false"
        (ppOnValueChange)="toggleAddComma()"
        ppStyle="blue"
      ></pp-checkbox>
    </div>
  </div>

  <div
    class="form-row"
    *ngIf="
      ppCustomField &&
      (ppCustomField.type === customFieldTypes.NUMBERS ||
        ppCustomField.type === customFieldTypes.COST ||
        ppCustomField.type === customFieldTypes.TIME)
    "
  >
    <label class="addCF__field-label modal__body-title">
      {{ 'show_total_in_table_header' | ppTranslate }}
    </label>

    <div class="addCF__field-checkbox-wrapper">
      <pp-checkbox
        [ppChecked]="ppCustomField.showTotal"
        (ppOnValueChange)="toggleShowTotal()"
        ppStyle="blue"
      ></pp-checkbox>
    </div>
  </div>

  <div class="form-row" *ngIf="ppCustomField && ppCustomField.type === customFieldTypes.TIME">
    <label class="addCF__field-label modal__body-title">
      {{ 'show_hours_only' | ppTranslate }}
    </label>

    <div class="addCF__field-checkbox-wrapper">
      <pp-checkbox
        [ppChecked]="ppCustomField.showHoursOnly"
        (ppOnValueChange)="toggleHoursOnly()"
        ppStyle="blue"
      ></pp-checkbox>
    </div>
  </div>

  <div
    class="form-row"
    [class.decimals--hidden]="!(ppCustomField && ppCustomField.type === customFieldTypes.NUMBERS)"
  >
    <label class="addCF__field-label modal__body-title">
      {{ 'number_of_decimal_places' | ppTranslate }}
    </label>

    <div class="decimals__container">
      <input
        [ppAutonumeric]="autonumericOptions"
        id="decimal__input"
        name="decimal__input"
        class="decimal__input copyReg"
        [(ngModel)]="ppCustomField.decimalPlaces"
        placeholder="1"
        inputmode="numeric"
        pattern="[0-9]*"
        (keydown.enter)="blurInput($event)"
      />

      <div class="inputArrow__container">
        <div
          class="inputArrow__wrapper"
          [class.inputArrow__wrapper--disabled]="+ppCustomField.decimalPlaces >= 4"
          (click)="increaseDecimal()"
        >
          <img
            class="inputArrow inputArrow--up"
            [class.inputArrow__wrapper--disabled]="+ppCustomField.decimalPlaces >= 4"
            [src]="EIconPath.ARROW_DOWN_GRAY"
          />
        </div>

        <div
          class="inputArrow__wrapper"
          [class.inputArrow__wrapper--disabled]="+ppCustomField.decimalPlaces <= 0"
          (click)="decreaseDecimal()"
        >
          <img
            class="inputArrow"
            [class.inputArrow__wrapper--disabled]="+ppCustomField.decimalPlaces <= 0"
            [src]="EIconPath.ARROW_DOWN_GRAY"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="form-row row__list"
    [class.form-row__list--scroll]="maxListDepthExceeded"
    *ngIf="ppCustomField.type === customFieldTypes.LIST"
  >
    <h6 class="editCF__field-label">
      {{ 'list_items' | ppTranslate }}
    </h6>

    <button
      class="addCF__alphabetizeList"
      [class.addCF__alphabetizeList--sorted]="sorted.sorted"
      (click)="alphabetizeListEntries()"
    >
      {{ 'alphabetize_items' | ppTranslate }}
    </button>

    <pp-custom-field-list
      [ppItem]="ppCustomField"
      [ppShowAddItem]="true"
      [ppMaxDepthExceeded]="maxListDepthExceeded"
      (ppChange)="checkListCF()"
      [ppEmptyLabelError]="false"
      [ppPrimaryField]="true"
    ></pp-custom-field-list>
  </div>
</form>
