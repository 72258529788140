function AcceptorStateMachine(states, state) {
  this.currentState = state || null;
  this.states = states || {};
}
AcceptorStateMachine.prototype.runTo = function runTo(finalState, done, bindObject, inputError) {
  if (typeof finalState === 'function') {
    inputError = bindObject;
    bindObject = done;
    done = finalState;
    finalState = null;
  }
  var self = this;
  var state = self.states[self.currentState];
  state.fn.call(bindObject || self, inputError, function (err) {
    if (err) {
      if (state.fail) self.currentState = state.fail;else return done ? done.call(bindObject, err) : null;
    } else {
      if (state.accept) self.currentState = state.accept;else return done ? done.call(bindObject) : null;
    }
    if (self.currentState === finalState) {
      return done ? done.call(bindObject, err) : null;
    }
    self.runTo(finalState, done, bindObject, err);
  });
};
AcceptorStateMachine.prototype.addState = function addState(name, acceptState, failState, fn) {
  if (typeof acceptState === 'function') {
    fn = acceptState;
    acceptState = null;
    failState = null;
  } else if (typeof failState === 'function') {
    fn = failState;
    failState = null;
  }
  if (!this.currentState) this.currentState = name;
  this.states[name] = {
    accept: acceptState,
    fail: failState,
    fn: fn
  };
  return this;
};

/**
 * @api private
 */
module.exports = AcceptorStateMachine;