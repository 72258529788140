import { cloneDeep } from 'lodash';
import { translate } from 'src/app/project/features/translate/translate';
import { EDashletType } from '../dashlets-enums';
import { TExportDashlet } from './dashboard-export-dashlet.model';
import { TPreferences } from '../../preferences/preferences.model';

export function generateDashletList(
  preferences: TPreferences,
  accounts,
  workspaces,
): TExportDashlet[] {
  let dashlets = cloneDeep(preferences.dashlets?.data).filter(
    (_dashlet) => _dashlet.name !== EDashletType.ACTIVITIES,
  );

  dashlets = dashlets.sort((a, b) => {
    if (a.y !== b.y) {
      return a.y - b.y;
    } else {
      return a.x - b.x;
    }
  });

  return dashlets.map((_dashlet) => {
    let accountName = translate('all_accounts');
    let workspaceName = translate('all_sites');

    if (_dashlet.selectedRange.accountId) {
      accountName = accounts.find(
        (_account) => _account.accountId === _dashlet.selectedRange.accountId,
      ).accountName;
    }

    if (_dashlet.selectedRange.workspaceId) {
      workspaceName = workspaces[_dashlet.selectedRange.workspaceId].siteName;
    }

    return {
      name: _dashlet.name,
      selectedRange: _dashlet.selectedRange,
      period: _dashlet.period,
      selected: true,
      accountName,
      workspaceName,
    };
  });
}
