import { TGuid } from '@core/helpers';
import { TStatusFilterRequest } from 'src/app/project/modules/filters/utils/get-statuses-to-filter';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';

export const API_points_selected = (): string => '/api/v3/points/selected';
export const API_points_bulk = (): string => '/api/v2/points/bulk';
export const API_point = (_pointId: string): string => `/api/v3/points/${_pointId}`;
export const API_overview_points_pagination = (
  pageNumber: string,
  statuses?: TStatusFilterRequest[],
): string => {
  let url = `/api/v4/points/overview?pageAfter=${pageNumber ? pageNumber : ''}`;

  if (statuses?.length > 0) {
    url += `&status=${statuses.join(',')}`;
  }

  return url;
};

export const API_point_additional_details = (_pointId: string): string =>
  `api/v1/points/${_pointId}/additional-details`;
export const API_V1_point = (_id: string): string => `/api/v1/points/${_id}`;
export const API_points_bulk_simple_update = (): string => '/api/v1/points/bulk/simple-update';
export const API_point_simple_update = (_pointId: string): string =>
  `/api/v3/points/${_pointId}/simple-update`;
export const API_V4_points_workspace = (_workspaceId: string, pageNumber: string): string =>
  `/api/v4/points?workspaceId=${_workspaceId}${pageNumber ? '&pageAfter=' + pageNumber : ''}`;
export const API_points_create = (_workspaceId: string): string =>
  `/api/v3/points?workspaceId=${_workspaceId}`;
export const API_points_transfer_size = (): string => '/api/v2/points-transfer/total-size';
export const API_points_transfer_copy = (): string => '/api/v2/points-transfer/copy';
export const API_points_transfer_check = (): string => '/api/v2/points-transfer/check';
export const API_points_transfer_move = (): string => '/api/v2/points-transfer/move';

export const API_points_users_with_access = (
  pointId: TGuid,
  field: EAccessField,
  customFieldId?: TGuid,
): string => {
  let url = `api/v4/points/visibility/${pointId}?field=${field}`;

  if (field === EAccessField.CUSTOM_FIELD && customFieldId) {
    url += `&customFieldId=${customFieldId}`;
  }

  return url;
};
