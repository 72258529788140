<h6
  id="editSiteNameBtn"
  data-m-target="Edit site name dropdown button"
  class="dropdownItem"
  (click)="editSiteName()"
>
  {{ 'edit_site_name' | ppTranslate }}
</h6>

<ng-container *ngIf="dropdown.data && user">
  <a
    id="manageTagsBtn"
    data-m-target="Edit site tags dropdown button"
    class="dropdownItem"
    (click)="hideDropdown('tags')"
  >
    {{ 'manage_tags' | ppTranslate }}
  </a>

  <a
    id="manageCFBtn"
    data-m-target="Edit site custom fields dropdown button"
    class="dropdownItem"
    (click)="hideDropdown('custom-fields')"
  >
    {{ 'manage_custom_fields' | ppTranslate }}
  </a>

  <a
    id="manageUsersBtn"
    data-m-target="Edit site users dropdown button"
    class="dropdownItem"
    (click)="hideDropdown('users')"
  >
    {{ 'manage_users' | ppTranslate }}
  </a>

  <a
    id="sitePlanSettingsBtn"
    data-m-target="Edit site plan dropdown button"
    class="dropdownItem"
    *ngIf="user.isSuperUser || share.shareOption === EUserRole.OWNER"
    (click)="hideDropdown('plan')"
  >
    {{ 'site_plan' | ppTranslate }}
  </a>

  <a
    id="siteLogoSettingsBtn"
    data-m-target="Edit site logo button"
    class="dropdownItem"
    *ngIf="user.isSuperUser || share.shareOption === EUserRole.OWNER"
    (click)="hideDropdown('logo')"
  >
    {{ 'pdf_logo_header' | ppTranslate }}
  </a>

  <ng-container *ngIf="showDeleteOption">
    <hr class="headerOptions__hr" />

    <a
      id="siteDeleteSettingsBtn"
      data-m-target="Delete site button"
      class="dropdownItem"
      (click)="deleteSite()"
      (mouseenter)="onDeleteHover(true)"
      (mouseleave)="onDeleteHover(false)"
    >
      <span class="deleteSite__text">
        {{ 'delete_site' | ppTranslate }}
      </span>

      <pp-icon
        [ppSrc]="EIconPath.ICON_BADGE_INFO"
        ppTooltip
        [ppSanitize]="false"
        ppTitle="
        <div>
          {{ 'delete_site_tooltip_1' | ppTranslate }}
        </div>
        <br />
        <div>
          {{ 'delete_site_tooltip_2' | ppTranslate }}
        </div>"
        class="deleteSite__info"
        [ppColor]="deleteHover ? 'white' : 'grey-600'"
      ></pp-icon>
    </a>
  </ng-container>
</ng-container>
