import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { SiteOptionsService } from '../../../site/site-options/site-options.service';
import { SiteTableColumnsService } from '../../../site/site-table/site-table-columns.service';
import { SiteTableService } from '../../../site/site-table/site-table.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { ESavedView } from '../../models/saved-view.enum';
import { TSavedViews, TViewShort } from '../../models/saved-views.model';
import { SavedViewsOptionsDropdownComponent } from '../../saved-views-options-dropdown/saved-views-options-dropdown.component';
import {
  ESavedViewsOptionsDropdownCallback,
  TSavedViewsOptionsDropdownData,
} from '../../saved-views-options-dropdown/saved-views-options-dropdown.model';
import { SavedViewsService } from '../../saved-views.service';
import { SavedViewsDropdownService } from '../saved-views-dropdown.service';
import { generateViewName } from './utils/generate-view-name';

@Component({
  selector: 'pp-saved-views-dropdown-row',
  templateUrl: './saved-views-dropdown-row.component.html',
  styleUrls: ['./saved-views-dropdown-row.component.scss'],
})
export class SavedViewsDropdownRowComponent implements OnInit, AfterViewInit {
  @Input() ppSecondaryDropdownService: DropdownService;
  @Input() ppView: TViewShort;
  @Input() ppType: ESavedView;
  @Input() ppViews: TSavedViews;
  @ViewChild('nameInput', { static: false }) nameInput: ElementRef<HTMLInputElement>;

  EIconPath = EIconPath;
  lastKey: string | null = null;

  secondaryDropdown: TDropdown;
  dropdownVisible = false;
  renamingView = false;
  selectedViewIds: {
    [workspaceId: string]: string;
  };

  constructor(
    private savedViewsDropdownService: SavedViewsDropdownService,
    private savedViewsService: SavedViewsService,
    private siteTableColumnsService: SiteTableColumnsService,
    private preferencesService: PreferencesService,
    private activeService: ActiveService,
    private siteTableService: SiteTableService,
    private sitePointFilterService: SitePointFilterService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private siteOptionsService: SiteOptionsService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.secondaryDropdown = this.ppSecondaryDropdownService.getDropdown();
    this.selectedViewIds = this.savedViewsService.getSelectedViewIds();
  }

  ngAfterViewInit(): void {
    this.savedViewsService.createInputEvent$.subscribe((response) => {
      this.focusInput(response);
    });
  }

  focusInput(response: string): void {
    if (response === this.ppView.id) {
      const input = this.nameInput.nativeElement;

      input.parentElement.classList.add('savedViews__focus-border');
      input.contentEditable = 'true';
      this.renamingView = true;
      input.style.cursor = 'text';
      input.focus();
    }
  }

  handleInputBlur($event: FocusEvent): void {
    this.renamingView = false;
    const input = $event.target as HTMLElement;
    input.contentEditable = 'false';
    input.style.cursor = 'pointer';

    this.lastKey !== 'Enter'
      ? this.checkName(input)
      : input.parentElement.classList.remove('savedViews__focus-border');

    this.lastKey = null;
  }

  checkName(input: HTMLElement): void {
    if (input.innerText.trim() === '') {
      this.focusInput(this.ppView.id);
      const prompt = this.translationPipe.transform('prompt_empty_name');
      this.promptService.showWarning(prompt);
    } else {
      input.contentEditable = 'false';
      this.renamingView = false;
      input.style.cursor = 'pointer';
      this.ppView.name = input.innerText;
      this.renameView();
    }
  }

  handleKeyDown($event: KeyboardEvent): void {
    this.lastKey = $event.key;
  }

  handleEnterKeyPress($event: Event): void {
    this.handleKeyDown($event as KeyboardEvent);
    $event.preventDefault();
    const input = $event.target as HTMLElement;
    input.parentElement.classList.remove('savedViews__focus-border');
    this.checkName(input);
  }

  setCursorToEnd(element: HTMLElement): void {
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false); // Set to the end
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  }

  showDropdown(event: MouseEvent, buttonId: string): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.secondaryDropdown.visible && this.secondaryDropdown.buttonId === buttonId) {
      this.dropdownVisible = false;
      this.ppSecondaryDropdownService.hideDropdown();
    } else {
      this.dropdownVisible = true;
      this.ppSecondaryDropdownService.setData<TSavedViewsOptionsDropdownData>({
        view: this.ppView,
        type: this.ppType,
        views: this.ppViews,
      });

      this.ppSecondaryDropdownService.showDropdown(buttonId, SavedViewsOptionsDropdownComponent, {
        secondary: true,
        onClose: () => this.hideDropdown(),
        callback: (option: ESavedViewsOptionsDropdownCallback) => {
          switch (option) {
            case ESavedViewsOptionsDropdownCallback.RENAME:
              this.renamingView = true;
              this.focusInput(this.ppView.id);
              break;
            case ESavedViewsOptionsDropdownCallback.SET_PERSONAL_DEFAULT:
              this.savedViewsDropdownService
                .setPersonalDefaultView(this.ppViews.personal.workspaceId, this.ppView.id)
                .pipe(
                  tap(() => {
                    this.ppViews.personal.defaultViewId = this.ppView.id;
                  }),
                )
                .subscribe();
              break;
            case ESavedViewsOptionsDropdownCallback.SET_SHARED_DEFAULT:
              this.savedViewsDropdownService
                .setSharedDefaultView(this.ppViews.shared.workspaceId, this.ppView.id)
                .pipe(
                  tap(() => {
                    this.ppViews.shared.sharedDefaultViewId = this.ppView.id;
                  }),
                )
                .subscribe();

              break;
            case ESavedViewsOptionsDropdownCallback.DUPLICATE:
              this.savedViewsDropdownService.duplicateView(
                this.ppView.id,
                this.ppViews.personal.workspaceId,
                this.ppType,
              );
              break;
            case ESavedViewsOptionsDropdownCallback.DUPLICATE_TO_SHARED:
              this.savedViewsDropdownService.duplicateView(
                this.ppView.id,
                this.ppViews.personal.workspaceId,
                ESavedView.SHARED,
              );

              break;
            case ESavedViewsOptionsDropdownCallback.DUPLICATE_TO_PERSONAL:
              this.savedViewsDropdownService.duplicateView(
                this.ppView.id,
                this.ppViews.personal.workspaceId,
                ESavedView.PERSONAL,
              );
              break;
            case ESavedViewsOptionsDropdownCallback.DELETE:
              this.savedViewsService.deleteView(
                this.ppView.id,
                this.ppViews.personal.workspaceId,
                this.ppType,
              );

              break;
            case ESavedViewsOptionsDropdownCallback.CLEAR_PERSONAL_DEFAULT:
              this.savedViewsDropdownService
                .setPersonalDefaultView(this.ppViews.personal.workspaceId, null)
                .pipe(
                  tap(() => {
                    this.ppViews.personal.defaultViewId = null;
                  }),
                )
                .subscribe();

              break;
            case ESavedViewsOptionsDropdownCallback.CLEAR_SHARED_DEFAULT:
              this.savedViewsDropdownService
                .setSharedDefaultView(this.ppViews.shared.workspaceId, null)
                .pipe(
                  tap(() => {
                    this.ppViews.shared.sharedDefaultViewId = null;
                  }),
                )
                .subscribe();

              break;
          }
        },
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
      });
    }
  }

  hideDropdown(): void {
    this.secondaryDropdown.visible = false;
    this.dropdownVisible = false;
  }

  renameView(): void {
    this.ppView.name = generateViewName(
      this.ppViews,
      this.ppType,
      this.ppView.name,
      this.ppView.id,
    );

    this.renamingView = false;

    this.savedViewsService
      .renameView(this.ppView.id, this.ppView.name, this.ppViews.personal.workspaceId)
      .subscribe();
  }

  selectView(): void {
    const workspaceId = this.ppViews.personal.workspaceId;
    const workspace = this.workspaceService.getWorkspace(workspaceId);

    this.savedViewsService.setSelectedViewId(workspaceId, this.ppView.id);

    this.savedViewsService
      .fetchView({
        workspaceId,
        viewId: this.ppView.id,
        customFields: workspace.customFields,
      })
      .pipe(
        tap((savedView) => {
          this.siteTableColumnsService.initColumns();

          const preferences = this.preferencesService.getPreferences();
          const workspaceId = this.activeService.getActiveWorkspaceId();
          const savedViews = this.savedViewsService.getSavedViews(workspaceId);
          const selectedViewId = this.savedViewsService.getSelectedViewId(workspaceId);

          const currentWorkspacePrefs = this.preferencesService.getCurrentWorkspacePrefs({
            preferences,
            workspaceId,
            savedViews,
            selectedViewId,
          });

          this.siteTableService.processPreferencesResponse(currentWorkspacePrefs, workspaceId);
          this.siteOptionsService.getGroupButton().update();
          this.siteOptionsService.getSortButton().update();
          this.sitePointFilterService.filterPoints(false);
        }),
      )
      .subscribe();
  }
}
