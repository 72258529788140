<div class="dashletFlip__container">
  <div
    id="dashlet-flip__card"
    [ngClass]="{
      flipped: isFlipped,
      classicDashlet:
        ppDashlet.name === dashletTypes.ACTIVITIES ||
        ppDashlet.name === dashletTypes.CURRENT_STATUS ||
        ppDashlet.name === dashletTypes.CURRENT_PRIORITY ||
        ppDashlet.name === dashletTypes.OVER_TIME_STATUS ||
        ppDashlet.name === dashletTypes.OVER_TIME_PRIORITY
    }"
  >
    <div class="dashlet-flip__content-wrapper dashletFlip__front">
      <pp-dashlet-head
        [ppDashlet]="ppDashlet"
        [ppAccountName]="account?.accountName"
        [ppSiteName]="workspaces[ppDashlet.selectedRange.workspaceId]?.siteName"
        (ppToggleFlip)="toggleFlip()"
      >
      </pp-dashlet-head>

      <pp-dashlet-activities
        [ppAccountId]="ppDashlet.selectedRange.accountId"
        [ppWorkspaceId]="ppDashlet.selectedRange.workspaceId"
        [ppLoading]="loading"
        [ppDashlet]="ppDashlet"
        *ngIf="ppDashlet.name === dashletTypes.ACTIVITIES"
      ></pp-dashlet-activities>

      <div
        class="dashletBody__container"
        *ngIf="
          ppDashlet.name === dashletTypes.DEFECT_CREATE ||
          ppDashlet.name === dashletTypes.COMMENT_CREATE ||
          ppDashlet.name === dashletTypes.HIGH ||
          ppDashlet.name === dashletTypes.MEDIUM ||
          ppDashlet.name === dashletTypes.LOW ||
          ppDashlet.name === dashletTypes.OPEN ||
          ppDashlet.name === dashletTypes.IN_PROGRESS ||
          ppDashlet.name === dashletTypes.TO_REVIEW ||
          ppDashlet.name === dashletTypes.ONHOLD ||
          ppDashlet.name === dashletTypes.CLOSED ||
          ppDashlet.name === dashletTypes.CANCELED
        "
      >
        <div class="dashletBody">
          <pp-dashlet-events
            [ppDashlet]="ppDashlet"
            [ppPeriod]="ppDashlet.period"
            [ppAccountId]="ppDashlet.selectedRange.accountId"
            [ppWorkspaceId]="ppDashlet.selectedRange.workspaceId"
            [ppLoading]="loading"
          >
          </pp-dashlet-events>
        </div>
      </div>

      <div
        class="dashletBody__container"
        *ngIf="
          ppDashlet.name === dashletTypes.OVER_TIME_STATUS ||
          ppDashlet.name === dashletTypes.OVER_TIME_PRIORITY ||
          ppDashlet.name === dashletTypes.CURRENT_STATUS ||
          ppDashlet.name === dashletTypes.CURRENT_PRIORITY
        "
      >
        <div class="dashletBody dashletBody__graphs">
          <div
            class="dashletBody__bar"
            *ngIf="
              ppDashlet.name === dashletTypes.OVER_TIME_STATUS ||
              ppDashlet.name === dashletTypes.OVER_TIME_PRIORITY
            "
          >
            <canvas #chart width="400" height="400"></canvas>
          </div>

          <div
            class="dashletBody__doughnut"
            *ngIf="
              ppDashlet.name === dashletTypes.CURRENT_STATUS ||
              ppDashlet.name === dashletTypes.CURRENT_PRIORITY
            "
          >
            <canvas #chart width="400" height="400"></canvas>
          </div>
        </div>

        <div
          class="dashletBody__totalPoints"
          *ngIf="
            ppDashlet.name === dashletTypes.CURRENT_STATUS ||
            ppDashlet.name === dashletTypes.CURRENT_PRIORITY ||
            ppDashlet.name === dashletTypes.OVER_TIME_STATUS ||
            ppDashlet.name === dashletTypes.OVER_TIME_PRIORITY
          "
        >
          {{ 'total_points_number' | ppTranslate: { totalPoints: totalPoints } }}
        </div>
      </div>
    </div>

    <pp-dashlet-flip
      [ppDashlet]="ppDashlet"
      [ppFlipped]="isFlipped"
      (ppToggleFlip)="toggleFlip()"
      (ppSelectPeriod)="selectPeriod($event)"
      (ppSelectSiteRange)="selectSiteRange($event)"
    >
    </pp-dashlet-flip>
  </div>
</div>
