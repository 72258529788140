import { GET_CUSTOM_FIELDS } from '../custom-fields/custom-fields.store';
import { TWorkspace, TWorkspacesById } from '../workspace/workspace.model';
import { generateShareAdvancedAccessLevels } from './share-utils/generate-share-aal';
import { EUserRole } from './share-utils/user-roles';
import { TCustomFieldAccessOption, TShare, TShareCreateData } from './share.model';

export function generateShares(_shares: TShare[], workspaces: TWorkspacesById): TShare[] {
  const shares = [];

  _shares.forEach((_share) => {
    shares.push(generateShare(_share, _share.workspaceId, workspaces[_share.workspaceId]));
  });

  return shares;
}

export function generateShare(
  _share: TShare | TShareCreateData,
  workspaceId: string,
  workspace?: TWorkspace,
): TShare {
  let share: TShare;

  if (!_share) {
    share = {
      workspaceId: workspaceId,
      shareOption: EUserRole.NORMAL,
      defectTags: [],
      tagLimited: false,
      userId: null,
      shareId: null,
      hidden: false,
      advancedAccessLevels: generateShareAdvancedAccessLevels(null, null),
      userEmail: '',
    };
  } else {
    share = {
      workspaceId: workspaceId,
      shareOption: _share.shareOption,
      defectTags: _share.defectTags,
      tagLimited: generateTagLimitedValue(_share.tagLimited),
      userId: _share.userId,
      shareId: _share.shareId,
      advancedAccessLevels: generateShareAdvancedAccessLevels(
        _share.advancedAccessLevels,
        _share.shareOption as EUserRole,
      ),
      hidden: !!_share.hidden,
      userEmail: _share.userEmail,
    };
  }

  if (!share.defectTags) {
    share.defectTags = [];
  }

  if (workspace) {
    const targetTags = workspace.tags;

    if (share.advancedAccessLevels) {
      share.advancedAccessLevels.customFields = getActualAALCustomFields(
        share.advancedAccessLevels.customFields,
        workspace.customFields,
      );
    }

    share.defectTags = targetTags ? share.defectTags.filter((tag) => targetTags.includes(tag)) : [];

    if (workspace.customFields) {
      const missingCustomFieldsAccessList = getMissingCustomFieldsAccessList(
        workspace.customFields,
        share.advancedAccessLevels.customFields,
        true,
        share.shareOption !== EUserRole.LIMITED,
      );

      share.advancedAccessLevels.customFields.push(...missingCustomFieldsAccessList);
    }
  }

  share.advancedAccessLevels.tags.permission.read = checkTimelineAccess(share);

  return share;
}

function generateTagLimitedValue(tagLimited: boolean) {
  if (tagLimited === undefined) {
    return false;
  }

  return tagLimited;
}

export function getMissingCustomFieldsAccessList(
  workspaceCustomFields: string[],
  shareCustomFields: TCustomFieldAccessOption[],
  permissionRead: boolean,
  permissionEdit: boolean,
): TCustomFieldAccessOption[] {
  const definedCustomFields = shareCustomFields.filter((customField) =>
    workspaceCustomFields.includes(customField.templateId.toString()),
  );

  const newFields: TCustomFieldAccessOption[] = [];
  workspaceCustomFields.forEach((customFieldId) => {
    const permissionAlreadyDefined = definedCustomFields.find(
      (definedCustomField) => definedCustomField.templateId.toString() === customFieldId.toString(),
    );
    if (!permissionAlreadyDefined) {
      newFields.push({
        templateId: customFieldId,
        permission: {
          read: permissionRead,
          edit: permissionEdit,
        },
      });
    }
  });

  return newFields;
}

function checkTimelineAccess(share: TShare): boolean {
  if (!share.advancedAccessLevels.tags.permission.read) {
    return false;
  } else {
    return share.advancedAccessLevels.tags.permission.read;
  }
}

export function getActualAALCustomFields(
  shareAALCustomFields: TCustomFieldAccessOption[],
  workspaceCustomFields: string[] = [],
): TCustomFieldAccessOption[] {
  const customFields = GET_CUSTOM_FIELDS();
  const existingCustomFields: TCustomFieldAccessOption[] = [];

  shareAALCustomFields.forEach((_customField) => {
    const existingCustomFieldId = workspaceCustomFields.find(
      (_fieldId) =>
        customFields[_fieldId] &&
        customFields[_customField.templateId] &&
        customFields[_fieldId].type === customFields[_customField.templateId].type &&
        customFields[_fieldId].label === customFields[_customField.templateId].label,
    );

    if (existingCustomFieldId) {
      existingCustomFields.push({
        ..._customField,
        templateId: existingCustomFieldId,
      });
    }
  });

  return existingCustomFields;
}
