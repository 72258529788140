<ng-container *ngIf="editedShare">
  <button class="selectUsers__back" (click)="closeShare()">
    <img [src]="EIconPath.ARROW_LEFT" />

    {{ 'back_to_user_import' | ppTranslate }}
  </button>

  <pp-share-edit
    [(ppShare)]="editedShare"
    (ppEmails)="([editedShare.userEmail])"
    (ppEmailsChange)="editedShare ? (editedShare.userEmail = $event[0]) : null"
    [ppShowAdvanced]="true"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppEdit]="true"
  ></pp-share-edit>
</ng-container>

<ng-container *ngIf="!editedShare">
  <div class="siteList" [class.siteList__invisible]="activeStep !== 'sites'">
    <pp-site-list-select
      [ppAccounts]="accounts"
      ppType="share"
      [ppFieldsPerAccount]="usersPerAccount"
      [ppFieldsPerWorkspace]="usersPerWorkspace"
      (ppAction)="openSiteUsers($event)"
    ></pp-site-list-select>
  </div>

  <div class="userList" *ngIf="activeStep === 'users'">
    <button class="selectUsers__back" (click)="back()">
      <img [src]="EIconPath.ARROW_LEFT" />

      {{ 'back_to_site_selection' | ppTranslate }}
    </button>

    <div ppTooltip [ppTrimTooltip]="true" [ppTitle]="site.siteName" class="selectUsers__site-name">
      <h6 class="copyHeadingReg">
        {{ 'select_user_import_src' | ppTranslate }} <strong>{{ site.siteName }}</strong>
      </h6>
    </div>

    <div class="importUsers__warning">
      <pp-icon
        class="importUsers__warning-icon"
        [ppSrc]="EIconPath.ICON_BADGE_INFO"
        ppClass="setSize14"
        ppColor="grey-400"
      ></pp-icon>

      <p class="importUsers__warning-text">
        {{ 'import_users_warning_text_1' | ppTranslate }}
        <span class="importUsers__link" (click)="goToTagsSettings()">{{
          'tags' | ppTranslate
        }}</span>

        {{ 'import_users_warning_text_2' | ppTranslate }}
        <span class="importUsers__link" (click)="goToCustomFieldsSettings()">{{
          'custom_fields' | ppTranslate
        }}</span>
        {{ 'import_users_warning_text_3' | ppTranslate }}

        <strong>{{ 'import_users_warning_text_4' | ppTranslate }}</strong>
        {{ 'import_users_warning_text_5' | ppTranslate }}
      </p>
    </div>

    <ng-container *ngIf="processing">
      <app-spinner [ppUsersList]="true"></app-spinner>
    </ng-container>

    <table aria-label="Import shares table" class="usersContainer" *ngIf="!processing">
      <thead>
        <tr class="tableHeader--import">
          <th scope="col" class="checkbox__row tableHeader__import-row">
            <pp-checkbox
              [ppChecked]="false"
              ppSize="medium"
              ppType="solid"
              *ngIf="
                site &&
                numberOfSelectableUsers !== usersPerWorkspace[site.workspaceId] &&
                !(
                  site &&
                  numberOfSelectableUsers === usersPerWorkspace[site.workspaceId] &&
                  numberOfSelectableUsers === 0
                )
              "
              (ppOnValueChange)="selectAll()"
            ></pp-checkbox>

            <pp-checkbox
              [ppChecked]="true"
              ppSize="medium"
              ppType="solid"
              *ngIf="
                site &&
                numberOfSelectableUsers === usersPerWorkspace[site.workspaceId] &&
                !(
                  site &&
                  numberOfSelectableUsers === usersPerWorkspace[site.workspaceId] &&
                  numberOfSelectableUsers === 0
                )
              "
              (ppOnValueChange)="deselectAll()"
            >
            </pp-checkbox>

            <pp-checkbox
              [ppChecked]="false"
              ppSize="medium"
              ppType="solid"
              [ppDisabled]="true"
              *ngIf="
                site &&
                numberOfSelectableUsers === usersPerWorkspace[site.workspaceId] &&
                numberOfSelectableUsers === 0
              "
            ></pp-checkbox>
          </th>

          <th scope="col" class="name__row name__row--header tableHeader__import-row">
            {{ 'user' | ppTranslate }}
          </th>

          <th scope="col" class="accessRow accessRow--header tableHeader__import-row">
            {{ 'access' | ppTranslate }}
          </th>

          <th scope="col" class="tagsRow tagsRow--header tableHeader__import-row">
            {{ 'accessible_tags' | ppTranslate }}
          </th>

          <th scope="col" class="actionRow actionRow--header tableHeader__import-row">
            {{ 'action' | ppTranslate }}
          </th>
        </tr>
      </thead>

      <ng-container *ngFor="let user of shares[openedWorkspaceId]">
        <pp-share-import-user-row
          *ngIf="site && user.userId !== accountOwnerId && !(user.accessLevel === EUserRole.OWNER)"
          class="user"
          [class.user-selected]="ppSelectedUsers[site.accountId][site.workspaceId][user.userId]"
          [class.user--disabled]="
            initialWorkspaceUsers.includes(user.userId) ||
            (selectedAllUsers.includes(user.userId) &&
              !ppSelectedUsers[site.accountId][site.workspaceId][user.userId])
          "
          [ppUserSelected]="!!ppSelectedUsers[site.accountId][site.workspaceId][user.userId]"
          [ppChecked]="!!ppSelectedUsers[site.accountId][site.workspaceId][user.userId]"
          [ppUser]="user"
          [ppRole]="
            ppEditedShares[user.userId]
              ? shareOptions[ppEditedShares[user.userId].shareOption]
              : shareOptions[user.accessLevel]
          "
          [ppLimitedTags]="
            ppEditedShares[user.userId] ? ppEditedShares[user.userId].tagLimited : user.tagLimited
          "
          [ppTags]="
            ppEditedShares[user.userId] ? ppEditedShares[user.userId].defectTags : user.tags
          "
          (ppSelectUser)="selectUser(user)"
          (ppEditShare)="editShare(user)"
          [ppAlreadyExists]="initialWorkspaceUsers.includes(user.userId)"
          [ppAlreadySelected]="
            selectedAllUsers.includes(user.userId) &&
            !ppSelectedUsers[site.accountId][site.workspaceId][user.userId]
          "
        ></pp-share-import-user-row>
      </ng-container>
    </table>
  </div>
</ng-container>
