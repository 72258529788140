<ng-container>
  <div class="conflict__list__description">
    <div class="conflict__list__description--layout">
      <div class="iconWrapper">
        <pp-icon [ppSrc]="EIconPath.ICON_BANNER_COPY_MOVE_INFO" [ppDisplayContents]="true"></pp-icon>
      </div>

      <div>

        <div
        class="conflict__list-header conflict__list-info conflict__list-info--first"
        *ngIf="ppPointPinsToBeCentered"
      >
        <span>
          {{ 'site_plans_different' | ppTranslate }}
        </span>
      </div>

      <div class="conflict__list-header conflict__list-info">
        <p>{{ 'information_transfer_1' | ppTranslate }}</p>

        <ul class="conflict__list">
          <li>{{ 'information_transfer_2' | ppTranslate }}</li>
          <li>{{ 'information_transfer_3' | ppTranslate }}</li>
          <li>{{ 'information_transfer_4' | ppTranslate }}</li>
        </ul>

        <p>
          {{ 'information_transfer_5_copy' | ppTranslate }}<br>
          {{ 'information_transfer_6' | ppTranslate }}
        </p>

      </div>
      </div>
    </div>

    <div class="conflict__list-header conflict__list-header--site" *ngIf="ppFieldConflicts?.length">
      <p>
        {{ 'copy_move_conflict_1' | ppTranslate }}
        <span class="conflict__list-header--bold">{{ ppSelectedWorkspaceName }}</span>
        {{ 'copy_move_points_to_the_site' | ppTranslate }}:
      </p>

      <ul class="conflict__list-fields" *ngIf="ppFieldConflicts?.length">
        <li class="conflict__list-item mainBody" *ngFor="let fieldConflict of ppFieldConflicts">
          <ng-container [ngSwitch]="fieldConflict.type">
            <pp-icon
              *ngSwitchCase="EBasicField.TAGS"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_ACTIVITIES_TAGS_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.LIST"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_LIST_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />

            <pp-icon
              *ngSwitchCase="ECustomFieldType.PERCENTAGE"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_PERCENTAGE_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.NUMBERS"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_NUMBERS_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.COST"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_COST_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.TIME"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_TIME_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.DATE"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_DATE_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.CHECKBOX"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_CHECKBOX_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.RICHTEXT"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_RICH_TEXT_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.TEXT"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_TEXT_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
            <pp-icon
              *ngSwitchCase="ECustomFieldType.TIMELINE"
              class="conflictImage"
              [ppSrc]="EIconPath.ICON_FIELDS_TIMELINE_14"
              [ppDisplayContents]="true"
              ppColor="grey-500"
            />
          </ng-container>

          {{ fieldConflict.name }}
        </li>
      </ul>
    </div>
  </div>
</ng-container>
