import { monthNames } from './timeline-timeframes';

export function splitYearIntoMonths(start: Date, end: Date): Date[][] {
  const months = [];

  const startDate = new Date(start.getFullYear(), start.getMonth(), 1);

  const current = new Date(startDate.setDate(startDate.getDate() - startDate.getDay() + 1));
  let endDate = null;

  let endReached = false;

  while (!endReached) {
    endDate = new Date(current.setDate(current.getDate() + 7));

    if (endDate > end) {
      endReached = true;
    }
  }

  let startOfMonth = startDate;
  let endOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

  while (!(startOfMonth > end)) {
    const minDate = startOfMonth < start ? start : startOfMonth;
    const diffInMs = endOfMonth.getTime() - minDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    const width = (98 / 7) * (diffInDays + 1);
    const midMonth = new Date((endOfMonth.getTime() + startOfMonth.getTime()) / 2);

    if (
      startOfMonth.getTime() >= startDate.getTime() &&
      startOfMonth.getTime() < endDate.getTime()
    ) {
      months.push({
        name: width > 50 ? `${monthNames[midMonth.getMonth()]} - ${midMonth.getFullYear()}` : '',
        width: width,
      });
    }

    startOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 1);
    endOfMonth =
      new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0) < end
        ? new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0)
        : end;
  }

  months[months.length - 1].last = true;

  return months;
}
