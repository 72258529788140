import { Component, OnInit } from '@angular/core';

import { Modal, ModalService } from '../modal/modal.service';

export type TConfirmModalParams = {
  message?: string;
  heading?: string;
  redButton?: boolean;
  confirmText?: string;
  boldText?: boolean;
  showCheckbox?: boolean;
  checkboxText?: string;
  extraPadding?: boolean;
  zIndex?: number;
  secondaryMessage?: string;
  dontShowAgainCallback?: (dontShowAgain: boolean) => void;
};

@Component({
  selector: 'pp-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  private modal: Modal;

  public message = '';
  public heading = '';
  public secondaryMessage = '';
  public confirmText = '';
  public redButton = false;
  public boldText = false;
  public showCheckbox = false;
  public checkboxText = '';
  public extraPadding = false;
  public zIndex = 900;
  dontShowAgainCallback: (dontShowAgain: boolean) => void;
  public dontShowAgain = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    const modalData: TConfirmModalParams = this.modal.data;

    this.message = modalData.message;
    this.heading = modalData.heading;
    this.redButton = modalData.redButton;
    this.confirmText = modalData.confirmText;
    this.boldText = modalData.boldText;
    this.showCheckbox = modalData.showCheckbox;
    this.checkboxText = modalData.checkboxText;
    this.extraPadding = modalData.extraPadding;
    this.secondaryMessage = modalData.secondaryMessage;
    this.zIndex = modalData.zIndex;
    this.dontShowAgainCallback = modalData.dontShowAgainCallback;
  }

  hideModal(cancel: boolean): void {
    this.modalService.hideModal(cancel);
  }

  dontShowAgainChecked(): void {
    this.dontShowAgain = !this.dontShowAgain;

    if (this.dontShowAgainCallback) {
      this.dontShowAgainCallback(this.dontShowAgain);
    }
  }
}
