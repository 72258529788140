<tbody class="userManagementTableHeader">
  <tr>
    <td
      class="userManagement__sticky"
      [class.userManagement__sticky--first]="ppIndex === 0"
      [class.userManagement__sticky--last]="ppLast"
    >
      <ng-container *ngIf="ppExpanded">
        <pp-user-management-select-cell [ppShare]="ppShare"></pp-user-management-select-cell>
        <pp-user-management-site-cell
          [ppWorkspaceId]="ppShare.workspaceId"
          (ppNavigate)="emitNavigateToSite($event)"
        ></pp-user-management-site-cell>
      </ng-container>
    </td>
    <ng-container *ngIf="ppExpanded">
      <pp-user-management-access-level-cell
        [ppShare]="ppShare"
      ></pp-user-management-access-level-cell>
      <pp-user-management-restricted-tags-cell
        [ppShare]="ppShare"
      ></pp-user-management-restricted-tags-cell>
      <pp-user-management-export-cell [ppShare]="ppShare"></pp-user-management-export-cell>
      <pp-user-management-comments-cell [ppShare]="ppShare"></pp-user-management-comments-cell>
      <pp-user-management-activity-cell [ppShare]="ppShare"></pp-user-management-activity-cell>
      <pp-user-management-tag-cell [ppShare]="ppShare"></pp-user-management-tag-cell>
      <pp-user-management-custom-field-cell
        *ngFor="let customField of ppCustomFields"
        [ppShare]="ppShare"
        [ppCustomField]="customField"
      ></pp-user-management-custom-field-cell>
    </ng-container>
  </tr>
</tbody>
