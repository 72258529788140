import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TPoint } from '../../../points/points.model';
import { sortNumbers } from './sort-numbers';
import { sortTitle } from './sort-title';

export function sortTimeline(firstPoint: TPoint, secondPoint: TPoint, index: number): number {
  const customFieldAIndex = Object.keys(firstPoint.customFieldsMap).find(
    (_customFieldId) =>
      firstPoint.customFieldsMap[_customFieldId].type === ECustomFieldType.TIMELINE,
  );

  const customFieldBIndex = Object.keys(secondPoint.customFieldsMap).find(
    (_customFieldId) =>
      secondPoint.customFieldsMap[_customFieldId].type === ECustomFieldType.TIMELINE,
  );

  const customFieldA = firstPoint.customFieldsMap[customFieldAIndex];
  const customFieldB = secondPoint.customFieldsMap[customFieldBIndex];

  let a = null;
  let b = null;

  if (customFieldA) {
    a = customFieldA.value;
  }

  if (customFieldB) {
    b = customFieldB.value;
  }

  let result: number;

  const firstFieldValue = a ? a.split('~')[0] : null;
  const secondFieldValue = b ? b.split('~')[0] : null;

  sortNumbers(firstFieldValue, secondFieldValue, [{ sortOrder: 'DESC' }], index, {});

  if (+firstFieldValue && !+secondFieldValue) {
    result = -1;
  } else if (+secondFieldValue && !+firstFieldValue) {
    result = 1;
  } else if (+firstFieldValue < +secondFieldValue) {
    result = -1;
  } else if (+firstFieldValue > +secondFieldValue) {
    result = 1;
  }

  if (result) {
    return result;
  }

  return sortTitle(firstPoint, secondPoint, [{ sortOrder: 'ASC' }], index);
}
