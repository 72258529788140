import {
  ESemiSimplePermission,
  ESimplePermission,
} from 'src/app/project/modules/share/permissions.consts';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EColumn } from '../../user-management.consts';

const hiddenPermissionItem = {
  translationKey: 'hide',
  value: ESimplePermission.NONE,
  iconSrc: EIconPath.ICON_USER_GROUPS_HIDDEN_14,
};

const viewPermissionItem = {
  translationKey: 'view_only',
  value: ESimplePermission.VIEW,
  iconSrc: EIconPath.ICON_USER_GROUPS_VISIBLE_14,
};

const editPermissionItem = {
  translationKey: 'can_edit',
  value: ESimplePermission.EDIT,
  iconSrc: EIconPath.ICON_USER_GROUPS_EDITABLE_14,
};

const hiddenSemiPermissionItem = {
  translationKey: 'hide',
  value: ESemiSimplePermission.NONE,
  iconSrc: EIconPath.ICON_USER_GROUPS_HIDDEN_14,
};

const allowSemiPermissionItem = {
  translationKey: 'allow',
  value: ESemiSimplePermission.ALLOW,
  iconSrc: EIconPath.ICON_USER_GROUPS_VISIBLE_14,
};

const viewSemiPermissionItem = {
  translationKey: 'view_only',
  value: ESemiSimplePermission.VIEW,
  iconSrc: EIconPath.ICON_USER_GROUPS_VISIBLE_14,
};

const emptyPermissionItem = {
  translationKey: '',
  value: ESimplePermission.NONE,
  iconSrc: null,
};

export type TPermissionItemByColumn = {
  translationKey: string;
  value: ESimplePermission | ESemiSimplePermission;
  iconSrc: EIconPath;
};
export type TPermissionItemsByColumn = TPermissionItemByColumn[];

// TODO: use EUserRole as type for userRole instead of string
export const getPermissionItemsByColumn = (
  column: EColumn,
  userRole: string,
): TPermissionItemsByColumn => {
  switch (column) {
    case EColumn.COMMENTS:
      return [hiddenPermissionItem, viewPermissionItem, editPermissionItem];
    case EColumn.TAGS:
    case EColumn.CUSTOM_FIELD:
      if (userRole === EUserRole.LIMITED) {
        return [hiddenPermissionItem, viewPermissionItem];
      }

      return [hiddenPermissionItem, viewPermissionItem, editPermissionItem];
    case EColumn.ACTIVITIES:
      return [hiddenSemiPermissionItem, viewSemiPermissionItem];
    case EColumn.EXPORT:
      return [hiddenSemiPermissionItem, allowSemiPermissionItem];

    default:
      return [emptyPermissionItem];
  }
};
