import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { tap } from 'rxjs';
import { TAsset } from '../../../asset-service/asset.consts';
import { AssetReorderService } from '../asset-reorder.service';

@Component({
  selector: 'pp-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent {
  @ViewChild('assetCard') assetCardElement: ElementRef;
  @Input() ppAsset: TAsset;
  @Input() ppIndex: number;

  isBeingReordered: boolean;

  constructor(private assetReorderService: AssetReorderService) {
    this.assetReorderService.reorderedAssetIdChange$
      .pipe(
        tap((assetId) => {
          this.checkIfIsBeingReordered(assetId);
        }),
      )
      .subscribe();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();

    if (this.assetReorderService.getReorderedAssetId() === null) {
      return;
    }

    const targetElementCoords = this.assetCardElement.nativeElement.getBoundingClientRect();
    const elementCenterY =
      targetElementCoords.top + (targetElementCoords.bottom - targetElementCoords.top) / 2;
    const scrolledBeyondFistHalf = event.clientY > elementCenterY;

    if (scrolledBeyondFistHalf) {
      this.assetReorderService.setNewAssetIndex(this.ppIndex + 1);
    } else {
      this.assetReorderService.setNewAssetIndex(this.ppIndex);
    }
  }

  private checkIfIsBeingReordered(assetId: string): void {
    this.isBeingReordered = assetId === this.ppAsset.id;

    if (this.isBeingReordered) {
      this.assetCardElement.nativeElement.classList.add('assetCard__reordered');
    } else {
      this.assetCardElement.nativeElement.classList.remove('assetCard__reordered');
    }
  }
}
