import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DeviceService } from 'src/app/core/services/device.service';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TSelectOption } from '../select.model';
import { TSelectDropdownData } from './select-dropdown.model';

@Component({
  selector: 'pp-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
})
export class SelectDropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  dropdownWidth: string = '558px';
  isMobile = false;
  filteredItems: TSelectOption[] = [];
  noSearchBar = false;
  selectedItem: TSelectOption = null;
  EIconPath = EIconPath;

  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private searchedKeyword = '';
  private items: TSelectOption[];

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });

    this.filterItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: string | TSelectOption): void {
    this.dropdown.callback(item);

    this.hideDropdown();
  }

  setKeyword(keyword: string): void {
    this.searchedKeyword = keyword;

    this.filterItems();
  }

  clearAll(): void {
    this.dropdown.callback();
    this.hideDropdown();
  }

  private filterItems(): void {
    const filteredItems = [];

    this.items.forEach((item) => {
      if (item.label.toLowerCase().includes(this.searchedKeyword.toLowerCase())) {
        filteredItems.push(item);
      }
    });

    this.filteredItems = filteredItems;
  }

  private setDataFromDropdown(): void {
    const dropdownData: TSelectDropdownData = this.dropdown.data;

    this.noSearchBar = dropdownData.noSearchBar;
    this.items = dropdownData.items;
    this.selectedItem = dropdownData.selectedItem;
    this.dropdownWidth = dropdownData.width ? dropdownData.width : `0px`;
  }
}
