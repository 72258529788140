import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { TColumn } from '../../columns/column.model';
import { createDefaultElement } from './create-default-element';
import { createNumbersElement } from './create-numbers-element';
import { createPercentageElement } from './create-percentage-element';
import { createCheckboxElement } from './create-checkbox-element';
import { createRichTextElement } from './create-rich-text-element';
import { createTimeElement } from './create-time-element';
import { createCostElement } from './create-cost-element';
import { createTimelineElement } from './create-timeline-element';
import { createDateElement } from './create-date-element';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';

export function createCustomFieldElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();

  // FIXME investigate why somethimes this field doesn't exist while refactoring the logic
  if (!_column.customFieldIds) {
    logErrorInSentry('Missing custom fields in column: ' + JSON.stringify(_column));

    return createDefaultElement(_index, _column, null);
  }

  const customFieldIndex = Object.keys(table.points[_index].customFieldsMap).find(
    (customFieldId) => {
      for (let i = 0; i < _column.customFieldIds.length; i++) {
        const _id = _column.customFieldIds[i];

        if (customFieldId === _id) {
          return true;
        }
      }

      return false;
    },
  );

  const customField = table.points[_index].customFieldsMap[customFieldIndex];

  let customFieldElement: HTMLElement = createElement('div');
  customFieldElement.innerHTML = '';

  switch (customField?.type) {
    case ECustomFieldType.DATE:
      customFieldElement = createDateElement(_index, _column, customField);
      break;
    case ECustomFieldType.COST:
      customFieldElement = createCostElement(_index, _column, customField);
      break;
    case ECustomFieldType.TIME:
      customFieldElement = createTimeElement(_index, _column, customField);
      break;
    case ECustomFieldType.RICHTEXT:
      customFieldElement = createRichTextElement(_index, _column, customField);
      break;
    case ECustomFieldType.CHECKBOX:
      customFieldElement = createCheckboxElement(_index, _column, customField);
      break;
    case ECustomFieldType.PERCENTAGE:
      customFieldElement = createPercentageElement(_index, _column, customField);
      break;
    case ECustomFieldType.NUMBERS:
      customFieldElement = createNumbersElement(_index, _column, customField);
      break;
    case ECustomFieldType.TIMELINE:
      customFieldElement = createTimelineElement(_index, _column, customField);
      break;
    default:
      customFieldElement = createDefaultElement(_index, _column, customField);
      break;
  }

  return customFieldElement;
}
