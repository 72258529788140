import { cloneDeep } from 'lodash';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { TAdvancedFilterSingle } from '../../filters-advanced/models/advanced-filter.model';
import { TCustomFieldFilter } from '../../filters/site-filter.model';
import { TSavedView } from '../models/saved-view.model';

// TODO Remove fixViewCustomFields function when PS-1309 is done
export function fixViewCustomFields(view: TSavedView, workspaceCustomFields: string[]): TSavedView {
  const newView = cloneDeep(view);

  if (newView.filters?.advanced?.filters) {
    newView.filters.advanced.filters = filterOutMissingCustomFieldsFromAdvancedFilters(
      newView.filters.advanced.filters,
      workspaceCustomFields,
    );
  }

  if (newView.filters?.basic?.customFields) {
    newView.filters.basic.customFields = filterOutMissingCustomFieldsFromBasicFilters(
      newView.filters.basic.customFields,
      workspaceCustomFields,
    );
  }

  return newView;
}

function filterOutMissingCustomFieldsFromAdvancedFilters(
  filters: TAdvancedFilterSingle[],
  workspaceCustomFields: string[],
): TAdvancedFilterSingle[] {
  const basicFieldTypes = Object.values(EBasicField);

  return filters.filter((filter) => {
    const isCustomField = !basicFieldTypes.includes(filter.id as EBasicField);

    if (isCustomField) {
      return workspaceCustomFields.includes(filter.id.toString()); // TODO Refactor backend to return custom field ids as STRINGS. In every endpoint
    }

    return true;
  });
}

function filterOutMissingCustomFieldsFromBasicFilters(
  customFieldsFiler: TCustomFieldFilter[],
  workspaceCustomFields: string[],
): TCustomFieldFilter[] {
  return customFieldsFiler.filter((customFieldFilter) =>
    workspaceCustomFields.includes(customFieldFilter.id.toString()),
  );
}
