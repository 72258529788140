import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { TDashletData } from '../../dashlet/dashlet-events/dashlet-events.service';

export function findDashletData(
  dashlet: TExportDashlet,
  dashletsData: TDashletData[],
): TDashletData {
  return dashletsData.find((_dashlet) => {
    const range = dashlet.selectedRange;
    const targetId = range.workspaceId || range.accountId;

    return (
      (_dashlet.targetId.includes(targetId) || (!_dashlet.targetId && !targetId)) &&
      _dashlet.type === dashlet.name &&
      _dashlet.period === dashlet.period
    );
  });
}
