import { Component, Input, NgZone, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TWorkspaceUser } from '@project/view-models';
import { Subject, takeUntil } from 'rxjs';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { TAccountUser } from '../../../users/account.user.model';
import { UsersService } from '../../../users/users.service';
import { UserManagementUserSectionService } from './user-management-user-section.service';

@Component({
  selector: 'pp-user-management-user-section',
  templateUrl: './user-management-user-section.component.html',
  styleUrls: ['./user-management-user-section.component.scss'],
})
export class UserManagementUserSectionComponent implements OnChanges, OnDestroy {
  @Input() ppUser: TAccountUser;
  @Input() ppAccountId: string;
  @Input() ppCustomFields: TCombinedCustomField[];

  expanded = false;
  sites = [];
  user: TWorkspaceUser;
  delayedExpanded = false;
  animationDurationInSeconds = 0.3;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private ngZone: NgZone,
    private userManagementUserSectionService: UserManagementUserSectionService,
    private previousPageService: PreviousPageService,
    private router: Router,
  ) {
    this.userManagementUserSectionService.expandedUsersChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.trySetInitialExpanded();
      });
  }

  ngOnChanges() {
    this.checkUser();
    this.trySetInitialExpanded();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private checkUser(): void {
    if (this.ppUser) {
      this.user = this.usersService.getUser(this.ppUser.userId);
    } else {
      this.user = null;
    }
  }

  private trySetInitialExpanded(): void {
    if (this.user) {
      this.expanded = this.userManagementUserSectionService.isExpandedUser(this.user.userId);
      this.delayedExpanded = this.expanded;
    }
  }

  onExpandedChange(expanded: boolean): void {
    if (!expanded) {
      this.expanded = false;
      this.delayedExpanded = false;
    } else {
      const timeoutOffset = 100; // Finishing timeout before animation ends makes it look a bit better
      const timeoutDuration = Math.max(0, this.animationDurationInSeconds * 1000 - timeoutOffset);
      this.expanded = true;

      this.ngZone.run(() => {
        setTimeout(() => {
          this.delayedExpanded = expanded;
        }, timeoutDuration);
      });
    }

    this.userManagementUserSectionService.toggleExpandedUser(this.user.userId, expanded);
  }

  navigateToSite(workspaceId: string): void {
    this.previousPageService.setPreviousSiteUrl(this.router.url);
    this.router.navigate(['/settings/site', workspaceId, 'users']);
  }
}
