import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { TAccount } from 'src/app/project/modules/account/account.model';

import { DashboardService } from '../../dashboard-service/dashboard.service';
import { DashletService } from '../dashlet-service/dashlet.service';
import { DashboardChartService } from '../../dashboard-chart/dashboard-chart.service';
import { EDashletType } from '../../dashlets-enums';
import { EReportType } from '../../report-types-enum';
import { DashboardReportService } from '../../dashboard-report.service';
import { checkAccount } from './check-account';
import { WindowService } from '@core/services';
import { EStore } from '../../../../shared/enums/store.enum';
import { TDashletGraph } from '../../dashlet-graph.model';
import { TDashletFlipDataData } from '../dashlet-flip/dashlet-flip.model';

Chart.register(ChartDataLabels);

@Component({
  selector: 'pp-dashlet',
  templateUrl: './dashlet.component.html',
  styleUrls: ['./dashlet.component.scss'],
})
export class DashletComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false }) chartElement: ElementRef;
  @Input() ppDashlet: TDashlet;
  @Output() ppUpdateUserDashlets = new EventEmitter();

  workspaces: TWorkspacesById;
  account: TAccount = null;
  dashletTypes = EDashletType;
  loading = false;
  isFlipped = false;
  totalPoints = 0;

  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private chart: Chart = null;
  private graph: TDashletGraph;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
    }>,
    private dashboardService: DashboardService,
    private dashletService: DashletService,
    private dashboardChartService: DashboardChartService,
    private dashboardReportService: DashboardReportService,
    private windowService: WindowService,
    private zone: NgZone,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;

      this.onWorkspacesUpdate();
    });

    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;

      this.setAccount();
    });
  }

  setAccount(): void {
    this.account = checkAccount(this.ppDashlet, this.accounts);

    if (!this.account) {
      this.ppDashlet.selectedRange.accountId = null;
      this.ppDashlet.selectedRange.workspaceId = null;
    }
  }

  ngAfterViewInit() {
    this.windowService.resize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (
        this.ppDashlet.name === EDashletType.OVER_TIME_STATUS ||
        this.ppDashlet.name === EDashletType.OVER_TIME_PRIORITY ||
        this.ppDashlet.name === EDashletType.CURRENT_STATUS ||
        this.ppDashlet.name === EDashletType.CURRENT_PRIORITY
      ) {
        this.updateCharts();
      }
    });
  }

  updateCharts(): void {
    this.graph = this.dashletService.getDashletGraph(this.ppDashlet.name);
    this.updateData();
  }

  toggleFlip(): void {
    this.isFlipped = !this.isFlipped;
  }

  selectSiteRange(data: TDashletFlipDataData): void {
    if (data.workspaceId) {
      this.ppDashlet.selectedRange.accountId = data.accountId;
      this.ppDashlet.selectedRange.workspaceId = data.workspaceId;
    } else if (data.accountId) {
      this.ppDashlet.selectedRange.accountId = data.accountId;
      this.ppDashlet.selectedRange.workspaceId = null;
    } else {
      this.ppDashlet.selectedRange.accountId = null;
      this.ppDashlet.selectedRange.workspaceId = null;
    }

    this.setAccount();
    this.updateData();

    this.ppUpdateUserDashlets.emit();
  }

  selectPeriod(data: TDashletFlipDataData): void {
    this.ppDashlet.period = data.period;

    this.updateData();

    this.ppUpdateUserDashlets.emit();
  }

  fetchCurrentTypeCount(type: EReportType): void {
    this.loading = true;

    this.dashboardService
      .fetchCurrentTypeCount({
        type,
        accountId: this.ppDashlet.selectedRange.accountId,
        workspaceId: this.ppDashlet.selectedRange.workspaceId,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.graph.data.datasets[0].data = response;
          this.totalPoints = response.reduce((a, b) => a + b, 0);

          this.graph.data.labels = this.dashboardChartService.addGraphLabels(type, response);

          this.addChartToView();
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  fetchReports(type: EReportType): void {
    this.loading = true;

    this.dashboardReportService
      .fetchReports(this.ppDashlet, this.graph)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.loading = false;
          this.totalPoints = response.totalPoints;
          this.graph = response.graph;

          this.dashboardChartService.addGraphDatasetLabels(this.graph, type, response.response);

          this.addChartToView();
        }),
      )
      .subscribe();
  }

  addChartToView(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    const ctx = this.chartElement.nativeElement.getContext('2d');

    this.zone.runOutsideAngular(() => {
      this.chart = new Chart(ctx, this.graph);
    });
  }

  updateData(): void {
    switch (this.ppDashlet.name) {
      case EDashletType.CURRENT_STATUS:
        this.fetchCurrentTypeCount(EReportType.STATUS);
        break;
      case EDashletType.CURRENT_PRIORITY:
        this.fetchCurrentTypeCount(EReportType.PRIORITY);
        break;
      case EDashletType.OVER_TIME_STATUS:
        this.fetchReports(EReportType.STATUS);
        break;
      case EDashletType.OVER_TIME_PRIORITY:
        this.fetchReports(EReportType.PRIORITY);
        break;
      default:
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private onWorkspacesUpdate(): void {
    if (this.ppDashlet.name === EDashletType.ACTIVITIES) {
      if (Object.keys(this.workspaces).length > 0 && this.ppDashlet.selectedRange.workspaceId) {
        if (!this.workspaces[this.ppDashlet.selectedRange.workspaceId]) {
          this.ppDashlet.selectedRange.accountId = null;
          this.ppDashlet.selectedRange.workspaceId = null;
          this.ppUpdateUserDashlets.emit();
        }
      }
    }
  }
}
