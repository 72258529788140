var escapeAttribute = require('./escape-attribute').escapeAttribute;

/**
 * Represents an XML node.
 * @api private
 */
function XmlNode(name, children) {
  if (children === void 0) {
    children = [];
  }
  this.name = name;
  this.children = children;
  this.attributes = {};
}
XmlNode.prototype.addAttribute = function (name, value) {
  this.attributes[name] = value;
  return this;
};
XmlNode.prototype.addChildNode = function (child) {
  this.children.push(child);
  return this;
};
XmlNode.prototype.removeAttribute = function (name) {
  delete this.attributes[name];
  return this;
};
XmlNode.prototype.toString = function () {
  var hasChildren = Boolean(this.children.length);
  var xmlText = '<' + this.name;
  // add attributes
  var attributes = this.attributes;
  for (var i = 0, attributeNames = Object.keys(attributes); i < attributeNames.length; i++) {
    var attributeName = attributeNames[i];
    var attribute = attributes[attributeName];
    if (typeof attribute !== 'undefined' && attribute !== null) {
      xmlText += ' ' + attributeName + '=\"' + escapeAttribute('' + attribute) + '\"';
    }
  }
  return xmlText += !hasChildren ? '/>' : '>' + this.children.map(function (c) {
    return c.toString();
  }).join('') + '</' + this.name + '>';
};

/**
 * @api private
 */
module.exports = {
  XmlNode: XmlNode
};