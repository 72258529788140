import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  TAsset,
  TNewAsset,
} from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { API_asset, API_asset_image, API_fleet_asset } from './asset-paths';
import { TAssetCreateDTO } from './asset-requests.model';

@Injectable({
  providedIn: 'root',
})
export class AssetApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchAssets(fleetId: string): Observable<TAsset[]> {
    const url = API_fleet_asset(fleetId);

    return this.apiService.get<TAsset[]>(url);
  }

  createAsset(fleetId: string, body: TAssetCreateDTO): Observable<TAsset> {
    const url = API_fleet_asset(fleetId);

    return this.apiService.post<TAsset>(url, body);
  }

  fetchAsset(assetId: string): Observable<TAsset> {
    const url = API_asset(assetId);

    return this.apiService.get<TAsset>(url);
  }

  editAsset(assetId: string, body: Partial<TNewAsset>): Observable<TAsset> {
    const url = API_asset(assetId);

    return this.apiService.put<TAsset>(url, body);
  }

  deleteAsset(assetId: string): Observable<null> {
    const url = API_asset(assetId);

    return this.apiService.delete<null>(url);
  }

  uploadAssetImage(image: File): Observable<null> {
    const url = API_asset_image();

    const formData = new FormData();
    formData.append('image_file', image);

    return this.apiService.postWithFormData<null>(url, formData);
  }
}
