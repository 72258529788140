import { GET_ACCOUNTS } from 'src/app/project/modules/account/account.store';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import {
  GET_COLUMNS,
  SET_COLUMN_SORT_INDEX,
  SET_COLUMN_SORT_ORDER,
} from '../../columns/columns.store';
import { GET_GROUPING } from '../../columns/grouping.store';
import { CLEAR_SORTING } from '../../columns/sorting.store';
import { GET_TABLE } from '../../table.ui.store';

import { createElement } from 'src/app/core/helpers/dom';
import { createHeaderCellTypeElement } from './utils/create-header-cell-type-element';
import { createHeaderRowElement } from './utils/create-header-row-element';
import { createHeaderSelectElement } from './utils/create-header-select-element';

import { checkElement } from '@core/helpers';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TColumn } from '../../columns/column.model';
import Table from '../table/Table';
import { getSelectElementWidthPx } from '../table/utils/select-element-width';

export default class TableHead {
  private table: Table;
  minColumnWidth = 60;
  allSelected = false;
  canResize = true;
  tooltips: Tooltip[] = [];
  resizeElement: HTMLElement = null;

  element: HTMLElement = null;
  rowElement: HTMLElement = createHeaderRowElement();

  constructor(parent: Table) {
    this.table = parent;

    this.element = this.create();

    this.table.element.appendChild(checkElement(this.element));
  }

  private create(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'table__head',
      },
      children: [this.rowElement],
    });
  }

  clear(): void {
    this.updateWidth(0);
  }

  load(keepScrollPosition: boolean = false): void {
    const wrapperElement = this.element.children[0];
    const scrollPosition = keepScrollPosition ? this.element.scrollLeft : 0;
    this.updateWidth(this.table.width);

    this.tooltips.forEach((tooltip) => {
      tooltip.removeTooltip();
    });

    this.tooltips = [];

    while (wrapperElement.firstChild) {
      wrapperElement.removeChild(wrapperElement.firstChild);
    }

    wrapperElement.appendChild(checkElement(createHeaderSelectElement()));

    this.checkResizePermission();

    GET_COLUMNS().forEach((column) => {
      if (!column.hidden) {
        const cellElement = createHeaderCellTypeElement(column);

        wrapperElement.appendChild(checkElement(cellElement));
      }
    });

    this.table.sortTable();

    this.element.scrollLeft = scrollPosition;
  }

  updateWidth(width: number = this.table.width): void {
    (this.element.children[0] as HTMLElement).style.width = `${width}px`;
  }

  scrollLeft(left: number): void {
    this.element.scrollLeft = left;
  }

  checkResizePermission(): void {
    const table = GET_TABLE();
    let canResize = true;

    if (table.workspaceId) {
      const accounts = GET_ACCOUNTS();
      const share = GET_SHARES().find(
        (searchedShare) => searchedShare.workspaceId === table.workspaceId,
      );
      const account = accounts.find((searchedAccount) =>
        searchedAccount.workspaces.includes(table.workspaceId),
      );

      let globalSitePreferences: boolean;

      if (
        account &&
        account.accountFeatures &&
        typeof account.accountFeatures.globalSitePreferences !== 'undefined'
      ) {
        globalSitePreferences = account.accountFeatures.globalSitePreferences;
      } else {
        globalSitePreferences = false;
      }

      if (share) {
        canResize =
          !globalSitePreferences ||
          share.shareOption === EUserRole.SITE_ADMIN ||
          share.shareOption === EUserRole.ACCOUNT_ADMIN ||
          share.shareOption === EUserRole.OWNER;
      }
    }

    this.canResize = canResize;
  }

  addColumn(columnIndex: number): void {
    const columns = GET_COLUMNS();
    let hiddenColumnsCount = 0;

    for (let index = 0; index < columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsCount += 1;
      }
    }

    this.rowElement.insertBefore(
      createHeaderCellTypeElement(columns[columnIndex]),
      this.rowElement.children[columnIndex - hiddenColumnsCount + 1],
    );
  }

  removeColumn(columnIndex: number): void {
    const columnElement = this.rowElement.children[columnIndex + 1];

    if (columnElement) {
      columnElement.parentNode.removeChild(columnElement);
    }
  }

  moveColumn(columnIndex: number, destinationIndex: number): void {
    const columns = GET_COLUMNS();

    let hiddenColumnsBeforeDestinationCount = 0;
    let hiddenColumnsBeforeColumnCount = 0;

    for (let index = 0; index < destinationIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeDestinationCount += 1;
      }
    }

    for (let index = 0; index < columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeColumnCount += 1;
      }
    }

    const columnElement =
      this.rowElement.children[columnIndex - hiddenColumnsBeforeColumnCount + 1];

    columnElement.parentNode.removeChild(columnElement);

    this.rowElement.insertBefore(
      columnElement,
      this.rowElement.children[destinationIndex - hiddenColumnsBeforeDestinationCount + 1],
    );
  }

  sortColumn(sortedColumn: TColumn): void {
    const sortButton = this.table.siteOptionsService.getSortButton();
    const groupButton = this.table.siteOptionsService.getGroupButton();
    const oldSortOrder = sortedColumn.sortOrder;

    CLEAR_SORTING();

    GET_COLUMNS().forEach((column) => {
      delete column.sortIndex;
      delete column.sortOrder;
    });

    let sortOrder = null;

    if (!oldSortOrder) {
      sortOrder = 'ASC';
    } else if (oldSortOrder === 'ASC') {
      sortOrder = 'DESC';
    }

    if (sortOrder) {
      SET_COLUMN_SORT_INDEX(sortedColumn.index, 0);
      SET_COLUMN_SORT_ORDER(sortedColumn.index, sortOrder);
    } else {
      SET_COLUMN_SORT_INDEX(sortedColumn.index);
      SET_COLUMN_SORT_ORDER(sortedColumn.index);
    }

    this.table.sortTable({ updatePreferences: true });

    sortButton.update({ closeDropdown: true });
    groupButton.update();
  }

  updateFrozenColumnWidth(): void {
    const selectElement: HTMLElement = document.querySelector('.table__head__select');
    const rowElement: HTMLElement = document.querySelector('.table__head__row');
    const grouping = GET_GROUPING();

    if (selectElement) {
      if (grouping.length) {
        selectElement.style.width = getSelectElementWidthPx(grouping.length);
        // selectElement.style.marginLeft = `${16 * grouping.length}px`;
      } else {
        selectElement.style.width = getSelectElementWidthPx(grouping.length);
        // selectElement.style.marginLeft = '0';
      }
    }

    if (rowElement) {
      rowElement.style.marginLeft = `0px`;
    }
  }

  // Points

  selectPoints(): void {
    if (!this.allSelected) {
      const checkmark = this.element.children[0].children[0].children[0].children[0].children[0]
        .children[0] as HTMLImageElement;

      checkmark.src = EIconPath.ICON_CHECHMARK_TABLE_SMALL;
      checkmark.classList.add('customSiteTable__checkbox--selected');
    }

    this.allSelected = true;
  }

  deselectPoints(): void {
    if (this.allSelected) {
      const element = this.element.children[0].children[0].children[0].children[0].children[0]
        .children[0] as HTMLImageElement;

      element.src = EIconPath.ICON_CHECHMARK_TABLE_FALSE;

      element.classList.remove('customSiteTable__checkbox--selected');
    }

    this.allSelected = false;
  }

  addTooltip(tooltip: Tooltip): void {
    this.tooltips.push(tooltip);
  }
}
