import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of, Subject, timer } from 'rxjs';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';

import { TPoint } from 'src/app/project/modules/points/points.model';
import { TUser } from 'src/app/project/modules/user/user.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TRichTextUpdate } from 'src/app/project/components/input/rich-text/rich-text.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { PointsUsersService } from '../../../points-users.service';

@Component({
  selector: 'pp-point-fields-rich-text',
  templateUrl: './point-fields-rich-text.component.html',
  styleUrls: ['./point-fields-rich-text.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsRichTextComponent implements OnChanges, OnDestroy {
  @ViewChild('richTextContainer', { static: true }) richTextContainer: ElementRef;
  @ViewChild('richText', { static: true }) richTextElement: ElementRef;

  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppRichText: string;
  @Input() ppPlainText: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  richText: string;

  user$: Observable<TUser>;
  user: TUser;

  processing = false;
  success = false;
  error = false;
  editing = false;
  changed = false;
  updating = false;
  EIconPath = EIconPath;
  userListIds: string[];
  richTextOptions: TRichTextOptions;

  private successTimerMs = 2500;

  constructor(
    private store: Store,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private pointsUpdateService: PointsUpdateService,
    private pointFieldsService: PointFieldsService,
    private translationPipe: TranslationPipe,
    private pointsService: PointsService,
    private sitePointFilterService: SitePointFilterService,
    private customTableService: CustomTableService,
    private pointsUsersService: PointsUsersService,
  ) {}

  ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    if (changes.ppPointId) {
      const point: TPoint = this.pointsService.findPoint(changes.ppPointId.currentValue);

      if (point?.customFieldsMap[this.ppFieldId]) {
        this.richText = point.customFieldsMap[this.ppFieldId].value;
      }
    }

    if (changes.ppRichText) {
      this.richText = changes.ppRichText.currentValue;
    }

    if (changes.ppPlainText) {
      this.ppPlainText = changes.ppPlainText.currentValue;
    }

    this.setTagSelectOptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setTagSelectOptions(): void {
    this.richTextOptions = {
      defaultBorder: true,
      showButtons: !this.ppNew,
      processing: this.processing,
      small: true,
      pointField: true,
      clearable: true,
    };
  }

  updateRichText(plainText: string, richText: string, mentions: string[]): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const workspaceId = this.ppWorkspaceId;
    const customFieldId = this.ppFieldId;
    const customField = this.pointFieldsService.createCustomFieldRichText(fieldId, richText);

    if (this.ppNew) {
      this.updateRichTextForNewPoint(
        plainText,
        richText,
        mentions,
        _id,
        workspaceId,
        customFieldId,
      );

      return;
    }

    this.processing = true;

    this.setTagSelectOptions();

    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        _id: _id,
        customFieldValue: richText,
        customFieldId: customFieldId,
        plainText: plainText,
      }),
    );

    this.pointsUpdateService
      .updatePointField(_id, {
        customFieldsMap: customField,
        mentions: mentions,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_rich_text_update');

          this.customTableService.updatePoint({
            _id: this.ppPointId,
            field: this.ppFieldLabel,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.processing = false;

          this.setTagSelectOptions();
        }),
      )
      .subscribe();
  }

  updateField(value: TRichTextUpdate): void {
    const newValue = {
      plainText: value.mergedText,
      richText: value.richText,
      mentions: value.mentions,
    };

    this.richText = newValue.richText;
    this.ppPlainText = newValue.plainText;

    this.updateRichText(newValue.plainText, newValue.richText, newValue.mentions);
  }

  updateUserList(): void {
    this.pointsUsersService
      .fetchUsersWithAccessToPoint(this.ppPointId, EAccessField.CUSTOM_FIELD, this.ppFieldId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userIds) => {
        this.userListIds = userIds;
      });
  }

  private updateRichTextForNewPoint(
    plainText: string,
    richText: string,
    mentions: string[],
    _id: string,
    workspaceId: string,
    customFieldId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        _id: _id,
        customFieldValue: richText,
        customFieldId: customFieldId,
        plainText: plainText,
        mentions: mentions,
      }),
    );
  }
}
