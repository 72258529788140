import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { TDashlet, TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { SiteDataService, TSiteData } from 'src/app/project/modules/site/site-data.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { DashletActivitiesService } from '../dashlet-activities/dashlet-activities.service';
import { DashletService } from '../dashlet-service/dashlet.service';

import { TAllUsers } from '@project/view-models';
import { generateErrorPrompt } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { EStore } from '../../../../shared/enums/store.enum';
import { ActivityFormatterService } from '../../../points/point-modal/point-timeline/activity-formatter.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { TDashletActivity } from '../dashlet-activities/dashlet-activity/dashlet-activity.model';
import { TDashletModalData } from './activities-dashlet-modal.model';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);

@Component({
  selector: 'pp-activities-dashlet-modal',
  templateUrl: './activities-dashlet-modal.component.html',
  styleUrls: ['./activities-dashlet-modal.component.scss'],
})
export class ActivitiesDashletModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dashletActivities', { static: false }) dashletActivitiesElement: ElementRef;
  @Input() ppLoading: boolean;

  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  accountId: string;
  workspaceId: string;
  dashlet: TDashlet;
  destroyed = false;

  activities: TDashletActivity[];
  allActivitiesLoaded: boolean;
  fetched: boolean;
  dashletSettingsVisible = false;
  EIconPath = EIconPath;

  allUsers: TAllUsers = {};
  site: TSiteData = this.siteDataService.getSite();
  selectedSiteRange: TDashletModalData;

  private page: {
    p: number;
    size: number;
  };

  private modal: Modal;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      preferences: TPreferences;
    }>,
    private modalService: ModalService,
    private siteDataService: SiteDataService,
    private promptService: PromptService,
    private dashletActivitiesService: DashletActivitiesService,
    private activityFormatterService: ActivityFormatterService,
    private usersService: UsersService,
    private dashletService: DashletService,
    private dashboardService: DashboardService,
    private responseErrorService: ResponseErrorService,
    private workspaceService: WorkspaceService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit(): void {
    this.modal = this.modalService.getModal();

    this.activities = [];
    this.fetched = false;
    this.allActivitiesLoaded = false;

    this.accountId = this.modal.data.accountId;
    this.workspaceId = this.modal.data.workspaceId;
    this.ppLoading = this.modal.data.loading;
    this.dashlet = this.modal.data.dashlet;

    this.page = {
      p: 0,
      size: 20,
    };

    this.fetchActivities();

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
      this.allUsers = this.usersService.getUsers();
    });
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      (changes.ppAccountId && !changes.accountId.isFirstChange()) ||
      (changes.ppWorkspaceId && !changes.ppWorkspaceId.isFirstChange())
    ) {
      this.page.p = 0;
      this.page.size = 20;
      this.fetched = false;
      this.allActivitiesLoaded = false;

      this.fetchActivities(true);
    }
  }

  ngOnDestroy() {
    this.activities = [];
    this.fetched = false;
    this.allActivitiesLoaded = false;
    this.destroyed = true;

    this.destroy$.next();
  }

  fetchActivities(refresh?: boolean): void {
    if (refresh) {
      this.allActivitiesLoaded = false;
      this.page.p = 0;
    }

    if (!this.allActivitiesLoaded && !this.destroyed) {
      this.ppLoading = true;

      this.dashletActivitiesService
        .fetchActivities({
          from: this.page.p * this.page.size,
          size: this.page.size,
          accountId: this.accountId,
          workspaceId: this.workspaceId,
        })
        .pipe(
          tap((response) => {
            // TODO Use single base component with DashletActivitiesComponent

            // Merge new activities only
            const newActivities = this.activities.length
              ? [this.activities.pop(), ...response] // Give last previous fetched activity a chance to be merged with next fetched
              : response;

            const parsedActivities = this.dashletActivitiesService.formatActivities(
              newActivities,
              this.allUsers,
            );

            if (refresh) {
              this.activities.length = 0;
              this.activities = parsedActivities;
            } else {
              this.activities = this.activities.concat(parsedActivities);
            }

            this.page.p += 1;
            this.fetched = true;

            if (response.length < 20) {
              this.allActivitiesLoaded = true;
            }
          }),
          catchError((error) => {
            const promptText = generateErrorPrompt(error, 'prompt_error');
            this.promptService.showError(promptText);

            return throwError(error);
          }),
          finalize(() => {
            this.ppLoading = false;
          }),
        )
        .subscribe();
    }
  }

  toggleSettings(): void {
    this.dashletSettingsVisible = !this.dashletSettingsVisible;
  }

  selectSiteRange(data: TDashletModalData): void {
    this.selectedSiteRange = data;
  }

  updateNames(): void {
    this.workspaceService
      .generateBasicWorkspaces()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.dashletSettingsVisible = false;

          this.fetchActivities(true);
          this.updateUserDashlets();
        }),
      )
      .subscribe();
  }

  removeDashlet(): void {
    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__DELETE, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_value: this.dashlet.name,
    });

    this.dashboardService.removeDashlet(this.dashlet);

    this.hideModal();
  }

  apply(): void {
    if (this.selectedSiteRange.workspaceId) {
      this.dashlet.selectedRange.accountId = this.selectedSiteRange.accountId;
      this.dashlet.selectedRange.workspaceId = this.selectedSiteRange.workspaceId;
    } else if (this.selectedSiteRange.accountId) {
      this.dashlet.selectedRange.accountId = this.selectedSiteRange.accountId;
      this.dashlet.selectedRange.workspaceId = null;
    } else {
      this.dashlet.selectedRange.accountId = null;
      this.dashlet.selectedRange.workspaceId = null;
    }

    this.accountId = this.dashlet.selectedRange.accountId;
    this.workspaceId = this.dashlet.selectedRange.workspaceId;

    this.updateNames();
  }

  updateUserDashlets(): void {
    this.dashboardService.updateUserDashlets(true);
  }
}
