import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { siteFilterReducer } from './site-filter.reducer';

import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component.';
import { FilterTagsComponent } from './filter-tags/filter-tags.component';
import { FilterUsersComponent } from './filter-users/filter-users.component';
import { SiteFilterDropdownComponent } from './site-filter-dropdown/site-filter-dropdown.component';
import { SiteFilterComponent } from './site-filter.component';

import { SiteFilterService } from 'src/app/project/modules/filters/site-filter-service/site-filter.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { FilterFieldNumberInputModule } from '../../components/filter-field-numbers-input/filter-field-number-input.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { SelectModule } from '../../components/input/select/select.module';
import { TagModule } from '../../components/tag/tag.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { UserPickerModule } from '../../components/user-picker/user-picker.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { FiltersAdvancedModule } from '../filters-advanced/filters-advanced.module';
import { LayoutModule } from '../layout/layout.module';
import { SavedViewsModule } from '../saved-views/saved-views.module';
import { TagsModule } from '../tags/tags.module';
import { BasicFiltersComponent } from './basic-filters/basic-filters.component';
import { FilterFieldAssigneesComponent } from './filter-fields/filter-field-assignees/filter-field-assignees.component';
import { FilterFieldCreatedByComponent } from './filter-fields/filter-field-created-by/filter-field-created-by.component';
import { FilterFieldCheckboxComponent } from './filter-fields/filter-field-custom-fields/filter-field-checkbox/filter-field-checkbox.component';
import { FilterFieldCostComponent } from './filter-fields/filter-field-custom-fields/filter-field-cost/filter-field-cost.component';
import { FilterFieldCustomFieldsComponent } from './filter-fields/filter-field-custom-fields/filter-field-custom-fields.component';
import { FilterFieldDateComponent } from './filter-fields/filter-field-custom-fields/filter-field-date/filter-field-date.component';
import { FilterFieldListComponent } from './filter-fields/filter-field-custom-fields/filter-field-list/filter-field-list.component';
import { FilterFieldNumbersComponent } from './filter-fields/filter-field-custom-fields/filter-field-numbers/filter-field-numbers.component';
import { FilterFieldPercentageComponent } from './filter-fields/filter-field-custom-fields/filter-field-percentage/filter-field-percentage.component';
import { FilterFieldTimelineComponent } from './filter-fields/filter-field-custom-fields/filter-field-timeline/filter-field-timeline.component';
import { FilterFieldDateCreatedComponent } from './filter-fields/filter-field-date-created/filter-field-date-created.component';
import { FilterFieldDateUpdatedComponent } from './filter-fields/filter-field-date-updated/filter-field-date-updated.component';
import { FilterFieldFlagComponent } from './filter-fields/filter-field-flag/filter-field-flag.component';
import { FilterFieldLocationComponent } from './filter-fields/filter-field-location/filter-field-location.component';
import { FilterFieldPriorityComponent } from './filter-fields/filter-field-priority/filter-field-priority.component';
import { FilterFieldRemindersComponent } from './filter-fields/filter-field-reminders/filter-field-reminders.component';
import { FilterFieldSitesComponent } from './filter-fields/filter-field-sites/filter-field-sites.component';
import { FilterFieldStatusComponent } from './filter-fields/filter-field-status/filter-field-status.component';
import { FilterFieldTagsComponent } from './filter-fields/filter-field-tags/filter-field-tags.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    StoreModule.forFeature('siteFilter', siteFilterReducer),
    ButtonModule,
    ScrollingModule,
    LayoutModule,
    SelectModule,
    DatepickerModule,
    TagsModule,
    CheckboxModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ToggleModule,
    PipesModule,
    SecureSrcModule,
    TagModule,
    IconModule,
    FiltersAdvancedModule,
    UserPickerModule,
    SavedViewsModule,
    FilterFieldNumberInputModule,
  ],
  exports: [FilterDropdownComponent, SiteFilterDropdownComponent, SiteFilterComponent],
  providers: [SiteFilterService, SitePointFilterService],
  declarations: [
    SiteFilterComponent,
    SiteFilterDropdownComponent,
    FilterTagsComponent,
    FilterUsersComponent,
    FilterDropdownComponent,
    FilterFieldRemindersComponent,
    FilterFieldSitesComponent,
    FilterFieldLocationComponent,
    FilterFieldFlagComponent,
    FilterFieldPriorityComponent,
    FilterFieldStatusComponent,
    FilterFieldTagsComponent,
    FilterFieldCreatedByComponent,
    FilterFieldAssigneesComponent,
    FilterFieldDateCreatedComponent,
    FilterFieldDateUpdatedComponent,
    FilterFieldCustomFieldsComponent,
    FilterFieldListComponent,
    FilterFieldCostComponent,
    FilterFieldNumbersComponent,
    FilterFieldPercentageComponent,
    FilterFieldCheckboxComponent,
    FilterFieldDateComponent,
    FilterFieldTimelineComponent,
    BasicFiltersComponent,
  ],
})
export class FiltersModule {}
