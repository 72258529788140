<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_LIST_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content" #list>
    <div
      #pointFieldsList
      id="{{ 'clist' + ppFieldId.toString() }}"
      class="pointCF__input pointCF__input--list"
      [ngClass]="{ 'pointCF__input--focus': focused }"
      [class.pointCF__input--error]="error"
      [class.pointCF__input--disabled]="!ppCanEdit"
      (click)="showList()"
    >
      <span *ngIf="ppFieldValue">{{ ppFieldValue }}</span>
      <span class="pointCF__input-list-placeholder" *ngIf="!ppFieldValue">{{
        'select...' | ppTranslate
      }}</span>
    </div>

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="!!ppFieldValue"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    >
    </pp-save-indicator>

    <ng-container>
      <div
        class="pointCF__dropdown-icon"
        [class.pointCF__input--list--hide-arrow]="focused && ppFieldValue"
        [class.pointCF__dropdown-icon--hide-hover]="!!ppFieldValue"
        (click)="arrowClicked()"
      >
        <div class="select__icon-container">
          <pp-icon
            [ppSrc]="
              dropdownVisible ? EIconPath.ICON_MISC_UP_ARROW_18 : EIconPath.ICON_MISC_DOWN_ARROW_18
            "
            ppColor="grey-400"
            ppClass="setSize18"
          ></pp-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
