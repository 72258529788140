import { TCustomFieldResponse } from '@project/view-models';

export function getCostExportValue(exportType: string, customField: TCustomFieldResponse): string {
  switch (exportType) {
    case 'xlsx':
      return customField.value;
    default:
      return `${customField.currencySymbol} ${customField.value}`;
  }
}
