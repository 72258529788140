import { EIconPath } from '../../shared/enums/icons.enum';
import { customFieldNames } from './custom-fields.model';

export type TCustomFieldTypeData = {
  value: customFieldNames;
  text: string;
  description: string;
  descriptionLong: string;
  iconPath: EIconPath;
};

export const customFieldTypesData: TCustomFieldTypeData[] = [
  {
    value: 'TEXT',
    text: 'Text',
    description: '',
    descriptionLong: 'A single line text field for short phrases of information.',
    iconPath: EIconPath.CUSTOM_FIELD_TEXT_BLUE,
  },
  {
    value: 'RICHTEXT',
    text: 'Rich Text',
    description: '',
    descriptionLong:
      'Multiple line text field using rich text options, such as bold, italics and numbering.',
    iconPath: EIconPath.CUSTOM_FIELD_RICH_TEXT_BLUE,
  },
  {
    value: 'LIST',
    text: 'List',
    description: '',
    descriptionLong:
      'Create a simple list or sub-lists of predefined options where one option can be selected.',
    iconPath: EIconPath.CUSTOM_FIELD_LIST_BLUE,
  },
  {
    value: 'CHECKBOX',
    text: 'Checkbox',
    description: '',
    descriptionLong: 'Single checkbox that is shown in the table.',
    iconPath: EIconPath.CUSTOM_FIELD_CHECKBOX_BLUE,
  },
  {
    value: 'DATE',
    text: 'Date',
    description: '',
    descriptionLong: 'For dates selected from a calendar',
    iconPath: EIconPath.CUSTOM_FIELD_DATE_BLUE,
  },
  {
    value: 'TIME',
    text: 'Time',
    description: '',
    descriptionLong: 'Track time in days, hours, and minutes.',
    iconPath: EIconPath.CUSTOM_FIELD_TIME_BLUE,
  },
  {
    value: 'COST',
    text: 'Cost',
    description: '',
    descriptionLong: 'For monetary data.',
    iconPath: EIconPath.CUSTOM_FIELD_COST_BLUE,
  },
  {
    value: 'NUMBERS',
    text: 'Numbers',
    description: '',
    descriptionLong: 'For numerical data with options for setting decimal places.',
    iconPath: EIconPath.CUSTOM_FIELD_NUMBERS_BLUE,
  },
  {
    value: 'PERCENTAGE',
    text: 'Percentage',
    description: '',
    descriptionLong: 'Enter a whole integer from 0-100.',
    iconPath: EIconPath.CUSTOM_FIELD_PERCENTAGE_BLUE,
  },
  {
    value: 'TIMELINE',
    text: 'Timeline',
    description: '',
    descriptionLong: 'Add a start and end date to points which can be viewed on a Timeline.',
    iconPath: EIconPath.CUSTOM_FIELD_TIMELINE_BLUE,
  },
];

export const humanizedTypeNames = {
  TEXT: 'Text',
  LIST: 'List',
  DATE: 'Date',
  COST: 'Cost',
  NUMBERS: 'Numbers',
  RICHTEXT: 'Rich Text',
  TIME: 'Time',
  PERCENTAGE: 'Percentage',
  CHECKBOX: 'Checkbox',
  TIMELINE: 'Timeline',
};

export const customFieldIconSrc = {
  TEXT: EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY,
  DATE: EIconPath.CUSTOM_FIELD_DATE_ACTIVITY,
  COST: EIconPath.CUSTOM_FIELD_COST_ACTIVITY,
  LIST: EIconPath.CUSTOM_FIELD_LIST_ACTIVITY,
  NUMBERS: EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY,
  RICHTEXT: EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY,
  PERCENTAGE: EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY,
  TIME: EIconPath.CUSTOM_FIELD_TIME_ACTIVITY,
  CHECKBOX: EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY,
  TIMELINE: EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY,
};

export const customFieldIconSrcBlue = {
  TEXT: EIconPath.CUSTOM_FIELD_TEXT_BLUE,
  DATE: EIconPath.CUSTOM_FIELD_DATE_BLUE,
  COST: EIconPath.CUSTOM_FIELD_COST_BLUE,
  LIST: EIconPath.CUSTOM_FIELD_LIST_BLUE,
  NUMBERS: EIconPath.CUSTOM_FIELD_NUMBERS_BLUE,
  RICHTEXT: EIconPath.CUSTOM_FIELD_RICH_TEXT_BLUE,
  PERCENTAGE: EIconPath.CUSTOM_FIELD_PERCENTAGE_BLUE,
  TIME: EIconPath.CUSTOM_FIELD_TIME_BLUE,
  CHECKBOX: EIconPath.CUSTOM_FIELD_CHECKBOX_BLUE,
  TIMELINE: EIconPath.CUSTOM_FIELD_TIMELINE_BLUE,
};
