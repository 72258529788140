import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { TUser } from 'src/app/project/modules/user/user.model';

import { EditNotificationsModalComponent } from 'src/app/project/modules/user/edit-notifications-modal/edit-notifications-modal.component';
import {
  notificationLabels,
  TNotificationLabels,
} from 'src/app/project/modules/user/user-settings/notification-labels';

import { DeviceService, WindowService } from '@core/services';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { RemindersService } from 'src/app/project/modules/reminders/reminders.service';
import { SharesService } from 'src/app/project/modules/share/shares.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { OfflineService } from '../../offline/offline.service';
import { SubscriptionsService, TSubscriptionsById } from '../../user/subscriptions.service';
import { UserService } from '../../user/user.service';

import { SortingService } from '@core/helpers';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { setChangedAccounts } from 'src/app/project/modules/account/account';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { TEditNotificationModalData } from 'src/app/project/modules/user/edit-notifications-modal/edit-notification-modal.model';
import { setChangedWorkspaces } from 'src/app/project/modules/workspace/workspace';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TWorkspaceSimpleResponse } from 'src/app/project/view-models/account-simple-response.model';
import { getUserLabels, TUserLabels } from '../../share/share-utils/user-labels';
import { TUpdateShareDTO } from '../../share/share.model';

@Component({
  selector: 'pp-user-settings-table-container',
  templateUrl: './settings-table-container.component.html',
  styleUrls: ['./settings-table-container.component.scss'],
})
export class UserSettingsTableContainerComponent implements OnInit, OnChanges {
  @Input() ppWorkspaces: TWorkspaceSimpleResponse[];

  private readonly destroy$ = new Subject<void>();

  offline$: Observable<boolean>;
  offline = false;

  shareOptionLabels: TUserLabels = getUserLabels();
  notificationLabels: TNotificationLabels = notificationLabels;
  subscriptionsById: TSubscriptionsById;

  isMobile = false;

  user: TUser;
  workspaces: TWorkspaceSimpleResponse[] = [];

  deletingWorkspace: {
    [key: string]: boolean;
  } = {};

  constructor(
    private store: Store<{ offline: boolean }>,
    private offlineService: OfflineService,
    private deviceService: DeviceService,
    private userService: UserService,
    private subscriptionsService: SubscriptionsService,
    private modalService: ModalService,
    private promptService: PromptService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private sortingService: SortingService,
    private sharesService: SharesService,
    private remindersService: RemindersService,
    private windowService: WindowService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit(): void {
    this.offline = this.offlineService.getOffline();
    this.isMobile = this.deviceService.isMobile();
    this.user = this.userService.getUser();
    this.subscriptionsById = this.subscriptionsService.getSubscriptionsById();

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngOnChanges(): void {
    this.workspaces = this.ppWorkspaces.sort((a, b) =>
      this.sortingService.naturalSort(a.name, b.name),
    );
  }

  editNotifications(workspace: TWorkspaceSimpleResponse): void {
    const share = GET_SHARES().find((_share) => _share.workspaceId === workspace.workspaceId);

    const data: TEditNotificationModalData = {
      workspaceId: workspace.workspaceId,
      userRef: share.userId,
      siteName: workspace.name,
      accountName: workspace.accountName,
    };

    this.modalService.setData(data);

    this.modalService.showModal(EditNotificationsModalComponent, {
      callback: () => {
        this.subscriptionsById = this.subscriptionsService.getSubscriptionsById();
      },
    });
  }

  removeWorkspace(workspaceId: string): void {
    const confirmationMessage = this.translationPipe.transform('prompt_site_deleted');

    if (!this.windowService.confirm(confirmationMessage)) {
      return;
    }

    this.deletingWorkspace[workspaceId] = true;

    this.workspaceService
      .deleteWorkspace(workspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_site_deleted');

          this.promptService.showSuccess(promptText);
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('save_changes_failed');

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.deletingWorkspace[workspaceId] = false;
        }),
      )
      .subscribe();
  }

  toggleSiteHidden(workspace: TWorkspaceSimpleResponse): void {
    workspace.hidden = !workspace.hidden;

    const shares = GET_SHARES();
    const share = shares.find((_share) => _share.workspaceId === workspace.workspaceId);

    const body: TUpdateShareDTO = {
      hidden: workspace.hidden,
    };

    this.sharesService
      .updateShare(share.shareId, body)
      .pipe(
        tap(() => {
          setChangedWorkspaces(true);
          this.remindersService.getRemindersForCurrentDay();
          setChangedAccounts(true);
        }),
      )
      .subscribe();
  }
}
