import { TCustomFieldAccessOption } from '../../share.model';

export function getWorkspaceCustomFieldsPermissions({
  workspaceCustomFields,
  permissionRead,
  permissionEdit,
}: {
  workspaceCustomFields: string[];
  permissionRead: boolean;
  permissionEdit: boolean;
}): TCustomFieldAccessOption[] {
  return workspaceCustomFields.map((customFieldId) => ({
    templateId: customFieldId,
    permission: {
      read: permissionRead,
      edit: permissionEdit,
    },
  }));
}
