<ul class="dashletSiteacc__site-ul">
  <ng-container *ngFor="let workspace of workspaceList">
    <li
      class="dashletSiteacc__site"
      [ngClass]="{
        'dashletSiteacc__site--active': workspace.workspaceId === ppSiteRange.selected
      }"
      (click)="
        updateSiteRange({
          dashlet: ppDashlet,
          id: workspace.workspaceId,
          accountId: ppAccount.accountId,
          workspaceId: workspace.workspaceId
        })
      "
      *ngIf="!workspace.blocked"
    >
      <h4 class="dashletSiteacc__site-circle">
        {{ ppWorkspaces[workspace.workspaceId]?.siteName | firstLetter }}
      </h4>

      <p class="dashletSiteacc__site-label">
        {{ ppWorkspaces[workspace.workspaceId]?.siteName }}
      </p>
    </li>

    <li class="dashletSiteacc__site--disabled" *ngIf="workspace.blocked">
      <h4 class="dashletSiteacc__site-circle">
        {{ ppWorkspaces[workspace.workspaceId]?.siteName | firstLetter }}
      </h4>

      <p class="dashletSiteacc__site-label">
        {{ ppWorkspaces[workspace.workspaceId]?.siteName }}
      </p>
    </li>
  </ng-container>
</ul>
