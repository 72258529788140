<pp-modal [ppThin]="true">
  <ng-container heading> {{ 'import_tags' | ppTranslate }} </ng-container>

  <ng-container body>
    <pp-double-select
      [ppFirstOption]="'import_cf_capital' | ppTranslate"
      [ppSecondOption]="'import_from_excel' | ppTranslate"
      (ppFirstChoice)="importFromSites()"
      (ppSecondChoice)="importFromFile()"
      [ppSelected]="fileImport ? 1 : 0"
    ></pp-double-select>

    <pp-import-tag-file
      [class.hidden]="activeStep !== ETagImportStep.FILE"
      [(ppFileLoaded)]="fileLoaded"
      [(ppFileName)]="fileName"
      (ppRemoveFile)="removeFile()"
      (ppImportFileTags)="importFileTags($event)"
      (ppOpenSpreadsheet)="openSpreadsheet($event)"
      [ppProcessing]="processingFile"
    ></pp-import-tag-file>

    <div class="siteList" [class.siteList__invisible]="activeStep !== ETagImportStep.SITES">
      <pp-site-list-select
        [ppAccounts]="accounts"
        ppType="tag"
        [ppFieldsPerAccount]="tagsPerAccount"
        [ppFieldsPerWorkspace]="tagsPerWorkspace"
        (ppAction)="openSiteTags($event)"
      ></pp-site-list-select>
    </div>

    <pp-import-tag-select
      *ngIf="tagsLoaded && activeStep === ETagImportStep.TAG_LIST"
      [(ppSelectedFileTags)]="selectedFileTags"
      [ppInitialWorkspaceTags]="initialWorkspaceTags"
      (ppBack)="back()"
      (ppDeselectAll)="deselectAll()"
      (ppSelectAll)="selectAll()"
      (ppCalculateTotalSelectedTagsNumber)="calculateTotalSelectedTagsNumber()"
      [(ppFileLoaded)]="fileLoaded"
      [ppSite]="site"
      [(ppSelectedTags)]="selectedTags"
      [(ppMergedTags)]="mergedTags"
      [ppData]="data"
      [ppFileName]="fileName"
      [(ppTagsPerAccount)]="tagsPerAccount"
      [(ppTagsPerWorkspace)]="tagsPerWorkspace"
      [ppProcessingTags]="processingTag"
    ></pp-import-tag-select>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      *ngIf="activeStep !== ETagImportStep.FILE || !fileLoaded"
      [ppConfirmText]="'import_tags' | ppTranslate"
      [ppProcess]="processingTag"
      (ppCancel)="hideModal()"
      (ppConfirm)="importTags()"
      [ppDisabled]="processingTag || (totalSelectedTags === 0 && selectedFileTags.length === 0)"
    ></pp-modal-generic-footer>

    <pp-modal-generic-footer
      *ngIf="activeStep === ETagImportStep.FILE && fileLoaded"
      [ppConfirmText]="'import_tags_from_excel' | ppTranslate"
      [ppProcess]="processingTag"
      (ppCancel)="hideModal()"
      (ppConfirm)="openFileImportList()"
      [ppDisabled]="processingImport || totalSelectedTags === 0"
      [ppConfirmTooltip]="totalSelectedTags === 0 ? ('no_tags_found' | ppTranslate) : ''"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
