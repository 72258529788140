import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointDescription } from '../../points.actions';

import { PromptService } from '../../../../components/prompt/prompt.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TRichTextUpdate } from 'src/app/project/components/input/rich-text/rich-text.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';
import { PointsUsersService } from '../../points-users.service';

@Component({
  selector: 'pp-point-description',
  templateUrl: './point-description.component.html',
  styleUrls: ['./point-description.component.scss'],
})
export class PointDescriptionComponent implements OnChanges, OnDestroy {
  @ViewChild('descriptionContainer', { static: true }) descriptionContainer: ElementRef;
  @ViewChild('description', { static: true }) descriptionElement: ElementRef;
  @Input() ppPointId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppDescription: string;
  @Input() ppDescriptionRich: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppUpdate = true;
  @Output() ppReturn? = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  processing = false;
  success = false;
  error = false;
  editing = false;
  changed = false;
  userListIds: string[];
  richTextOptions: TRichTextOptions;

  private successTimerMs = 2500;

  constructor(
    private store: Store,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private sitePointFilterService: SitePointFilterService,
    private customTableService: CustomTableService,
    private pointsUsersService: PointsUsersService,
  ) {}

  ngOnChanges(): void {
    this.setTagSelectOptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setTagSelectOptions(): void {
    this.richTextOptions = {
      showButtons: !this.ppNew,
      success: this.success,
      processing: this.processing,
      characterLimit: 10000,
    };
  }

  updateDescription(description: string, descriptionRich: string, mentions: string[]): void {
    if (this.ppNew) {
      this.updateDescriptionForNewPoint(description, descriptionRich);

      return;
    }

    const _id = this.ppPointId;

    this.processing = true;

    this.setTagSelectOptions();

    this.pointsUpdateService
      .updatePointField(_id, {
        description,
        descriptionRich,
        mentions,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_description_update');

          this.store.dispatch(
            new UpdatePointDescription({
              workspaceId: this.ppWorkspaceId,
              _id: _id,
              description,
              descriptionRich,
            }),
          );

          this.customTableService.updatePoint({
            _id: this.ppPointId,
            field: EBasicField.DESCRIPTION,
            newValue: description,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          } else {
            logErrorInSentry(error);
          }

          this.error = true;

          this.promptService.showError(promptText);

          return of(null);
        }),
        finalize(() => {
          this.processing = false;

          this.setTagSelectOptions();
        }),
      )
      .subscribe();
  }

  updateField(value: TRichTextUpdate): void {
    const newValue = {
      plainText: value.mergedText,
      richText: value.richText,
      mentions: value.mentions,
    };

    this.ppDescriptionRich = value.richText;
    this.ppDescription = value.mergedText;

    this.updateDescription(newValue.plainText, newValue.richText, newValue.mentions);
  }

  updateUserList(): void {
    this.pointsUsersService
      .fetchUsersWithAccessToPoint(this.ppPointId, EAccessField.DESCRIPTION)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userIds) => {
        this.userListIds = userIds;
      });
  }

  private updateDescriptionForNewPoint(description: string, descriptionRich: string): void {
    this.store.dispatch(
      new UpdatePointDescription({
        workspaceId: this.ppWorkspaceId,
        _id: this.ppPointId,
        description: description,
        descriptionRich: descriptionRich,
      }),
    );
  }
}
