import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-grid-tile',
  templateUrl: './grid-tile.component.html',
  styleUrls: ['./grid-tile.component.scss'],
})
export class GridTileComponent {
  @Input() ppImage: EIconPath;
  @Input() ppGridTitle = '';
  @Input() ppText = '';
  @Input() ppWide = false;
  @Input() ppDisabled = false;
  @Input() ppTitle = ''; // Workaround so it doesn't throw errors for tippy

  @Output() ppOnSelect = new EventEmitter<void>();

  EIconPath = EIconPath;

  constructor() {}

  optionSelect(): void {
    if (!this.ppDisabled) {
      this.ppOnSelect.emit();
    }
  }
}
