import { cloneDeep } from 'lodash';

import {
  CLEAR_COLUMNS,
  GET_COLUMNS,
  HIDE_COLUMN,
  MOVE_COLUMN,
  SET_COLUMNS,
  SET_COLUMN_WIDTH,
  SHOW_COLUMN,
} from '../../columns/columns.store';
import { GET_GROUPING, SET_GROUPING } from '../../columns/grouping.store';
import { SET_SORTING } from '../../columns/sorting.store';
import { SET_TABLE } from '../../table.ui.store';

import { createElement } from 'src/app/core/helpers/dom';
import { checkTableColumnsHover } from '../../../site-options/site-options';
import { getDefaultWidth } from '../../columns/columns';
import { groupTable } from '../../grouping/group';
import { sortPoints } from '../../sorting/sorting';

import { TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';
import { TImportUser, TWorkspaceUser } from '@project/view-models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { clearSelectedPoints } from 'src/app/project/modules/points/selected-points';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { PointAttachmentsService } from '../../../../points/point-modal/point-attachments/point-attachments.service';
import { SiteOptionsService } from '../../../site-options/site-options.service';
import { TColumn } from '../../columns/column.model';
import { TGroupedPoints } from '../../grouping/group-model';
import { CustomTableService } from '../custom-table.service';
import TableBody from '../table-body/table-body';
import TableFooter from '../table-footer/table-footer';
import TableHead from '../table-head/table-head';
import { TTableItem, TTableParams } from '../table.model';
import { findPointIndexInTable } from '../utils/find-point-index-in-table';
import { digestColumnPreferences } from './utils/digest-column-preferences';
import { generateGroupedPointItems } from './utils/generate-grouped-points';
import { generateSelectedGroupedPointItems } from './utils/generate-selected-grouped-points';
import { getExtraTableWidth } from './utils/get-extra-table-width';
import { getSelectElementWidth } from './utils/select-element-width';

export default class Table {
  workspaceId: string;
  selectPointCallback: TAnyFunction;
  selectAllPointsCallback: TAnyFunction;
  openPointCallback: TAnyFunction;
  savePreferencesCallback: TAnyFunction;
  highlightPinCallback: TAnyFunction;
  dehighlightPinCallback: TAnyFunction;

  visibleColumnIndexes: number[] = [];
  visibleColumnPositions: number[] = [];
  users: TImportUser[] = [];
  points: TPoint[] = [];
  items: TTableItem[] = [];
  width = 0;
  selectedPoints: Set<string> = new Set();
  keyword = '';
  groupedPoints: TGroupedPoints[];
  activePointId: string;
  siteOptionsService: SiteOptionsService;

  tableHead: TableHead = null;
  tableBody: TableBody = null;
  tableFooter: TableFooter = null;

  element: HTMLElement = null;

  private getCustomFieldsCallback: () => Observable<TCustomField[]>;
  private pointAttachmentsService: PointAttachmentsService;
  private visibleColumnsSet: Set<string> = new Set();
  private hiddenColumnsSet: Set<string> = new Set();
  overview: boolean;
  visibleColumns: TColumn[] = [];
  private dropdownService: DropdownService;
  defaultColumnsLoaded: boolean;
  private apiService: ApiService;
  private customTableService: CustomTableService;

  constructor(
    overview: boolean,
    {
      workspaceId: workspaceId = null,
      selectPointCallback = null,
      selectAllPointsCallback = null,
      openPointCallback = null,
      savePreferencesCallback = null,
      highlightPinCallback = null,
      dehighlightPinCallback = null,
      getCustomFieldsCallback = null,
      dropdownService = null,
      apiService = null,
      pointAttachmentsService = null,
      siteOptionsService = null,
      activePointId = null,
      customTableService = null,
    }: TTableParams,
  ) {
    this.overview = overview;
    this.workspaceId = workspaceId;
    this.selectPointCallback = selectPointCallback;
    this.selectAllPointsCallback = selectAllPointsCallback;
    this.openPointCallback = openPointCallback;
    this.savePreferencesCallback = savePreferencesCallback;
    this.highlightPinCallback = highlightPinCallback;
    this.dehighlightPinCallback = dehighlightPinCallback;
    this.getCustomFieldsCallback = getCustomFieldsCallback;
    this.pointAttachmentsService = pointAttachmentsService;
    this.activePointId = activePointId;
    this.dropdownService = dropdownService;
    this.siteOptionsService = siteOptionsService;
    this.apiService = apiService;
    this.customTableService = customTableService;

    SET_TABLE(this);
    CLEAR_COLUMNS();

    this.element = this.create();

    this.tableHead = new TableHead(this);
    this.tableBody = new TableBody(
      this,
      this.pointAttachmentsService,
      this.dropdownService,
      this.apiService,
    );

    this.tableFooter = new TableFooter(this);
  }

  private create(): HTMLElement {
    return createElement('div', {
      attrs: {
        style: {
          height: '100%',
        },
      },
    });
  }

  load(keepScrollPosition: boolean): void {
    const scrollPosition = keepScrollPosition
      ? this.tableBody.virtualScroller.scrollElement.scrollLeft
      : 0;

    this.tableHead.updateFrozenColumnWidth();
    this.tableBody.updateFrozenColumnWidth();
    this.tableFooter.updateFrozenColumnWidth();

    this.tableHead.load(keepScrollPosition);
    this.tableBody.load(keepScrollPosition);
    this.tableFooter.load(keepScrollPosition);
    this.tableHead.scrollLeft(scrollPosition);
    this.tableFooter.scrollLeft(scrollPosition);
  }

  clear(): void {
    CLEAR_COLUMNS();
    this.updateWidth(0);
    this.tableHead.clear();
    this.tableBody.clear();
    this.tableFooter.clear();
  }

  updateWidth(width?: number): void {
    if (width === undefined && GET_COLUMNS().length) {
      const grouping = GET_GROUPING();
      const selectWidth = grouping.length ? getSelectElementWidth(grouping.length) : 40;

      this.width =
        selectWidth +
        (GET_COLUMNS() as any).reduce((columnA, columnB) => {
          let aWidth = 0;
          let bWidth = 0;

          if (typeof columnA === 'number') {
            aWidth = columnA;
          } else if (!columnA.hidden) {
            aWidth = columnA.width;
          }

          if (!columnB.hidden) {
            bWidth = columnB.width;
          }

          return aWidth + bWidth;
        });
    } else {
      this.width = width;
    }
  }

  sortTable({
    updatePreferences = false,
    keepScrollPosition = true,
  }: {
    updatePreferences?: boolean;
    keepScrollPosition?: boolean;
  } = {}): void {
    const headScroll = this.tableHead.element.scrollLeft;

    this.digestColumnPreferences();
    this.sortPoints();
    this.generateItems();
    this.tableBody.virtualScroller.load(this.items, keepScrollPosition);

    if (updatePreferences) {
      this.savePreferencesCallback();
    }

    this.tableHead.scrollLeft(headScroll);
  }

  updateColumns({
    columns,
    updatePreferences = true,
    reset = false,
  }: { columns?: TColumn[]; updatePreferences?: boolean; reset?: boolean } = {}): void {
    this.visibleColumns.length = 0;

    if (columns === undefined || reset) {
      this.generateColumns();
    } else {
      this.processExistingColumns(columns, updatePreferences);
    }

    this.digestColumnPreferences();
    this.tableBody.updateColumns();
  }

  toggleColumn(column: TColumn): void {
    const columnIndex = GET_COLUMNS().findIndex(
      (searchedColumn) => searchedColumn.name === column.name,
    );

    if (!column.hidden) {
      HIDE_COLUMN(columnIndex);
    } else {
      SHOW_COLUMN(columnIndex);
    }

    this.refreshColumns();
  }

  moveColumn(columnIndex: number, destinationIndex: number): void {
    const column = GET_COLUMNS()[columnIndex];

    if (!column.hidden) {
      this.tableHead.moveColumn(columnIndex, destinationIndex);
      this.tableBody.moveColumn(columnIndex, destinationIndex);
      this.tableFooter.moveColumn(columnIndex, destinationIndex);
    }

    MOVE_COLUMN(columnIndex, destinationIndex);
    this.refreshColumns();
  }

  updateVisibleColumnIndexes(): void {
    this.visibleColumnIndexes.length = 0;
    this.visibleColumnIndexes = GET_COLUMNS()
      .filter((column) => !column.hidden)
      .map((column) => column.index);

    checkTableColumnsHover();
  }

  updateVisibleColumnPositions(): void {
    const columns = GET_COLUMNS();
    this.visibleColumnPositions.length = 0;

    this.visibleColumnIndexes.forEach((columnIndex, index) => {
      if (index === 0) {
        if (this.visibleColumnIndexes.length > 1) {
          this.visibleColumnPositions[index + 1] = columns[columnIndex].width;
        }

        this.visibleColumnPositions[index] = 0;
      } else if (this.visibleColumnIndexes.length > index + 1) {
        if (this.visibleColumnIndexes.length > index + 1 && columns[columnIndex]) {
          this.visibleColumnPositions[index + 1] =
            this.visibleColumnPositions[index] + columns[columnIndex].width;
        }
      }
    });
  }

  updatePoints(points: TPoint[]): void {
    this.points = cloneDeep(points);

    this.generateItems();
  }

  updatePointAttachments(pointId: string, newPoint: TPoint): void {
    const point = this.points.find((searchedPoint: TPoint) => searchedPoint._id === pointId);

    if (newPoint && point) {
      point.containsAttachments = newPoint.containsAttachments;
    }
  }

  highlightPoint(sequenceNumber: number): void {
    this.tableBody.highlightPoint(sequenceNumber);
  }

  dehighlightPoint(sequenceNumber: number): void {
    this.tableBody.dehighlightPoint(sequenceNumber);
  }

  deselectPoints(): void {
    clearSelectedPoints();
    this.tableBody.deselectPoints();
    this.tableHead.deselectPoints();

    this.sortTable();
  }

  addPoint(point: TPoint): void {
    this.points.push(point);
    this.sortTable();
  }

  activatePoint(pointSequenceNumber: string | number): void {
    const attachmentUploading = this.pointAttachmentsService.getAttachmentUploading();

    if (!attachmentUploading) {
      this.deactivatePoint();

      const pointIndexInTable = findPointIndexInTable(this.points, this.items, pointSequenceNumber);
      const pointElement = this.tableBody.virtualScroller.dataElements[pointIndexInTable];

      if (pointElement) {
        pointElement.classList.add('table__row--active');
      }
    }
  }

  sortPoints(): void {
    this.points.sort(sortPoints());

    const sortButton = this.siteOptionsService.getSortButton();

    if (sortButton) {
      // remove when sort button is available for all users
      sortButton.update({ updateTable: false });
    }
  }

  // Cells

  loadAvatars(users: TImportUser[] | TWorkspaceUser[]): void {
    this.users = users as TImportUser[];

    this.tableBody.load(true);
  }

  setKeyword(keyword: string): void {
    this.keyword = keyword;
  }

  setWorkspaceId(workspaceId: string): void {
    this.workspaceId = workspaceId;
  }

  setActivePointId(pointId: string): void {
    this.activePointId = pointId;

    this.activatePointFromId(this.activePointId);
  }

  generateColumns(): void {
    this.defaultColumnsLoaded = true;

    this.getCustomFieldsCallback()
      .pipe(
        tap((customFields) => {
          this.customTableService.checkColumns(customFields);
          this.updateVisibleColumnIndexes();
          this.updateVisibleColumnPositions();
          this.load(true);
        }),
      )
      .subscribe();
  }

  checkColumnVisibility(): { columnsToHide: TColumn[]; columnsToShow: TColumn[] } {
    const columnsToHide = [];
    const columnsToShow = [];

    GET_COLUMNS().forEach((column) => {
      if (column.hidden && this.visibleColumnsSet.has(column.name)) {
        this.visibleColumnsSet.delete(column.name);
        this.hiddenColumnsSet.add(column.name);
        columnsToHide.push(column);
      } else if (!column.hidden && this.hiddenColumnsSet.has(column.name)) {
        this.hiddenColumnsSet.delete(column.name);
        this.visibleColumnsSet.add(column.name);
        columnsToShow.push(column);
      }
    });

    return {
      columnsToHide,
      columnsToShow,
    };
  }

  private refreshColumns(): void {
    this.updateVisibleColumnIndexes();
    this.updateVisibleColumnPositions();
    this.tableBody.virtualScroller.disableSmoothScrolling();
    this.updateWidth();
    this.load(true);
    this.tableBody.virtualScroller.enableSmoothScrolling();
  }

  private digestColumnPreferences(): void {
    const { groupingColumns, sortedColumns } = digestColumnPreferences();

    SET_GROUPING(groupingColumns.sort((a, b) => a.groupIndex - b.groupIndex));
    SET_SORTING(sortedColumns);

    // get items

    this.generateItems();

    this.tableHead.updateFrozenColumnWidth();
    this.tableBody.updateFrozenColumnWidth();
    this.tableFooter.updateFrozenColumnWidth();
  }

  private activatePointFromId(pointId: string): void {
    const point = this.points.find((searchedPoint) => searchedPoint._id === pointId);

    this.deactivatePoint();

    if (point) {
      this.activatePoint(point.sequenceNumber);
    }
  }

  private deactivatePoint(): void {
    this.tableBody.virtualScroller.dataElements.forEach((element) => {
      if (element?.classList) {
        element.classList.remove('table__row--active');
      }
    });
  }

  private generateItems(): void {
    const grouping = GET_GROUPING();

    this.items.length = 0;

    if (grouping.length) {
      this.generateGroupedPoints();
    } else {
      this.generateUngroupedPoints();
    }

    this.updateWidth();
  }

  private processExistingColumns(columns: TColumn[], updatePreferences: boolean = true): void {
    columns.forEach((column, index) => {
      if (!column.hasOwnProperty('width')) {
        SET_COLUMN_WIDTH(index, getDefaultWidth(column.name));
      }

      if (column.hidden) {
        this.hiddenColumnsSet.add(column.name);
      } else {
        this.visibleColumnsSet.add(column.name);
        this.visibleColumns.push(column);
      }
    });

    SET_COLUMNS(columns);
    this.defaultColumnsLoaded = false;
    this.updateVisibleColumnIndexes();
    this.updateVisibleColumnPositions();

    this.getCustomFieldsCallback()
      .pipe(
        tap((customFields) => {
          SET_COLUMNS(columns);
          this.defaultColumnsLoaded = false;

          this.customTableService.checkColumns(customFields, updatePreferences);
          this.updateVisibleColumnIndexes();
          this.load(true);
        }),
      )
      .subscribe();
  }

  private generateUngroupedPoints(): void {
    this.tableBody.virtualScroller.updateExtraWidth(0);
    this.tableBody.virtualScroller.updateFrozenColumnWidth(getExtraTableWidth());
    this.element.classList.remove('table__body--grouped');

    this.items = this.points.map((point, index) => ({
      type: ERowType.POINT,
      index: index,
      pointId: point._id,
    }));
  }

  private generateGroupedPoints(): void {
    this.groupedPoints = groupTable(this.points);

    this.tableBody.virtualScroller.updateExtraWidth(getExtraTableWidth());
    this.tableBody.virtualScroller.updateFrozenColumnWidth(46);
    this.element.classList.add('table__body--grouped');

    this.items = generateGroupedPointItems(this.groupedPoints);
  }

  getSelectedItems(): TTableItem[] {
    const grouping = GET_GROUPING();
    const groupingEnabled = grouping.length > 0;

    return groupingEnabled
      ? generateSelectedGroupedPointItems(this.groupedPoints, this.selectedPoints)
      : this.items.filter((item) => this.selectedPoints.has(item.pointId));
  }
}
