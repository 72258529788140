import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAccount } from 'src/app/project/modules/account/account.model';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { TagsService } from '../tags.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointDocumentsService } from 'src/app/project/modules/points/point-modal/point-attachments/point-documents/point-documents.service';
import { ModalService, Modal } from 'src/app/project/components/modal/modal.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { ImportTagService } from './import-tag.service';
import { sendImportEvent } from './utils/send-import-event';
import { onFileReaderLoad } from './utils/on-file-reader-load';
import { ImportTagModalService } from './import-tag-modal.service';
import { ETagImportStep } from './tag-import-active-step.enum';
import { SortingService } from '@core/helpers';
import { EStore } from '../../../shared/enums/store.enum';
import { TAddCustomFieldModalData } from '../../custom-fields/add-custom-field-modal/add-custom-field.model';

@Component({
  selector: 'pp-import-tag-modal',
  templateUrl: './import-tag-modal.component.html',
  styleUrls: ['./import-tag-modal.component.scss'],
})
export class ImportTagModalComponent implements OnInit, OnDestroy {
  private modal: Modal<TAddCustomFieldModalData>;
  private workspaces: TWorkspacesById;
  accounts: TAccount[];

  data: string[] = [];
  selectedTags: {
    [accountId: string]: {
      [workspaceId: string]: string[];
    };
  } = {};
  site: TWorkspace;
  private activeWorkspaceId = '';
  totalSelectedTags = 0;
  ETagImportStep = ETagImportStep;

  processingImport = false;
  fileImport = false;
  tagsLoaded = false;
  fileLoaded = false;
  processingTag = false;
  mergedTags: string[] = [];
  processingFile = false;

  activeStep: ETagImportStep = ETagImportStep.SITES;
  fileName = '';
  initialWorkspaceTags: string[] = [];
  tagsPerAccount: {
    [accountId: string]: number;
  } = {};
  selectedFileTags: string[] = [];

  tagsPerWorkspace: {
    [workspaceId: string]: number;
  } = {};

  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;
  private accounts$: Observable<TAccount[]>;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
    }>,
    private activeService: ActiveService,
    private tagsService: TagsService,
    private promptService: PromptService,
    private pointDocumentsService: PointDocumentsService,
    private modalService: ModalService,
    private sortingService: SortingService,
    private translationPipe: TranslationPipe,
    private importTagService: ImportTagService,
    private importTagModalService: ImportTagModalService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.activeWorkspaceId = this.modal.data.workspaceId;

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.activeWorkspaceId = this.activeService.getActiveWorkspaceId();

      if (this.activeWorkspaceId) {
        this.workspaces = workspaces;
        this.initialWorkspaceTags = this.workspaces[this.activeWorkspaceId].tags;
      }
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;

      this.accounts.forEach((account) => {
        this.selectedTags[account.accountId] = {};

        account.workspaces.forEach((workspace) => {
          this.selectedTags[account.accountId][workspace] = [];
        });
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  importFileTags(files: File[]): void {
    this.fileImport = true;
    this.processingFile = true;

    this.openSpreadsheet(files[0]);
  }

  openSpreadsheet(file: File): void {
    const isSpreadSheet = this.pointDocumentsService.isSpreadSheet(file);

    if (!isSpreadSheet) {
      const prompt = this.translationPipe.transform('prompt_file_spreadsheet_invalid');

      this.promptService.showWarning(prompt);

      return;
    }

    const fileReader = new FileReader();

    this.processingImport = true;

    try {
      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        const spreadData = onFileReaderLoad(event);

        this.fileName = file.name;
        this.processingImport = false;
        this.tagsLoaded = true;
        this.fileLoaded = true;
        this.processingFile = false;

        this.data = this.importTagModalService.setSpreadsheetTags(
          spreadData,
          this.initialWorkspaceTags,
        );
        this.selectAll();
      };

      fileReader.readAsBinaryString(file);
    } catch (error) {
      const prompt = this.translationPipe.transform('prompt_file_spreadsheet_invalid_error');

      this.processingImport = false;
      this.processingFile = false;

      this.promptService.showError(prompt);
      logErrorInSentry(error);
    }
  }

  selectAll(): void {
    if (this.processingTag) {
      return;
    }

    if (this.site) {
      const selectedTags = [];
      let numberOfSelectedAccountTags = 0;

      this.data.forEach((tag) => {
        if (
          !this.initialWorkspaceTags.includes(tag) &&
          !(
            this.mergedTags.includes(tag) &&
            !this.selectedTags[this.site.accountId][this.site.workspaceId].includes(tag)
          )
        ) {
          selectedTags.push(tag);
        }
      });

      this.tagsPerWorkspace[this.site.workspaceId] = selectedTags.length;
      this.selectedTags[this.site.accountId][this.site.workspaceId] = selectedTags;

      Object.keys(this.selectedTags[this.site.accountId]).forEach((workspaceId) => {
        numberOfSelectedAccountTags += this.selectedTags[this.site.accountId][workspaceId].length;
      });

      this.tagsPerAccount[this.site.accountId] = numberOfSelectedAccountTags;
    } else {
      this.selectedFileTags = [];

      this.data.forEach((tag) => {
        if (!this.initialWorkspaceTags.includes(tag)) {
          this.selectedFileTags.push(tag);
        }
      });
    }

    this.calculateTotalSelectedTagsNumber();
  }

  deselectAll(): void {
    if (this.processingTag) {
      return;
    }

    if (this.site) {
      let numberOfSelectedAccountTags = 0;

      this.selectedTags[this.site.accountId][this.site.workspaceId] = [];

      Object.keys(this.selectedTags[this.site.accountId]).forEach((workspaceId) => {
        numberOfSelectedAccountTags += this.selectedTags[this.site.accountId][workspaceId].length;
      });

      this.tagsPerAccount[this.site.accountId] = numberOfSelectedAccountTags;
      this.tagsPerWorkspace[this.site.workspaceId] = 0;
    } else {
      this.selectedFileTags = [];
    }

    this.calculateTotalSelectedTagsNumber();
  }

  importFromSites(): void {
    if (this.fileImport) {
      this.fileImport = false;
      this.activeStep = ETagImportStep.SITES;
      this.tagsLoaded = false;
      this.fileLoaded = false;
      this.site = null;
      this.selectedFileTags = [];

      this.calculateTotalSelectedTagsNumber();
    }
  }

  importFromFile(): void {
    if (!this.fileImport) {
      this.fileImport = true;
      this.activeStep = ETagImportStep.FILE;
      this.tagsLoaded = false;
      this.site = null;
    }

    this.calculateTotalSelectedTagsNumber();
  }

  openFileImportList(): void {
    this.activeStep = ETagImportStep.TAG_LIST;
  }

  back(): void {
    if (this.fileImport) {
      this.activeStep = ETagImportStep.FILE;
    } else {
      this.activeStep = ETagImportStep.SITES;
    }

    this.calculateTotalSelectedTagsNumber();
  }

  removeFile(): void {
    this.fileLoaded = false;
    this.fileName = '';
  }

  openSiteTags(workspaceId: string): void {
    if (
      workspaceId === this.activeWorkspaceId ||
      this.workspaces[workspaceId]?.tags?.length === 0
    ) {
      return;
    }

    this.data = [...this.workspaces[workspaceId].tags];
    this.activeStep = ETagImportStep.TAG_LIST;
    this.tagsLoaded = true;
    this.site = this.workspaces[workspaceId];
    const selectableTags = [];
    this.data.sort((a, b) => this.sortingService.naturalSort(a, b));

    this.data.forEach((tag) => {
      if (
        !this.initialWorkspaceTags.includes(tag) &&
        !(
          this.mergedTags.includes(tag) &&
          !this.selectedTags[this.site.accountId][this.site.workspaceId].includes(tag)
        )
      ) {
        selectableTags.push(tag);
      }
    });

    this.importTagModalService.setSelectableTags(selectableTags);
    this.calculateTotalSelectedTagsNumber();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  importTags(): void {
    if (!this.processingTag) {
      this.processingTag = true;

      this.importTagService
        .importTags({
          fileLoaded: this.fileLoaded,
          selectedFileTags: this.selectedFileTags,
          selectedTags: this.selectedTags,
          activeWorkspaceId: this.activeWorkspaceId,
        })
        .pipe(
          takeUntil(this.destroy$),
          tap((newTags) => {
            this.tagsService.addTags(newTags);

            sendImportEvent(this.fileImport);

            this.hideModal();
          }),
          finalize(() => {
            this.processingTag = false;
          }),
        )
        .subscribe();
    }
  }

  calculateTotalSelectedTagsNumber(): void {
    const result = this.importTagService.calculateTotalSelectedTagsNumber(
      this.activeStep,
      this.selectedFileTags,
      this.selectedTags,
      this.tagsPerAccount,
    );

    this.mergedTags = result.mergedTags;
    this.totalSelectedTags = result.totalSelectedTags;
  }
}
