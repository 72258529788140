export function getTargetId(workspaceId, accountId) {
  let targetId = '';

  if (accountId && workspaceId) {
    targetId = `&workspaceId=${workspaceId}`;
  } else if (accountId) {
    targetId = `&accountId=${accountId}`;
  }

  return targetId;
}
