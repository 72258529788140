import dayjs from 'dayjs';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';
import { TCustomFieldFilter } from '../site-filter.model';

export function filterTimelineCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TCustomFieldResponse,
): boolean {
  if (filterField.appliedValues?.startDate || filterField.appliedValues?.endDate) {
    const [pointStartDate, pointEndDate] = pointCustomField.value
      ? pointCustomField.value.split('~')
      : [null, null];

    if (filterField.appliedValues.startDate && filterField.appliedValues.endDate) {
      const endOfDay = dayjs(filterField.appliedValues.endDate).endOf('day');

      if (pointStartDate >= filterField.appliedValues.startDate && pointEndDate <= endOfDay) {
        return true;
      }
    } else if (filterField.appliedValues.startDate && !filterField.appliedValues.endDate) {
      if (parseInt(pointStartDate, 10) >= filterField.appliedValues.startDate) {
        return true;
      }
    } else if (!filterField.appliedValues.startDate && filterField.appliedValues.endDate) {
      const endOfDay = dayjs(filterField.appliedValues.endDate).endOf('day');

      if (pointEndDate !== null && pointEndDate <= endOfDay) {
        return true;
      }
    }
  } else {
    return true;
  }

  return false;
}
