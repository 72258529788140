<header class="dashletHead dashletHead__front">
  <div
    class="dashletHead__titlebar--draggable"
    [class.dashletHead__titlebar--draggableDisabled]="offline"
  >
    <div class="dashletHead__acc-site" *ngIf="ppDashlet.selectedRange">
      <p class="dashletHead__account">
        {{ ppDashlet.selectedRange.accountId ? ppAccountName : 'All accounts' }}
      </p>

      <h5 class="dashletHead__site">
        {{ ppDashlet.selectedRange.workspaceId ? ppSiteName : 'All sites' }}
      </h5>
    </div>

    <div class="dashletHead__title-front">
      {{ ppDashlet.name === 'ACTIVITIES' ? 'Activity' : '' }}
      {{ ppDashlet.name === 'CURRENT_STATUS' ? 'Live data: Status' : '' }}
      {{ ppDashlet.name === 'CURRENT_PRIORITY' ? 'Live data: Priority' : '' }}
      {{ ppDashlet.name === 'OVER_TIME_STATUS' ? 'Status Over Time' : '' }}
      {{ ppDashlet.name === 'OVER_TIME_PRIORITY' ? 'Priority Over Time' : '' }}
    </div>

    <button
      data-m-target="Dashlet settings button"
      class="dashletHead__button"
      [class.dashletHead__button--disabled]="offline"
      (click)="flipDashlet()"
    >
      <img class="dashletHead__image" [src]="EIconPath.INPUT_COG_GRAY" />
    </button>
  </div>
</header>

<header class="dashletHead dashletHead__back" data-m-target="Dashlet settings back button">
  <button class="dashletHead__button dashletHead__button--back" (click)="ppToggleFlip.emit()">
    <i class="fas fa-chevron-left" aria-hidden="true"></i>
    Back
  </button>

  <h5 class="dashletHead__title">Dashlet settings</h5>
</header>
