import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { UploadService } from 'src/app/project/components/input/upload/upload.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { translate } from 'src/app/project/features/translate/translate';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EUserType } from 'src/app/project/modules/users/user-types-enum';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { TUserResponse } from '../response-models/user-response-model';
import { SetAvatarId } from '../user.actions';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private userTypes: TSelectOption[] = [
    {
      label: translate('tester'),
      value: EUserType.TESTER,
    },
    {
      label: translate('developer'),
      value: EUserType.DEVELOPER,
    },
    {
      label: translate('customer'),
      value: EUserType.CUSTOMER,
    },
    {
      label: translate('unset'),
      value: EUserType.UNSET,
    },
  ];

  constructor(
    private store: Store,
    private preferencesService: PreferencesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private userService: UserService,
    private uploadService: UploadService,
  ) {}

  getUserTypes(): TSelectOption[] {
    return this.userTypes;
  }

  saveDatePreferences(selectedDateFormat: EDateFormat): Observable<TPreferences> {
    const body: TPreferences = {
      dateFormat: selectedDateFormat,
    };

    return this.preferencesService.updatePreferences(body).pipe(
      tap(() => {
        logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__DATE_FORMAT, {
          event_category: EGoogleEventCategory.SETTINGS,
          event_value: selectedDateFormat,
        });

        const promptText = this.translationPipe.transform(
          'prompt_date_displayed_format_change_success',
        );

        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        const promptText = this.translationPipe.transform('save_changes_failed');

        this.promptService.showError(promptText);

        throw error;
      }),
    );
  }

  updateUserType(userId: string, userTypeIndex: number): Observable<TUserResponse> {
    return this.userService.updateUserType(userId, this.userTypes[userTypeIndex].value).pipe(
      tap(() => {
        const promptText = this.translationPipe.transform('prompt_user_type_change_success');

        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        const promptText = this.translationPipe.transform('save_changes_failed');
        this.promptService.showError(promptText);

        throw error;
      }),
    );
  }

  deleteCustomAvatar(userId: string): Observable<TUserResponse> {
    return this.userService.deleteUserAvatar(userId).pipe(
      tap((response) => {
        const promptText = this.translationPipe.transform('prompt_avatar_delete');

        this.store.dispatch(new SetAvatarId(response.images[0].id));

        logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__AVATAR_DELETE, {
          event_category: EGoogleEventCategory.PROFILE_SETTINGS,
        });

        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        const promptText = this.translationPipe.transform('prompt_avatar_delete_error');

        this.promptService.showError(promptText);

        throw error;
      }),
    );
  }

  updateZoomFactor(zoomFactor: number): Observable<TPreferences> {
    const body: TPreferences = {
      mapZoomFactor: zoomFactor,
    };

    return this.preferencesService.updatePreferences(body).pipe(
      tap(() => {
        logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__ZOOM_SPEED, {
          event_category: EGoogleEventCategory.PROFILE_SETTINGS,
        });

        const promptText = this.translationPipe.transform('prompt_site_plan_zoom_update');

        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        const promptText = this.translationPipe.transform('save_changes_failed');

        this.promptService.showError(promptText);

        throw error;
      }),
    );
  }

  uploadUserImage(file: File[], userId: string): Observable<TUserResponse> {
    logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__AVATAR, {
      event_category: EGoogleEventCategory.PROFILE_SETTINGS,
    });

    return this.uploadService.addImagesToDoc('User', userId, file).pipe(
      tap((response) => {
        const promptText = this.translationPipe.transform('prompt_avatar_update');

        this.store.dispatch(new SetAvatarId(response.images[0].id));
        this.promptService.showSuccess(promptText);
      }),
      catchError((error) => {
        const promptText = this.translationPipe.transform('prompt_avatar_update_error');

        this.promptService.showError(promptText);

        throw error;
      }),
    );
  }
}
