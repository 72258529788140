import { cloneDeep } from 'lodash';
import { UpdatePointCustomField } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointCustomFields(
  oldState: TPointsByWorkspace,
  action: UpdatePointCustomField,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === action.payload._id,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();
      point.customFieldsMap[action.payload.customFieldId].value = action.payload.customFieldValue;

      if (action.payload.customFieldValueId) {
        point.customFieldsMap[action.payload.customFieldId].idOfChosenElement =
          action.payload.customFieldValueId;
      }

      if (action.payload.plainText) {
        point.customFieldsMap[action.payload.customFieldId].plainText = action.payload.plainText;
      }

      if (action.payload.mentions) {
        point.customFieldsMap[action.payload.customFieldId].mentions = action.payload.mentions;
      }

      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  }

  return state;
}
