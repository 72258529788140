import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { UpdatePointsPriorities } from '../../points.actions';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { ActiveService } from '../../../../services/active/active.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointsService } from '../../points.service';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';
import { EPriority } from '../../priorities';

type TPriority = {
  value: EPriority;
  label: string;
  color: string;
};

@Component({
  selector: 'app-point-bulk-priority-modal',
  templateUrl: './point-bulk-priority-modal.component.html',
  styleUrls: ['./point-bulk-priority-modal.component.scss'],
})
export class PointBulkPriorityModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspace = this.workspaceService.findWorkspace(this.activeService.getActiveWorkspaceId());

  processing: boolean;
  selectedPriority: EPriority;
  priorities: TPriority[];

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private activeService: ActiveService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private sitePointFilterService: SitePointFilterService,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit() {
    this.processing = false;

    this.priorities = [
      {
        value: EPriority.HIGH,
        label: this.translationPipe.transform('high'),
        color: 'red',
      },
      {
        value: EPriority.MEDIUM,
        label: this.translationPipe.transform('medium'),
        color: 'yellow',
      },
      {
        value: EPriority.LOW,
        label: this.translationPipe.transform('low'),
        color: 'blue',
      },
    ];

    this.selectedPriority = null;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  save(): void {
    this.processing = true;
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = pointIds.length;

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EDIT, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'PRIORITY',
      amount: numberOfSelectedPoints,
    });

    this.pointsUpdateService
      .updatePointsPriority(pointIds, this.selectedPriority)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const priority: EPriority = this.selectedPriority;
          const promptText = this.translationPipe.transform('prompt_points_update_success');

          this.store.dispatch(
            new UpdatePointsPriorities({
              workspaceId: this.workspace.workspaceId,
              points: pointIds,
              priority,
            }),
          );

          pointIds.forEach((_id) => {
            this.customTableService.updatePoint({
              _id: _id,
              field: EBasicField.PRIORITY,
              newValue: priority,
              updatedDate: new Date().getTime(),
            });
          });

          this.updateSitePlan();
          this.promptService.showSuccess(promptText);
          this.sitePointFilterService.filterPoints(true);
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  updateSitePlan(): void {
    this.sitePointFilterService.filterPoints();
  }

  togglePriority(priority: TPriority): void {
    this.selectedPriority = priority.value;
  }
}
