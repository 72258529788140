import { Injectable } from '@angular/core';
import {
  API_V1_point,
  API_V4_points_workspace,
  API_overview_points_pagination,
  API_point,
  API_point_additional_details,
  API_point_simple_update,
  API_points_bulk,
  API_points_bulk_simple_update,
  API_points_create,
  API_points_selected,
  API_points_transfer_check,
  API_points_transfer_copy,
  API_points_transfer_move,
  API_points_transfer_size,
  API_points_users_with_access,
} from '@core/api/paths';
import { TGuid } from '@core/helpers';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TStatusFilterRequest } from 'src/app/project/modules/filters/utils/get-statuses-to-filter';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { TCheckTransferResponse } from 'src/app/project/view-models/check-transfer-response.model';
import { TPointsSizeResponse } from 'src/app/project/view-models/points-size-response.model';
import { TPointResponse, TPointUpdate } from '../../../view-models/point-response.model';
import { TPointDetails } from '../../../view-models/points-details-response.model';
import { TPointPaginationResponse } from '../../../view-models/points-pagination-response.model';
import {
  TCheckTargetSiteRequest,
  TNewPoint,
  TPointSizesRequest,
  TUpdatePointsRequest,
} from './points-requests.model';

@Injectable({
  providedIn: 'root',
})
export class PointsApiProviderService {
  constructor(private apiService: ApiService) {}

  deletePoints(body: string[]): Observable<null> {
    const url = API_points_bulk();

    return this.apiService.delete<null>(url, body); // FIXME Add type when this request doesn't fail
  }

  checkTargetSiteForTransfer(body: TCheckTargetSiteRequest): Observable<TCheckTransferResponse> {
    const url = API_points_transfer_check();

    return this.apiService.post<TCheckTransferResponse>(url, body);
  }

  fetchPointDetails(_id: string): Observable<TPointDetails> {
    const url = API_point_additional_details(_id);

    return this.apiService.get<TPointDetails>(url);
  }

  fetchUsersWithAccessToPoint(
    pointId: TGuid,
    field: EAccessField,
    customFieldId?: TGuid,
  ): Observable<any> {
    return this.apiService.get<string>(API_points_users_with_access(pointId, field, customFieldId));
  }

  movePoints(body: TPointSizesRequest): Observable<string> {
    const url = API_points_transfer_move();

    return this.apiService.post<string>(url, body);
  }

  copyPoints(body: TPointSizesRequest): Observable<string> {
    const url = API_points_transfer_copy();

    return this.apiService.post<string>(url, body);
  }

  deletePoint(_id: string): Observable<null> {
    const url = API_V1_point(_id);

    return this.apiService.delete(url);
  }

  updatePoints(body: TUpdatePointsRequest): Observable<null> {
    const url = API_points_bulk_simple_update();

    return this.apiService.put<null>(url, body);
  }

  updatePointField(_id: string, body: TPointUpdate): Observable<TPointResponse> {
    const url = API_point_simple_update(_id);

    return this.apiService.put<TPointResponse>(url, body);
  }

  fetchPointSizes(body: TPointSizesRequest): Observable<TPointsSizeResponse> {
    const url = API_points_transfer_size();

    return this.apiService.post<TPointsSizeResponse>(url, body);
  }

  createPoint(workspaceId: string, body: TNewPoint): Observable<TPointResponse> {
    const url = API_points_create(workspaceId);

    return this.apiService.post<TPointResponse>(url, body);
  }

  fetchPoint(_id: string): Observable<TPointResponse> {
    const url = API_point(_id);

    return this.apiService.get<TPointResponse>(url);
  }

  fetchPoints(workspaceId: string, pageNumber: string): Observable<TPointPaginationResponse> {
    const url = API_V4_points_workspace(workspaceId, pageNumber);

    return this.apiService.get<TPointPaginationResponse>(url);
  }

  fetchPointsById(pointList: string[]): Observable<TPointResponse[]> {
    const url = API_points_selected();

    return this.apiService.post<TPointResponse[]>(url, pointList);
  }

  fetchAllPointsPagination({
    pageNumber,
    accounts,
    customFields,
    statuses,
  }: {
    pageNumber: string;
    accounts: string[];
    customFields: string[];
    statuses: TStatusFilterRequest[];
  }): Observable<TPointPaginationResponse> {
    let url = API_overview_points_pagination(pageNumber, statuses);

    if (accounts.length > 0) {
      url += `&accountId=${accounts.join(',')}`;
    }

    if (customFields.length > 0) {
      url += `&cfTemplateId=${customFields.join(',')}`;
    }

    return this.apiService.get<TPointPaginationResponse>(url);
  }
}
