import { Component, OnInit } from '@angular/core';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { GET_SECONDARY_DROPDOWN_SERVICE } from 'src/app/project/components/dropdown/secondary-dropdown';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSavedViews,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EUserRole } from '../../share/share-utils/user-roles';
import { WorkspaceService } from '../../workspace/workspace.service';
import { ESavedView } from '../models/saved-view.enum';
import { TSavedViews, TViewShort } from '../models/saved-views.model';
import { SavedViewsService } from '../saved-views.service';
import {
  ESavedViewsOptionsDropdownCallback,
  TSavedViewsOptionsDropdownData,
} from './saved-views-options-dropdown.model';

@Component({
  selector: 'pp-saved-views-options-dropdown',
  templateUrl: './saved-views-options-dropdown.component.html',
  styleUrls: ['./saved-views-options-dropdown.component.scss'],
})
export class SavedViewsOptionsDropdownComponent implements OnInit {
  EIconPath = EIconPath;
  dropdownOption = ESavedViewsOptionsDropdownCallback;
  deleteHover = false;

  private dropdown: TDropdown<TSavedViewsOptionsDropdownData>;
  private dropdownService: DropdownService;
  ESavedView = ESavedView;

  isAdminUser: boolean;
  views: TSavedViews;
  type: ESavedView;
  view: TViewShort;

  constructor(
    private workspaceService: WorkspaceService,
    private savedViewsService: SavedViewsService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  ngOnInit(): void {
    this.dropdownService = GET_SECONDARY_DROPDOWN_SERVICE();
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    const dropdownData: TSavedViewsOptionsDropdownData = this.dropdown.data;

    this.type = dropdownData.type;
    this.view = dropdownData.view;
    this.views = dropdownData.views;
    const workspaces = this.workspaceService.getWorkspaces();

    // Both personal and shared should have the same workspaceId
    const shareOption = workspaces[this.views.personal.workspaceId].share.shareOption;

    this.isAdminUser =
      shareOption === EUserRole.SITE_ADMIN ||
      shareOption === EUserRole.OWNER ||
      shareOption === EUserRole.ACCOUNT_ADMIN;
  }

  renameView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.RENAME);
    this.hideDropdown();

    logEventInGTAG(EGoogleEventSavedViews.RENAME_SAVED_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  setPersonalDefaultView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.SET_PERSONAL_DEFAULT);
    this.hideDropdown();
    const prompt = this.translationPipe.transform('my_default_view_changed');
    this.promptService.showSuccess(prompt);

    logEventInGTAG(EGoogleEventSavedViews.SET_VIEW_AS_DEFAULT, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  setSharedDefaultView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.SET_SHARED_DEFAULT);
    this.hideDropdown();
    const prompt = this.translationPipe.transform('site_default_view_changed');
    this.promptService.showSuccess(prompt);

    logEventInGTAG(EGoogleEventSavedViews.SET_VIEW_AS_DEFAULT, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  clearPersonalDefaultView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.CLEAR_PERSONAL_DEFAULT);
    this.hideDropdown();

    logEventInGTAG(EGoogleEventSavedViews.DELETE_SAVED_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  clearSharedDefaultView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.CLEAR_SHARED_DEFAULT);
    this.hideDropdown();

    logEventInGTAG(EGoogleEventSavedViews.CLEAR_DEFAULT, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  duplicateView(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.DUPLICATE);
    this.hideDropdown();
  }

  duplicateViewToShared(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.DUPLICATE_TO_SHARED);
    this.hideDropdown();

    logEventInGTAG(EGoogleEventSavedViews.DUPLICATE_TO_SHARED_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  duplicateViewToPersonal(): void {
    this.dropdown.callback(ESavedViewsOptionsDropdownCallback.DUPLICATE_TO_PERSONAL);
    this.hideDropdown();

    logEventInGTAG(EGoogleEventSavedViews.DUPLICATE_TO_PERSONAL_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  deleteView(): void {
    const viewIsPersonal = this.type === ESavedView.PERSONAL;
    this.setModalData(viewIsPersonal);

    logEventInGTAG(EGoogleEventSavedViews.DELETE_SAVED_VIEW, {
      event_category: EGoogleEventCategory.SAVED_VIEWS,
      event_value: this.type,
    });
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
    this.dropdown.visible = false;
  }

  setModalData(personalView: boolean): void {
    const message = personalView ? 'from_your_saved_views' : 'from_shared_views';
    const modalData: TConfirmModalParams = {
      message: `
      ${this.translationPipe.transform('delete_warning')}
      <strong>${this.view.name}</strong>
      ${this.translationPipe.transform(message)}`,
      heading: this.translationPipe.transform('delete_view'),
      redButton: true,
      confirmText: this.translationPipe.transform('confirm'),
      boldText: false,
      zIndex: 950,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      closeWarning: true,
      callback: () => {
        this.savedViewsService.deleteView(this.view.id, this.views.personal.workspaceId, this.type);
      },
    });
  }
}
