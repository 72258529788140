import { cloneDeep } from 'lodash';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SortingService } from '@core/helpers';
import { getDragIcon } from 'src/app/core/helpers/dragging/get-drag-icon';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { autosizeTextArea } from 'src/app/project/shared/utils/autosize-text-area';
import { ModalService } from '../../../components/modal/modal.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TCustomField, TCustomFieldList } from '../custom-fields.model';
import { CustomFieldsService, ECustomFieldsEventType } from '../custom-fields.service';

@Component({
  selector: 'pp-custom-field-list',
  templateUrl: './custom-field-list.component.html',
  styleUrls: ['./custom-field-list.component.scss'],
})
export class CustomFieldListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() ppItem: TCustomField;
  @Input() ppShowAddItem: boolean;
  @Input() ppEmptyLabelError: boolean | string;
  @Input() ppAutofocus = true;
  @Input() ppPrimaryField = false;
  @Input() ppMaxDepthExceeded: boolean;

  @Output() ppChange = new EventEmitter();

  @ViewChildren('input') inputList: QueryList<ElementRef>;
  @ViewChildren('customFieldListComponent') sublists: CustomFieldListComponent[];
  @ViewChild('inputLine') indicatorLine;

  dragging = false;
  sortedFieldsBackup = [];
  sortedCustomFieldIds = [];
  onDragOverIndex = null;
  indicatorStart: number;
  initialScroll: number;
  sorted = false;
  reverseSorted = false;
  hovered: number = null;
  collapsed = false;
  EIconPath = EIconPath;

  private draggedFieldIndex = null;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private customFieldsService: CustomFieldsService,
    private sortingService: SortingService,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    if (this.ppItem && !this.ppItem.subList) {
      this.ppItem.subList = [
        {
          label: '',
          subList: [],
        },
      ];
    }

    this.customFieldsService.saveCustomFieldClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.ppItem?.subList) {
          this.ppItem.subList.forEach((item) => {
            if (
              typeof item.label === 'string' &&
              (item.label.indexOf('/') !== -1 || item.label.trim() === '')
            ) {
              item.fieldError = true;
            } else {
              item.fieldError = false;
            }
          });
        }
      });

    this.customFieldsService.alphabetize$.pipe(takeUntil(this.destroy$)).subscribe((sorted) => {
      this.alphabetizeListEntries(sorted);
    });

    merge(this.customFieldsService.allSort$, this.customFieldsService.fieldChanges$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkSortedList();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.ppItem && changes.ppItem.currentValue) {
      if (!this.ppItem.subList) {
        this.ppItem.subList = [
          {
            label: '',
            subList: [],
          },
        ];
      }
    }

    if (this.ppItem && this.ppEmptyLabelError) {
      this.ppItem.subList.forEach((item) => {
        if (
          typeof item.label === 'string' &&
          (item.label.indexOf('/') !== -1 || item.label.trim() === '')
        ) {
          item.fieldError = true;
        } else {
          item.fieldError = false;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.inputList.forEach((element) => {
          element.nativeElement.style.height = 'auto';
          element.nativeElement.style.height = element.nativeElement.scrollHeight / 10 + 'rem';
        });
      });
    });

    if (this.inputList.last && this.ppAutofocus) {
      this.inputList.last.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  addSublist(item: TCustomField, index?: number): void {
    if (!item.subList) {
      item.subList = [];
    }

    item.subList.push({
      label: '',
      subList: [],
      fieldError: false,
    });

    if (item === this.ppItem) {
      this.customFieldsService.emit(ECustomFieldsEventType.LEVEL_CHANGE);
    }

    let newSublist = true;

    if (typeof index === 'number' && item.subList && item.subList.length > 0) {
      newSublist = false;
    }
  }

  removeSublist(item: TCustomFieldList): void {
    this.ppItem.subList.splice(this.ppItem.subList.indexOf(item), 1);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.addSublist(this.ppItem);
    }
  }

  onKeyUp(event: KeyboardEvent, item: TCustomFieldList): void {
    if (event.key === '/') {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);
    }

    item.fieldError =
      typeof item.label === 'string' && item.label.indexOf('/') !== -1 && item.label.trim() !== '';

    this.ppChange.emit();
  }

  checkItem(item: TCustomFieldList): void {
    this.ppItem.subList.forEach((listItem) => {
      if (!listItem.label) {
        listItem.label = '';
      }

      if (listItem.label) {
        listItem.label = listItem.label.trim();
      }
    });

    item.fieldError =
      typeof item.label === 'string' &&
      (item.label.indexOf('/') !== -1 || item.label.trim() === '');

    if (item.fieldError) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);
    }

    this.triggerSortChange();
  }

  onChange(): void {
    this.ppChange.emit();
  }

  alphabetizeListEntries(sorted: boolean = false): void {
    if (this.ppItem?.subList) {
      this.ppItem.subList.sort((a, b) => this.sortingService.naturalSort(a.label, b.label));

      if (sorted && this.sorted) {
        this.ppItem.subList.reverse();
      }

      this.sorted = false;
    }

    this.triggerSortChange();
  }

  onDragStart(event: DragEvent, index: number): void {
    this.indicatorStart = this.indicatorLine.nativeElement.getBoundingClientRect().top;
    this.indicatorLine.nativeElement.children[0].style.visibility = 'visible';
    this.initialScroll = this.modalService.getModalScrollTop();

    const img = new Image();

    this.draggedFieldIndex = index;
    this.sortedFieldsBackup = [...this.ppItem.subList];
    this.sortedCustomFieldIds = [...this.ppItem.subList];
    img.src = getDragIcon();

    event.dataTransfer.setDragImage(img, 0, 0);

    this.dragging = true;
  }

  onDragOver(event: DragEvent, index: number): void {
    const itemRowElement = document.getElementById('listItemRow-' + this.ppItem.label + index);
    const targetElementCoords = itemRowElement.getBoundingClientRect();
    const elementCenterY =
      targetElementCoords.top + (targetElementCoords.bottom - targetElementCoords.top) / 2;
    const currentCustomFieldIds = [...this.ppItem.subList];

    let columnToReplaceIndex: number;

    if (event.clientY < elementCenterY) {
      this.onDragOverIndex = index - 1;
      columnToReplaceIndex = index;

      if (index > this.draggedFieldIndex) {
        columnToReplaceIndex = index - 1;
      }
    } else {
      this.onDragOverIndex = index;
      columnToReplaceIndex = index;

      if (index < this.draggedFieldIndex) {
        columnToReplaceIndex = index + 1;
      }
    }

    let boundingRect = this.inputList
      .toArray()
      [this.onDragOverIndex + 1]?.nativeElement.getBoundingClientRect();

    const scrollTop = this.modalService.getModalScrollTop();
    const scrollDiff = (scrollTop !== null ? scrollTop : this.initialScroll) - this.initialScroll;

    if (boundingRect) {
      this.indicatorLine.nativeElement.children[0].style.top = `${
        boundingRect.y - this.indicatorStart + scrollDiff - 5
      }px`;
    } else {
      boundingRect = this.inputList
        .toArray()
        [this.onDragOverIndex]?.nativeElement.getBoundingClientRect();

      this.indicatorLine.nativeElement.children[0].style.top = `${
        boundingRect.y + boundingRect.height - this.indicatorStart + scrollDiff - 5
      }px`;
    }

    event.preventDefault();

    const itemToReplace = this.ppItem.subList[this.draggedFieldIndex];

    currentCustomFieldIds.splice(this.draggedFieldIndex, 1);
    currentCustomFieldIds.splice(columnToReplaceIndex, 0, itemToReplace);

    this.sortedCustomFieldIds = currentCustomFieldIds;
  }

  onDrop(event: DragEvent): void {
    this.dragging = false;

    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnd(): void {
    this.dragging = false;

    this.indicatorLine.nativeElement.children[0].style.visibility = 'hidden';

    this.ppItem.subList = this.sortedCustomFieldIds;
    this.draggedFieldIndex = null;
    this.onDragOverIndex = null;

    this.triggerSortChange();
  }

  checkSortedList(): void {
    if (this.ppItem) {
      const sortedArray = cloneDeep(this.ppItem.subList).sort((a, b) =>
        this.sortingService.naturalSort(a.label, b.label),
      );

      let arraysEqual = true;
      let reverseArraysEqual = true;

      if (this.ppItem.subList.length === 1) {
        this.sorted = true;
      } else {
        for (let i = 0; i < this.ppItem.subList.length; i++) {
          if (this.ppItem.subList[i].label !== sortedArray[i].label) {
            arraysEqual = false;

            break;
          }
        }

        const reverseList = cloneDeep(this.ppItem.subList).reverse();

        for (let i = 0; i < reverseList.length; i++) {
          if (reverseList[i].label !== sortedArray[i].label) {
            reverseArraysEqual = false;

            break;
          }
        }

        this.sorted = arraysEqual;
        this.reverseSorted = reverseArraysEqual;
      }

      if (!this.sorted) {
        this.customFieldsService.setAllSorted(false);
      }
    }
  }

  triggerSortChange(): void {
    this.customFieldsService.setAllSorted(true);
    this.customFieldsService.emit(ECustomFieldsEventType.FIELD_CHANGES);
  }

  mouseEnter(index: number): void {
    this.hovered = index;
  }

  mouseLeave(index: number): void {
    if (this.hovered === index) {
      this.hovered = null;
    }
  }

  toggleList(): void {
    this.collapsed = !this.collapsed;
  }

  onInput(event: Event): void {
    const element = event.target as HTMLElement;
    autosizeTextArea(element);
  }

  focusElement(element: HTMLElement): void {
    element.focus();
  }
}
