export const API_dashboard_current = (type: string, targetId: string): string =>
  `/api/v1/dashboard/current?type=${type}${targetId}`;
export const API_dashboard_report = (
  type: string,
  period: string,
  length: number,
  targetId: string,
): string => `/api/v1/dashboard/report?type=${type}&period=${period}&length=${length}${targetId}`;
export const API_dashboard_events = (type: string, period: string, targetId: string): string =>
  `/api/v1/dashboard/events?type=${type}&period=${period}${targetId}`;
