<pp-modal>
  <ng-container heading>
    {{ 'Delete' | ppTranslate }}
    <strong>{{ modal.data.label }}</strong>
    {{ 'field' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="no-margin word-break">
      {{ 'delete_warning' | ppTranslate }}
      <strong>{{ modal.data.label }}</strong>
      {{ 'field' | ppTranslate }}?
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="delete()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
