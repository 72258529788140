import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { RemoveCustomField } from 'src/app/project/modules/workspace/workspace.actions';

import { TAllFilters, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SiteFilterDataService } from 'src/app/project/modules/filters/site-filter-data-service/site-filter-data.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { CustomFieldsService } from '../custom-fields.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EStore } from '../../../shared/enums/store.enum';
import { AdvancedFilterService } from '../../filters-advanced/advanced-filter.service';
import { SetSiteFilter } from '../../filters/site-filter.actions';
import { setChangedWorkspace } from '../../workspace/workspace';

@Component({
  selector: 'app-delete-custom-field-modal',
  templateUrl: './delete-custom-field-modal.component.html',
  styleUrls: ['./delete-custom-field-modal.component.scss'],
})
export class DeleteCustomFieldModalComponent implements OnInit, OnDestroy {
  modal: Modal = this.modalService.getModal();

  processing = false;

  workspaceId: string;
  allFilters: TAllFilters;
  filters: TFilters;

  private readonly destroy$ = new Subject<void>();
  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private customFieldsService: CustomFieldsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private siteFilterDataService: SiteFilterDataService,
    private translationPipe: TranslationPipe,
    private advancedFilterService: AdvancedFilterService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit(): void {
    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[this.workspaceId];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  delete(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();
    const fieldId = this.modal.data.id;

    this.customFieldsService
      .deleteCustomField(workspaceId, fieldId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_field_deleted');

          const workspaceFieldIndex = workspaces[workspaceId].customFields.findIndex(
            (_fieldId) => fieldId === _fieldId,
          );

          if (workspaceFieldIndex > -1) {
            workspaces[workspaceId].customFields.splice(workspaceFieldIndex, 1);
          }

          this.store.dispatch(
            new RemoveCustomField({
              workspaceId: this.workspaceId,
              customFieldId: fieldId,
            }),
          );

          const filterFieldIndex = this.filters.customFields.findIndex(
            (_field) => fieldId === _field.id,
          );

          if (filterFieldIndex > -1) {
            const newFilters = {
              ...this.filters,
              customFields: [...this.filters.customFields],
            };
            newFilters.customFields.splice(filterFieldIndex, 1);

            this.store.dispatch(
              new SetSiteFilter({
                workspaceId,
                filter: newFilters,
              }),
            );
          }

          this.siteFilterDataService.tryDeleteCustomFieldFromFilters(fieldId, workspaceId);
          setChangedWorkspace(workspaceId);

          this.promptService.showSuccess(promptText);
          this.modalService.hideModal(false);
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('prompt_field_deleted_error');

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
