<button
  class="dropdownItem dropdownItem--btn"
  id="renameFleetButton"
  data-test="renameFleetButton"
  (click)="editFleet()"
>
  {{ 'edit_fleet' | ppTranslate }}
</button>

<button
  ppTooltip
  [ppTitle]="canDeleteFleet ? '' : ('delete_fleet_disabled_tooltip' | ppTranslate)"
  [class.dropdownItem--disabled]="!canDeleteFleet"
  class="dropdownItem dropdownItem--btn deleteFleetButton"
  id="deleteFleetButton"
  data-test="deleteFleetButton"
  (click)="deleteFleet()"
>
  {{ 'delete_fleet' | ppTranslate }}
</button>
