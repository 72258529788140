var util = require('../util');
function JsonBuilder() {}
JsonBuilder.prototype.build = function (value, shape) {
  return JSON.stringify(translate(value, shape));
};
function translate(value, shape) {
  if (!shape || value === undefined || value === null) return undefined;
  switch (shape.type) {
    case 'structure':
      return translateStructure(value, shape);
    case 'map':
      return translateMap(value, shape);
    case 'list':
      return translateList(value, shape);
    default:
      return translateScalar(value, shape);
  }
}
function translateStructure(structure, shape) {
  if (shape.isDocument) {
    return structure;
  }
  var struct = {};
  util.each(structure, function (name, value) {
    var memberShape = shape.members[name];
    if (memberShape) {
      if (memberShape.location !== 'body') return;
      var locationName = memberShape.isLocationName ? memberShape.name : name;
      var result = translate(value, memberShape);
      if (result !== undefined) struct[locationName] = result;
    }
  });
  return struct;
}
function translateList(list, shape) {
  var out = [];
  util.arrayEach(list, function (value) {
    var result = translate(value, shape.member);
    if (result !== undefined) out.push(result);
  });
  return out;
}
function translateMap(map, shape) {
  var out = {};
  util.each(map, function (key, value) {
    var result = translate(value, shape.value);
    if (result !== undefined) out[key] = result;
  });
  return out;
}
function translateScalar(value, shape) {
  return shape.toWireFormat(value);
}

/**
 * @api private
 */
module.exports = JsonBuilder;