<div class="pointCF__content" #customFieldElement>
  <div
    class="customFieldCost"
    [class.pointCF__input--focus]="focused"
    [class.pointCF__input--disabled]="!ppCanEdit"
  >
    <div class="customFieldCost__currency">
      {{ ppFieldCurrencyCode }} {{ ppFieldCurrencySymbol }}
    </div>

    <input
      class="customFieldCost__input"
      #costInput
      [(ngModel)]="ppFieldValue"
      (change)="updateField()"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.disabled]="ppCanEdit ? null : true"
      [attr.readonly]="ppCanEdit ? null : true"
      placeholder="00.00"
      inputmode="numeric"
      pattern="[0-9]*"
      (keydown.enter)="blurInput($event)"
    />

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="ppFieldValue?.length > 0"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    ></pp-save-indicator>
  </div>
</div>
