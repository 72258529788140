import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import {
  DeselectWorkspacePoints,
  SelectWorkspacePoints,
  TogglePointSelection,
} from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { EditPointService } from 'src/app/project/modules/points/point-full-modal/edit-point.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointHalfModalService } from '../../points/point-half-modal/point-half-modal.service';
import { SiteDataService, TSiteData } from '../site-data.service';

import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNotifications,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { PointAttachmentsService } from '../../points/point-modal/point-attachments/point-attachments.service';
import { getSelectedPoints, setSelectedPoints, togglePoint } from '../../points/selected-points';
import { CustomTableService } from './custom-table/custom-table.service';

@Injectable({
  providedIn: 'root',
})
export class SiteTableEventsService {
  private site: TSiteData = this.siteDataService.getSite();

  constructor(
    private store: Store,
    private router: Router,
    private pointHalfModalService: PointHalfModalService,
    private siteDataService: SiteDataService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private editPointService: EditPointService,
    private pointAttachmentsService: PointAttachmentsService,
    private customTableService: CustomTableService,
  ) {}

  togglePointsSelection(selectAll: boolean, workspaceId: string): void {
    if (selectAll) {
      this.store.dispatch(new SelectWorkspacePoints({ workspaceId }));
    } else {
      this.store.dispatch(new DeselectWorkspacePoints({ workspaceId }));
    }
  }

  selectPoints({ points, selected }: { points: TPoint[]; selected: boolean }): void {
    points.forEach((point) => {
      if (point && getSelectedPoints().includes(point._id) !== selected) {
        togglePoint(point);
      }
    });
  }

  toggleAllPointsSelection(selectAll: boolean): void {
    const table = this.customTableService.getTable();

    if (selectAll) {
      setSelectedPoints(table.points.map((point) => point._id));
    } else {
      setSelectedPoints([]);
    }
  }

  deselectAllPoints(): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const workspaceIds = [];

    Object.keys(workspaces).forEach((workspaceId) => {
      workspaceIds.push(workspaceId);
    });

    setSelectedPoints([]);
  }

  deselectWorkspacePoints(workspaceId: string): void {
    this.store.dispatch(new DeselectWorkspacePoints({ workspaceId }));
  }

  togglePointSelection(point: { workspaceId: string; _id: string }): void {
    this.store.dispatch(
      new TogglePointSelection({
        workspaceId: point.workspaceId,
        _id: point._id,
      }),
    );
  }

  openPoint(
    point: TPoint,
    {
      isOverview = false,
      isTimeline = false,
    }: {
      isOverview?: boolean;
      isTimeline?: boolean;
    },
  ): void {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return;
    }

    if (isOverview) {
      this.router.navigate(['/site/overview', 'point', point._id]);
    } else if (isTimeline) {
      this.router.navigate(['/site/timeline', 'point', point._id]);
    } else {
      if (this.site.sitePlanVisible) {
        this.router.navigate(['/site', point.workspaceRef.id, 'point', point._id]);
      } else {
        this.pointHalfModalService.hideModal();
        this.router.navigate([
          '/site',
          point.workspaceRef.id,
          { outlets: { full: ['point', point._id] } },
        ]);
      }
    }
  }

  openPointFromNotification(workspaceId: string, _id: string): void {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return;
    }

    if (this.activeService.getActiveWorkspaceId() !== workspaceId) {
      this.pointHalfModalService.hideModal();
      this.editPointService.hideModal(false);
    }

    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__OPEN, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
    });

    if (this.site.sitePlanVisible) {
      this.router.navigate(['/site', workspaceId, 'point', _id]);
    } else {
      this.router.navigate(['/site', workspaceId, { outlets: { full: ['point', _id] } }]);
    }
  }
}
