import { Injectable } from '@angular/core';

import { createChartLabels } from './create-chart-labels';
import { addGraphLabels } from './add-graph-labels';
import { addGraphDatasetLabels } from './add-graph-dataset-labels';
import { TDashletGraph } from '../dashlet-graph.model';
import { EReportType } from '../report-types-enum';

@Injectable({
  providedIn: 'root',
})
export class DashboardChartService {
  createChartLabels(capitalizedPeriodWord: string, length: number, period: string): string[] {
    return createChartLabels(capitalizedPeriodWord, length, period);
  }

  addGraphLabels(type: string, data: number[]): string[] {
    return addGraphLabels(type, data);
  }

  addGraphDatasetLabels(graph: TDashletGraph, type: EReportType, data: number[][]): void {
    addGraphDatasetLabels(graph, type, data);
  }
}
