import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { TAssetSite } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { FilterAssetService } from 'src/app/project/modules/preferences/filter-asset.service';

@Component({
  selector: 'pp-asset-card-sites-table',
  templateUrl: './asset-card-sites-table.component.html',
  styleUrls: ['./asset-card-sites-table.component.scss'],
})
export class AssetCardSitesTableComponent {
  @Input() ppSites: TAssetSite[];

  constructor(
    private previousPageService: PreviousPageService,
    private router: Router,
    private filterAssetService: FilterAssetService,
    private fleetService: FleetService,
  ) {}

  navigateToSite(workspaceId: string): void {
    const fleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(fleetId);
    const fleetStatuses = fleet.statusSettings;

    this.filterAssetService.setOpeningSiteFromAsset(true, fleetStatuses, false);
    this.previousPageService.setPreviousFleetUrl(this.router.url);
    this.router.navigate(['/site', workspaceId]);
  }
}
