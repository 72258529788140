import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { GridsterModule } from 'angular-gridster2';

import { LayoutModule } from '../layout/layout.module';
import { RemindersModule } from '../reminders/reminders.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';

import { DashboardService } from './dashboard-service/dashboard.service';
import { DashboardComponent } from './dashboard.component';
import { dashboardRoutes } from './dashboard.routes';

import { DashletActivitiesComponent } from './dashlet/dashlet-activities/dashlet-activities.component';
import { DashletActivitiesService } from './dashlet/dashlet-activities/dashlet-activities.service';

import { DashletEventsComponent } from './dashlet/dashlet-events/dashlet-events.component';
import { DashletEventsService } from './dashlet/dashlet-events/dashlet-events.service';

import { DashletFlipComponent } from './dashlet/dashlet-flip/dashlet-flip.component';
import { DashletHeadComponent } from './dashlet/dashlet-head/dashlet-head.component';

import { DashletComponent } from './dashlet/dashlet-component/dashlet.component';
import { DashletService } from './dashlet/dashlet-service/dashlet.service';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { DeleteButtonModule } from '../../components/delete-button/delete-button.module';
import { DoubleSelectModule } from '../../components/double-select/double-select.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { DashboardExportModalComponent } from './dashboard-export-modal/dashboard-export-modal.component';
import { DashboardSettingsDropdownComponent } from './dashboard-settings-dropdown/dashboard-settings-dropdown.component';
import { ActivitiesDashletModalSettingsComponent } from './dashlet/activities-dashlet-modal/activities-dashlet-modal-settings/activities-dashlet-modal-settings.component';
import { ActivitiesDashletModalComponent } from './dashlet/activities-dashlet-modal/activities-dashlet-modal.component';
import { DashletActivitiesPlaceholderComponent } from './dashlet/dashlet-activities/dashlet-activities-placeholder/dashlet-activities-placeholder.component';
import { DashletActivityCopyComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-copy/dashlet-activity-copy.component';
import { DashletActivityDeleteComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-delete/dashlet-activity-delete.component';
import { DashletActivityMoveComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-move/dashlet-activity-move.component';
import { DashletActivityNewCommentComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-new-comment/dashlet-activity-new-comment.component';
import { DashletActivityNewPointComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-new-point/dashlet-activity-new-point.component';
import { DashletActivityAssigneesComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-assignees/dashlet-activity-assignees.component';
import { DashletActivityCustomFieldComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-custom-field/dashlet-activity-custom-field.component';
import { DashletActivityDescriptionRichComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-description-rich/dashlet-activity-description-rich.component';
import { DashletActivityDescriptionComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-description/dashlet-activity-description.component';
import { DashletActivityDocumentsComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-documents/dashlet-activity-documents.component';
import { DashletActivityFlagComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-flag/dashlet-activity-flag.component';
import { DashletActivityImagesComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-images/dashlet-activity-images.component';
import { DashletActivityImages360Component } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-images360/dashlet-activity-images360.component';
import { DashletActivityLocationComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-location/dashlet-activity-location.component';
import { DashletActivityPriorityComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-priority/dashlet-activity-priority.component';
import { DashletActivityRichTextComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-rich-text/dashlet-activity-rich-text.component';
import { DashletActivityStatusComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-status/dashlet-activity-status.component';
import { DashletActivityTagsListComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-tags/components/dashlet-activity-tags-list.component';
import { DashletActivityTagsComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-tags/dashlet-activity-tags.component';
import { DashletActivityTitleComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-title/dashlet-activity-title.component';
import { DashletActivityUpdateChangesComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-update-changes.component';
import { DashletActivityVideosComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/dashlet-activity-videos/dashlet-activity-videos.component';
import { DashletActivityComponent } from './dashlet/dashlet-activities/dashlet-activity/dashlet-activity.component';
import { DescriptionDashletActivityComponent } from './dashlet/dashlet-activities/description-dashlet-activity/description-dashlet-activity.component';
import { DashletTimelineAccountComponent } from './dashlet/dashlet-timeline-account/dashlet-timeline-account.component';
import { NewDashletModalComponent } from './new-dashlet-modal/new-dashlet-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(dashboardRoutes),
    GridsterModule,
    CheckboxModule,
    ButtonModule,
    RadioModule,
    DeleteButtonModule,
    TagModule,
    DoubleSelectModule,
    SpinnerModule,
    LayoutModule,
    RemindersModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    SupportedBrowsersModule,
    ModalModule,
    SecureSrcModule,
    IconModule,
  ],
  providers: [DashboardService, DashletService, DashletActivitiesService, DashletEventsService],
  declarations: [
    DashboardComponent,
    DashletActivitiesComponent,
    DashletEventsComponent,
    DashletFlipComponent,
    DashletHeadComponent,
    DashletComponent,
    NewDashletModalComponent,
    DescriptionDashletActivityComponent,
    DashboardSettingsDropdownComponent,
    ActivitiesDashletModalComponent,
    ActivitiesDashletModalSettingsComponent,
    DashboardExportModalComponent,
    DashletActivityComponent,
    DashletActivitiesPlaceholderComponent,
    DashletActivityDeleteComponent,
    DashletActivityCopyComponent,
    DashletActivityMoveComponent,
    DashletActivityNewPointComponent,
    DashletActivityNewCommentComponent,
    DashletActivityUpdateChangesComponent,
    DashletActivityImagesComponent,
    DashletActivityImages360Component,
    DashletActivityVideosComponent,
    DashletActivityDocumentsComponent,
    DashletActivityStatusComponent,
    DashletActivityPriorityComponent,
    DashletActivityDescriptionComponent,
    DashletActivityDescriptionRichComponent,
    DashletActivityRichTextComponent,
    DashletActivityTitleComponent,
    DashletActivityTagsComponent,
    DashletActivityTagsListComponent,
    DashletActivityAssigneesComponent,
    DashletActivityCustomFieldComponent,
    DashletActivityLocationComponent,
    DashletTimelineAccountComponent,
    DashletActivityFlagComponent,
  ],
})
export class DashboardModule {}
