<div class="checkbox" (click)="toggleCheckbox()">
  <div
    class="checkbox__input-box"
    [ngClass]="class"
    [class.selected]="ppChecked && !ppDisabled"
    [class.selected--disabled]="ppChecked && ppDisabled"
    [class.checkbox--solid-selected]="ppType === 'solid' && ppChecked"
  >
    <span
      [class.checkbox__check-wrapper--large]="ppSize === 'large'"
      [class.checkbox__check-wrapper--medium]="ppSize === 'medium'"
    >
      <pp-icon
        [style.visibility]="ppChecked ? 'visible' : 'hidden'"
        class="checkbox__check"
        [ppClass]="ppSize === 'large' ? 'setSize18 displayFlex' : 'setSize12 displayFlex'"
        [ppSrc]="
          ppSize === 'large'
            ? EIconPath.ICON_INPUT_CHECKBOX_CHECK_18
            : EIconPath.ICON_INPUT_CHECKBOX_CHECK_12
        "
        [ppColor]="ppDisabled ? 'grey-400' : ppType === 'solid' ? 'white' : 'primary-600'"
      ></pp-icon>
    </span>
  </div>

  <div class="checkbox__label" [class.checkbox__label--solid]="ppType === 'solid'">
    <ng-content></ng-content>
  </div>
</div>
