<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_asset_details_header' | ppTranslate }}
    </header>
    <hgroup class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_asset_details_subheader' | ppTranslate }}
    </hgroup>
  </div>

  <div class="fleetNewItemSectionContent">
    <div class="newAssetDetails">
      <div class="newAssetDetail" *ngFor="let detail of assetDetails">
        <header class="newAssetDetail__name">{{ detail.label }}</header>
        <input
          type="text"
          class="newAssetDetail__input"
          [placeholder]="detail.placeholder"
          [(ngModel)]="detail.value"
          (ngModelChange)="updateAssetDetails()"
        />
      </div>
    </div>
  </div>
</section>
