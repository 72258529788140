import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';
import {
  TRichTextUpdate,
  TRichTextValue,
} from '../../../components/input/rich-text/rich-text.model';

@Component({
  selector: 'pp-custom-field-rich-text',
  templateUrl: './custom-field-rich-text.component.html',
  styleUrls: ['./custom-field-rich-text.component.scss'],
})
export class CustomFieldRichTextComponent {
  @Input() ppField: TCustomFieldResponse;
  @Input() ppValue: {
    plainText?: string;
    richTextComponent?: string;
    mentions?: string[];
  };
  @Input() ppWorkspaceId: string;

  @Output() ppUpdate = new EventEmitter<TRichTextValue>();

  richTextOptions: TRichTextOptions = {
    showButtons: false,
    defaultBorder: true,
    small: true,
    bulkChanges: true,
  };

  constructor() {}

  updateField(value: TRichTextUpdate): void {
    if (this.ppUpdate) {
      const newValue: TRichTextValue = {
        plainText: value.mergedText,
        richTextComponent: value.richText,
        mentions: value.mentions,
      };

      this.ppUpdate.emit(newValue);
    }
  }
}
