import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UpdatePoint } from './points.actions';

import { TWorkspacesById } from '../workspace/workspace.model';
import { TPoint, TPoints, TPointsByWorkspace } from './points.model';

import { ActiveService } from '../../services/active/active.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { PointsGenerateService } from './point-generate/points-generate.service';

import { PointsApiProviderService } from '@core/api';
import { catchError, map } from 'rxjs/operators';
import {
  TNewPoint,
  TNewPointAttachments,
} from '../../data-providers/api-providers/points-api-provider/points-requests.model';
import { EDocumentType } from '../../shared/enums/document-type.enum';
import { EStore } from '../../shared/enums/store.enum';
import { ECustomFieldType } from '../custom-fields/custom-field-types-enums';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';
import { SiteTablePointsService } from '../site/site-table/site-table-points.service';
import { AttachmentsService } from './attachments/attachments.service';
import { polygonsToArea } from './point-generate/polygon-to-area';
import { SET_POINTS } from './points.store';
import { getSelectedPoints } from './selected-points';
import { getDescriptionMentions } from './utils/get-description-mention';
import { getVisiblePoints } from './visible-points';

@Injectable({
  providedIn: 'root',
})
export class PointsService {
  private points$: Observable<TPointsByWorkspace>;
  private points: TPointsByWorkspace = {};
  private mapPoints: TPoint[] = [];

  constructor(
    private store: Store<{
      points: TPointsByWorkspace;
      workspaces: TWorkspacesById;
    }>,
    private pointsApiProviderService: PointsApiProviderService,
    private responseErrorService: ResponseErrorService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsGenerateService: PointsGenerateService,
    private siteTablePointsService: SiteTablePointsService,
    private attachmentsService: AttachmentsService,
    private customTableService: CustomTableService,
  ) {
    this.points$ = this.store.pipe(select(EStore.POINTS));

    this.points$.subscribe((points) => {
      const activeWorkspaceId = this.activeService.getActiveWorkspaceId();
      this.points = cloneDeep(points);

      if (activeWorkspaceId && points[activeWorkspaceId]) {
        SET_POINTS(cloneDeep(points[activeWorkspaceId].entities));
      } else {
        let _points = [];

        Object.keys(points).forEach((workspaceId) => {
          _points = [..._points, ...points[workspaceId].entities];
        });

        SET_POINTS(cloneDeep(_points));
      }
    });
  }

  getAllPoints(): TPointsByWorkspace {
    return this.points;
  }

  getPointsData(id: string = null): TPoints {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    if (id) {
      return this.points[id];
    } else {
      return this.points[workspaceId];
    }
  }

  getPoints(workspaceId: string = null): TPoint[] {
    const points = this.getPointsData(workspaceId);
    return points ? points.entities : [];
  }

  getPointsForWorkspace(workspaceId: string): TPoint[] {
    const points = this.points[workspaceId];
    return points ? points.entities : [];
  }

  getSelectedPoints(): TPoint[] {
    const selectedPointsIds = getSelectedPoints();
    const points = this.getPointsData();

    return points ? points.entities.filter((point) => selectedPointsIds.includes(point._id)) : [];
  }

  getVisiblePoints(): TPoint[] {
    const points = this.getPointsData();
    const visiblePointsIds = getVisiblePoints();

    return points && points.entities
      ? points.entities.filter((point) => visiblePointsIds.includes(point._id))
      : [];
  }

  findPoint(_id: string): TPoint {
    if (this.workspaceService.getActiveWorkspace()) {
      return this.getPoints().find((point) => point._id === _id);
    } else {
      const points = this.getAllPoints();
      let allPoints: TPoint[] = [];

      Object.keys(points).forEach((workspaceId) => {
        allPoints = [...allPoints, ...points[workspaceId].entities];
      });

      return allPoints.find((point) => point._id === _id);
    }
  }

  createPoint(point: TPoint): Observable<TPoint> {
    const newPoint = this.createNewPoint(point);

    return this.pointsApiProviderService.createPoint(point.workspaceRef.id, newPoint).pipe(
      map((response) => {
        const generatedPoint = this.pointsGenerateService.generatePoint(response);

        this.store.dispatch(
          new UpdatePoint({
            workspaceId: generatedPoint.workspaceRef.id,
            point: generatedPoint,
          }),
        );

        return generatedPoint;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  //
  // Points
  //

  createNewPoint(point: TPoint): TNewPoint {
    const polygons = polygonsToArea(point.polygons);

    const {
      customFieldSimplyList,
      mentions,
    }: { customFieldSimplyList: { [x: string]: string }[]; mentions: string[] } =
      this.getNewPointCustomFields(point);

    const descriptionMentions = getDescriptionMentions(point);
    const pointMentions = [...mentions, ...descriptionMentions];
    const pointAttachments = this.getNewPointAttachments();

    return {
      customFieldSimplyList,
      description: point.description,
      descriptionRich: point.descriptionRich,
      mentions: pointMentions,
      documents: pointAttachments.documents,
      images: pointAttachments.images,
      images360: pointAttachments.images360,
      videos: pointAttachments.videos,
      pins: point.pins,
      polygons: polygons,
      priority: point.priority,
      status: point.status,
      tags: point.tags,
      title: point.title,
      assignees: point.assignees,
    };
  }

  setMapPoints(points: TPoint[]): void {
    this.mapPoints = points;
  }

  getMapPoints(): TPoint[] {
    return this.mapPoints;
  }

  getOverviewSelectedWorkspaces(): string[] {
    const allPoints = this.getAllPoints();
    const workspaces: string[] = [];
    const selectedPointsIds = getSelectedPoints();

    let selectedPoints = [];

    Object.keys(allPoints).forEach((workspaceId) => {
      selectedPoints = [
        ...selectedPoints,
        ...allPoints[workspaceId].entities.filter((point) => selectedPointsIds.includes(point._id)),
      ];
    });

    if (selectedPoints.length > 0) {
      selectedPoints.forEach((point) => {
        if (!workspaces.includes(point.workspaceRef.id)) {
          workspaces.push(point.workspaceRef.id);
        }
      });
    }

    return workspaces;
  }

  getSelectedPointsExport(): string[] {
    const selectedPoints = this.getSelectedPoints();
    const pointIds: string[] = [];

    let points: TPoint[];

    if (selectedPoints.length > 0) {
      points = this.siteTablePointsService.sortPoints(selectedPoints);
    } else {
      const visiblePoints = this.customTableService.getTable().points;

      if (visiblePoints.length > 0) {
        points = this.siteTablePointsService.sortPoints(visiblePoints);
      } else {
        points = this.getPoints();
        points = this.siteTablePointsService.sortPoints(points);
      }
    }

    points.forEach((point) => {
      pointIds.push(point._id);
    });

    return pointIds;
  }

  getNewPointCustomFields(point: TPoint): {
    customFieldSimplyList: { [x: string]: string }[];
    mentions: string[];
  } {
    let mentions: string[] = [];

    const customFieldSimplyList: {
      [x: string]: string;
    }[] = Object.keys(point.customFieldsMap)
      .filter((key) => {
        if (
          point.customFieldsMap[key].value !== undefined &&
          point.customFieldsMap[key].value !== null
        ) {
          return true;
        }

        return false;
      })
      .map((key) => {
        if (point.customFieldsMap[key].type === ECustomFieldType.LIST) {
          return { [key]: point.customFieldsMap[key].idOfChosenElement };
        }

        if (point.customFieldsMap[key].type === ECustomFieldType.COST) {
          return { [key]: point.customFieldsMap[key].value.replace(/,/g, '') };
        }

        if (point.customFieldsMap[key].type === ECustomFieldType.PERCENTAGE) {
          return {
            [key]: point.customFieldsMap[key].value.replace(/,/g, '').replace(/%/g, '').trim(),
          };
        }

        if (point.customFieldsMap[key].type === ECustomFieldType.RICHTEXT) {
          mentions = [...mentions, ...point.customFieldsMap[key].mentions];

          return { [key]: point.customFieldsMap[key].value };
        }

        return { [key]: point.customFieldsMap[key].value };
      });
    return { customFieldSimplyList, mentions };
  }

  getNewPointAttachments(): TNewPointAttachments {
    const attachments = this.attachmentsService.getAttachments();

    const pointAttachments: TNewPointAttachments = {
      images: [],
      videos: [],
      images360: [],
      documents: [],
    };

    attachments.files.attachmentIds.forEach((attachment) => {
      pointAttachments.documents.push({
        id: attachment,
      });
    });

    Object.keys(attachments.media.attachments).forEach((attachmentId) => {
      const attachment = attachments.media.attachments[attachmentId];

      switch (attachment.type) {
        case EDocumentType.IMAGE:
          pointAttachments.images.push({
            id: attachmentId,
          });
          break;
        case EDocumentType.IMAGE_360:
          pointAttachments.images360.push({
            id: attachmentId,
          });
          break;
        case EDocumentType.VIDEO:
          pointAttachments.videos.push({
            id: attachmentId,
          });
          break;
      }
    });

    return pointAttachments;
  }
}
