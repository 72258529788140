<div class="pointCF__field pointCF__field--expandable">
  <div class="pointCF__content">
    <pp-rich-text
      class="richText__container"
      [ppId]="ppField.id"
      [ppCanEdit]="true"
      [ppValue]="{ richText: ppValue?.richTextComponent, plainText: ppValue?.plainText }"
      [ppWorkspaceId]="ppWorkspaceId"
      ppPlaceholder="Enter text..."
      (ppUpdate)="updateField($event)"
      [ppOptions]="richTextOptions"
    ></pp-rich-text>
  </div>
</div>
