<h6 class="siteList__title">
  {{ 'select_site' | ppTranslate }}
</h6>

<div
  class="siteList__account"
  [class.account--expanded]="expandedAccounts.includes(account.accountId)"
  *ngFor="let account of ppAccounts"
>
  <div
    class="siteList__accountName"
    [class.siteList__accountName--expanded]="expandedAccounts.includes(account.accountId)"
    (click)="toggleAccount(account.accountId)"
  >
    <img
      [class.arrowDown]="expandedAccounts.includes(account.accountId)"
      [src]="EIconPath.INTERACTION_EXPAND_ICON"
    />

    <h6 class="siteList__accountName--text">
      {{ account.accountName }}
    </h6>

    <p
      class="selectedCustomFieldsCounter copy"
      *ngIf="
        !expandedAccounts.includes(account.accountId) &&
        ppFieldsPerAccount[account.accountId] &&
        ppFieldsPerAccount[account.accountId] > 1
      "
    >
      {{ ppFieldsPerAccount[account.accountId] }}
      {{ SiteListSelectTypesMultiple[ppType] | ppTranslate }}
    </p>

    <p
      *ngIf="
        !expandedAccounts.includes(account.accountId) &&
        ppFieldsPerAccount[account.accountId] &&
        ppFieldsPerAccount[account.accountId] === 1
      "
      class="selectedCustomFieldsCounter copy"
    >
      {{ ppFieldsPerAccount[account.accountId] }}
      {{ SiteListSelectTypesSingle[ppType] | ppTranslate }}
    </p>
  </div>

  <ng-container *ngIf="expandedAccounts.includes(account.accountId)">
    <div
      class="siteList__site"
      [class.siteList__siteName--disabled]="
        workspace === activeWorkspaceId ||
        (ppType === ListSelectTypes.CUSTOM_FIELD &&
          !(workspaces[workspace].customFields?.length > 0)) ||
        (ppType === ListSelectTypes.TAG && !(this.workspaces[workspace].tags?.length > 0)) ||
        (ppType === ListSelectTypes.SHARE && !(this.workspaces[workspace].users?.length > 0))
      "
      *ngFor="let workspace of account.workspaces; let last = last"
      (click)="openSiteCallback(workspace)"
    >
      <ng-container
        *ngIf="
          (ppType === ListSelectTypes.CUSTOM_FIELD &&
            !(workspaces[workspace].customFields?.length > 0)) ||
          (ppType === ListSelectTypes.TAG && !(this.workspaces[workspace].tags?.length > 0)) ||
          (ppType === ListSelectTypes.SHARE && !(this.workspaces[workspace].users?.length > 0))
        "
      >
        <h6
          ppTooltip
          [ppTitle]="
            workspace === activeWorkspaceId
              ? ('site_currently_on' | ppTranslate)
              : ppType === ListSelectTypes.CUSTOM_FIELD
              ? ('no_custom_fields_available' | ppTranslate)
              : ppType === ListSelectTypes.TAG
              ? ('no_tags_available' | ppTranslate)
              : ('no_users_available' | ppTranslate)
          "
          [ppOptions]="{ placement: 'right' }"
          class="siteList__siteName"
        >
          {{ workspaces[workspace].siteName }}
        </h6>
      </ng-container>

      <ng-container
        *ngIf="
          !(
            (ppType === ListSelectTypes.CUSTOM_FIELD &&
              !(workspaces[workspace].customFields?.length > 0)) ||
            (ppType === ListSelectTypes.TAG && !(this.workspaces[workspace].tags?.length > 0)) ||
            (ppType === ListSelectTypes.SHARE && !(this.workspaces[workspace].users?.length > 0))
          )
        "
      >
        <h6
          ppTooltip
          [ppTitle]="workspace === activeWorkspaceId ? ('site_currently_on' | ppTranslate) : ''"
          [ppOptions]="{ placement: 'right' }"
          class="siteList__siteName"
        >
          {{ workspaces[workspace].siteName }}
        </h6>
      </ng-container>

      <p class="selectedCustomFieldsCounter copy" *ngIf="ppFieldsPerWorkspace[workspace] > 1">
        {{ ppFieldsPerWorkspace[workspace] }}
        {{ SiteListSelectTypesMultiple[ppType] | ppTranslate }}
      </p>

      <p class="selectedCustomFieldsCounter copy" *ngIf="ppFieldsPerWorkspace[workspace] === 1">
        {{ ppFieldsPerWorkspace[workspace] }} {{ SiteListSelectTypesSingle[ppType] | ppTranslate }}
      </p>
    </div>
  </ng-container>
</div>
