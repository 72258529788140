import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TPreferences } from '../../preferences/preferences.model';
import { SiteOptionsService } from '../site-options/site-options.service';
import { TColumn } from '../site-table/columns/column.model';
import { CustomTableService } from '../site-table/custom-table/custom-table.service';
import { CLEAR_COLLAPSED_GROUPS } from '../site-table/table.ui.store';

@Injectable({
  providedIn: 'root',
})
export class SiteTableOverviewService {
  constructor(
    private siteOptionsService: SiteOptionsService,
    private customTableService: CustomTableService,
  ) {}

  processPreferencesResponse(preferences: TPreferences, columns: TColumn[]): void {
    const table = this.customTableService.getTable();
    const sortButton = this.siteOptionsService.getSortButton();
    const groupButton = this.siteOptionsService.getGroupButton();

    if (preferences?.overviewColumns) {
      preferences.overviewColumns = this.filterOverviewColumns(
        preferences.overviewColumns,
        columns,
      );
      this.findExistingColumns(columns, preferences);

      preferences.overviewColumns = this.fixColumnIndexes(preferences.overviewColumns);
      table.updateColumns({ columns: cloneDeep(preferences.overviewColumns) });
    } else {
      table.updateColumns();
    }

    CLEAR_COLLAPSED_GROUPS();

    table.setKeyword('');
    table.updateWidth();
    table.load(false);
    table.sortPoints();

    if (sortButton) {
      sortButton.update();
    }

    if (groupButton) {
      groupButton.update();
    }
  }

  filterOverviewColumns(preferencesColumns: TColumn[], columns: TColumn[]): TColumn[] {
    let newColumns = preferencesColumns.filter((column) => {
      if (column.customFieldIds) {
        const existingColumn = columns.find((_column) => {
          let match = false;

          if (_column?.customFieldIds) {
            _column?.customFieldIds.forEach((fieldId) => {
              if (column.customFieldIds.includes(fieldId)) {
                match = true;
              }
            });

            return match;
          } else {
            return column.name === _column.name;
          }
        });

        if (existingColumn) {
          column.currency = existingColumn.currency;
          column.decimalPlaces = existingColumn.decimalPlaces;
          column.unit = existingColumn.unit;

          if (existingColumn.showTotal || typeof existingColumn.showTotal === 'undefined') {
            column.showTotal = true;
          }

          return true;
        }

        return false;
      }

      return true;
    });

    newColumns = preferencesColumns.filter((column) => {
      if (!column.customFieldIds) {
        return true;
      } else {
        const customFields = GET_CUSTOM_FIELDS();

        column.customFieldIds = column.customFieldIds.filter(
          (customFieldId) => customFields[customFieldId],
        );

        const existingColumns = preferencesColumns.filter(
          (_column) =>
            _column.name === column.name &&
            _column.currency === column.currency &&
            _column.decimalPlaces === column.decimalPlaces &&
            !!_column.showTotal === !!column.showTotal &&
            _column.unit === column.unit,
        );

        if (existingColumns.length > 1) {
          if (!isEqual(existingColumns[0].customFieldIds, column.customFieldIds)) {
            existingColumns[0].customFieldIds = [
              ...existingColumns[0].customFieldIds,
              ...column.customFieldIds,
            ];

            return false;
          }
        }

        if (column.customFieldIds.length === 0) {
          return false;
        }

        return true;
      }
    });

    return newColumns;
  }

  private findExistingColumns(columns: TColumn[], preferences: TPreferences): void {
    columns.forEach((column) => {
      if (column.customFieldIds) {
        const existingColumn = preferences.overviewColumns.find(
          (_column) =>
            _column.name === column.name &&
            _column.currency === column.currency &&
            _column.decimalPlaces === column.decimalPlaces &&
            !!_column.showTotal === !!column.showTotal &&
            _column.unit === column.unit,
        );

        if (!existingColumn) {
          const columnToMerge = preferences.overviewColumns.find(
            (_column) =>
              _column.name === column.name &&
              _column.currency === column.currency &&
              _column.unit === column.unit,
          );

          if (columnToMerge) {
            column.customFieldIds.forEach((fieldId) => {
              if (!columnToMerge.customFieldIds.includes(fieldId)) {
                columnToMerge.customFieldIds.push(fieldId);
              }
            });
          } else {
            preferences.overviewColumns.push(column);
          }
        } else {
          column.customFieldIds.forEach((fieldId) => {
            if (
              existingColumn?.customFieldIds &&
              !existingColumn.customFieldIds.includes(fieldId)
            ) {
              existingColumn.customFieldIds.push(fieldId);
            }
          });
        }
      }
    });
  }

  private fixColumnIndexes(columns: TColumn[]) {
    const needReindexing = columns.some((column) => {
      return column.index >= columns.length;
    });

    if (needReindexing) {
      return columns.map((column, index) => {
        return { ...column, index };
      });
    }

    return columns;
  }
}
