import { Action } from '@ngrx/store';
import { TPin, TPolygon } from '../../view-models/point-response.model';

import { EStatus } from '../../shared/enums/status.enum';
import { TBulkCustomFieldExtended } from '../custom-fields/bulk-custom-fields-modal/bulk-custom-fields.model';
import { TPoint, TPointIdsByWorkspace, TPointListByWorkspace } from './points.model';
import { EPriority } from './priorities';

export enum EActionType {
  SET_POINTS = '[Points] Set',
  SET_ALL_POINTS = '[Points] Set All Points',
  FILTER_POINTS = '[Points] FilterPoints',
  FILTER_ALL_POINTS = '[Points] FilterAllPoints',
  CLEAR_POINTS = '[Points] Clear',
  ADD_POINT = '[Points] AddPoint',
  UPDATE_POINT = '[Points] UpdatePoint',
  UPDATE_POINTS = '[Points] UpdatePoints',
  DELETE_POINT = '[Points] DeletePoint',
  DELETE_POINTS = '[Points] DeletePoints',
  SELECT_WORKSPACE_POINTS = '[Points] SelectWorkspacePoints',
  DESELECT_WORKSPACE_POINTS = '[Points] DeselectWorkspacePoints',
  TOGGLE_ALL_POINTS_SELECTION = '[Points] ToggleAllPointsSelection',
  TOGGLE_POINT_SELECTION = '[Points] TogglePointSelection',
  TOGGLE_POINTS_SELECTION = '[Points] Toggle Points Selection',
  UPDATE_POINT_STATUS = '[Points] UpdatePointStatus',
  UPDATE_POINT_FLAG = '[Points] UpdatePointFlag',
  UPDATE_POINT_PRIORITY = '[Points] UpdatePointPriority',
  UPDATE_POINTS_PRIORITIES = '[Points] UpdatePointsPriorities',
  UPDATE_POINT_TITLE = '[Points] UpdatePointTitle',
  UPDATE_POINT_DESCRIPTION = '[Points] UpdatePointDescription',
  UPDATE_POINT_TAGS = '[Points] UpdatePointTags',
  UPDATE_POINT_ASSIGNEES = '[Points] UpdatePointAssignees',
  UPDATE_POINTS_TAGS = '[Points] UpdatePointsTags',
  UPDATE_POINTS_ASSIGNEES = '[Points] UpdatePointsAssignees',
  UPDATE_POINT_CUSTOM_FIELD = '[Points] UpdatePointCustomField',
  UPDATE_POINT_CUSTOM_FIELD_BULK = '[Points] UpdatePointCustomFieldBulk',
  UPDATE_POINTS_CUSTOM_FIELDS = '[Points] UpdatePointsCustomFields',
  UPDATE_POINT_PIN = '[Points] UpdatePointPin',
  UPDATE_POINT_COMMENT = '[Points] UpdatePointComment',
  ADD_IMAGE = '[Points] AddImage',
  ADD_IMAGE_360 = '[Points] AddImage360',
  ADD_VIDEO = '[Points] AddVideo',
  ADD_FILE = '[Points] AddFile',
  ADD_WORKSPACE_SORTED_POINT_IDS = '[Points] AddSortedPointIds',
  ADD_ALL_SORTED_POINT_IDS = '[Points] AddAllSortedPointIds',
  REMOVE_ATTACHMENT = '[Points] RemoveAttachment',
  REMOVE_ATTACHMENTS = '[Points] RemoveAttachments',
  REPLACE_IMAGE = '[Points] Replace Image',
  UPDATE_POINT_REMINDER = '[Point] Update Reminder',
}

export class SetPoints implements Action {
  readonly type = EActionType.SET_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
      points: TPoint[];
    },
  ) {}
}

export class SetAllPoints implements Action {
  readonly type = EActionType.SET_ALL_POINTS;
  constructor(
    readonly payload: {
      pointsByWorkspace: TPointListByWorkspace;
    },
  ) {}
}

export class FilterPoints implements Action {
  readonly type = EActionType.FILTER_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
      points: string[];
    },
  ) {}
}

export class FilterAllPoints implements Action {
  readonly type = EActionType.FILTER_ALL_POINTS;
  constructor(
    readonly payload: {
      pointsByWorkspace: TPointIdsByWorkspace;
      pointsToToggle: string[];
    },
  ) {}
}

export class ClearPoints implements Action {
  readonly type = EActionType.CLEAR_POINTS;
  constructor() {}
}

export class AddPoint implements Action {
  readonly type = EActionType.ADD_POINT;
  constructor(
    readonly payload: {
      workspaceId: string;
      point: TPoint;
    },
  ) {}
}

export class UpdatePoint implements Action {
  readonly type = EActionType.UPDATE_POINT;
  constructor(
    readonly payload: {
      workspaceId: string;
      point: TPoint;
    },
  ) {}
}

export class UpdatePoints implements Action {
  readonly type = EActionType.UPDATE_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
      fetched: boolean;
      changedPoints: TPoint[];
    },
  ) {}
}

export class DeletePoint implements Action {
  readonly type = EActionType.DELETE_POINT;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
    },
  ) {}
}

export class DeletePoints implements Action {
  readonly type = EActionType.DELETE_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
      pointIds: TPoint['_id'][];
    },
  ) {}
}

export class SelectWorkspacePoints implements Action {
  readonly type = EActionType.SELECT_WORKSPACE_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
    },
  ) {}
}

export class DeselectWorkspacePoints implements Action {
  readonly type = EActionType.DESELECT_WORKSPACE_POINTS;
  constructor(
    readonly payload: {
      workspaceId: string;
    },
  ) {}
}

export class ToggleAllPointsSelection implements Action {
  readonly type = EActionType.TOGGLE_ALL_POINTS_SELECTION;
  constructor(
    readonly payload: {
      allSelected: boolean;
    },
  ) {}
}

export class TogglePointSelection implements Action {
  readonly type = EActionType.TOGGLE_POINT_SELECTION;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
    },
  ) {}
}

export class TogglePointsSelection implements Action {
  readonly type = EActionType.TOGGLE_POINTS_SELECTION;
  constructor(
    readonly payload: {
      workspaceId: string;
      pointIds: TPoint['_id'][];
    },
  ) {}
}

export class UpdatePointStatus implements Action {
  readonly type = EActionType.UPDATE_POINT_STATUS;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      status: EStatus;
    },
  ) {}
}

export class UpdatePointFlag implements Action {
  readonly type = EActionType.UPDATE_POINT_FLAG;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      flagged: boolean;
    },
  ) {}
}

export class UpdatePointPriority implements Action {
  readonly type = EActionType.UPDATE_POINT_PRIORITY;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      priority: EPriority;
    },
  ) {}
}

export class UpdatePointReminder implements Action {
  readonly type = EActionType.UPDATE_POINT_REMINDER;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      value: string;
    },
  ) {}
}

export class UpdatePointsPriorities implements Action {
  readonly type = EActionType.UPDATE_POINTS_PRIORITIES;
  constructor(
    readonly payload: {
      workspaceId: string;
      points: string[];
      priority: EPriority;
    },
  ) {}
}

export class UpdatePointComment implements Action {
  readonly type = EActionType.UPDATE_POINT_COMMENT;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
    },
  ) {}
}

export class UpdatePointTitle implements Action {
  readonly type = EActionType.UPDATE_POINT_TITLE;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      title: string;
    },
  ) {}
}

export class UpdatePointDescription implements Action {
  readonly type = EActionType.UPDATE_POINT_DESCRIPTION;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      description: string;
      descriptionRich: string;
    },
  ) {}
}

export class UpdatePointTags implements Action {
  readonly type = EActionType.UPDATE_POINT_TAGS;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      tags: string[];
    },
  ) {}
}

export class UpdatePointAssignees implements Action {
  readonly type = EActionType.UPDATE_POINT_ASSIGNEES;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: TPoint['_id'];
      assignees: string[];
    },
  ) {}
}

export class UpdatePointsAssignees implements Action {
  readonly type = EActionType.UPDATE_POINTS_ASSIGNEES;
  constructor(
    readonly payload: {
      workspaceId: string;
      pointIds: TPoint['_id'][];
      assignees: string[];
    },
  ) {}
}

export class UpdatePointsTags implements Action {
  readonly type = EActionType.UPDATE_POINTS_TAGS;
  constructor(
    readonly payload: {
      workspaceId: string;
      pointIds: TPoint['_id'][];
      tags: string[];
    },
  ) {}
}

export class UpdatePointCustomField implements Action {
  readonly type = EActionType.UPDATE_POINT_CUSTOM_FIELD;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      customFieldId: string | number;
      customFieldValue: any;
      customFieldValueId?: string | number;
      plainText?: string;
      mentions?: string[];
    },
  ) {}
}

export class UpdatePointCustomFieldBulk implements Action {
  readonly type = EActionType.UPDATE_POINT_CUSTOM_FIELD_BULK;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      customFieldId: string | number;
      customField: TBulkCustomFieldExtended;
      customFieldValueId?: string | number;
      plainText?: string;
      mentions?: string[];
    },
  ) {}
}

export class UpdatePointsCustomFields implements Action {
  readonly type = EActionType.UPDATE_POINTS_CUSTOM_FIELDS;
  constructor(
    readonly payload: {
      workspaceId: string;
      pointIds: TPoint['_id'][];
      customFields: {
        [key: string]: {
          customFieldId: string | number;
          customFieldValue: any;
          customFieldValueId?: string | number;
        };
      };
    },
  ) {}
}

export class UpdatePointPin implements Action {
  readonly type = EActionType.UPDATE_POINT_PIN;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      pins: TPin[];
      polygons: TPolygon[];
    },
  ) {}
}

export class AddImage implements Action {
  readonly type = EActionType.ADD_IMAGE;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      attachmentId: string;
      // attachmentIds: string[],
    },
  ) {}
}

export class AddImage360 implements Action {
  readonly type = EActionType.ADD_IMAGE_360;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      attachmentId: string;
      // attachmentIds: string[],
    },
  ) {}
}

export class AddVideo implements Action {
  readonly type = EActionType.ADD_VIDEO;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      attachmentId: string;
      // attachmentIds: string[],
    },
  ) {}
}

export class AddFile implements Action {
  readonly type = EActionType.ADD_FILE;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      attachmentId: string;
      // attachmentIds: string[],
    },
  ) {}
}

export class RemoveAttachments implements Action {
  readonly type = EActionType.REMOVE_ATTACHMENTS;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      containsAttachments: boolean;
    },
  ) {}
}

export class ReplaceImage implements Action {
  readonly type = EActionType.REPLACE_IMAGE;
  constructor(
    readonly payload: {
      workspaceId: string;
      _id: string;
      oldAttachmentId: string;
      newAttachmentId: string;
    },
  ) {}
}

export class AddWorkspaceSortedPointIds implements Action {
  readonly type = EActionType.ADD_WORKSPACE_SORTED_POINT_IDS;
  constructor(
    readonly payload: {
      workspaceId: string;
      sortedPointIds: string[];
    },
  ) {}
}

export class AddAllSortedPointIds implements Action {
  readonly type = EActionType.ADD_ALL_SORTED_POINT_IDS;
  constructor(
    readonly payload: {
      sortedPointIds: string[];
    },
  ) {}
}

export type ActionsUnion =
  | SetPoints
  | SetAllPoints
  | FilterPoints
  | FilterAllPoints
  | ClearPoints
  | AddPoint
  | UpdatePoint
  | UpdatePoints
  | DeletePoint
  | DeletePoints
  | SelectWorkspacePoints
  | DeselectWorkspacePoints
  | ToggleAllPointsSelection
  | TogglePointSelection
  | TogglePointsSelection
  | UpdatePointStatus
  | UpdatePointPriority
  | UpdatePointsPriorities
  | UpdatePointTitle
  | UpdatePointDescription
  | UpdatePointFlag
  | UpdatePointTags
  | UpdatePointsAssignees
  | UpdatePointAssignees
  | UpdatePointsTags
  | UpdatePointCustomField
  | UpdatePointCustomFieldBulk
  | UpdatePointsCustomFields
  | UpdatePointPin
  | UpdatePointComment
  | AddImage
  | AddImage360
  | AddVideo
  | AddFile
  | RemoveAttachments
  | AddWorkspaceSortedPointIds
  | AddAllSortedPointIds
  | ReplaceImage
  | UpdatePointReminder;
