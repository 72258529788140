import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { CustomFieldListSelectDropdownComponent } from 'src/app/project/modules/custom-fields/custom-field-list-select-dropdown/custom-field-list-select-dropdown.component';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { TCustomFieldFilter, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-filter-field-list',
  templateUrl: './filter-field-list.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldListComponent {
  @Input() ppFilters: TFilters;
  @Input() ppSelectedCF: string;
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter();
  @Output() ppSelectCf = new EventEmitter();

  EIconPath = EIconPath;

  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private customFieldsService: CustomFieldsService,
    private dropdownService: DropdownService,
  ) {}

  showList(buttonId: string, filterField: TCustomFieldFilter): void {
    const customFields = this.customFieldsService.getCustomFields();
    const index = this.ppFilters.customFields.findIndex(
      (customfield) =>
        customfield.id.toString() === filterField.id.toString() &&
        filterField.workspaceId === customfield.workspaceId,
    );
    const field = customFields[this.ppFilters.customFields[index].workspaceId][filterField.id];
    this.selectCf(filterField.id);

    this.dropdownService.setData({
      field,
      selected: this.ppFilters.customFields[index].idOfChosenElement,
    });

    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(buttonId, CustomFieldListSelectDropdownComponent, {
        suppressScrollbar: true,
        callback: (item) => {
          if (item) {
            this.ppFilters.customFields[index].idOfChosenElement = item.id;
            this.ppFilters.customFields[index].text = item.label;
            this.ppFilters.customFields[index].path = item.path;
          } else {
            this.ppFilters.customFields[index].idOfChosenElement = null;
            this.ppFilters.customFields[index].text = null;
            this.ppFilters.customFields[index].path = null;
          }

          this.ppFilters.customFields[index].excluded = false;

          this.applyFilters();
        },
        onClose: () => {
          this.selectCf(null);
        },
      });
    }
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  selectCf(selectedCf: string | number): void {
    this.ppSelectCf.emit(selectedCf);
  }
}
