import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EFleetManagementRoutesSegments } from 'src/app/project/modules/fleet-management/fleet-management.routes';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-fleet-settings-dropdown',
  templateUrl: './fleet-settings-dropdown.component.html',
  styleUrls: ['./fleet-settings-dropdown.component.scss'],
})
export class FleetSettingsDropdownComponent implements OnInit {
  canDeleteFleet: boolean;

  constructor(
    private modalService: ModalService,
    private dropdownService: DropdownService,
    private fleetService: FleetService,
    private translationPipe: TranslationPipe,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const activeFleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(activeFleetId);

    this.canDeleteFleet = fleet.assetIds.length === 0;
  }

  editFleet(): void {
    const activeFleetId = this.fleetService.getActiveFleetId();

    logEventInGTAG(EGoogleEventFleetManagement.FLEET_EDIT, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.router.navigate([
      EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
      EFleetManagementRoutesSegments.FLEET,
      activeFleetId,
      EFleetManagementRoutesSegments.SETTINGS,
    ]);

    this.dropdownService.hideDropdown();
  }

  deleteFleet(): void {
    if (!this.canDeleteFleet) {
      return;
    }

    const activeFleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(activeFleetId);

    const promptText = this.translationPipe.transform('confirm_deletion_of', {
      name: fleet.name,
      interpolation: { escapeValue: false } as any,
    });

    this.modalService.setData<TConfirmModalParams>({
      message: this.translationPipe.transform('delete_fleet_modal_body'),
      heading: promptText,
      redButton: true,
      confirmText: this.translationPipe.transform('Delete'),
    });

    this.modalService.showModal(ConfirmModalComponent, {
      closeWarning: true,
      callback: () => {
        logEventInGTAG(EGoogleEventFleetManagement.FLEET_DELETE, {
          event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
        });

        this.handleDeleteConfirmation(activeFleetId);
      },
    });

    this.dropdownService.hideDropdown();
  }

  private handleDeleteConfirmation(fleetId: string): void {
    this.modalService.hideModal();
    this.fleetService.deleteFleet(fleetId);
  }
}
