import { cloneDeep } from 'lodash';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { UpdatePoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePoints(
  oldState: TPointsByWorkspace,
  action: UpdatePoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  if (action.payload.workspaceId === EWorkspaces.OVERVIEW) {
    action.payload.changedPoints.forEach((changedPoint) => {
      const workspaceId = changedPoint.workspaceRef.id;
      const index = state[workspaceId].entities.findIndex(
        (point) => changedPoint._id === point._id,
      );

      if (index > -1) {
        state[workspaceId].entities.splice(index, 1, changedPoint);
      } else {
        state[workspaceId].entities.push(changedPoint);
      }
    });
  } else {
    if (!state[action.payload.workspaceId]) {
      state[action.payload.workspaceId] = {};
    }

    action.payload.changedPoints
      .filter((changedPoint) => changedPoint.workspaceRef.id === action.payload.workspaceId)
      .forEach((changedPoint) => {
        const index = state[action.payload.workspaceId].entities.findIndex(
          (point) => changedPoint._id === point._id,
        );

        if (index > -1) {
          state[action.payload.workspaceId].entities.splice(index, 1, changedPoint);
        } else {
          state[action.payload.workspaceId].entities.push(changedPoint);
        }
      });
  }

  return state;
}
