<div id="tabContent" class="tabContent" *ngIf="workspaces$ | async as workspaces">
  <div class="">
    <pp-grid [ppOptions]="{ bodyPadding: false }">
      <ng-container title>
        {{ 'custom_fields' | ppTranslate }}

        <i
          ppTooltip
          [ppOptions]="{ placement: 'right' }"
          class="fa fa-info-circle settingsSection__header-info"
          aria-hidden="true"
          [ppMobileTooltip]="true"
          [ppSanitize]="false"
          ppTitle="
            {{ 'site_settings_custom_fields_tooltip_1' | ppTranslate }}
            <ul style='text-align: left'>
              <li>•	<strong>{{ 'text_field' | ppTranslate }}</strong> – {{
            'text_field_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'list_field' | ppTranslate }}</strong> – {{
            'list_field_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'date_field' | ppTranslate }}</strong> – {{
            'date_field_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'cost_field' | ppTranslate }}</strong> – {{
            'cost_field_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'rich_text' | ppTranslate }}</strong> – {{
            'rich_text_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'checkbox' | ppTranslate }}</strong> – {{
            'checkbox_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'time' | ppTranslate }}</strong> – {{
            'time_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'numbers' | ppTranslate }}</strong> – {{
            'numbers_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'percentage' | ppTranslate }}</strong> – {{
            'percentage_description' | ppTranslate
          }}</li>
              <li>•	<strong>{{ 'timeline' | ppTranslate }}</strong> – {{
            'timeline_CF_description' | ppTranslate
          }}</li>
            </ul>
            <p style='text-align: left; margin-bottom: 0'>
              <strong>{{ 'tip' | ppTranslate }}:</strong> {{
            'site_settings_custom_fields_tooltip_2' | ppTranslate
          }}
            </p>"
        ></i>
      </ng-container>

      <ng-container titleBar-right>
        <pp-button
          ppId="addNewCFBtn"
          data-m-target="Add new custom field button"
          ppStyle="primary"
          ppSize="small"
          (ppAction)="addCustomField()"
          [ppDisabled]="offline"
        >
          {{ 'add_new_cf' | ppTranslate }}
        </pp-button>
      </ng-container>

      <ng-container body>
        <div *ngIf="workspaces && workspaces[workspaceId]">
          <pp-site-settings-custom-fields-table
            *ngIf="workspacesLoaded"
            [ppWorkspaceId]="workspaceId"
          ></pp-site-settings-custom-fields-table>

          <div
            class="settingsSection__no-content"
            *ngIf="
              (!workspaces[workspaceId].customFields ||
                workspaces[workspaceId].customFields.length === 0) &&
              workspacesLoaded
            "
          >
            {{ 'no_cfs_found' | ppTranslate }}
          </div>

          <div class="settingsSection__no-content" *ngIf="!workspacesLoaded">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div *ngIf="!workspaces && !workspaces[workspaceId] && offline">
          <h5 class="settingsSection__notAvailable">
            {{ 'data_unavailable_offline' | ppTranslate }}
          </h5>
        </div>
      </ng-container>
    </pp-grid>
  </div>
</div>
