<pp-modal [zIndex]="zIndex">
  <ng-container heading>
    {{ heading }}
  </ng-container>

  <ng-container body>
    <p
      [class.modal__bold]="boldText"
      [class.modal__extra-padding-top]="extraPadding"
      [innerHTML]="message"
    ></p>
    <p *ngIf="secondaryMessage" class="modal__secondary" [innerHTML]="secondaryMessage"></p>
    <div class="confirmModal__checkbox-container" *ngIf="showCheckbox">
      <pp-checkbox
        [ppChecked]="dontShowAgain"
        ppSize="large"
        ppType="solid"
        (ppOnValueChange)="dontShowAgainChecked()"
      ></pp-checkbox>
      <p class="confirmModal__checkbox-text" [innerHTML]="checkboxText"></p>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="confirmText"
      (ppCancel)="hideModal(true)"
      (ppConfirm)="hideModal(false)"
      [ppError]="redButton"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
