export enum EIconPath {
  GREEN_CHECK_CIRCLE = 'assets/icons/check_circle.svg',
  RED_CANCEL_CIRCLE = 'assets/icons/cancel.svg',
  ACTIVITY_DELETE = 'assets/images/activity/dashboard-activity-bin.svg',
  ACTIVITY_IMAGE_360_DARK = 'assets/images/activity/icon_360_dark.svg',
  ARROW_BACK = 'assets/images/arrows/arrow-back.svg',
  ARROW_DOWN_ACTIVE = 'assets/images/arrows/arrow-down-active.svg',
  ARROW_DOWN_GRAY = 'assets/images/arrows/arrow-down-gray.svg',
  ARROW_DOWN_LONG = 'assets/images/arrows/arrow-down-long.svg',
  ARROW_LEFT = 'assets/images/arrows/arrow-left.svg',
  ARROW_RIGHT_BLANK = 'assets/images/arrows/arrow-right-blank.svg',
  ARROW_RIGHT_GRAY = 'assets/images/arrows/arrow-right-gray.svg',
  ARROW_RIGHT_WHITE = 'assets/images/arrows/arrow-right-white.svg',
  ARROW_UP_ACTIVE = 'assets/images/arrows/arrow-up-active.svg',
  ARROW_UP_GRAY = 'assets/images/arrows/arrow-up-gray.svg',
  BASIC_FIELD_ASSIGNEES = 'assets/images/basic-fields/assignees.svg',
  BASIC_FIELD_COMMENT = 'assets/images/basic-fields/comment.svg',
  BASIC_FIELD_COPY = 'assets/images/basic-fields/copy.svg',
  BASIC_FIELD_DATE = 'assets/images/basic-fields/date.svg',
  BASIC_FIELD_DESCRIPTION = 'assets/images/basic-fields/description.svg',
  BASIC_FIELD_FILE = 'assets/images/basic-fields/file.svg',
  BASIC_FIELD_IMAGE = 'assets/images/basic-fields/image.svg',
  BASIC_FIELD_IMAGE_360 = 'assets/images/basic-fields/image_360.svg',
  BASIC_FIELD_LOCATION = 'assets/images/basic-fields/location.svg',
  BASIC_FIELD_PRIORITY = 'assets/images/basic-fields/priority.svg',
  BASIC_FIELD_STATUS = 'assets/images/basic-fields/status.svg',
  BASIC_FIELD_TAGS = 'assets/images/basic-fields/tags.svg',
  BASIC_FIELD_TITLE = 'assets/images/basic-fields/title.svg',
  BASIC_FIELD_VIDEO = 'assets/images/basic-fields/video.svg',
  BROWSER_CHROME = 'assets/images/browsers/chrome_128x128.png',
  BROWSER_EDGE = 'assets/images/browsers/edge_12-18_128x128.png',
  BROWSER_FIREFOX = 'assets/images/browsers/firefox_128x128.png',
  BROWSER_OPERA = 'assets/images/browsers/opera_128x128.png',
  BROWSER_SAFARI = 'assets/images/browsers/safari_128x128.png',
  CUSTOM_FIELD_CHECKBOX_ACTIVITY = 'assets/images/custom-fields/checkbox/checkbox-activity.svg',
  CUSTOM_FIELD_CHECKBOX_BLUE = 'assets/images/custom-fields/checkbox/checkbox.svg',
  CUSTOM_FIELD_COST_ACTIVITY = 'assets/images/custom-fields/cost/cost-activity.svg',
  CUSTOM_FIELD_COST_BLUE = 'assets/images/custom-fields/cost/cost.svg',
  CUSTOM_FIELD_DATE_ACTIVITY = 'assets/images/custom-fields/date/date-activity.svg',
  CUSTOM_FIELD_DATE_BLUE = 'assets/images/custom-fields/date/date.svg',
  CUSTOM_FIELD_LIST_ACTIVITY = 'assets/images/custom-fields/list/list-activity.svg',
  CUSTOM_FIELD_LIST_BLUE = 'assets/images/custom-fields/list/list.svg',
  CUSTOM_FIELD_NUMBERS_ACTIVITY = 'assets/images/custom-fields/numbers/numbers-activity.svg',
  CUSTOM_FIELD_NUMBERS_BLUE = 'assets/images/custom-fields/numbers/numbers.svg',
  CUSTOM_FIELD_PERCENTAGE_ACTIVITY = 'assets/images/custom-fields/percentage/percentage-activity.svg',
  CUSTOM_FIELD_PERCENTAGE_BLUE = 'assets/images/custom-fields/percentage/percentage.svg',
  CUSTOM_FIELD_RICH_TEXT_ACTIVITY = 'assets/images/custom-fields/rich-text/rich-text-activity.svg',
  CUSTOM_FIELD_RICH_TEXT_BLUE = 'assets/images/custom-fields/rich-text/rich-text.svg',
  CUSTOM_FIELD_TEXT_ACTIVITY = 'assets/images/custom-fields/text/text-activity.svg',
  CUSTOM_FIELD_TEXT_BLUE = 'assets/images/custom-fields/text/text.svg',
  CUSTOM_FIELD_TIMELINE_ACTIVITY = 'assets/images/custom-fields/timeline/timeline-activity.svg',
  CUSTOM_FIELD_TIMELINE_BLUE = 'assets/images/custom-fields/timeline/timeline.svg',
  CUSTOM_FIELD_TIME_ACTIVITY = 'assets/images/custom-fields/time/time-activity.svg',
  CUSTOM_FIELD_TIME_BLUE = 'assets/images/custom-fields/time/time.svg',
  EXPORT_CSV = 'assets/images/export-types/csv.png',
  EXPORT_EXPORT = 'assets/images/interactions/export/export.svg',
  EXPORT_EXPORT_BLUE = 'assets/images/interactions/export/export-blue.svg',
  EXPORT_PDF = 'assets/images/export-types/pdf.svg',
  EXPORT_PLAN = 'assets/images/export-types/map-regular.svg',
  EXPORT_WORD = 'assets/images/export-types/word.png',
  EXPORT_ZIP = 'assets/images/export-types/zip.png',
  FLOATING_ACTIONS_CLEAR = 'assets/images/floating-actions/clear.svg',
  FLOATING_ACTIONS_EXPORT = 'assets/images/floating-actions/export.svg',
  FLOATING_ACTIONS_EXPORT_BLUE = 'assets/images/floating-actions/export-highlight.svg',
  GALLERY_ARROW_RIGHT = 'assets/images/gallery/arrow-right.svg',
  GALLERY_CIRCLE = 'assets/images/gallery/circle.svg',
  GALLERY_CLOSE = 'assets/images/gallery/close.svg',
  GALLERY_DELETE = 'assets/images/gallery/delete-icon.svg',
  GALLERY_DOWNLOAD = 'assets/images/gallery/download.svg',
  GALLERY_FREE_DRAWING = 'assets/images/gallery/free-drawing.svg',
  GALLERY_GO_LEFT = 'assets/images/gallery/go-left.svg',
  GALLERY_GO_RIGHT = 'assets/images/gallery/go-right.svg',
  GALLERY_ROTATE = 'assets/images/gallery/rotate.svg',
  GALLERY_TEXT = 'assets/images/gallery/text.svg',
  GALLERY_ZOOM = 'assets/images/gallery/zoom.svg',
  GALLERY_ZOOM_OUT = 'assets/images/gallery/zoom-out.svg',
  INPUT_CHECKBOX = 'assets/images/input/checkbox/checkbox.svg',
  INPUT_CHECKBOX_2 = 'assets/images/input/checkbox/checkbox2.svg',
  INPUT_CHECKBOX_BLUE = 'assets/images/input/checkbox/check-blue.svg',
  INPUT_CHECKBOX_CHECK = 'assets/images/input/checkbox/checkbox-check.svg',
  INPUT_CHECKBOX_CHECK_BLUE_PNG = 'assets/images/input/checkbox/icon-check-lg-blue.png',
  INPUT_CHECKBOX_DISABLED = 'assets/images/input/checkbox/checkbox-disabled.svg',
  INPUT_CHECKBOX_WHITE = 'assets/images/input/checkbox/check-white.svg',
  INPUT_COG_BLUE = 'assets/images/input/cog/cog-blue.svg',
  INPUT_COG_DEFAULT = 'assets/images/input/cog/cog-default.svg',
  INPUT_COG_GRAY = 'assets/images/input/cog/cog-gray.svg',
  INPUT_CONFIRM_CHECK = 'assets/images/input/confirm/confirm-check.svg',
  INPUT_CONFIRM_CHECK_GREEN = 'assets/images/input/confirm/confirm-check-green.svg',
  INPUT_RADIO = 'assets/images/input/radio/radio.svg',
  INPUT_RADIO_BLUE = 'assets/images/input/radio/radio-blue.svg',
  INPUT_RADIO_DISABLED = 'assets/images/input/radio/radio-disabled.svg',
  INPUT_UPLOAD_ILLUSTRATION = 'assets/images/input/upload/upload-illustration.png',
  INPUT_TOGGLE_SITE_PLAN = 'assets/images/input/toggle/icon-point-list.svg',
  INPUT_TOGGLE_POINT_LIST = 'assets/images/input/toggle/icon-site-plan.svg',
  INTERACTION_CARET_DOWN_BLUE = 'assets/images/input/caret/caret-down-blue.svg',
  INTERACTION_CARET_DOWN_GRAY = 'assets/images/input/caret/caret-down-gray.svg',
  INTERACTION_CLOSE = 'assets/images/interactions/close/close.svg',
  INTERACTION_CLOSE_ACCOUNT = 'assets/images/interactions/close/close-account.svg',
  INTERACTION_CLOSE_BLUE = 'assets/images/interactions/close/close-blue.svg',
  INTERACTION_CLOSE_REMOVE = 'assets/images/interactions/close/remove.svg',
  INTERACTION_CLOSE_WHITE = 'assets/images/interactions/close/close-white.svg',
  INTERACTION_COLLAPSE = 'assets/images/interactions/collapse/collapse.svg',
  INTERACTION_COLUMNS_ICON = 'assets/images/interactions/columns/columns-icon.svg',
  INTERACTION_COLUMNS_ICON_BLUE = 'assets/images/interactions/columns/columns-icon-blue.svg',
  INTERACTION_DELETE = 'assets/images/interactions/delete/delete.svg',
  INTERACTION_DELETE_ATTACHMENT_ICON = 'assets/images/interactions/delete/delete-attachment-icon.svg',
  INTERACTION_DELETE_BLUE = 'assets/images/interactions/delete/delete-highlight.svg',
  INTERACTION_DELETE_TEXT = 'assets/images/interactions/delete/delete-text.svg',
  INTERACTION_DELETE_X = 'assets/images/interactions/delete/delete-x.svg',
  INTERACTION_DOWNLOAD = 'assets/images/interactions/download/download.svg',
  INTERACTION_DRAG_INDICATOR = 'assets/images/interactions/drag/grab.svg',
  INTERACTION_EDIT = 'assets/images/interactions/edit/edit.svg',
  INTERACTION_EDIT_BLUE = 'assets/images/interactions/edit/edit-highlight.svg',
  INTERACTION_EDIT_WHITE = 'assets/images/interactions/edit/edit-white.svg',
  INTERACTION_EXPAND = 'assets/images/interactions/expand/expand.svg',
  INTERACTION_EXPAND_ICON = 'assets/images/interactions/expand/expand-icon.svg',
  INTERACTION_FILTER = 'assets/images/interactions/filter/filter.svg',
  INTERACTION_FILTER_BLUE = 'assets/images/interactions/filter/filter-selected.svg',
  INTERACTION_LIKE = 'assets/images/interactions/like/like.svg',
  INTERACTION_LIKE_FILLED = 'assets/images/interactions/like/like-filled.svg',
  INTERACTION_OPTIONS_DASHBOARD = 'assets/images/interactions/options/dashboard-options.svg',
  INTERACTION_PIN_DELETE = 'assets/images/interactions/pin-delete/pin-delete.svg',
  INTERACTION_PIN_DELETE_HOVER = 'assets/images/interactions/pin-delete/pin-delete-hover.svg',
  INTERACTION_POINT_PREVIEW = 'assets/images/point-interactions/preview.svg',
  INTERACTION_POINT_RENAME = 'assets/images/point-interactions/rename.svg',
  INTERACTION_SORT_ACTIVE = 'assets/images/search/sort-active.svg',
  INTERACTION_SORT_ASCENDING = 'assets/images/interactions/sort/sort-ascending.svg',
  INTERACTION_SORT_DESCENDING = 'assets/images/interactions/sort/sort-descending.svg',
  INTERACTION_SORT_INACTIVE = 'assets/images/search/sort-inactive.svg',
  INTERACTION_SUBSCRIBE_ACTIVE = 'assets/images/interactions/subscribe/subscribe-active.svg',
  INTERACTION_SUBSCRIBE_INACTIVE = 'assets/images/interactions/subscribe/subscribe-inactive.svg',
  INTERACTION_TIMEFRAME = 'assets/images/interactions/timeframe/timeframe_new.svg',
  INTERACTION_UPLOAD = 'assets/images/interactions/upload/upload.svg',
  LOGO = 'assets/images/logos/logo.svg',
  LOGO_2FA = 'assets/images/logos/logo-2fa.svg',
  LOGO_SMALL = 'assets/images/logos/logo-small.svg',
  MISC_BACKGROUND_DARK = 'assets/images/misc/bg-dark.png',
  MISC_ENVELOPE = 'assets/images/misc/envelope.svg',
  MISC_INFO = 'assets/images/misc/info.svg',
  MISC_INFO_WHITE = 'assets/images/misc/info-icon.svg',
  MISC_MORE = 'assets/images/misc/more.svg',
  MISC_PAPERCLIP = 'assets/images/misc/paperclip-diagonal.svg',
  MISC_USER = 'assets/images/misc/user.svg',
  NOTIFICATION_BELL = 'assets/images/notifications/notification-bell.svg',
  NOTIFICATION_BELL_NEW_NOTIFICATIONS = 'assets/images/notifications/notification-bell-new.svg',
  NOTIFICATION_CHECK = 'assets/images/notifications/notification-check.svg',
  NOTIFICATION_CHECK_BLUE = 'assets/images/notifications/notification-check-blue.svg',
  NOTIFICATION_CHECK_UNREAD = 'assets/images/notifications/notification-check-unread.svg',
  NOTIFICATION_COMMENT_CLOSE = 'assets/images/notifications/notification-comment-close.svg',
  NOTIFICATION_ICON_ASSIGNEES = 'assets/images/notifications/icons/assignees.svg',
  NOTIFICATION_ICON_COMMENT = 'assets/images/notifications/icons/comment.svg',
  NOTIFICATION_ICON_DATE = 'assets/images/notifications/icons/date.svg',
  NOTIFICATION_ICON_DESCRIPTION = 'assets/images/notifications/icons/description.svg',
  NOTIFICATION_ICON_FILE = 'assets/images/notifications/icons/file.svg',
  NOTIFICATION_ICON_IMAGE = 'assets/images/notifications/icons/image.svg',
  NOTIFICATION_ICON_IMAGE_360 = 'assets/images/notifications/icons/image_360.svg',
  NOTIFICATION_ICON_LOCATION = 'assets/images/notifications/icons/location.svg',
  NOTIFICATION_ICON_PRIORITY = 'assets/images/notifications/icons/priority.svg',
  NOTIFICATION_ICON_STATUS = 'assets/images/notifications/icons/status.svg',
  NOTIFICATION_ICON_TAGS = 'assets/images/notifications/icons/tags.svg',
  NOTIFICATION_ICON_TITLE = 'assets/images/notifications/icons/title.svg',
  NOTIFICATION_ICON_VIDEO = 'assets/images/notifications/icons/video.svg',
  NOTIFICATION_MARK_ALL_AS_READ = 'assets/images/notifications/mark-all-as-read.svg',
  NOTIFICATION_MARK_ALL_AS_READ_BLUE = 'assets/images/notifications/mark-all-as-read-blue.svg',
  NOTIFICATION_RESPOND = 'assets/images/notifications/notification-respond.svg',
  NOTIFICATION_RESPOND_BLUE = 'assets/images/notifications/notification-respond-blue.svg',
  NOTIFICATION_SEND = 'assets/images/notifications/send.svg',
  NOTIFICATION_SENT_TICK = 'assets/images/notifications/sent-tick.svg',
  PLACEHOLDER_IMAGE = 'assets/images/placeholders/image-placeholder.svg',
  PLACEHOLDER_MAP = 'assets/images/placeholders/map_placeholder.svg',
  PLACEHOLDER_USER = 'assets/images/placeholders/auth_placeholder.png',
  PLAN_PIN_ACTIVE = 'assets/images/site-plan/pin-active.svg',
  PLAN_PIN_INACTIVE = 'assets/images/site-plan/pin-inactive.svg',
  PLAN_POLYGON_ACTIVE = 'assets/images/site-plan/polygon-active.svg',
  PLAN_POLYGON_INACTIVE = 'assets/images/site-plan/polygon-inactive.svg',
  PLAN_ZOOM_IN = 'assets/images/site-plan/zoom-in.svg',
  PLAN_ZOOM_OUT = 'assets/images/site-plan/zoom-out.svg',
  PLUS = 'assets/images/plus/plus.svg',
  PLUS_BLUE = 'assets/images/plus/plus-blue.svg',
  PLUS_NEW_SITE = 'assets/images/plus/new-site-button.svg',
  PLUS_THICKER = 'assets/images/plus/icon-plus-thicker.svg',
  POINT_ACTIVITY_MOVE = 'assets/images/activity/move.svg',
  POINT_INTERACTION_COPY = 'assets/images/point-interactions/copy.svg',
  POINT_INTERACTION_COPY_BLUE = 'assets/images/point-interactions/copy-highlight.svg',
  POINT_INTERACTION_MOVE = 'assets/images/point-interactions/move.svg',
  POINT_INTERACTION_MOVE_BLUE = 'assets/images/point-interactions/move-highlight.svg',
  POINT_INTERACTION_OPEN_IN_SITE = 'assets/images/point-interactions/open-in-site.svg',
  REMINDER_ALERT_ICON = 'assets/images/reminders/reminders-alert-icon.svg',
  REMINDER_COLLAPSE = 'assets/images/reminders/collapse.svg',
  REMINDER_EXPAND = 'assets/images/reminders/expand.svg',
  REMINDER_NOTE = 'assets/images/reminders/reminder-note.svg',
  REMINDER_POINT = 'assets/images/reminders/reminder-point.svg',
  SEARCH_CLOSE = 'assets/images/search/search-close.svg',
  SEARCH_DARK = 'assets/images/search/search-dark.svg',
  SEARCH_ICON = 'assets/images/search/search-icon.svg',
  SIDE_PANEL_BACK_ARROW = 'assets/images/side-panel/back-arrow.svg',
  SIDE_PANEL_DASHBOARD = 'assets/images/side-panel/dashboard.svg',
  SIDE_PANEL_FOLDER = 'assets/images/side-panel/folder.svg',
  SIDE_PANEL_FOLDER_CLOSED = 'assets/images/side-panel/folder-closed.svg',
  SIDE_PANEL_MENU = 'assets/images/side-panel/menu.svg',
  SIDE_PANEL_OVERVIEW = 'assets/images/side-panel/overview.svg',
  SIDE_PANEL_FLAG = 'assets/images/point-interactions/flag.svg',
  SIDE_PANEL_REMINDER = 'assets/images/side-panel/reminder.svg',
  SIDE_PANEL_REMINDER_NOTIFICATION = 'assets/images/side-panel/reminder--notification.svg',
  SIDE_PANEL_TIMELINE = 'assets/images/side-panel/timeline.svg',
  STATUS_CANCELED = 'assets/images/basic-fields/status-icons/status--CANCELED.svg',
  STATUS_CANCELED_WHITE = 'assets/images/basic-fields/status-icons/status--CANCELED--white.svg',
  STATUS_CLOSED = 'assets/images/basic-fields/status-icons/status--CLOSED.svg',
  STATUS_CLOSED_WHITE = 'assets/images/basic-fields/status-icons/status--CLOSED--white.svg',
  STATUS_IN_PROGRESS = 'assets/images/basic-fields/status-icons/status--IN_PROGRESS.svg',
  STATUS_IN_PROGRESS_WHITE = 'assets/images/basic-fields/status-icons/status--IN_PROGRESS--white.svg',
  STATUS_ON_HOLD = 'assets/images/basic-fields/status-icons/status--ONHOLD.svg',
  STATUS_ON_HOLD_WHITE = 'assets/images/basic-fields/status-icons/status--ONHOLD--white.svg',
  STATUS_OPEN = 'assets/images/basic-fields/status-icons/status--OPEN.svg',
  STATUS_OPEN_WHITE = 'assets/images/basic-fields/status-icons/status--OPEN--white.svg',
  STATUS_TO_REVIEW = 'assets/images/basic-fields/status-icons/status--TO_REVIEW.svg',
  STATUS_TO_REVIEW_WHITE = 'assets/images/basic-fields/status-icons/status--TO_REVIEW--white.svg',
  SUCCESS_2FA = 'assets/images/misc/success-2fa.svg',
  USER_DROPDOWN_COG = 'assets/images/user-dropdown-icons/cog.svg',
  USER_DROPDOWN_CONTACT = 'assets/images/user-dropdown-icons/contact.svg',
  USER_DROPDOWN_COOKIES = 'assets/images/user-dropdown-icons/cookies.svg',
  USER_DROPDOWN_LOGO = 'assets/images/user-dropdown-icons/logo.svg',
  USER_DROPDOWN_LOGOUT = 'assets/images/user-dropdown-icons/logout.svg',
  USER_DROPDOWN_MEGAPHONE = 'assets/images/user-dropdown-icons/megaphone.svg',
  ICON_SIDE_PANEL_ARROW_DOWN = 'assets/new-images/arrows/side-panel-arrow-down.svg',
  ICON_SIDE_PANEL_ARROW_RIGHT = 'assets/new-images/arrows/side-panel-arrow-right.svg',
  ICON_COG_GREY = 'assets/new-images/inputs/cog/cog-gray.svg',
  ICON_PLUS_NEW_SITE = 'assets/new-images/plus/new-site-button.svg',
  ICON_MISC_INFO = 'assets/new-images/misc/info.svg',
  ICON_ARROW_DOWN = 'assets/new-images/arrows/arrow-down.svg',
  ICON_INTERACTION_DRAG_INDICATOR = 'assets/new-images/interactions/drag/drag-indicator.svg',
  ICON_DRAG_14 = 'assets/new-images/interactions/drag/drag_icon_14.svg',
  ICON_DRAG_18 = 'assets/new-images/interactions/drag/drag_icon_18.svg',
  ICON_DRAG_24 = 'assets/new-images/interactions/drag/drag_icon_24.svg',
  ICON_SEARCH_CLOSE = 'assets/new-images/search/search-close.svg',
  ICON_THREE_DOTS = 'assets/new-images/interactions/options/three-dots-vertical-5.svg',
  ICON_INTERACTION_SORT = 'assets/new-images/interactions/search/sort.svg',
  ICON_PLUS = 'assets/new-images/plus/plus.svg',
  ICON_THREE_DOTS_5 = 'assets/new-images/interactions/three-dots.svg',
  ICON_INTERACTION_FILTER = 'assets/new-images/interactions/filter/filter-18.svg',
  ICON_MARK_ALL_READ = 'assets/new-images/misc/mark-all-read.svg',
  ICON_CLEAR_ALL = 'assets/new-images/interactions/close/clear-all.svg',
  ICON_CHECKMARK_TRUE_SMALL = 'assets/new-images/inputs/checkmark/checkmark-true-small.svg',
  ICON_CHECKMARK_TRUE_LARGE = 'assets/new-images/inputs/checkmark/checkmark-true-large.svg',
  ICON_CHECKMARK_HALF_LARGE = 'assets/new-images/inputs/checkmark/checkmark-half-large.svg',
  ICON_CHECKMARK_HALF_SMALL = 'assets/new-images/inputs/checkmark/checkmark-half-small.svg',
  ICON_CHECKMARK_FALSE_LARGE = 'assets/new-images/inputs/checkmark/checkmark-false-large.svg',
  ICON_CHECKMARK_FALSE_SMALL = 'assets/new-images/inputs/checkmark/checkmark-false-small.svg',
  ICON_CHECHMARK_TABLE_SMALL = 'assets/new-images/inputs/checkmark/checkmark-table-small.svg',
  ICON_CHECHMARK_TABLE_FALSE = 'assets/new-images/inputs/checkmark/checkmark-table-false.svg',
  ICON_BADGE_INFO = 'assets/new-images/badges/info/info.svg',
  ICON_INPUT_DRAG = 'assets/new-images/inputs/drag/drag-indicator.svg',
  ICON_CLOSE_18 = 'assets/new-images/close/close-18.svg',
  ICON_CLOSE_24 = 'assets/new-images/close/close-24.svg',
  ICON_CLOSE_14 = 'assets/new-images/close/close-14.svg',
  ICON_COPY_LINK_24 = 'assets/new-images/icons/copy-link/copy-link-24.svg',
  ICON_COPY_LINK_18 = 'assets/new-images/icons/copy-link/copy-link-24.svg',
  ICON_COPY_LINK_14 = 'assets/new-images/icons/copy-link/copy-link-24.svg',
  ADVANCED_FILTERS_CLEAR_14 = 'assets/new-images/icons/misc/clear/clear-14.svg',
  ADVANCED_FILTERS_CLEAR_18 = 'assets/new-images/icons/misc/clear/clear-18.svg',
  ADVANCED_FILTERS_CLEAR_24 = 'assets/new-images/icons/misc/clear/clear-24.svg',
  ICON_GROUP_24 = 'assets/new-images/interactions/group/group-24.svg',
  ICON_ACTIVITIES_360_24 = 'assets/new-images/icons/activities/360-24.svg',
  ICON_ACTIVITIES_360_18 = 'assets/new-images/icons/activities/360-18.svg',
  ICON_ACTIVITIES_360_14 = 'assets/new-images/icons/activities/360-14.svg',
  ICON_BIN_24 = 'assets/new-images/icons/activities/bin-icon/bin-icon-24.svg',
  ICON_BIN_18 = 'assets/new-images/icons/activities/bin-icon/bin-icon-18.svg',
  ICON_BIN_14 = 'assets/new-images/icons/activities/bin-icon/bin-icon-14.svg',
  ICON_ACTIVITIES_COMMENT_24 = 'assets/new-images/icons/activities/comment/comment-24.svg',
  ICON_ACTIVITIES_COMMENT_18 = 'assets/new-images/icons/activities/comment/comment-18.svg',
  ICON_ACTIVITIES_COMMENT_14 = 'assets/new-images/icons/activities/comment/comment-14.svg',
  ICON_ACTIVITIES_COPY_24 = 'assets/new-images/icons/activities/copy/copy-24.svg',
  ICON_ACTIVITIES_COPY_18 = 'assets/new-images/icons/activities/copy/copy-18.svg',
  ICON_ACTIVITIES_COPY_14 = 'assets/new-images/icons/activities/copy/copy-14.svg',
  ICON_ACTIVITIES_DESCRIPTION_24 = 'assets/new-images/icons/activities/description/description-24.svg',
  ICON_ACTIVITIES_DESCRIPTION_18 = 'assets/new-images/icons/activities/description/description-18.svg',
  ICON_ACTIVITIES_DESCRIPTION_14 = 'assets/new-images/icons/activities/description/description-14.svg',
  ICON_ACTIVITIES_IMAGE_24 = 'assets/new-images/icons/activities/image/image-24.svg',
  ICON_ACTIVITIES_IMAGE_18 = 'assets/new-images/icons/activities/image/image-18.svg',
  ICON_ACTIVITIES_IMAGE_14 = 'assets/new-images/icons/activities/image/image-14.svg',
  ICON_ACTIVITIES_LOCATION_24 = 'assets/new-images/icons/activities/location/location-24.svg',
  ICON_ACTIVITIES_LOCATION_18 = 'assets/new-images/icons/activities/location/location-18.svg',
  ICON_ACTIVITIES_LOCATION_14 = 'assets/new-images/icons/activities/location/location-14.svg',
  ICON_ACTIVITIES_PRIORITY_24 = 'assets/new-images/icons/activities/priority/priority-24.svg',
  ICON_ACTIVITIES_PRIORITY_18 = 'assets/new-images/icons/activities/priority/priority-18.svg',
  ICON_ACTIVITIES_PRIORITY_14 = 'assets/new-images/icons/activities/priority/priority-14.svg',
  ICON_ACTIVITIES_STATUS_24 = 'assets/new-images/icons/activities/status/status-24.svg',
  ICON_ACTIVITIES_STATUS_18 = 'assets/new-images/icons/activities/status/status-18.svg',
  ICON_ACTIVITIES_STATUS_14 = 'assets/new-images/icons/activities/status/status-14.svg',
  ICON_ACTIVITIES_TAGS_24 = 'assets/new-images/icons/activities/tags/tags-24.svg',
  ICON_ACTIVITIES_TAGS_18 = 'assets/new-images/icons/activities/tags/tags-18.svg',
  ICON_ACTIVITIES_TAGS_14 = 'assets/new-images/icons/activities/tags/tags-14.svg',
  ICON_ACTIVITIES_TEXT_24 = 'assets/new-images/icons/activities/text/text-24.svg',
  ICON_ACTIVITIES_TEXT_18 = 'assets/new-images/icons/activities/text/text-18.svg',
  ICON_ACTIVITIES_TEXT_14 = 'assets/new-images/icons/activities/text/text-14.svg',
  ICON_ACTIVITIES_USER_24 = 'assets/new-images/icons/activities/user/user-24.svg',
  ICON_ACTIVITIES_USER_18 = 'assets/new-images/icons/activities/user/user-18.svg',
  ICON_ACTIVITIES_USER_14 = 'assets/new-images/icons/activities/user/user-14.svg',
  ICON_ACTIVITIES_VIDEO_24 = 'assets/new-images/icons/activities/video/video-24.svg',
  ICON_ACTIVITIES_VIDEO_18 = 'assets/new-images/icons/activities/video/video-18.svg',
  ICON_ACTIVITIES_VIDEO_14 = 'assets/new-images/icons/activities/video/video-14.svg',
  ICON_FIELDS_CHECKBOX_24 = 'assets/new-images/icons/fields/checkbox/checkbox-24.svg',
  ICON_FIELDS_CHECKBOX_18 = 'assets/new-images/icons/fields/checkbox/checkbox-18.svg',
  ICON_FIELDS_CHECKBOX_14 = 'assets/new-images/icons/fields/checkbox/checkbox-14.svg',
  ICON_FIELDS_COST_24 = 'assets/new-images/icons/fields/cost/cost-24.svg',
  ICON_FIELDS_COST_18 = 'assets/new-images/icons/fields/cost/cost-18.svg',
  ICON_FIELDS_COST_14 = 'assets/new-images/icons/fields/cost/cost-14.svg',
  ICON_FIELDS_DATE_24 = 'assets/new-images/icons/fields/date/date-24.svg',
  ICON_FIELDS_DATE_18 = 'assets/new-images/icons/fields/date/date-18.svg',
  ICON_FIELDS_DATE_14 = 'assets/new-images/icons/fields/date/date-14.svg',
  ICON_FIELDS_LIST_24 = 'assets/new-images/icons/fields/list/list-24.svg',
  ICON_FIELDS_LIST_18 = 'assets/new-images/icons/fields/list/list-18.svg',
  ICON_FIELDS_LIST_14 = 'assets/new-images/icons/fields/list/list-14.svg',
  ICON_FIELDS_NUMBERS_24 = 'assets/new-images/icons/fields/numbers/numbers-24.svg',
  ICON_FIELDS_NUMBERS_18 = 'assets/new-images/icons/fields/numbers/numbers-18.svg',
  ICON_FIELDS_NUMBERS_14 = 'assets/new-images/icons/fields/numbers/numbers-14.svg',
  ICON_FIELDS_PERCENTAGE_24 = 'assets/new-images/icons/fields/percentage/percentage-24.svg',
  ICON_FIELDS_PERCENTAGE_18 = 'assets/new-images/icons/fields/percentage/percentage-18.svg',
  ICON_FIELDS_PERCENTAGE_14 = 'assets/new-images/icons/fields/percentage/percentage-14.svg',
  ICON_FIELDS_RICH_TEXT_24 = 'assets/new-images/icons/fields/rich-text/rich-text-24.svg',
  ICON_FIELDS_RICH_TEXT_18 = 'assets/new-images/icons/fields/rich-text/rich-text-18.svg',
  ICON_FIELDS_RICH_TEXT_14 = 'assets/new-images/icons/fields/rich-text/rich-text-14.svg',
  ICON_FIELDS_TEXT_24 = 'assets/new-images/icons/fields/text/text-24.svg',
  ICON_FIELDS_TEXT_18 = 'assets/new-images/icons/fields/text/text-18.svg',
  ICON_FIELDS_TEXT_14 = 'assets/new-images/icons/fields/text/text-14.svg',
  ICON_FIELDS_TIME_24 = 'assets/new-images/icons/fields/time/time-24.svg',
  ICON_FIELDS_TIME_18 = 'assets/new-images/icons/fields/time/time-18.svg',
  ICON_FIELDS_TIME_14 = 'assets/new-images/icons/fields/time/time-14.svg',
  ICON_FIELDS_TIMELINE_24 = 'assets/new-images/icons/fields/timeline/timeline-24.svg',
  ICON_FIELDS_TIMELINE_18 = 'assets/new-images/icons/fields/timeline/timeline-18.svg',
  ICON_FIELDS_TIMELINE_14 = 'assets/new-images/icons/fields/timeline/timeline-14.svg',
  ICON_TABLE_ICON = 'assets/new-images/icons/table-menu/table-icon.svg',
  ICON_TABLE_GROUP = 'assets/new-images/icons/table-menu/group.svg',
  ICON_TABLE_FILTER = 'assets/new-images/icons/table-menu/filter.svg',
  ICON_TABLE_EXPORT = 'assets/new-images/icons/table-menu/export.svg',
  ICON_TABLE_VIEWS = 'assets/new-images/icons/table-menu/views.svg',
  ICON_TABLE_SORT = 'assets/new-images/icons/table-menu/sort.svg',
  ICON_MISC_3_DOTS_MENU_24 = 'assets/new-images/icons/misc/3-dots-menu/3-dots-menu-24.svg',
  ICON_MISC_3_DOTS_MENU_18 = 'assets/new-images/icons/misc/3-dots-menu/3-dots-menu-18.svg',
  ICON_MISC_3_DOTS_MENU_14 = 'assets/new-images/icons/misc/3-dots-menu/3-dots-menu-14.svg',
  ICON_MISC_A_Z_24 = 'assets/new-images/icons/misc/a-z/a-z-24.svg',
  ICON_MISC_A_Z_18 = 'assets/new-images/icons/misc/a-z/a-z-18.svg',
  ICON_MISC_A_Z_14 = 'assets/new-images/icons/misc/a-z/a-z-14.svg',
  ICON_MISC_ATTACH_24 = 'assets/new-images/icons/misc/attach/attach-24.svg',
  ICON_MISC_ATTACH_18 = 'assets/new-images/icons/misc/attach/attach-18.svg',
  ICON_MISC_ATTACH_14 = 'assets/new-images/icons/misc/attach/attach-14.svg',
  ICON_MISC_BELL_24 = 'assets/new-images/icons/misc/bell/bell-24.svg',
  ICON_MISC_BELL_18 = 'assets/new-images/icons/misc/bell/bell-18.svg',
  ICON_MISC_BELL_14 = 'assets/new-images/icons/misc/bell/bell-14.svg',
  ICON_MISC_BELL_PLUS_24 = 'assets/new-images/icons/misc/bell-plus/bell-plus-24.svg',
  ICON_MISC_BELL_PLUS_18 = 'assets/new-images/icons/misc/bell-plus/bell-plus-18.svg',
  ICON_MISC_BELL_PLUS_14 = 'assets/new-images/icons/misc/bell-plus/bell-plus-14.svg',
  ICON_MISC_CLOSE_24 = 'assets/new-images/icons/misc/close/close-24.svg',
  ICON_MISC_CLOSE_18 = 'assets/new-images/icons/misc/close/close-18.svg',
  ICON_MISC_CLOSE_14 = 'assets/new-images/icons/misc/close/close-14.svg',
  ICON_MISC_CLOSE_ARROWS_24 = 'assets/new-images/icons/misc/close-arrows/close-arrows-24.svg',
  ICON_MISC_CLOSE_ARROWS_18 = 'assets/new-images/icons/misc/close-arrows/close-arrows-18.svg',
  ICON_MISC_CLOSE_ARROWS_14 = 'assets/new-images/icons/misc/close-arrows/close-arrows-14.svg',
  ICON_MISC_COPY_24 = 'assets/new-images/icons/misc/copy/copy-24.svg',
  ICON_MISC_COPY_18 = 'assets/new-images/icons/misc/copy/copy-18.svg',
  ICON_MISC_COPY_14 = 'assets/new-images/icons/misc/copy/copy-14.svg',
  ICON_MISC_DOWN_ARROW_24 = 'assets/new-images/icons/misc/down-arrow/down-arrow-24.svg',
  ICON_MISC_DOWN_ARROW_18 = 'assets/new-images/icons/misc/down-arrow/down-arrow-18.svg',
  ICON_MISC_DOWN_ARROW_14 = 'assets/new-images/icons/misc/down-arrow/down-arrow-14.svg',
  ICON_MISC_DOWN_CHEVRON_LARGE = 'assets/new-images/icons/misc/down-chevron/down_chevron_large.svg',
  ICON_MISC_DOWN_CHEVRON_24 = 'assets/new-images/icons/misc/down-chevron/down-chevron-24.svg',
  ICON_MISC_DOWN_CHEVRON_18 = 'assets/new-images/icons/misc/down-chevron/down-chevron-18.svg',
  ICON_MISC_DOWN_CHEVRON_14 = 'assets/new-images/icons/misc/down-chevron/down-chevron-14.svg',
  ICON_MISC_DOWNLOAD_24 = 'assets/new-images/icons/misc/download/download-24.svg',
  ICON_MISC_DOWNLOAD_18 = 'assets/new-images/icons/misc/download/download-18.svg',
  ICON_MISC_DOWNLOAD_14 = 'assets/new-images/icons/misc/download/download-14.svg',
  ICON_MISC_EDIT_24 = 'assets/new-images/icons/misc/edit/edit-24.svg',
  ICON_MISC_EDIT_18 = 'assets/new-images/icons/misc/edit/edit-18.svg',
  ICON_MISC_EDIT_14 = 'assets/new-images/icons/misc/edit/edit-14.svg',
  ICON_MISC_GRAB_24 = 'assets/new-images/icons/misc/grab/grab-24.svg',
  ICON_MISC_GRAB_18 = 'assets/new-images/icons/misc/grab/grab-18.svg',
  ICON_MISC_GRAB_14 = 'assets/new-images/icons/misc/grab/grab-14.svg',
  ICON_MISC_LEFT_ARROW_24 = 'assets/new-images/icons/misc/left-arrow/left-arrow-24.svg',
  ICON_MISC_LEFT_ARROW_18 = 'assets/new-images/icons/misc/left-arrow/left-arrow-18.svg',
  ICON_MISC_LEFT_ARROW_14 = 'assets/new-images/icons/misc/left-arrow/left-arrow-14.svg',
  ICON_MISC_LEFT_CHEVRON_24 = 'assets/new-images/icons/misc/left-chevron/left-chevron-24.svg',
  ICON_MISC_LEFT_CHEVRON_18 = 'assets/new-images/icons/misc/left-chevron/left-chevron-18.svg',
  ICON_MISC_LEFT_CHEVRON_14 = 'assets/new-images/icons/misc/left-chevron/left-chevron-14.svg',
  ICON_MISC_LINK_24 = 'assets/new-images/icons/misc/link/link-24.svg',
  ICON_MISC_LINK_18 = 'assets/new-images/icons/misc/link/link-18.svg',
  ICON_MISC_LINK_14 = 'assets/new-images/icons/misc/link/link-14.svg',
  ICON_MISC_MAIL_24 = 'assets/new-images/icons/misc/mail/mail-24.svg',
  ICON_MISC_MAIL_18 = 'assets/new-images/icons/misc/mail/mail-18.svg',
  ICON_MISC_MAIL_14 = 'assets/new-images/icons/misc/mail/mail-14.svg',
  ICON_MISC_MOVE_24 = 'assets/new-images/icons/misc/move/move-24.svg',
  ICON_MISC_MOVE_18 = 'assets/new-images/icons/misc/move/move-18.svg',
  ICON_MISC_MOVE_14 = 'assets/new-images/icons/misc/move/move-14.svg',
  ICON_MISC_OPEN_24 = 'assets/new-images/icons/misc/open/open-24.svg',
  ICON_MISC_OPEN_18 = 'assets/new-images/icons/misc/open/open-18.svg',
  ICON_MISC_OPEN_14 = 'assets/new-images/icons/misc/open/open-14.svg',
  ICON_MISC_OPEN_LINK_TO_24 = 'assets/new-images/icons/misc/open-link-to/open-link-to-24.svg',
  ICON_MISC_OPEN_LINK_TO_18 = 'assets/new-images/icons/misc/open-link-to/open-link-to-18.svg',
  ICON_MISC_OPEN_LINK_TO_14 = 'assets/new-images/icons/misc/open-link-to/open-link-to-14.svg',
  ICON_MISC_RIGHT_ARROW_24 = 'assets/new-images/icons/misc/right-arrow/right-arrow-24.svg',
  ICON_MISC_RIGHT_ARROW_18 = 'assets/new-images/icons/misc/right-arrow/right-arrow-18.svg',
  ICON_MISC_RIGHT_ARROW_14 = 'assets/new-images/icons/misc/right-arrow/right-arrow-14.svg',
  ICON_MISC_RIGHT_CHEVRON_24 = 'assets/new-images/icons/misc/right-chevron/right-chevron-24.svg',
  ICON_MISC_RIGHT_CHEVRON_18 = 'assets/new-images/icons/misc/right-chevron/right-chevron-18.svg',
  ICON_MISC_RIGHT_CHEVRON_14 = 'assets/new-images/icons/misc/right-chevron/right-chevron-14.svg',
  ICON_MISC_SEARCH_24 = 'assets/new-images/icons/misc/search/search-24.svg',
  ICON_MISC_SEARCH_18 = 'assets/new-images/icons/misc/search/search-18.svg',
  ICON_MISC_SEARCH_14 = 'assets/new-images/icons/misc/search/search-14.svg',
  ICON_MISC_UP_ARROW_24 = 'assets/new-images/icons/misc/up-arrow/up-arrow-24.svg',
  ICON_MISC_UP_ARROW_18 = 'assets/new-images/icons/misc/up-arrow/up-arrow-18.svg',
  ICON_MISC_UP_ARROW_14 = 'assets/new-images/icons/misc/up-arrow/up-arrow-14.svg',
  ICON_MISC_UP_CHEVRON_LARGE = 'assets/new-images/icons/misc/up-chevron/up_chevron_large.svg',
  ICON_MISC_UP_CHEVRON_24 = 'assets/new-images/icons/misc/up-chevron/up-chevron-24.svg',
  ICON_MISC_UP_CHEVRON_18 = 'assets/new-images/icons/misc/up-chevron/up-chevron-18.svg',
  ICON_MISC_UP_CHEVRON_14 = 'assets/new-images/icons/misc/up-chevron/up-chevron-14.svg',
  ICON_MISC_VISIBLE_24 = 'assets/new-images/icons/misc/visible/visible-24.svg',
  ICON_MISC_VISIBLE_18 = 'assets/new-images/icons/misc/visible/visible-18.svg',
  ICON_MISC_VISIBLE_14 = 'assets/new-images/icons/misc/visible/visible-14.svg',
  ICON_MISC_Z_A_24 = 'assets/new-images/icons/misc/z-a/z-a-24.svg',
  ICON_MISC_Z_A_18 = 'assets/new-images/icons/misc/z-a/z-a-18.svg',
  ICON_MISC_Z_A_14 = 'assets/new-images/icons/misc/z-a/z-a-14.svg',
  ICON_MISC_ARCHIVE_24 = 'assets/new-images/icons/misc/archive/archive-24.svg',
  ICON_MISC_ARCHIVE_18 = 'assets/new-images/icons/misc/archive/archive-18.svg',
  ICON_MISC_ARCHIVE_14 = 'assets/new-images/icons/misc/archive/archive-14.svg',
  ICON_NAV_CLOSE_24 = 'assets/new-images/icons/nav/close/close-24.svg',
  ICON_NAV_CLOSE_18 = 'assets/new-images/icons/nav/close/close-18.svg',
  ICON_NAV_CLOSE_14 = 'assets/new-images/icons/nav/close/close-14.svg',
  ICON_NAV_PLUS_24 = 'assets/new-images/icons/nav/plus/plus-24.svg',
  ICON_NAV_PLUS_18 = 'assets/new-images/icons/nav/plus/plus-18.svg',
  ICON_NAV_PLUS_14 = 'assets/new-images/icons/nav/plus/plus-14.svg',
  ICON_NAV_ARROW_RIGHT_24 = 'assets/new-images/icons/nav/arrow-right/arrow-right-24.svg',
  ICON_NAV_ARROW_RIGHT_18 = 'assets/new-images/icons/nav/arrow-right/arrow-right-18.svg',
  ICON_NAV_ARROW_RIGHT_14 = 'assets/new-images/icons/nav/arrow-right/arrow-right-14.svg',
  ICON_NAV_ARROW_DOWN_24 = 'assets/new-images/icons/nav/arrow-down/arrow-down-24.svg',
  ICON_NAV_ARROW_DOWN_18 = 'assets/new-images/icons/nav/arrow-down/arrow-down-18.svg',
  ICON_NAV_ARROW_DOWN_14 = 'assets/new-images/icons/nav/arrow-down/arrow-down-14.svg',
  ICON_NAV_COG_24 = 'assets/new-images/icons/nav/cog/cog-24.svg',
  ICON_NAV_COG_18 = 'assets/new-images/icons/nav/cog/cog-18.svg',
  ICON_NAV_COG_14 = 'assets/new-images/icons/nav/cog/cog-14.svg',
  ICON_NAV_SEARCH_24 = 'assets/new-images/icons/nav/search/search-24.svg',
  ICON_NAV_SEARCH_18 = 'assets/new-images/icons/nav/search/search-18.svg',
  ICON_NAV_SEARCH_14 = 'assets/new-images/icons/nav/search/search-14.svg',
  ICON_NAV_TABLE_VIEW_24 = 'assets/new-images/icons/nav/table-view/table-view-24.svg',
  ICON_NAV_TABLE_VIEW_18 = 'assets/new-images/icons/nav/table-view/table-view-18.svg',
  ICON_NAV_TABLE_VIEW_14 = 'assets/new-images/icons/nav/table-view/table-view-14.svg',
  ICON_NAV_THREE_DOTS_VERTICAL_24 = 'assets/new-images/icons/nav/three-dots-vertical/three-dots-vertical-24.svg',
  ICON_NAV_THREE_DOTS_VERTICAL_18 = 'assets/new-images/icons/nav/three-dots-vertical/three-dots-vertical-18.svg',
  ICON_NAV_THREE_DOTS_VERTICAL_14 = 'assets/new-images/icons/nav/three-dots-vertical/three-dots-vertical-14.svg',
  ICON_BADGE_STATUS_OPEN_24 = 'assets/new-images/badges/status/open/open-24.svg',
  ICON_BADGE_STATUS_OPEN_18 = 'assets/new-images/badges/status/open/open-18.svg',
  ICON_BADGE_STATUS_OPEN_14 = 'assets/new-images/badges/status/open/open-14.svg',
  ICON_BADGE_STATUS_CLOSED_24 = 'assets/new-images/badges/status/closed/closed-24.svg',
  ICON_BADGE_STATUS_CLOSED_18 = 'assets/new-images/badges/status/closed/closed-18.svg',
  ICON_BADGE_STATUS_CLOSED_14 = 'assets/new-images/badges/status/closed/closed-14.svg',
  ICON_BADGE_STATUS_IN_PROGRESS_24 = 'assets/new-images/badges/status/in-progress/in-progress-24.svg',
  ICON_BADGE_STATUS_IN_PROGRESS_18 = 'assets/new-images/badges/status/in-progress/in-progress-18.svg',
  ICON_BADGE_STATUS_IN_PROGRESS_14 = 'assets/new-images/badges/status/in-progress/in-progress-14.svg',
  ICON_BADGE_STATUS_ON_HOLD_24 = 'assets/new-images/badges/status/on-hold/on-hold-24.svg',
  ICON_BADGE_STATUS_ON_HOLD_18 = 'assets/new-images/badges/status/on-hold/on-hold-18.svg',
  ICON_BADGE_STATUS_ON_HOLD_14 = 'assets/new-images/badges/status/on-hold/on-hold-14.svg',
  ICON_BADGE_STATUS_CANCELED_24 = 'assets/new-images/badges/status/canceled/canceled-24.svg',
  ICON_BADGE_STATUS_CANCELED_18 = 'assets/new-images/badges/status/canceled/canceled-18.svg',
  ICON_BADGE_STATUS_CANCELED_14 = 'assets/new-images/badges/status/canceled/canceled-14.svg',
  ICON_BADGE_STATUS_TO_REVIEW_24 = 'assets/new-images/badges/status/to-review/to-review-24.svg',
  ICON_BADGE_STATUS_TO_REVIEW_18 = 'assets/new-images/badges/status/to-review/to-review-18.svg',
  ICON_BADGE_STATUS_TO_REVIEW_14 = 'assets/new-images/badges/status/to-review/to-review-14.svg',
  ICON_TABLE_CHEVRON_RIGHT = 'assets/table-icons/table-chevron-right.svg',
  ICON_TABLE_CHEVRON_RIGHT_GREY = 'assets/table-icons/table-chevron-right-grey-400.svg',
  ICON_TABLE_CHEVRON_DOWN = 'assets/table-icons/table-chevron-down.svg',
  ICON_TABLE_CHEVRON_DOWN_GREY = 'assets/table-icons/table-chevron-down-grey-400.svg',
  ICON_TABLE_CANCELED = 'assets/table-icons/canceled.svg',
  ICON_TABLE_CLOSED = 'assets/table-icons/closed.svg',
  ICON_TABLE_IN_PROGRESS = 'assets/table-icons/in-progress.svg',
  ICON_TABLE_ON_HOLD = 'assets/table-icons/on-hold.svg',
  ICON_TABLE_OPEN = 'assets/table-icons/open.svg',
  ICON_TABLE_TO_REVIEW = 'assets/table-icons/to-review.svg',
  ICON_TABLE_ATTACHMENTS = 'assets/table-icons/table-attachments.svg',
  ICON_TABLE_OPTIONS = 'assets/table-icons/options.svg',
  ICON_INPUT_CHECKBOX_CHECK_18 = 'assets/new-images/inputs/checkbox/check-18.svg',
  ICON_INPUT_CHECKBOX_CHECK_12 = 'assets/new-images/inputs/checkbox/check-12.svg',
  ICON_INPUT_PLUS_32 = 'assets/new-images/inputs/plus/plus-input-32.svg',
  ICON_INPUT_SQUARE_PLUS_32 = 'assets/new-images/inputs/plus/plus-input-square-32.svg',
  ICON_BANNER_WARNING = 'assets/new-images/misc/warning-banner.svg',
  ICON_BANNER_COPY_MOVE_INFO = 'assets/new-images/misc/copy-move-info.svg',
  ICON_BADGE_INFO_WHITE = 'assets/new-images/badges/info/info-white.svg',
  ICON_USER_GROUPS_EDITABLE_24 = 'assets/new-images/icons/user-groups/editable/editable-24.svg',
  ICON_USER_GROUPS_EDITABLE_18 = 'assets/new-images/icons/user-groups/editable/editable-18.svg',
  ICON_USER_GROUPS_EDITABLE_14 = 'assets/new-images/icons/user-groups/editable/editable-14.svg',
  ICON_USER_GROUPS_RESET_PW_24 = 'assets/new-images/icons/user-groups/reset-pw/reset-pw-24.svg',
  ICON_USER_GROUPS_RESET_PW_18 = 'assets/new-images/icons/user-groups/reset-pw/reset-pw-18.svg',
  ICON_USER_GROUPS_RESET_PW_14 = 'assets/new-images/icons/user-groups/reset-pw/reset-pw-14.svg',
  ICON_SITE_PLAN_PIN_24 = 'assets/new-images/icons/site-plan/pin/pin-24.svg',
  ICON_SITE_PLAN_PIN_18 = 'assets/new-images/icons/site-plan/pin/pin-18.svg',
  ICON_SITE_PLAN_PIN_14 = 'assets/new-images/icons/site-plan/pin/pin-14.svg',
  ICON_SITE_PLAN_AREA_24 = 'assets/new-images/icons/site-plan/area/area-24.svg',
  ICON_SITE_PLAN_AREA_18 = 'assets/new-images/icons/site-plan/area/area-18.svg',
  ICON_SITE_PLAN_AREA_14 = 'assets/new-images/icons/site-plan/area/area-14.svg',
  ICON_MISC_PASSWORD_24 = 'assets/new-images/icons/misc/password/password-24.svg',
  ICON_MISC_PASSWORD_18 = 'assets/new-images/icons/misc/password/password-18.svg',
  ICON_MISC_PASSWORD_14 = 'assets/new-images/icons/misc/password/password-14.svg',
  ICON_MISC_PROFILE_24 = 'assets/new-images/icons/misc/profile/profile-24.svg',
  ICON_MISC_PROFILE_18 = 'assets/new-images/icons/misc/profile/profile-18.svg',
  ICON_MISC_PROFILE_14 = 'assets/new-images/icons/misc/profile/profile-14.svg',
  ICON_MISC_VISIBILITY_OFF_24 = 'assets/new-images/icons/misc/visibility-off/visibility-off-24.svg',
  ICON_MISC_VISIBILITY_OFF_18 = 'assets/new-images/icons/misc/visibility-off/visibility-off-18.svg',
  ICON_MISC_VISIBILITY_OFF_14 = 'assets/new-images/icons/misc/visibility-off/visibility-off-14.svg',
  ICON_LEFT_MENU_ARROW_BACK = 'assets/new-images/icons/left-menu/arrow/arrow-back.svg',
  ICON_LEFT_MENU_REMINDER_24 = 'assets/new-images/icons/left-menu/reminder/reminder-empty-24.svg',
  ICON_USER_GROUPS_HIDDEN_24 = 'assets/new-images/icons/user-groups/hidden/hidden-24.svg',
  ICON_USER_GROUPS_HIDDEN_18 = 'assets/new-images/icons/user-groups/hidden/hidden-18.svg',
  ICON_USER_GROUPS_HIDDEN_14 = 'assets/new-images/icons/user-groups/hidden/hidden-14.svg',
  ICON_USER_GROUPS_VISIBLE_24 = 'assets/new-images/icons/user-groups/visible/visible-24.svg',
  ICON_USER_GROUPS_VISIBLE_18 = 'assets/new-images/icons/user-groups/visible/visible-18.svg',
  ICON_USER_GROUPS_VISIBLE_14 = 'assets/new-images/icons/user-groups/visible/visible-14.svg',
  ICON_USER_SETTINGS_PERSON_SEARCH_24 = 'assets/new-images/icons/user-settings/person-search/person-search-24.svg',
  ICON_USER_SETTINGS_PERSON_SEARCH_18 = 'assets/new-images/icons/user-settings/person-search/person-search-18.svg',
  ICON_USER_SETTINGS_PERSON_SEARCH_14 = 'assets/new-images/icons/user-settings/person-search/person-search-14.svg',
  ICON_USER_SETTINGS_COG_24 = 'assets/new-images/icons/user-settings/cog/cog-24.svg',
  ICON_USER_SETTINGS_COG_18 = 'assets/new-images/icons/user-settings/cog/cog-18.svg',
  ICON_USER_SETTINGS_COG_14 = 'assets/new-images/icons/user-settings/cog/cog-14.svg',
  ICON_USER_SETTINGS_CONTACT_24 = 'assets/new-images/icons/user-settings/contact/contact-24.svg',
  ICON_USER_SETTINGS_CONTACT_18 = 'assets/new-images/icons/user-settings/contact/contact-18.svg',
  ICON_USER_SETTINGS_CONTACT_14 = 'assets/new-images/icons/user-settings/contact/contact-14.svg',
  ICON_USER_SETTINGS_COOKIE_24 = 'assets/new-images/icons/user-settings/cookie/cookie-24.svg',
  ICON_USER_SETTINGS_COOKIE_18 = 'assets/new-images/icons/user-settings/cookie/cookie-18.svg',
  ICON_USER_SETTINGS_COOKIE_14 = 'assets/new-images/icons/user-settings/cookie/cookie-14.svg',
  ICON_USER_SETTINGS_HELP_24 = 'assets/new-images/icons/user-settings/help/help-24.svg',
  ICON_USER_SETTINGS_HELP_18 = 'assets/new-images/icons/user-settings/help/help-18.svg',
  ICON_USER_SETTINGS_HELP_14 = 'assets/new-images/icons/user-settings/help/help-14.svg',
  ICON_USER_SETTINGS_LOG_OUT_24 = 'assets/new-images/icons/user-settings/log-out/log-out-24.svg',
  ICON_USER_SETTINGS_LOG_OUT_18 = 'assets/new-images/icons/user-settings/log-out/log-out-18.svg',
  ICON_USER_SETTINGS_LOG_OUT_14 = 'assets/new-images/icons/user-settings/log-out/log-out-14.svg',
  ICON_USER_SETTINGS_MEGAPHONE_24 = 'assets/new-images/icons/user-settings/megaphone/megaphone-24.svg',
  ICON_USER_SETTINGS_MEGAPHONE_18 = 'assets/new-images/icons/user-settings/megaphone/megaphone-18.svg',
  ICON_USER_SETTINGS_MEGAPHONE_14 = 'assets/new-images/icons/user-settings/megaphone/megaphone-14.svg',
  ICON_USER_SETTINGS_PERSON_ADD_24 = 'assets/new-images/icons/user-settings/person-add/person-add-24.svg',
  ICON_USER_SETTINGS_PERSON_ADD_18 = 'assets/new-images/icons/user-settings/person-add/person-add-18.svg',
  ICON_USER_SETTINGS_PERSON_ADD_14 = 'assets/new-images/icons/user-settings/person-add/person-add-14',
  ICON_USER_SETTINGS_SEND_MAIL_24 = 'assets/new-images/icons/user-settings/send-mail/send-mail-24.svg',
  ICON_USER_SETTINGS_SEND_MAIL_18 = 'assets/new-images/icons/user-settings/send-mail/send-mail-18.svg',
  ICON_USER_SETTINGS_SEND_MAIL_14 = 'assets/new-images/icons/user-settings/send-mail/send-mail-14.svg',
  ICON_USER_SETTINGS_UPGRADE_24 = 'assets/new-images/icons/user-settings/upgrade/upgrade-24.svg',
  ICON_USER_SETTINGS_UPGRADE_18 = 'assets/new-images/icons/user-settings/upgrade/upgrade-18.svg',
  ICON_USER_SETTINGS_UPGRADE_14 = 'assets/new-images/icons/user-settings/upgrade/upgrade-14.svg',
  ICON_SAVED_VIEWS_GROUPS = 'assets/new-images/icons/misc/saved-views/groups.svg',
  ICON_SAVED_VIEWS_STAR = 'assets/new-images/icons/misc/saved-views/star.svg',
  ICON_SAVED_VIEWS_VIEW = 'assets/new-images/icons/misc/saved-views/views.svg',
  ICON_SAVED_VIEWS_DUPLICATE = 'assets/new-images/icons/misc/saved-views/duplicate.svg',
  ICON_EXPAND_18 = 'assets/new-images/interactions/expand/expand_18.svg',
  ICON_BADGE_PRIORITY_HIGH_12 = 'assets/new-images/badges/priority/priority-high-12.svg',
  ICON_BADGE_PRIORITY_MEDIUM_12 = 'assets/new-images/badges/priority/priority-medium-12.svg',
  ICON_BADGE_PRIORITY_LOW_12 = 'assets/new-images/badges/priority/priority-low-12.svg',
  ICON_INPUT_NUMBER_ARROW_UP = 'assets/new-images/inputs/number/number-arrow-up.svg',
  ICON_INPUT_NUMBER_ARROW_DOWN = 'assets/new-images/inputs/number/number-arrow-down.svg',
  ICON_BADGE_WARNING_24 = 'assets/new-images/banners/warning/warning-24.svg',
  ICON_BADGE_WARNING_18 = 'assets/new-images/banners/warning/warning-18.svg',
  ICON_BADGE_WARNING_14 = 'assets/new-images/banners/warning/warning-14.svg',
  ICON_NOTIFICATION_CHECK_24 = 'assets/new-images/icons/notification-panel/notification-check/notification-check-24.svg',
  ICON_NOTIFICATION_CHECK_18 = 'assets/new-images/icons/notification-panel/notification-check/notification-check-18.svg',
  ICON_NOTIFICATION_CHECK_14 = 'assets/new-images/icons/notification-panel/notification-check/notification-check-14.svg',
  ICON_ACTIVITIES_RED_FLAG_24 = 'assets/new-images/icons/activities/red-flag/red-flag-24.svg',
  ICON_ACTIVITIES_RED_FLAG_18 = 'assets/new-images/icons/activities/red-flag/red-flag-18.svg',
  ICON_ACTIVITIES_RED_FLAG_14 = 'assets/new-images/icons/activities/red-flag/red-flag-14.svg',
  ICON_ACTIVITIES_RED_FLAG_ACTIVATED_24 = 'assets/new-images/icons/activities/red-flag-activated/red-flag-activated-24.svg',
  ICON_ACTIVITIES_RED_FLAG_ACTIVATED_18 = 'assets/new-images/icons/activities/red-flag-activated/red-flag-activated-18.svg',
  ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14 = 'assets/new-images/icons/activities/red-flag-activated/red-flag-activated-14.svg',
  ICON_COLORED_RED_FLAG = 'assets/new-images/colored/red-flag.svg',
  ICON_LEFT_MENU_BOAT_24 = 'assets/new-images/icons/left-menu/boat/boat-24.svg',
  ICON_LEFT_MENU_BOAT_BIG = 'assets/new-images/icons/left-menu/boat/boat-big.svg',
  ICON_MISC_PUBLISH = 'assets/new-images/misc/publish.svg',
  ICON_MISC_PHONE_24 = 'assets/new-images/icons/misc/phone/phone-24.svg',
  ICON_MISC_PHONE_18 = 'assets/new-images/icons/misc/phone/phone-18.svg',
  ICON_MISC_PHONE_14 = 'assets/new-images/icons/misc/phone/phone-14.svg',
  ICON_MISC_WHATSAPP_24 = 'assets/new-images/icons/misc/whatsapp/whatsapp-24.svg',
  ICON_MISC_WHATSAPP_18 = 'assets/new-images/icons/misc/whatsapp/whatsapp-18.svg',
  ICON_MISC_WHATSAPP_14 = 'assets/new-images/icons/misc/whatsapp/whatsapp-14.svg',
  ICON_SEARCH_ASSET_NO_RESULT = 'assets/new-images/search/no-search-result.svg',
}
