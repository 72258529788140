<span *ngIf="!dataFetched">
  <pp-account-user-management-placeholder></pp-account-user-management-placeholder>
</span>

<cdk-virtual-scroll-viewport
  itemSize="1"
  class="userManagementTable__wrapper"
  orientation="horizontal"
  #tableElement
>
  <section *ngIf="user?.userId && dataFetched" class="userManagementTable">
    <pp-account-user-management-header
      [ppAccountId]="accountId"
      [ppCustomFields]="customFields"
    ></pp-account-user-management-header>

    <section class="userManagementTableSection">
      <pp-user-management-user-section
        *cdkVirtualFor="let user of filteredUsers"
        [ppUser]="user"
        [ppAccountId]="accountId"
        [ppCustomFields]="customFields"
      ></pp-user-management-user-section>
    </section>

    <pp-user-management-table-footer
      [ppNumberOfUsers]="filteredUsers.length"
    ></pp-user-management-table-footer>
  </section>
</cdk-virtual-scroll-viewport>

<pp-user-management-bulk-changes-bar
  [ppAccountId]="accountId"
></pp-user-management-bulk-changes-bar>
