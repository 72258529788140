import { Component, Input } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-custom-field',
  templateUrl: './dashlet-activity-custom-field.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityCustomFieldComponent {
  @Input() ppChange: TActivityChange<string>;

  customFieldTypes = ECustomFieldType;
  EIconPath = EIconPath;

  constructor() {}
}
