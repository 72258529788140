import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';

@Component({
  selector: 'pp-custom-field-percentage',
  templateUrl: './custom-field-percentage.component.html',
  styleUrls: ['./custom-field-percentage.component.scss'],
})
export class CustomFieldPercentageComponent implements AfterViewInit {
  @Input() ppField: TCustomFieldResponse;
  @Input() ppValue: number;
  @Input() ppReadOnly = false;
  @Input() ppUpdating: boolean | string = false;
  @Output() ppUpdate = new EventEmitter();
  @Input() ppWorkspaceId = '';
  @Input() ppFieldId = '';

  value: number;

  autonumericOptions: TAutoNumericOptions = {
    watchExternalChanges: true,
    modifyValueOnWheel: false,
    decimalPlaces: 0,
    decimalPlacesRawValue: 0,
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.value = this.ppValue;
  }

  clearCustomField(): void {
    this.value = null;

    this.updateField();
  }

  updateField(): void {
    if (this.ppUpdate) {
      const value = this.value || null;

      this.ppUpdate.emit(value);
    }
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }
}
