import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { createElement } from 'src/app/core/helpers/dom';
import { GET_TIMELINE_COLUMNS, TTimelineColumns } from '../../timeframes/timeline-timeframes';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT } from './timeline-variables';
import { getTimelineGroupColumnWidth } from '../resizer-width';
import { onTimelineBasicElementMouseUp } from './on-timeline-basic-element-mouse-up';
import { onTimelineBasicElementMouseMove } from './on-timeline-basic-element-mouse-move';
import { onTimelineBasicElementScrollMove } from './on-timeline-basic-element-scroll-move';
import { onTimelineBasicElementMouseDown } from './on-timeline-basic-element-mouse-down';
import { getTimelineBasicElementStyling } from './get-timeline-basic-element-styling';
import { ETimelineGroup } from 'src/app/project/modules/preferences/preferences.model';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';

export function createTimelineBasicElement({
  width,
  data,
  pointIndex,
  startDate,
  startDifference,
  timelineGroup,
  timelineField,
  canEdit,
}: {
  width: number;
  data: TTimelineColumns;
  pointIndex: number;
  startDate: number;
  startDifference: number;
  timelineGroup: ETimelineGroup;
  timelineField: TCustomFieldResponse;
  canEdit: boolean;
}): HTMLElement {
  let elementRect;
  let difference = 0;
  let startPosition = 0;
  let initialScroll;
  let mouseMoveChangeAmount = 0;
  let scrollMoveChangeAmount = 0;

  const timeline = GET_TIMELINE();
  const timelineData = GET_TIMELINE_COLUMNS();
  const preferences = GET_PREFERENCES();
  const groupWidth = getTimelineGroupColumnWidth();

  const classList = getTimelineBasicElementStyling({ width, _canEdit: canEdit });

  const element = createElement('div', {
    attrs: {
      class: classList,
      style: {
        width: width > MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT ? `${width}px` : '8px',
        transform: `translateX(${
          data.bottomWidth * (startDate + startDifference) + (timelineGroup ? groupWidth + 1 : 0)
        }px)`,
      },
      'data-index': pointIndex,
    },
    children: [],
    eventListeners: {
      click: () => {
        if (!timeline.resizing) {
          timeline.openPointCallback(timeline.points[pointIndex]);
        }
      },
      mousedown: (_event) => {
        ({ initialScroll, elementRect, startPosition, difference } =
          onTimelineBasicElementMouseDown({
            _canEdit: canEdit,
            _event,
            element,
            initialScroll,
            timeline,
            elementRect,
            startPosition,
            difference,
            mouseup,
            mousemove,
            scrollmove,
          }));
      },
    },
  });

  const scrollmove = (): void => {
    scrollMoveChangeAmount = onTimelineBasicElementScrollMove({
      timeline,
      mouseMoveChangeAmount,
      initialScroll,
      scrollMoveChangeAmount,
      element,
      startPosition,
      difference,
    });
  };

  const mousemove = (_event: MouseEvent): void => {
    mouseMoveChangeAmount = onTimelineBasicElementMouseMove({
      _event,
      element,
      startPosition,
      difference,
      elementRect,
      scrollMoveChangeAmount,
      mouseMoveChangeAmount,
      timeline,
    });
  };

  const mouseup = (): void => {
    onTimelineBasicElementMouseUp({
      element,
      preferences,
      elementRect,
      timelineData,
      timeline,
      _pointIndex: pointIndex,
      _timelineField: timelineField,
    });
  };

  return element;
}
