import { Inject, Injectable } from '@angular/core';

import { DashboardAdvancedDashletService } from '../dashboard-advanced-dashlet.service';

import { TranslationPipe } from '../../../../features/translate/translation.pipe';
import { getTimeZone } from '../../../../../core/helpers/get-timezone';
import { generateExportDashletSimple } from '../dashboard-export-elements/dashboard-simple-dashlet';
import { createDashletWrapperElement } from './create-dashlet-wrapper-element';
import { findDashletData } from './find-dashlet-data';
import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { DOCUMENT } from '@angular/common';
import { TDashletData } from '../../dashlet/dashlet-events/dashlet-events.service';
import { DownloadService } from '../../../../services/download.service';
import { TDashboardImageElementData } from '../dashboard-export-elements/dashboard-image-element';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardExportService {
  dashletsWrapperElement: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dashboardAdvancedDashletService: DashboardAdvancedDashletService,
    private translationPipe: TranslationPipe,
    private downloadService: DownloadService,
  ) {}

  export(
    dashletList: TExportDashlet[],
    dashletsData: TDashletData[],
    selectedLayout: string,
  ): Observable<Response> {
    return this.generateExportDashlets(dashletList, dashletsData).pipe(
      switchMap((dashlets) => {
        const body = {
          format: selectedLayout,
          timeGMT: getTimeZone(),
          dashlets,
        };

        const promptErrorText = this.translationPipe.transform('prompt_export_error_dashboard');

        return this.downloadService.fromRequest(
          'api/v1/dashboard/exports/pdf',
          body,
          promptErrorText,
        );
      }),
    );
  }

  generateExportDashlets(
    dashletList: TExportDashlet[],
    dashletsData: TDashletData[],
  ): Observable<TDashboardImageElementData[]> {
    this.dashletsWrapperElement = createDashletWrapperElement();

    const asyncHandlers: Observable<TDashboardImageElementData>[] = [];

    // comment to show images in the app
    this.document.body.appendChild(this.dashletsWrapperElement);

    dashletList.forEach((dashlet) => {
      if (dashlet.selected) {
        const dashletData = findDashletData(dashlet, dashletsData);

        if (dashletData) {
          asyncHandlers.push(generateExportDashletSimple(dashletData, dashlet));
        } else {
          asyncHandlers.push(
            this.dashboardAdvancedDashletService.generateAdvancedDashlet(
              this.dashletsWrapperElement,
              dashlet,
            ),
          );
        }
      }
    });

    return forkJoin(asyncHandlers).pipe(
      tap(() => {
        this.document.body.removeChild(this.dashletsWrapperElement);
      }),
    );
  }
}
