/**
 * Takes in a buffer of event messages and splits them into individual messages.
 * @param {Buffer} buffer
 * @api private
 */
function eventMessageChunker(buffer) {
  /** @type Buffer[] */
  var messages = [];
  var offset = 0;
  while (offset < buffer.length) {
    var totalLength = buffer.readInt32BE(offset);

    // create new buffer for individual message (shares memory with original)
    var message = buffer.slice(offset, totalLength + offset);
    // increment offset to it starts at the next message
    offset += totalLength;
    messages.push(message);
  }
  return messages;
}

/**
 * @api private
 */
module.exports = {
  eventMessageChunker: eventMessageChunker
};