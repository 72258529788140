import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnChanges,
  RendererFactory2,
} from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit, OnChanges {
  @Input() ppId: string;
  @Input() ppChecked = false;
  @Input() ppReverseLayout = false;
  @Input() ppStyle: null | 'thick' | 'floating' | 'small' | 'blue' | 'columns' | 'filter';
  @Input() ppDisabled = false;
  @Input() ppRequiresConfirmation = false;
  @Output() ppAction = new EventEmitter();

  EIconPath = EIconPath;

  toggleClass: {
    [key: string]: boolean;
  };
  sliderClass: {
    [key: string]: boolean;
  };

  private renderer: Renderer2;

  constructor(private host: ElementRef, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.toggleClass = {
      'toggle--thick': this.ppStyle === 'thick',
      'toggle--floating': this.ppStyle === 'floating',
      'toggle--small': this.ppStyle === 'small',
      'toggle--blue': this.ppStyle === 'blue',
      'toggle--filter': this.ppStyle === 'filter',
      'toggle--columns': this.ppStyle === 'columns',
      'toggle--disabled': this.ppDisabled,
    };

    this.sliderClass = {
      'slider--thick': this.ppStyle === 'thick',
      'slider--floating': this.ppStyle === 'floating',
      'slider--small': this.ppStyle === 'small',
      'slider--blue': this.ppStyle === 'blue',
      'slider--filter': this.ppStyle === 'filter',
      'slider--columns': this.ppStyle === 'columns',
      'slider--disabled': this.ppDisabled === true,
    };

    if (this.ppReverseLayout) {
      this.renderer.setStyle(this.host.nativeElement, 'flex-direction', 'row-reverse');
    }
  }

  ngOnChanges() {
    this.toggleClass = {
      'toggle--thick': this.ppStyle === 'thick',
      'toggle--floating': this.ppStyle === 'floating',
      'toggle--small': this.ppStyle === 'small',
      'toggle--blue': this.ppStyle === 'blue',
      'toggle--filter': this.ppStyle === 'filter',
      'toggle--columns': this.ppStyle === 'columns',
      'toggle--disabled': this.ppDisabled,
    };

    this.sliderClass = {
      'slider--thick': this.ppStyle === 'thick',
      'slider--floating': this.ppStyle === 'floating',
      'slider--small': this.ppStyle === 'small',
      'slider--blue': this.ppStyle === 'blue',
      'slider--filter': this.ppStyle === 'filter',
      'slider--columns': this.ppStyle === 'columns',
      'slider--disabled': this.ppDisabled === true,
    };

    if (this.ppReverseLayout) {
      this.renderer.setStyle(this.host.nativeElement, 'flex-direction', 'row-reverse');
    }
  }

  action(event: MouseEvent): void {
    if (this.ppRequiresConfirmation) {
      event.preventDefault();
    }

    if (this.ppAction) {
      this.ppAction.emit();
    }
  }
}
