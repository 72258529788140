var eventMessageChunker = require('../event-stream/event-message-chunker').eventMessageChunker;
var parseEvent = require('./parse-event').parseEvent;
function createEventStream(body, parser, model) {
  var eventMessages = eventMessageChunker(body);
  var events = [];
  for (var i = 0; i < eventMessages.length; i++) {
    events.push(parseEvent(parser, eventMessages[i], model));
  }
  return events;
}

/**
 * @api private
 */
module.exports = {
  createEventStream: createEventStream
};