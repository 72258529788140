import { Injectable } from '@angular/core';
import { API_workspace_custom_fields_excel } from '@core/api/paths';
import { ApiService } from '@core/http';
import { TCustomFieldResponse } from '@project/view-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsApiProviderService {
  constructor(private apiService: ApiService) {}

  importCustomFieldsFromExcel(
    workspaceId: string,
    formData: FormData,
  ): Observable<TCustomFieldResponse> {
    const url = API_workspace_custom_fields_excel(workspaceId);

    return this.apiService.postWithFormData<TCustomFieldResponse>(url, formData);
  }
}
