let visiblePoints: string[] = [];

export function getVisiblePoints(): string[] {
  return visiblePoints;
}

export function addVisiblePoint(_id: string): void {
  if (!visiblePoints.includes(_id)) {
    visiblePoints.push(_id);
  }
}

// FIXME This function was breaking visiblePoints array before fix
//  therefore it seems that this function usage is unnecessary
export function removeVisiblePoint(_id: string): void {
  const pointIndex = visiblePoints.indexOf(_id);

  if (pointIndex !== -1) {
    visiblePoints.splice(pointIndex, 1);
  }
}
