import { minimumFillRatioToDisplayLabel } from '../../dashboard-export/dashboard-export-variables';
import { TDashletGraphOptions } from '../../dashlet-graph.model';
import { createBarGraphTooltipLabel } from './create-bar-graph-tooltip-label';
import { createDashletTooltip } from './dashlet-tooltip';

const fontColor = '#586378';

export function generateBarGraphOptions(
  isMobile: boolean,
  isExport: boolean,
): TDashletGraphOptions {
  const fontSize = isExport ? 42 : 12;

  if (!isMobile) {
    return generateBarGraphOptionsDesktop(fontSize);
  } else {
    return generateBarGraphOptionsMobile(fontSize);
  }
}

function generateBarGraphOptionsMobile(fontSize: number): TDashletGraphOptions {
  return {
    devicePixelRatio: window.devicePixelRatio,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          min: 0,
          font: {
            size: fontSize,
            color: fontColor,
          },
          maxTicksLimit: 7,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        custom: function (tooltipModel): void {
          return createDashletTooltip(tooltipModel, this._chart);
        },
        callbacks: {
          label: function (tooltipItem) {
            return createBarGraphTooltipLabel(tooltipItem);
          },
        },
      },
      legend: {
        display: true,
        onClick: null,
        position: 'bottom',
        labels: {
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },

      datalabels: {
        display: (value) => {
          return (
            value.dataset.data[value.dataIndex] >
            minimumFillRatioToDisplayLabel * value.chart.canvas.height
          );
        },
        color: 'white',
        font: {
          size: fontSize,
          color: fontColor,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    cutout: 55,
  };
}

function generateBarGraphOptionsDesktop(fontSize: number): TDashletGraphOptions {
  return {
    devicePixelRatio: window.devicePixelRatio,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          min: 0,
          font: {
            size: fontSize,
            color: fontColor,
          },
          maxTicksLimit: 7,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        onClick: null,
        position: 'right',
        labels: {
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: function (tooltipModel): void {
          return createDashletTooltip(tooltipModel, this._chart);
        },
        callbacks: {
          label: function (tooltipItem) {
            return createBarGraphTooltipLabel(tooltipItem);
          },
        },
      },

      datalabels: {
        color: 'white',
        display: (value) =>
          value.dataset.data[value.dataIndex] >
          minimumFillRatioToDisplayLabel * value.chart.canvas.height,
        font: {
          size: fontSize,
          color: fontColor,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: 55,
  };
}
