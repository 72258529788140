import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TCombinedCustomField } from 'src/app/project/modules/custom-fields/combine-custom-fields/combined-custom-field.model';
import { TShare } from 'src/app/project/modules/share/share.model';

@Component({
  selector: 'pp-user-management-site-row',
  templateUrl: './user-management-site-row.component.html',
  styleUrls: ['./user-management-site-row.component.scss'],
})
export class UserManagementSiteRowComponent {
  @Input() ppExpanded: boolean;
  @Input() ppShare: TShare;
  @Input() ppIndex: number;
  @Input() ppCustomFields: TCombinedCustomField[];
  @Input() ppLast: boolean;
  @Output() ppNavigate = new EventEmitter<string>();

  emitNavigateToSite(workspaceId: string): void {
    this.ppNavigate.emit(workspaceId);
  }
}
