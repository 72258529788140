import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { BulkChangesBarModule } from '../../components/bulk-changes-bar/bulk-changes-bar.module';
import { ButtonModule } from '../../components/button/button.module';
import { ErrorBoxModule } from '../../components/error-box/error-box.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { EndButtonModule } from '../../components/input/end-button/end-button.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { SelectModule } from '../../components/input/select/select.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { WarningBoxModule } from '../../components/warning-box/warning-box.module';
import { DataTestModule } from '../../directives/data-test/data-test.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { LayoutModule } from '../layout/layout.module';
import { RemindersModule } from '../reminders/reminders.module';
import { ShareModule } from '../share/share.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';
import { FleetManagementEmptyFleetAccountSelectComponent } from './fleet-management-empty-fleet/fleet-management-empty-fleet-account-select/fleet-management-empty-fleet-account-select.component';
import { FleetManagementEmptyFleetComponent } from './fleet-management-empty-fleet/fleet-management-empty-fleet.component';
import { FleetManagementNewFleetNameAndAccountComponent } from './fleet-management-empty-fleet/fleet-management-new-fleet-name-and-account/fleet-management-new-fleet-name-and-account.component';
import { FleetManagementNewFleetStatusCheckboxComponent } from './fleet-management-empty-fleet/fleet-management-new-fleet-status-filter/fleet-management-new-fleet-status-checkbox/fleet-management-new-fleet-status-checkbox.component';
import { FleetManagementNewFleetStatusFilterComponent } from './fleet-management-empty-fleet/fleet-management-new-fleet-status-filter/fleet-management-new-fleet-status-filter.component';
import { FleetManagementNewFleetStatusInputComponent } from './fleet-management-empty-fleet/fleet-management-new-fleet-status-weights/fleet-management-new-fleet-status-input/fleet-management-new-fleet-status-input.component';
import { FleetManagementNewFleetStatusWeightsComponent } from './fleet-management-empty-fleet/fleet-management-new-fleet-status-weights/fleet-management-new-fleet-status-weights.component';
import { AssetCardHeaderComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-header/asset-card-header.component';
import { AssetCardInformationDetailsComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-information/asset-card-information-details/asset-card-information-details.component';
import { AssetCardInformationLabelComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-information/asset-card-information-label/asset-card-information-label.component';
import { assetCardInformationLabelsComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-information/asset-card-information-labels/asset-card-information-labels.component';
import { assetCardInformationToggleComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-information/asset-card-information-toggle/asset-card-information-toggle.component';
import { assetCardInformationComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-information/asset-card-information.component';
import { AssetCardOptionsDropdownComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-options-dropdown/asset-card-options-dropdown.component';
import { AssetCardSitesTableHeaderComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-sites/asset-card-sites-table/asset-card-sites-table-header/asset-card-sites-table-header.component';
import { AssetCardSitesTableRowComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-sites/asset-card-sites-table/asset-card-sites-table-row/asset-card-sites-table-row.component';
import { AssetCardSitesTableComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-sites/asset-card-sites-table/asset-card-sites-table.component';
import { AssetCardSitesComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-sites/asset-card-sites.component';
import { AssetCardComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card.component';
import { FleetAssetsPageComponent } from './fleet-management-fleet-page/fleet-assets/fleet-assets-page.component';
import { FleetManagementFleetPageComponent } from './fleet-management-fleet-page/fleet-management-fleet-page.component';
import { AssetNameAndImageComponent } from './fleet-management-fleet-page/new-asset-page/asset-name-and-image/asset-name-and-image.component';
import { NewAssetDetailsComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-details/new-asset-details.component';
import { AssetLabelUserPickerComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-labels/asset-label-user-picker/asset-label-user-picker.component';
import { NewAssetLabelComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-labels/new-asset-label/new-asset-label.component';
import { NewAssetLabelsComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-labels/new-asset-labels.component';
import { NewAssetPageComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-page.component';
import { NewAssetSitePickerAccountComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-site-picker/new-asset-site-picker-account/new-asset-site-picker-account.component';
import { NewAssetSitePickerWorkspaceComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-site-picker/new-asset-site-picker-workspace/new-asset-site-picker-workspace.component';
import { NewAssetSitePickerComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-site-picker/new-asset-site-picker.component';
import { NoAssetsCreatedYetPageComponent } from './fleet-management-fleet-page/no-assets-created-yet-page/no-assets-created-yet-page.component';
import { FleetManagementLandingPageComponent } from './fleet-management-landing-page/fleet-management-landing-page.component';
import { fleetManagementRoutes } from './fleet-management.routes';
import { FleetPageComponent } from './fleet-page/fleet-page.component';
import { FleetSharingErrorBoxComponent } from './fleet-sharing-modal/fleet-sharing-error-box/fleet-sharing-error-box.component';
import { FleetSharingModalEmailInputComponent } from './fleet-sharing-modal/fleet-sharing-modal-email-input/fleet-sharing-modal-email-input.component';
import { FleetSharingModalComponent } from './fleet-sharing-modal/fleet-sharing-modal.component';
import { FleetSharingUserRowComponent } from './fleet-sharing-modal/fleet-sharing-user-row/fleet-sharing-user-row.component';
import { FleetSharingBulkChangesBarComponent } from './fleet-sharing/fleet-sharing-bulk-changes-bar/fleet-sharing-bulk-changes-bar.component';
import { AssetCardFooterComponent } from './fleet-management-fleet-page/fleet-assets/asset-card/asset-card-footer/asset-card-footer.component';
import { NoAssetSearchResultPageComponent } from './fleet-management-fleet-page/no-asset-search-result-page/no-asset-search-result-page.component';

@NgModule({
  declarations: [
    FleetManagementLandingPageComponent,
    FleetManagementEmptyFleetComponent,
    FleetManagementEmptyFleetAccountSelectComponent,
    FleetManagementFleetPageComponent,
    NoAssetsCreatedYetPageComponent,
    NewAssetPageComponent,
    FleetPageComponent,
    AssetNameAndImageComponent,
    FleetAssetsPageComponent,
    AssetCardComponent,
    AssetCardSitesComponent,
    AssetCardSitesTableComponent,
    AssetCardSitesTableHeaderComponent,
    AssetCardSitesTableRowComponent,
    assetCardInformationComponent,
    assetCardInformationToggleComponent,
    assetCardInformationLabelsComponent,
    AssetCardInformationDetailsComponent,
    AssetCardHeaderComponent,
    NewAssetDetailsComponent,
    AssetCardInformationLabelComponent,
    NewAssetLabelsComponent,
    NewAssetLabelComponent,
    AssetLabelUserPickerComponent,
    NewAssetSitePickerComponent,
    NewAssetSitePickerAccountComponent,
    NewAssetSitePickerWorkspaceComponent,
    AssetCardOptionsDropdownComponent,
    FleetSharingModalComponent,
    FleetSharingModalEmailInputComponent,
    FleetSharingErrorBoxComponent,
    FleetSharingUserRowComponent,
    FleetSharingBulkChangesBarComponent,
    FleetManagementNewFleetNameAndAccountComponent,
    FleetManagementNewFleetStatusWeightsComponent,
    FleetManagementNewFleetStatusInputComponent,
    FleetManagementNewFleetStatusFilterComponent,
    FleetManagementNewFleetStatusCheckboxComponent,
    AssetCardFooterComponent,
    NoAssetSearchResultPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(fleetManagementRoutes),
    SupportedBrowsersModule,
    LayoutModule,
    RemindersModule,
    IconModule,
    PipesModule,
    FormsModule,
    EndButtonModule,
    SelectModule,
    ButtonModule,
    TooltipsModule,
    UploadModule,
    SearchBoxModule,
    CheckboxModule,
    RadioModule,
    SecureSrcModule,
    ModalModule,
    ShareModule,
    ErrorBoxModule,
    WarningBoxModule,
    BulkChangesBarModule,
    SpinnerModule,
    DataTestModule,
  ],
})
export class FleetManagementModule {}
