import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiService } from '@core/http';
import { TCustomDropdownParams } from '../../../../../../components/dropdown/AbstractDropdown';
import { EDropdownWidth } from '../../../../../../components/dropdown/dropdown.consts';
import { GET_DROPDOWN } from '../../../../../../components/dropdown/dropdown.ui.store';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { TColumn } from '../../../../site-table/columns/column.model';
import GroupByDropdown from '../../../group/GroupByDropdown';
import { getFieldIcon } from '../../../utils/get-field-icon';
import { GroupButtonDropdownService } from '../group-button-dropdown.service';

@Component({
  selector: 'pp-group-or-sort-button-column',
  templateUrl: './group-or-sort-button-column.component.html',
  styleUrls: ['./group-or-sort-button-column.component.scss'],
})
export class GroupOrSortButtonColumnComponent {
  @Input() ppColumn: TColumn;
  @ViewChild('editGroupButton') editGroupButtonElement: ElementRef;
  @Output() ppAction = new EventEmitter<TColumn>();
  @Output() ppUpdate = new EventEmitter<TColumn>();
  @Input() ppType: 'group' | 'sort';

  EIconPath = EIconPath;
  closedWithAnchor = false;
  dropdown = GET_DROPDOWN('SITE_GROUP_BY');
  iconSrc: EIconPath;

  constructor(
    private groupButtonDropdownService: GroupButtonDropdownService,
    private apiService: ApiService,
  ) {}

  ngOnChanges(): void {
    if (this.ppColumn) {
      this.iconSrc = getFieldIcon(this.ppColumn);
    } else {
      this.iconSrc = null;
    }
  }

  showDropdown(): void {
    const dropdownName = 'SITE_GROUP_BY';
    this.dropdown = GET_DROPDOWN(dropdownName);

    if (!this.dropdown && !this.closedWithAnchor) {
      const dropdownParams: TCustomDropdownParams = {
        name: dropdownName,
        anchorElement: this.editGroupButtonElement.nativeElement,
        width: EDropdownWidth.REM_21,
        groupButtonDropdownService: this.groupButtonDropdownService,
        apiService: this.apiService,
        offset: [0, 8],
        onClose: (_anchor) => {
          this.closedWithAnchor = _anchor;
          this.dropdown = null;
        },
        onSelect: (item) => {
          if (this.ppColumn) {
            this.ppUpdate.emit(item);
          } else {
            this.ppAction.emit(item);
          }
        },
        type: this.ppType,
      };

      this.dropdown = new GroupByDropdown(dropdownParams);
    } else {
      this.closedWithAnchor = false;
    }
  }
}
