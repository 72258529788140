import { createElement } from 'src/app/core/helpers/dom';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';
import { TCustomFieldResponse } from 'src/app/project/view-models/custom-field-response-model';

export function createPercentageElement(
  _index: number,
  _column: TColumn,
  _customField: TCustomFieldResponse<string>,
): HTMLElement {
  let value = _customField?.value && _customField?.value !== null ? _customField.value : '';

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = '';
  }

  const formattedValue = value && value !== null ? value + ' %' : '';

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(formattedValue ? formattedValue : '')],
    }),
  });
}
