import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TPointDetails } from '@project/view-models';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { ReminderPointModalComponent } from 'src/app/project/modules/reminders/reminder-point-modal/reminder-point-modal.component';
import { TReminderPointModalData } from 'src/app/project/modules/reminders/reminder-point-modal/reminder-point-modal.model';
import { ReminderSettingsDropdownComponent } from 'src/app/project/modules/reminders/reminders/reminder-settings-dropdown/reminder-settings-dropdown.component';
import { TReminderSettingsDropdownData } from 'src/app/project/modules/reminders/reminders/reminder-settings-dropdown/reminder-settings-dropdown.model';
import { RemindersUtilityService } from 'src/app/project/modules/reminders/reminders/reminders-utility.service';
import { TSiteData } from 'src/app/project/modules/site/site-data.service';
import { GET_TIMELINE } from 'src/app/project/modules/site/site-timeline/timeline.ui.store';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { PointsDetailsService } from '../points-details.service';
import { TPoint, TPointsByWorkspace } from '../points.model';
import { PointModalHeaderService } from './point-modal-header.service';
import { PointsUpdateService } from '../points-update.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { UpdatePointFlag } from '../points.actions';
import { PointActivityService } from '../point-modal/point-timeline/point-activity.service';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { canEditFlag } from '../../share/share-utils/share-permissions';
import { UserService } from '../../user/user.service';
import { GET_SHARE } from '../../share/shares.store';
import { SitePointFilterService } from '../../filters/site-point-filter.service';

@Component({
  selector: 'pp-point-modal-header',
  templateUrl: './point-modal-header.component.html',
  styleUrls: [
    './point-modal-header.component.scss',
    '../point-full-modal/point-full-modal.component.scss',
  ],
})
export class PointModalHeaderComponent implements OnInit {
  @Input() ppFull: boolean;
  @Input() ppPoint: TPoint;
  @Input() ppAttachmentUploading: boolean;
  @Input() ppPointDetails: TPointDetails;
  @Output() ppPointDetailsChange = new EventEmitter<TPointDetails>();
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Output() ppHide = new EventEmitter<void>();
  @Output() ppUpdate = new EventEmitter<void>();
  @Input() ppSite: TSiteData;
  @Input() ppNew: boolean;
  @Input() ppDetailsFetched: boolean;

  workspaces$: Observable<TWorkspacesById>;
  private readonly destroy$ = new Subject<void>();
  remindersEnabled = false;
  private points$: Observable<TPointsByWorkspace>;
  activePlan = GET_ACTIVE_PLAN();
  isReminders = false;
  isTimeline = false;
  isOverview = false;
  hasWorkspaces = false;
  nextPointExists = false;
  offline$: Observable<boolean>;
  offline: boolean;
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  optionsDropdownVisible = false;
  routerPath = this.router.url;
  EIconPath = EIconPath;
  processing: boolean = false;
  canEditFlag: boolean = false;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      points: TPointsByWorkspace;
      offline: boolean;
    }>,
    private accountService: AccountService,
    private remindersUtilityService: RemindersUtilityService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private dropdownService: DropdownService,
    private pointsDetailsService: PointsDetailsService,
    private pointModalHeaderService: PointModalHeaderService,
    private customTableService: CustomTableService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private userService: UserService,
    private sitePointFilterService: SitePointFilterService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit(): void {
    this.workspaces$
      .pipe(takeUntil(this.destroy$))
      .subscribe((workspaces) => this.workspacesHandler(workspaces));

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => this.pointsHandler(points));

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.paramsHandler());

    this.offline$
      .pipe(takeUntil(this.destroy$))
      .subscribe((offline) => this.offlineHandler(offline));

    this.checkIfCanEditFlag();
  }

  offlineHandler(offline: boolean): void {
    this.offline = offline;
  }

  workspacesHandler(workspaces: TWorkspacesById): void {
    this.hasWorkspaces = !!workspaces;

    if (this.hasWorkspaces) {
      const workspace = workspaces[this.ppWorkspaceId];

      if (workspace) {
        const workspaceAccount = this.accountService
          .getAccounts()
          ?.find((account) => account.accountId === workspace.accountId);

        this.remindersEnabled = workspaceAccount?.accountFeatures?.reminders;
      }
    }
  }

  pointsHandler(points: TPointsByWorkspace): void {
    if (Object.keys(points).length > 0) {
      if (this.ppWorkspaceId && points[this.ppWorkspaceId]) {
        if (this.isReminders) {
          this.nextPointExists =
            this.remindersUtilityService.getIdOfNextReminderPoint(this.ppPointId) !==
            this.ppPointId;
        } else if (this.isTimeline) {
          const timeline = GET_TIMELINE();

          this.nextPointExists = timeline?.points.length > 1;
        } else {
          const table = this.customTableService.getTable();

          this.nextPointExists = table?.points.length > 1;
        }
      }
    }
  }

  prevPoint(): void {
    this.pointModalHeaderService.prevPoint({
      attachmentUploading: this.ppAttachmentUploading,
      point: this.ppPoint,
      _id: this.ppPointId,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      isFull: this.ppFull,
    });
  }

  paramsHandler(): void {
    this.isReminders = this.router.url.search(EWorkspaces.REMINDERS) !== -1;
    this.isTimeline = this.router.url.search(EWorkspaces.TIMELINE) !== -1;
    this.isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;

    this.routerPath = this.router.url;
  }

  nextPoint(): void {
    this.pointModalHeaderService.nextPoint({
      attachmentUploading: this.ppAttachmentUploading,
      point: this.ppPoint,
      _id: this.ppPointId,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      isFull: this.ppFull,
    });
  }

  checkIfCanEditFlag(): void {
    const share = GET_SHARE(this.ppWorkspaceId);
    this.canEditFlag = canEditFlag(share.shareOption, this.userService.getUser());
  }

  toggleFlag(): void {
    if (this.processing === true || !this.canEditFlag) {
      return;
    }

    this.processing = true;

    let flagged = !this.ppPoint.flagged;
    this.pointsUpdateService
      .updatePointField(this.ppPointId, { flagged })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_flag_update');

          this.store.dispatch(
            new UpdatePointFlag({
              workspaceId: this.ppWorkspaceId,
              _id: this.ppPointId,
              flagged,
            }),
          );

          this.customTableService.updatePoint({
            _id: this.ppPointId,
            field: EBasicField.FLAGGED,
            newValue: flagged,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.promptService.showSuccess(promptText);
          this.sitePointFilterService.filterPoints(true);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, this.ppPointId);

          this.ppUpdate.emit();
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  toggleReminder(buttonId: string): void {
    if (this.ppPointDetails) {
      if (!this.ppPointDetails.reminders[0]) {
        const modalData: TReminderPointModalData = {
          _id: this.ppPointId,
        };

        this.modalService.setData(modalData);

        this.modalService.showModal(ReminderPointModalComponent);
      } else {
        this.openRemindersDropdown(buttonId);
      }
    }
  }

  openRemindersDropdown(buttonId: string): void {
    if (!this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.dropdownService.hideDropdown();
      } else {
        const dropdownData: TReminderSettingsDropdownData = {
          _id: this.ppPointId,
          date: this.ppPointDetails.reminders[0].timestampEpochMillis,
          reminderId: this.ppPointDetails.reminders[0].reminderId,
          pointName: this.ppPoint.title,
          note: this.ppPointDetails.reminders[0].note,
        };

        this.dropdownService.setData(dropdownData);

        this.optionsDropdownVisible = true;

        this.dropdownService.showDropdown(buttonId, ReminderSettingsDropdownComponent, {
          onClose: () => (this.optionsDropdownVisible = false),
        });
      }
    }
  }

  toggleSubscription(): void {
    this.pointsDetailsService
      .togglePointSubscription(this.ppPointId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.ppPointDetails = this.pointsDetailsService.getPointDetails(this.ppPointId);

          this.ppPointDetailsChange.emit(this.ppPointDetails);
        }),
      )
      .subscribe();
  }

  expandModal(): void {
    if (!this.ppAttachmentUploading && !this.activePlan.active) {
      this.pointModalHeaderService.expandModal({
        attachmentUploading: this.ppAttachmentUploading,
        _id: this.ppPointId,
        workspaceId: this.ppWorkspaceId,
        isReminders: this.isReminders,
        isTimeline: this.isTimeline,
        isOverview: this.isOverview,
      });
    }
  }

  openInSite(): void {
    const URL = ['/site', this.ppPoint.workspaceRef.id, 'point', this.ppPoint._id];

    this.router.navigate(URL);
  }

  hide(): void {
    this.ppHide.emit();
  }

  toggleModal(): void {
    if (this.ppFull) {
      this.collapseModal();
    } else {
      this.expandModal();
    }
  }

  collapseModal(): void {
    this.pointModalHeaderService.collapseModal({
      attachmentUploading: this.ppAttachmentUploading,
      isReminders: this.isReminders,
      isTimeline: this.isTimeline,
      isOverview: this.isOverview,
      point: this.ppPoint,
    });
  }
}
