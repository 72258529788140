import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TAllUsers } from '../../view-models/user.view-model';
import { TPoint } from '../points/points.model';
import { GET_WORKSPACES } from '../workspace/workspace.store';

export function checkSequenceNumberKeyword(_point: TPoint, _keyword: string): boolean {
  const id = _point.sequenceNumber.toString();

  return id.indexOf(_keyword) > -1;
}

export function checkTitleKeyword(_point: TPoint, _keyword: string): boolean {
  const title = _point.title.toLowerCase();

  return title.indexOf(_keyword.toLowerCase()) > -1;
}

export function checkCreatedByForKeyword(
  _point: TPoint,
  _keyword: string,
  allUsers: TAllUsers,
): boolean {
  const userName = allUsers[_point.header.createdBy?.id]
    ? allUsers[_point.header.createdBy?.id].userName
    : _point.header.createdBy?.caption;

  if (userName) {
    const createdBy = userName.toLowerCase();

    if (_keyword.length > 0 && _point) {
      if (createdBy.indexOf(_keyword.toLowerCase()) > -1) {
        return true;
      }
    }
  }

  return false;
}

export function checkCreatedKeyword(_point: TPoint, _keyword: string): boolean {
  const preferences = GET_PREFERENCES();

  if (_point.header?.createdEpochMillis) {
    const created = transformDate(_point.header.createdEpochMillis, '', preferences.dateFormat);

    if (_keyword.length > 0 && _point) {
      if (created.toLowerCase().indexOf(_keyword.toLowerCase()) > -1) {
        return true;
      }
    }
  }

  return false;
}

export function checkUpdatedKeyword(_point: TPoint, _keyword: string): boolean {
  const preferences = GET_PREFERENCES();

  if (_point.header?.updatedEpochMillis) {
    const updated = transformDate(_point.header.updatedEpochMillis, '', preferences.dateFormat);

    if (_keyword.length > 0 && _point) {
      if (updated.toLowerCase().indexOf(_keyword.toLowerCase()) > -1) {
        return true;
      }
    }
  }

  return false;
}

export function checkDescriptionKeyword(_point: TPoint, _keyword: string): boolean {
  const description = _point.description;

  return description && description.toLowerCase().indexOf(_keyword.toLowerCase()) > -1;
}

export function checkTagsForKeyword(_point: TPoint, _keyword: string): boolean {
  const tags = _point.tags;

  if (_keyword.length > 0) {
    for (let index = 0; index < tags.length; index++) {
      const tag = tags[index];

      if (tag.toLowerCase().indexOf(_keyword.toLowerCase()) > -1) {
        return true;
      }
    }
  }

  return false;
}

export function checkAccountForKeyword(_point: TPoint, _keyword: string): boolean {
  const workspaces = GET_WORKSPACES();
  const accountName = workspaces[_point.workspaceRef.id].accountName;

  return accountName && accountName.toLowerCase().indexOf(_keyword.toLowerCase()) > -1;
}

export function checkWorkspaceForKeyword(_point: TPoint, _keyword: string): boolean {
  const workspaces = GET_WORKSPACES();
  const workspaceName = workspaces[_point.workspaceRef.id].siteName;

  return workspaceName && workspaceName.toLowerCase().indexOf(_keyword.toLowerCase()) > -1;
}
