import dayjs from 'dayjs';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TPoint } from '../../points/points.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';

export function fillUpdatedValue(point: TPoint): string {
  if (point.header && dayjs(point.header.updatedEpochMillis).isValid()) {
    const preferences = GET_PREFERENCES();

    return transformDate(dayjs(point.header.updatedEpochMillis), '', preferences.dateFormat);
  }

  return '';
}
