import { Injectable } from '@angular/core';
import {
  API_account_all_workspaces,
  API_account_all_workspaces_simple,
  API_account_folder,
  API_account_folders,
  API_account_simulated_usage,
  API_account_subscription,
  API_account_update,
  API_account_usage,
  API_account_workspace_info,
} from '@core/api/paths';
// FIXME Find out why `ng test` cannot recognize `compilerOptions.paths` aliases
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/http/api.service';
import { TAccountUsageResponse } from 'src/app/project/modules/account/account-usage/account-usage.consts';
import {
  TAccount,
  TAccountSubscription,
  TAccountSubscriptionUpdate,
  TSimulatedUsageBody,
} from '../../../modules/account/account.model';
import {
  AccountViewModelFactory,
  TAccountResponse,
  TAccountSummaryResponse,
} from '../../../view-models/account-response.model';
import {
  TAccountSimpleResponse,
  TRequestFolder,
} from '../../../view-models/account-simple-response.model';

@Injectable({
  providedIn: 'root',
})
export class AccountApiProviderService {
  constructor(private apiService: ApiService) {}

  editFolder(
    accountId: string,
    folderId: string,
    body: TRequestFolder,
  ): Observable<TAccountSimpleResponse> {
    const url = API_account_folder(accountId, folderId);

    return this.apiService.post<TAccountSimpleResponse>(url, body);
  }

  deleteFolder(accountId: string, folderId: string): Observable<TAccountSimpleResponse> {
    const url = API_account_folder(accountId, folderId);

    return this.apiService.delete<TAccountSimpleResponse>(url);
  }

  addNewFolder(accountId: string, body: TRequestFolder): Observable<TAccountSimpleResponse> {
    const url = API_account_folders(accountId);

    return this.apiService.put<TAccountSimpleResponse>(url, body);
  }

  fetchAccount(accountId: string): Observable<TAccount> {
    const url = API_account_workspace_info(accountId);

    return this.apiService
      .get<TAccountResponse>(url)
      .pipe(map((dto) => AccountViewModelFactory.createFromDTO(dto)));
  }

  fetchAccountUsage(accountId: string): Observable<TAccountUsageResponse> {
    const url = API_account_usage(accountId);

    return this.apiService.get<TAccountUsageResponse>(url);
  }

  fetchAccounts(
    refetch: boolean,
    showUnsubscribed: boolean,
    showHidden?: boolean,
  ): Observable<TAccountSummaryResponse[]> {
    const url = API_account_all_workspaces(showHidden, showUnsubscribed);

    return this.apiService.get<TAccountSummaryResponse[]>(url);
  }

  fetchAccountsSimple(
    refetch: boolean,
    showUnsubscribed: boolean,
  ): Observable<TAccountSimpleResponse[]> {
    const url = API_account_all_workspaces_simple(false, showUnsubscribed);

    return this.apiService.get<TAccountSimpleResponse[]>(url);
  }

  fetchAccountsSimpleHidden(showUnsubscribed: boolean): Observable<TAccountSimpleResponse[]> {
    const url = API_account_all_workspaces_simple(true, showUnsubscribed);

    return this.apiService.get<TAccountSimpleResponse[]>(url);
  }

  updateAccount(accountId: string, body: Partial<TAccountResponse>): Observable<TAccountResponse> {
    const url = API_account_update(accountId);

    return this.apiService.put<TAccountResponse>(url, body);
  }

  fetchAccountSubscription(accountId: string): Observable<TAccountSubscription> {
    const url = API_account_subscription(accountId);

    return this.apiService.get<TAccountSubscription>(url);
  }

  updateAccountSubscription(
    body: TAccountSubscriptionUpdate,
    accountId: string,
  ): Observable<TAccountSubscription> {
    const url = API_account_subscription(accountId);

    return this.apiService.post<TAccountSubscription>(url, body);
  }

  fetchSimulatedUsage(
    accountId: string,
    body: TSimulatedUsageBody,
  ): Observable<TAccountSubscription> {
    const url = API_account_simulated_usage(accountId);

    return this.apiService.post<TAccountSubscription>(url, body);
  }
}
