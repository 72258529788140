import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { NotificationFilterSitesComponent } from '../notification-filter-sites/notification-filter-sites.component';

import { isArray } from 'lodash';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TNotificationFilterData } from '../notification-filter-sites/notification-filters.model';
import { NotificationOptionsDropdownComponent } from '../notification-options-dropdown/notification-options-dropdown.component';
import {
  TNotificationDropdownOption,
  TNotificationsOptionsDropdownData,
} from '../notification-options-dropdown/notifications-options-dropdown.model';
import { TNotificationFilter } from '../notification.model';
import { NotificationsService } from '../notifications.service';
import { GET_NOTIFICATIONS, notificationsChange$ } from '../notifications.store';

@Component({
  selector: 'pp-notification-top-toolbar',
  templateUrl: './notification-top-toolbar.component.html',
  styleUrls: ['./notification-top-toolbar.component.scss'],
})
export class NotificationTopToolbarComponent implements OnInit, OnDestroy {
  @Input() ppAllNotificationsRead = false;
  @Output() ppMarkAllAsRead = new EventEmitter<void>();
  @Output() ppFilter = new EventEmitter<TNotificationFilter>();

  private readonly destroy$ = new Subject<void>();

  filterHovered = false;
  markAllHovered = false;
  noNotifications = false;

  selectedFilters: TNotificationFilter = this.notificationsService.getFilters();
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  accounts$: Observable<TAccount[]>;
  accounts: TAccount[] = [];
  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  EIconPath = EIconPath;
  dropdownVisible = false;

  constructor(
    private store: Store<{
      accounts: TAccount[];
      workspaces: TWorkspacesById;
    }>,
    private dropdownService: DropdownService,
    private notificationsService: NotificationsService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.checkExistingNotifications();

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (isArray(accounts)) {
        this.accounts = accounts;
      }
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });

    notificationsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkExistingNotifications();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  showNotificationOptionsDropdown(): void {
    this.dropdownService.setData<TNotificationsOptionsDropdownData>({});

    this.dropdownVisible = true;

    this.dropdownService.showDropdown(
      'notification__options',
      NotificationOptionsDropdownComponent,
      {
        callback: (option) => this.handleDropdownCallback(option),
        suppressScrollbar: true,
        onClose: () => {
          this.dropdownVisible = false;
        },
      },
    );
  }

  private markAllAsRead(): void {
    this.ppMarkAllAsRead.emit();
  }

  private showFiltersDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === 'notification__options') {
      this.dropdownService.hideDropdown();
    }

    this.dropdownVisible = true;

    this.dropdownService.setData<TNotificationFilterData>({
      accounts: this.accounts,
      workspaces: this.workspaces,
      selectedFilters: this.selectedFilters,
    });

    this.dropdownService.showDropdown('notification__options', NotificationFilterSitesComponent, {
      callback: (filters: TNotificationFilter) => this.setFilters(filters),
      onClose: () => (this.dropdownVisible = false),
      suppressScrollbar: true,
    });
  }

  private setFilters(filters: TNotificationFilter): void {
    this.selectedFilters = filters;

    this.notificationsService.setFilters(filters);

    this.ppFilter.emit(filters);
  }

  private checkExistingNotifications(): void {
    const notifications = GET_NOTIFICATIONS();

    let noNotifications = true;

    Object.keys(notifications).forEach((mode) => {
      if (notifications[mode].length > 0) {
        noNotifications = false;
      }
    });

    this.noNotifications = noNotifications;
  }

  handleDropdownCallback(option: TNotificationDropdownOption): void {
    switch (option) {
      case 'clear_all_notifications':
        this.notificationsService.removeNotifications();
        break;
      case 'mark_all_as_read':
        this.markAllAsRead();
        break;
      case 'filter':
        this.showFiltersDropdown();
        break;
    }
  }
}
