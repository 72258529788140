import { createElement } from 'src/app/core/helpers/dom';
import { TColumn } from '../../../columns/column.model';
import { createHeaderTooltip } from './create-header-tooltip';
import { createSortingIndicatorElement } from './create-sorting-indicator-element';

export function createHeaderCustomFieldCellInnerElement(
  child: HTMLElement | string,
  column: TColumn,
): HTMLElement {
  const nameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: column?.sortOrder
        ? 'site-table__sum__title--sorted site-table__sum__title'
        : 'site-table__sum__title',
      id: 'sortingElement',
    },
    children: [child],
  });

  const sortingElement = column?.sortOrder
    ? createSortingIndicatorElement(column.sortIndex, column.sortOrder)
    : '';

  const cellNameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'table__head__cell--inner',
    },
    children: [nameElement, sortingElement],
  });

  const cellInnerElement = createElement<HTMLElement>('div', {
    attrs: {
      class:
        'table__cell__inner__namedColumn table__cell__inner table__cell__inner--noPadding table__head__cell',
    },
    children: [cellNameElement],
  });

  createHeaderTooltip(column.name, cellNameElement);

  return cellInnerElement;
}
